export const TRANSLATIONS_EN = {
  'GLOBAL.ENGLISH': 'English',
  'GLOBAL.GERMAN': 'German',
  'GLOBAL.FRENCH': 'French',
  'GLOBAL.ITALIAN': 'Italian',
  'GLOBAL.STEP_X_OF_Y': 'Step {{current}} of {{total}}',
  'GLOBAL.PUSH_NOTIFICATION': 'Push notification',
  'GLOBAL.MESSAGE': 'Message',
  'GLOBAL.PHONE': 'Phone number',
  'GLOBAL.TITLE': 'Title',
  'GLOBAL.EMAIL': 'Email',
  'GLOBAL.PASSWORD': 'Password',
  'GLOBAL.SAVE': 'Save',
  'GLOBAL.DESCRIPTION': 'Description',
  'GLOBAL.PERIOD': 'Period',
  'GLOBAL.ERROR': 'Error',
  'GLOBAL.ERROR.EMAIL_EXISTS': 'The entered email address is already in use.',
  'GLOBAL.TRANSLATION_MISSING': 'Translation missing',
  'GLOBAL.SUCCESS': 'Success',
  'GLOBAL.LOADING': 'Loading...',
  'GLOBAL.LOADING_LOGIN': 'Login...',
  'GLOBAL.MAX_100_CHARS': 'Maximum 100 characters allowed.',
  'GLOBAL.YES': 'Yes',
  'GLOBAL.NO': 'No',
  'GLOBAL.ACCEPT': 'Accept',
  'GLOBAL.LATER': 'Later',
  'GLOBAL.ABSTAIN': 'Abstain',
  'GLOBAL.CANCEL': 'Cancel',
  'GLOBAL.OK': 'OK',
  'GLOBAL.UPDATE': 'Update',
  'GLOBAL.UPDATING': 'Updating...',
  'GLOBAL.PROPERTY': 'Property',
  'GLOBAL.MANDATE': 'Property',
  'GLOBAL.MANDATES': 'Properties',
  'GLOBAL.EXTERNAL': 'External',
  'GLOBAL.NAME': 'Name',
  'GLOBAL.FIRSTNAME': 'First name',
  'GLOBAL.LASTNAME': 'Last name',
  'GLOBAL.NO_ENTRIES': 'No entries available.',
  'GLOBAL.NO_MORE_ENTRIES': 'No more entries available.',
  'GLOBAL.PRIVATE': 'Private',
  'GLOBAL.PRIVATE_E': 'Private',
  'GLOBAL.ATTACHMENTS': 'Attachments',
  'GLOBAL.UNDO': 'Undo',
  'GLOBAL.FROM': 'From',
  'GLOBAL.NEXT': 'Next',
  'GLOBAL.EMAIL_PLACEHOLDER': 'Please enter your email...',
  'GLOBAL.EMAIL_REQUIRED': 'Email is required.',
  'GLOBAL.EMAIL_INVALID': 'Enter a valid email.',
  'GLOBAL.FIELD_REQUIRED': 'Field is required.',
  'GLOBAL.FIELD_MAX_LENGTH': 'The text can be a maximum of {{length}} characters long.',
  'GLOBAL.FIELD_MIN_NUMBER': 'Minimum: {{number}}',
  'GLOBAL.FIELD_MAX_NUMBER': 'Maximum: {{number}}',
  'GLOBAL.ONLY_NUMBER': 'Please enter a number.',
  'GLOBAL.ONLY_NUMBERS': 'Please only enter numbers.',
  'GLOBAL.RESET': 'Reset',
  'GLOBAL.STREET': 'Street',
  'GLOBAL.ZIP': 'ZIP',
  'GLOBAL.CITY': 'City',
  'GLOBAL.OPTIONAL': '(optional)',
  'GLOBAL.CHOOSE_NOW': 'Select now',
  'GLOBAL.CONTACTDATA': 'Contact details',
  'GLOBAL.VISIBILITY': 'Visibility',
  'GLOBAL.COMMENT': 'Comment',
  'GLOBAL.PUBLIC': 'Public',
  'GLOBAL.SENDER': 'Sender',
  'GLOBAL.LOCATION': 'Location',
  'GLOBAL.ADMINISTRATION': 'Administration',
  'GLOBAL.LINK': 'Link',
  'GLOBAL.INTRODUCTION': 'Introduction',
  'GLOBAL.IN': 'In',
  'GLOBAL.QUESTIONS': 'Questions?',
  'GLOBAL.CODE': 'Code',
  'GLOBAL.TO': 'To',
  'GLOBAL.FOR': 'For',
  'GLOBAL.PERSONALLY_FOR_YOU': 'Personally for you',
  'GLOBAL.FROM_THE_IMMOAPP': 'from the ImmoApp',
  'GLOBAL.START': 'Start',
  'GLOBAL.HERE': 'Here',
  'GLOBAL.PARTIES': 'Parties',
  'GLOBAL.THE': 'the',
  'GLOBAL.RESTRICTED.COMMENT': 'You currently do not have permission to submit comments. If you have any questions, please contact your administration.',
  'GLOBAL.RESTRICTED.REPORT': 'You currently do not have authorization to submit reports. If you have any questions, please contact your administration.',

  'ERROR.UNEXPECTED_RETRY_WITH_EXPLANATION': 'An unexpected error occured: {{error}}. Please try again.',
  'ERROR.UNEXPECTED_RETRY_SIMPLE': 'An unexpected error occured. Please try again.',

  'DATE.SHORT': 'LLL d',
  'DATE.LONG': "MM/dd/YYYY 'at' hh:mm a",
  'DATE.FULL': "MMMM dd, YYYY 'at' hh:mm a",
  'DATE.HOUR': 'hh a',

  'ACCOUNT_ROLE.TENANT_RESTRICTED': 'Tenant Restricted',
  'ACCOUNT_ROLE.TENANT': 'Tenant',
  'ACCOUNT_ROLE.OWNER': 'Owner',
  'ACCOUNT_ROLE.JANITOR': 'Janitor',
  'ACCOUNT_ROLE.MANAGER': 'Manager',
  'ACCOUNT_ROLE.ADMIN': 'Admin',

  'APP.WEB_PUSH_NA.TEXT': 'Unfortunately, your browser does not support push notifications. In order to always be well informed about all the latest news, we recommend you to download the iOS or Android app.',
  'APP.WEB_PUSH_NA.BUTTON.HIDE': 'Hide',
  'APP.UPDATE.AVAILABLE': 'App update available',
  'APP.UPDATE.UPDATE_NOW_QUESTION': 'Would you like to update to the newest version now?',
  'APP.UPDATE.LOADING': 'Loading new app version...',
  'APP.UPDATE.INSTALLING': 'Installing new app version...',
  'APP.NO_INTERNET_CONNECTION.TEXT': 'No internet connection.',
  'APP.BACKBUTTON_EXIT_WARNING': 'Press the back button again to exit the ImmoApp.',
  'APP.UPDATE.NEEDED.HEADER': 'Update needed',
  'APP.UPDATE.NEEDED.MESSAGE': 'An important security update is available. To continue using the ImmoApp, please update to the latest version.',
  'APP.UPDATE.NEEDED.BUTTON': 'Update now',

  'TABS.HOME': 'Home',
  'TABS.DOCUMENTS': 'Documents',
  'TABS.VOTES': 'Votes',
  'TABS.PROFILE': 'Profile',

  'REPAIR.STATUS_0': 'Pending',
  'REPAIR.STATUS_1': 'In progress',
  'REPAIR.STATUS_2': 'Completed',

  'AUTHENTICATION.LOGIN': 'Login',
  'AUTHENTICATION.FORGOT_PASSWORD': 'Forgot Password?',
  'AUTHENTICATION.REGISTER': 'Register',
  'AUTHENTICATION.NO_ACCOUNT': 'No account yet?',
  'AUTHENTICATION.INSTRUCTIONS': 'Please fill in account info.',
  'AUTHENTICATION.PASSWORD_PLACEHOLDER': 'Please enter your password...',
  'AUTHENTICATION.PASSWORD_REQUIRED': 'Password is required.',
  'AUTHENTICATION.PASSWORD_LENGTH': 'Password must be at least 6 characters long.',
  'AUTHENTICATION.PASSWORDS_NOT_THE_SAME': 'The two passwords are not the same.',
  'AUTHENTICATION.WRONG_PASSWORD': 'The password you entered is incorrect. Please try again.',
  'AUTHENTICATION.TOO_MANY_REQUEST': 'Too many failed login attempts. Access to your account has been blocked for a short time. Please try again later.',
  'AUTHENTICATION.EMAIL_NOT_VERIFIED': 'Your email is not yet verified. You have received the instructions to your inbox.',
  'AUTHENTICATION.USER_NOT_FOUND': 'We could not find a user with this email address. Please try again.',
  'AUTHENTICATION.NO_RIGHTS': 'Your user has no access rights. If you suspect that this is an error, please contact your administration.',
  'AUTHENTICATION.SELECT_SPACE_FIRST': 'Please select an administration first.',
  'AUTHENTICATION.SIGN_IN_FAILED': 'Login failed.',
  'AUTHENTICATION.RESET_PASSWORD_FAILED': 'Password reset failed.',
  'AUTHENTICATION.NO_SPACES_AVAILABLE': 'The email address you entered is not assigned to any administration.',
  'AUTHENTICATION.OTHER_ERROR': 'Login failed. Please try again.',
  'AUTHENTICATION.SELECT_ADMINISTRATION': 'Select administration',
  'AUTHENTICATION.UPDATE_PASSWORD_ALERT.HEADER': 'ImmoApp Version 2',
  'AUTHENTICATION.UPDATE_PASSWORD_ALERT.MESSAGE': 'The ImmoApp has been updated. For security reasons, we ask you to set a new password.',
  'AUTHENTICATION.UPDATE_PASSWORD_ALERT.NEW_PASSWORD': 'New password...',
  'AUTHENTICATION.UPDATE_PASSWORD_ALERT.NEW_PASSWORD_REPEAT': 'Repeat new password...',

  'AUTHENTICATION.SIGNUP.STEP_1_TITLE': 'Information',
  'AUTHENTICATION.SIGNUP.STEP_2_TITLE': 'Email & access code',
  'AUTHENTICATION.SIGNUP.STEP_3_TITLE': 'Name & password',
  'AUTHENTICATION.SIGNUP.STEP_1_DESCRIPTION': 'If you have already received your personal access details from your administration, you can log in directly on the start screen. Registration is not required in this case.',
  'AUTHENTICATION.SIGNUP.STEP_2_DESCRIPTION': 'Please enter your email and the 6-digit access code that you received from your administration.',
  'AUTHENTICATION.SIGNUP.STEP_3_DESCRIPTION': 'Please enter your name and a password of your choice.',
  'AUTHENTICATION.SIGNUP.STEP_4_DESCRIPTION_A': 'You have successfully logged in. Thank you for your registration.',
  'AUTHENTICATION.SIGNUP.STEP_4_DESCRIPTION_B': 'Please confirm your email address. A verification email has been sent to you for this.',
  'AUTHENTICATION.SIGNUP.STEP_4_DESCRIPTION_C': 'Note: The administration will first check your account before you are given access to the ImmoApp. You will be informed of the account activation by email.',
  'AUTHENTICATION.SIGNUP.FINISH': 'Finish',
  'AUTHENTICATION.SIGNUP.TERMS': 'I hereby agree to the data protection regulations.',
  'AUTHENTICATION.SIGNUP.ERROR.INVITE_CODE_REQUIRED': 'Access code is required.',
  'AUTHENTICATION.SIGNUP.ERROR.FIRST_NAME_REQUIRED': 'First name is required.',
  'AUTHENTICATION.SIGNUP.ERROR.LAST_NAME_REQUIRED': 'Last name is required.',
  'AUTHENTICATION.SIGNUP.ERROR.INVITE_CODE_INVALID': 'The entered access code is not valid. Please try again or contact your administration.',
  'AUTHENTICATION.SIGNUP.ERROR.DOMAIN_INVALID': 'The domain of your email address ({{domain}}) could not be verified. Please watch out for typos and try again. If the error persists, please contact your administration.',
  'AUTHENTICATION.SIGNUP.INVITE_CODE_PLACEHOLDER': 'Access code',
  'AUTHENTICATION.INVITE_CODE_LENGTH': 'The access code must be 6 characters long.',

  'AUTHENTICATION.RECOVER_PASSWORD.TITLE': 'Recover your password',
  'AUTHENTICATION.RECOVER_PASSWORD.DESCRIPTION': 'Please enter your email address and we will send you an email to reset your password.',
  'AUTHENTICATION.RECOVER_PASSWORD.BUTTON': 'Reset password',
  'AUTHENTICATION.RECOVER_PASSWORD.SUCCESS_MESSAGE': 'An email with instructions was successfully sent to you.',

  'PROFILE.TITLE': 'Profile',
  'PROFILE.TENANT.TITLE': 'Tenants',
  'PROFILE.TENANT.TITLE_LONG': 'Tenant administration',
  'PROFILE.TENANT.ADMINISTER_TITLE': 'Tenants administration',
  'PROFILE.TENANT.ADMINISTER_DESCRIPTION': 'tenants',
  'PROFILE.TENANT.INVITED_ON': 'Invited on',
  'PROFILE.TENANT.NO_TENANTS_AVAILABLE': 'You are currently not managing any tenants.',
  'PROFILE.TENANT.ADD.TITLE': 'Add tenants',
  'PROFILE.TENANT.ADD.MANDATE.PLACEHOLDER': 'Please select a property',
  'PROFILE.TENANT.ADD.FIRSTNAME': 'First name',
  'PROFILE.TENANT.ADD.FIRSTNAME.PLACEHOLDER': 'First name',
  'PROFILE.TENANT.ADD.LASTNAME': 'Last name',
  'PROFILE.TENANT.ADD.LASTNAME.PLACEHOLDER': 'Last name',
  'PROFILE.TENANT.ADD.EMAIL_REQUIRED': 'Email is required.',
  'PROFILE.TENANT.ADD.EMAIL_INVALID': 'Enter a valid email.',
  'PROFILE.TENANT.ADD.MANDATE_REQUIRED': 'Property is required.',
  'PROFILE.TENANT.ADD.FIRSTNAME_REQUIRED': 'First name is required.',
  'PROFILE.TENANT.ADD.LASTNAME_REQUIRED': 'Last name is required.',
  'PROFILE.TENANT.ADD.LOADING_MESSAGE': 'Tenant is being added...',
  'PROFILE.TENANT.ADD.SUCCESS': 'Tenant was successfully added.',
  'PROFILE.TENANT.ADD.ERROR.EXISTING_EMAIL': 'The email address you entered is already assigned to another account.',

  'PROFILE.TENANT.EDIT.TITLE': 'Edit Tenant',
  'PROFILE.TENANT.EDIT.MESSAGE': 'Here you can send your tenant the access data again and end the tenancy (and thus access to the ImmoApp).',
  'PROFILE.TENANT.EDIT.TERMINATE': 'Terminate the tenancy',
  'PROFILE.TENANT.EDIT.TERMINATE.LOADING_MESSAGE': 'Tenancy is being terminated...',
  'PROFILE.TENANT.EDIT.TERMINATE.SUCCESS': 'Tenant has been successfully removed and no longer has access to the ImmoApp.',
  'PROFILE.TENANT.EDIT.RESEND_ACCESS': 'Resend access',
  'PROFILE.TENANT.EDIT.RESEND_ACCESS.LOADING_MESSAGE': 'Access is being sent...',
  'PROFILE.TENANT.EDIT.RESEND_ACCESS.SUCCESS': "Access data was successfully sent to the tenant's email address.",
  'PROFILE.INFO.TITLE': 'Info',
  'PROFILE.INFO.YOUR_DATA_TITLE': 'Your data',
  'PROFILE.INFO.YOUR_DATA_DESCRIPTION': 'Logged in as',
  'PROFILE.INFO.TERMS_OF_USE_TITLE': 'Terms of Use',
  'PROFILE.INFO.TERMS_OF_USE_DESCRIPTION': 'Rules for the ImmoApp',
  'PROFILE.INFO.INSTRUCTION_TITLE': 'Instruction',
  'PROFILE.INFO.INSTRUCTION_GENERATING': 'Instruction is being generated...',
  'PROFILE.INFO.INSTRUCTION_DESCRIPTION': 'Introduction into the ImmoApp',
  'PROFILE.INFO.CONTACT_TITLE': 'Contact',
  'PROFILE.INFO.CONTACT_DESCRIPTION': 'Directly contact the administration',
  'PROFILE.INFO.LEGAL_TITLE': 'Legal',
  'PROFILE.INFO.LEGAL_DESCRIPTION': 'Privacy policy',
  'PROFILE.SETTINGS.TITLE': 'Settings',
  'PROFILE.SETTINGS.ACCOUNT_TITLE': 'Account settings',
  'PROFILE.SETTINGS.ACCOUNT_DESCRIPTION': 'Email, password & delete',
  'PROFILE.SETTINGS.APP_TITLE': 'App settings',
  'PROFILE.SETTINGS.APP_DESCRIPTION': 'Language & notifications',
  'PROFILE.SETTINGS.DELETE_ACCOUNT': 'Delete account',
  'PROFILE.SETTINGS.DELETE_ACCOUNT.CONFIRM': 'If you confirm with OK, your account will be deleted.',
  'PROFILE.SETTINGS.DELETE_ACCOUNT.SUCCESS': 'Your request has been sent successfully. We will notify you by email in the coming weeks once your account has been deleted.',
  'PROFILE.SETTINGS.LOGOUT': 'Log out',
  'PROFILE.SETTINGS.LOGOUT.CONFIRM': 'Do you want to log out?',
  'PROFILE.SETTINGS.LOGOUT.IN_PROGRESS': 'You are being logged out. Please wait a moment.',
  'PROFILE.UPDATE_PHOTO.TITLE': 'Edit Photo',
  'PROFILE.UPDATE_PHOTO_SUCCESS': 'Your photo was successfully updated.',

  'PROFILE.APP_SETTINGS.SELECT_LANGUAGE': 'Select your language',
  'PROFILE.APP_SETTINGS.PUSH_NOTIFICATIONS': 'Push notifications',
  'PROFILE.APP_SETTINGS.EMAIL_NOTIFICATIONS': 'Email notifications',
  'PROFILE.APP_SETTINGS.LANGUAGE': 'Language',

  'PROFILE.APP_SETTINGS.PUSH_PREFERENCES.DOCUMENT.TITLE': 'Documents',
  'PROFILE.APP_SETTINGS.PUSH_PREFERENCES.DOCUMENT.DESCRIPTION': 'Newly added documents',
  'PROFILE.APP_SETTINGS.PUSH_PREFERENCES.MANDATE.TITLE': 'News',
  'PROFILE.APP_SETTINGS.PUSH_PREFERENCES.MANDATE.DESCRIPTION': 'News and repair reports',
  'PROFILE.APP_SETTINGS.PUSH_PREFERENCES.PINBOARD.TITLE': 'Pinboard',
  'PROFILE.APP_SETTINGS.PUSH_PREFERENCES.PINBOARD.DESCRIPTION': 'News from your neighbours',
  'PROFILE.APP_SETTINGS.PUSH_PREFERENCES.VOTE.TITLE': 'Votes',
  'PROFILE.APP_SETTINGS.PUSH_PREFERENCES.VOTE.DESCRIPTION': 'Updates and reminders',
  'PROFILE.APP_SETTINGS.PUSH_PREFERENCES.STAFF.TITLE': 'Registration (only for employees)',
  'PROFILE.APP_SETTINGS.PUSH_PREFERENCES.STAFF.DESCRIPTION': 'Notification on new registration',

  'PROFILE.APP_SETTINGS.EMAIL_PREFERENCES.MESSAGE.TITLE': 'Messages',
  'PROFILE.APP_SETTINGS.EMAIL_PREFERENCES.MESSAGE.DESCRIPTION': 'Receive email for new messages',
  'PROFILE.APP_SETTINGS.EMAIL_PREFERENCES.REPAIR.TITLE': 'New tickets',
  'PROFILE.APP_SETTINGS.EMAIL_PREFERENCES.REPAIR.DESCRIPTION': 'Receive email for a new ticket',
  'PROFILE.APP_SETTINGS.EMAIL_PREFERENCES.REPAIR_UPDATE.TITLE': 'Ticket updates',
  'PROFILE.APP_SETTINGS.EMAIL_PREFERENCES.REPAIR_UPDATE.DESCRIPTION': 'Receive email for new ticket status',
  'PROFILE.APP_SETTINGS.EMAIL_PREFERENCES.PINBOARD.TITLE': 'Pinboard entries',
  'PROFILE.APP_SETTINGS.EMAIL_PREFERENCES.PINBOARD.DESCRIPTION': 'Receive email for new pinboard entries',
  'PROFILE.APP_SETTINGS.EMAIL_PREFERENCES.COMMENT.TITLE': 'Comments',
  'PROFILE.APP_SETTINGS.EMAIL_PREFERENCES.COMMENT.DESCRIPTION': 'Receive email for new comments',
  'PROFILE.APP_SETTINGS.EMAIL_PREFERENCES.VOTEITEM.TITLE': 'Vote items (only for employees)',
  'PROFILE.APP_SETTINGS.EMAIL_PREFERENCES.VOTEITEM.DESCRIPTION': 'Receive email for new vote item',

  'PROFILE.ACCOUNT_SETTINGS.UPDATE_PASSWORD': 'Password was successfully updated.',
  'PROFILE.ACCOUNT_SETTINGS.UPDATE_EMAIL': 'Email was successfully updated. For security reasons you must log in again.',
  'PROFILE.ACCOUNT_SETTINGS.UPDATE_PASSWORD_TITLE': 'Change password',
  'PROFILE.ACCOUNT_SETTINGS.UPDATE_EMAIL_TITLE': 'Change email',
  'PROFILE.ACCOUNT_SETTINGS.UPDATE_EMAIL_ERP_NOTICE1': 'Your email address has to be adjusted in the system of your administration.',
  'PROFILE.ACCOUNT_SETTINGS.UPDATE_EMAIL_ERP_NOTICE2': 'Click here to contact your administration via email.',
  'PROFILE.ACCOUNT_SETTINGS.UPDATE_PASSWORD_PLACEHOLDER': 'Enter a new password...',
  'PROFILE.ACCOUNT_SETTINGS.UPDATE_EMAIL_PLACEHOLDER': 'Enter a new email...',
  'PROFILE.ACCOUNT_SETTINGS.UPDATE_EMAIL_INPUT': 'New email',
  'PROFILE.ACCOUNT_SETTINGS.UPDATE_EMAIL_REPEATED': 'Please enter a new email address.',
  'PROFILE.ACCOUNT_SETTINGS.UPDATE_PASSWORD_REPEATED': 'Please enter a new password.',
  'PROFILE.ACCOUNT_SETTINGS.UPDATE_PASSWORD_INPUT': 'New password',
  'PROFILE.ACCOUNT_SETTINGS.CURRENT_PASSWORD_PLACEHOLDER': 'Enter your current password...',
  'PROFILE.ACCOUNT_SETTINGS.CURRENT_PASSWORD_TITLE': 'Current Password',
  'PROFILE.ACCOUNT_SETTINGS.CURRENT_PASSWORD_NOTE': 'To prevent misuse, we ask you to re-enter your password before you can make any changes.',

  'PROFILE.CONTACT.TITLE': 'Contact',
  'PROFILE.CONTACT.DEVELOPMENT': 'Developed by',

  'PROFILE.PERSON_DATA.STATUS': 'Status',
  'PROFILE.PERSON_DATA.POST_BOX': 'Post box',
  'PROFILE.PERSON_DATA.PRIVATE_PHONE': 'Phone (private)',
  'PROFILE.PERSON_DATA.MOBILE_PHONE': 'Phone (mobile)',
  'PROFILE.PERSON_DATA.WORK_PHONE': 'Phone (work)',

  'PROFILE.TERMS_OF_USE.TITLE': 'ImmoApp Terms of Use',
  'PROFILE.TERMS_OF_USE.SUBTITLE': 'Together for a respectful and positive app experience',
  'PROFILE.TERMS_OF_USE.INTRO_TITLE': 'What we expect when using the ImmoApp',
  'PROFILE.TERMS_OF_USE.INTRO_TEXT1': 'To ensure that everyone in our community feels welcome and comfortable, we have established some basic rules for the ImmoApp. These rules reflect our shared values and provide the foundation for a respectful and positive app experience.',
  'PROFILE.TERMS_OF_USE.INTRO_TEXT2': 'We ask that all users adhere to the following guidelines:',
  'PROFILE.TERMS_OF_USE.LIST_1_TITLE': 'Publication of personal information',
  'PROFILE.TERMS_OF_USE.LIST_1_TEXT': 'Personal information that could lead to the identification of a private person may only be published with the explicit consent of the person concerned. This also applies to pictures/videos of groups of people or random people/car license plates in the background.',
  'PROFILE.TERMS_OF_USE.LIST_2_TITLE': 'Harassment and/or incitement to violence',
  'PROFILE.TERMS_OF_USE.LIST_2_TEXT': 'Any form of harassment, threats or incitement to violence against individuals or groups of individuals is not permitted in the ImmoApp.',
  'PROFILE.TERMS_OF_USE.LIST_3_TITLE': 'Discrimination',
  'PROFILE.TERMS_OF_USE.LIST_3_TEXT': 'We do not tolerate discrimination or hate speech for any reason. Everyone is welcome in our community and accepted for who he/she is. Express your opinions and experiences constructively without insulting others, making unnecessary generalizations, or provokations.',
  'PROFILE.TERMS_OF_USE.LIST_4_TITLE': 'Disruptive behavior',
  'PROFILE.TERMS_OF_USE.LIST_4_TEXT': 'Repeated posting of the same or similar content, unnecessarily rude replies, or intentionally disrupting the ImmoApp experience of other users are not permitted.',
  'PROFILE.TERMS_OF_USE.LIST_5_TITLE': 'Intrusive sexual behavior',
  'PROFILE.TERMS_OF_USE.LIST_5_TEXT': 'Intrusive sexual behavior or offering sexual services will not be tolerated. Always treat other users with respect and dignity.',
  'PROFILE.TERMS_OF_USE.LIST_6_TITLE': 'Violent and/or explicit sexual content',
  'PROFILE.TERMS_OF_USE.LIST_6_TEXT': 'Any kind of drastic violent and/or explicit sexual content (in written or visual form) is not allowed. Images of people are allowed as long as all intimate body regions / sexual parts are covered.',
  'PROFILE.TERMS_OF_USE.LIST_7_TITLE': 'Third-party content',
  'PROFILE.TERMS_OF_USE.LIST_7_TEXT': 'The sharing of copyrighted content is not permitted in the ImmoApp.',
  'PROFILE.TERMS_OF_USE.LIST_8_TITLE': 'Commercial content',
  'PROFILE.TERMS_OF_USE.LIST_8_TEXT': 'Commercial advertising is not allowed in the ImmoApp. However, private classified ads are fine.',
  'PROFILE.TERMS_OF_USE.LIST_9_TITLE': 'Illegal activities',
  'PROFILE.TERMS_OF_USE.LIST_9_TEXT': 'Performing, promoting, planning or inciting illegal activities is not allowed.',
  'PROFILE.TERMS_OF_USE.MODERATION_TITLE': 'Moderation by the administration',
  'PROFILE.TERMS_OF_USE.MODERATION_TEXT1': 'Moderation of the ImmoApp is in the hands of the respective administration. It is their responsibility and privilege to ensure that all users enjoy the app and community contributions in a safe, respectful and positive atmosphere. The administration is vigilant in ensuring that the above policies are adhered to and has the right to act accordingly if any violations are found.',
  'PROFILE.TERMS_OF_USE.MODERATION_TEXT2': 'If you encounter any problematic content or have any questions regarding moderation, please contact your administration directly. We thank you for your understanding and cooperation in creating a welcoming and respectful community on ImmoApp.',

  'DASHBOARD.NO_DESCRIPTION_AVAILABLE': 'No description available.',

  'REPORT.TICKET.REPAIR.TITLE': 'Repair report',
  'REPORT.TICKET.REPAIR.INTERNAL.DESCRIPTION': 'Inform the administration (and optionally your neighbors) of any damage',
  'REPORT.TICKET.REPAIR.EXTERNAL.DESCRIPTION': 'Inform the administration of any damage',
  'REPORT.TICKET.KEYORDER.TITLE': 'Key or card',
  'REPORT.TICKET.KEYORDER.DESCRIPTION': 'Replace lost keys, order replacement keys or replace cylinders',
  'REPORT.TICKET.NAMEPLATE.TITLE': 'Name tag',
  'REPORT.TICKET.NAMEPLATE.DESCRIPTION': 'New name plate for front door, apartment door or mailbox',
  'REPORT.TICKET.DOCUMENT.TITLE': 'Document or form',
  'REPORT.TICKET.DOCUMENT.DESCRIPTION': 'Order contract, regulations, application form and other documents',
  'REPORT.TICKET.OTHER.TITLE': 'Other message',
  'REPORT.TICKET.OTHER.DESCRIPTION': 'Inform the administration about something else',
  'REPORT.MESSAGE': 'Message',
  'REPORT.PINBOARD': 'Pinboard',
  'REPORT.VOTE_ITEM': 'Vote item',
  'REPORT.ADD.TITLE': 'Hello, how can we help?',
  'REPORT.ADD.SECTION.REPAIR': 'Repair report',
  'REPORT.ADD.SECTION.ORDER': 'Order',
  'REPORT.ADD.SECTION.OTHER': 'Other concerns',
  'REPORT.ADD.NOTE.REPAIR.PUBLIC': 'You are submitting a public repair report for {{location}}.',
  'REPORT.ADD.NOTE.REPAIR.PRIVATE': 'You are submitting a private repair report for {{location}}.',
  'REPORT.ADD.NOTE.KEYORDER': 'You are submitting a key or card order for {{location}}.',
  'REPORT.ADD.NOTE.NAMEPLATE': 'You are submitting a nameplate order for {{location}}.',
  'REPORT.ADD.NOTE.DOCUMENT': 'You are submitting a document or form order for {{location}}.',
  'REPORT.ADD.NOTE.PINBOARD': 'You are submitting a public pinboard entry for {{location}}.',
  'REPORT.ADD.NOTE.VOTEITEM': 'You are submitting a vote agenda item for {{location}}.',
  'REPORT.ADD.NOTE.OTHER': 'You are submitting a private other message for {{location}}.',
  'REPORT.ADD.SUBMIT': 'Submit',
  'REPORT.ADD.LIST.PINBOARD.TITLE': 'Pinboard entry',
  'REPORT.ADD.LIST.PINBOARD.DESCRIPTION': 'Share information, offers and events with your neighbors',
  'REPORT.ADD.LIST.VOTE_ITEM.TITLE': 'Vote item',
  'REPORT.ADD.LIST.VOTE_ITEM.DESCRIPTION': 'Record a discussion or voting point for the next meeting',
  'REPORT.ADD.NEW_REPAIR': 'New repair report',
  'REPORT.ADD.NEW_PINBOARD': 'New pinboard entry',
  'REPORT.ADD.NEW_KEYORDER': 'New key or card order',
  'REPORT.ADD.NEW_NAMEPLATE': 'New nameplate order',
  'REPORT.ADD.NEW_DOCUMENT': 'New document or form order',
  'REPORT.ADD.NEW_VOTE_ITEM': 'New vote item',
  'REPORT.ADD.NEW_OTHER': 'Other request',
  'REPORT.ADD.LOCATION': 'Location',
  'REPORT.ADD.LOCATION.PLACEHOLDER': 'Please select a location...',
  'REPORT.ADD.LOCATION.DETAILS': 'More details about the location',
  'REPORT.ADD.PUBLIC': 'Public',
  'REPORT.ADD.PUBLIC.VISIBILITY': 'Visible to neighbours',
  'REPORT.ADD.NAMEPLATE.TITLE': 'Name plate',
  'REPORT.ADD.NAMEPLATE.ROW1': 'Line 1',
  'REPORT.ADD.NAMEPLATE.ROW2': 'Line 2',
  'REPORT.ADD.KEYORDER.TITLE': 'Further information about the key / card',
  'REPORT.ADD.KEYORDER.QUANTITY': 'Quantity',
  'REPORT.ADD.KEYORDER.LABEL': 'Key label',
  'REPORT.ADD.ATTACHMENTS.ADD': 'Add attachment',
  'REPORT.ADD.ATTACHMENTS.REPAIR': 'Please upload pictures of the damage.',
  'REPORT.ADD.ATTACHMENTS.KEYORDER': 'Please upload an image of the key / card.',
  'REPORT.ADD.TITLE_DESCRIPTION': 'Title / Message',
  'REPORT.ADD.TITLE_DESCRIPTION.DESCRIPTION': 'Message',
  'REPORT.SUBMIT_REPORT.REPAIR.LOADING': 'Your repair report is being sent...',
  'REPORT.SUBMIT_REPORT.ORDER.LOADING': 'Your order is being sent...',
  'REPORT.SUBMIT_REPORT.OTHER.LOADING': 'Your message is being sent...',
  'REPORT.SUBMIT_REPORT.PINBOARD.LOADING': 'Your pinboard entry is being sent...',
  'REPORT.SUBMIT_REPORT.VOTE_ITEM.LOADING': 'Your vote item is being sent...',
  'REPORT.SUBMIT_REPORT.REPAIR.SUCCESS': 'Your repair report was successfully submitted.',
  'REPORT.SUBMIT_REPORT.ORDER.SUCCESS': 'Your order has been successfully submitted.',
  'REPORT.SUBMIT_REPORT.OTHER.SUCCESS': 'Your message has been successfully submitted.',
  'REPORT.SUBMIT_REPORT.PINBOARD.SUCCESS': 'Your pinboard entry was successfully submitted.',
  'REPORT.SUBMIT_REPORT.VOTE_ITEM.SUCCESS': 'Your vote item was successfully submitted.',
  'REPORT.UPDATE_REPORT.REPAIR.LOADING': 'Repair report is being updated...',
  'REPORT.UPDATE_REPORT.ORDER.LOADING': 'Order is being updated...',
  'REPORT.UPDATE_REPORT.OTHER.LOADING': 'Message is being updated...',
  'REPORT.UPDATE_REPORT.PINBOARD.LOADING': 'Pinboard entry is being updated...',
  'REPORT.UPDATE_REPORT.VOTE_ITEM.LOADING': 'Vote item is being updated...',
  'REPORT.UPDATE_REPORT.REPAIR.SUCCESS': 'Your repair report was successfully updated.',
  'REPORT.UPDATE_REPORT.ORDER.SUCCESS': 'Order has been successfully updated.',
  'REPORT.UPDATE_REPORT.OTHER.SUCCESS': 'Your message has been successfully updated.',
  'REPORT.UPDATE_REPORT.PINBOARD.SUCCESS': 'Your pinboard entry was successfully updated.',
  'REPORT.UPDATE_REPORT.VOTE_ITEM.SUCCESS': 'Your vote item was successfully updated.',
  'REPORT.TOO_MANY_REQUESTS': 'There were too many requests in a short period of time. Please wait a few minutes and then try again.',
  'REPORT.UPLOAD_CANCEL': 'Upload was canceled.',
  'REPORT.ADD.ACCEPT_PRIVACY_POLICY_WRAPPER': 'I accept the {{link}}.',
  'REPORT.ADD.ACCEPT_PRIVACY_POLICY_WORD': 'Privacy Policy',
  'REPORT.ADD.TITLE_REQUIRED': 'Please enter a title.',
  'REPORT.ADD.STATUS_REQUIRED': 'Please select a status.',
  'REPORT.ADD.DESCRIPTION_REQUIRED': 'Please enter a description.',
  'REPORT.ADD.MESSAGE_REQUIRED': 'Please enter a message.',
  'REPORT.ADD.CAPTCHA_REQUIRED': 'Please solve the CAPTCHA first.',
  'REPORT.ADD.PROPERTY_REQUIRED': 'Please select a property.',
  'REPORT.ADD.FIRSTNAME_REQUIRED': 'Please enter a first name.',
  'REPORT.ADD.LASTNAME_REQUIRED': 'Please enter a last name.',
  'REPORT.DELETE.TITLE': 'Delete entry',
  'REPORT.DELETE.DESCRIPTION': 'If you confirm with OK, the entry you have created will be irrevocably deleted.',
  'REPORT.DELETE.NOT_POSSIBLE': 'The ticket can no longer be deleted because the status has already changed.',
  'REPORT.DELETE.SUCCESS': 'The entry was successfully deleted.',

  'REPORT.EDIT.GENERAL': 'Edit entry',
  'REPORT.EDIT.REPAIR': 'Edit repair report',
  'REPORT.EDIT.PINBOARD': 'Edit pinboard entry',
  'REPORT.EDIT.KEYORDER': 'Edit key or card order',
  'REPORT.EDIT.NAMEPLATE': 'Edit name plate order',
  'REPORT.EDIT.DOCUMENT': 'Edit document or form order',
  'REPORT.EDIT.VOTE_ITEM': 'Edit vote item',
  'REPORT.EDIT.CONTACT_DATA': 'Edit data request',
  'REPORT.EDIT.OTHER': 'Edit other request',

  'VOTES.ITEMS.SUBMITTED_ON': 'Submitted on',
  'VOTES.ITEMS.EDIT_NOT_ALLOWED': 'Your vote item is already being used in a vote and can therefore no longer be edited.',

  'VOTES.TITLE': 'Votes',
  'VOTES.MY_VOTE_ITEMS': 'My vote items',
  'VOTES.DATE_UNTIL': 'until',
  'VOTES.LIST.TITLE': 'Votes in',
  'VOTES.DATE_END': 'End',
  'VOTES.NO_VOTES_AVAILABLE': 'No votes available.',
  'VOTES.NO_VOTE_ITEMS_SUBMITTED': 'You have not yet submitted any vote items.',
  'VOTES.STATUS_ACTIVE_NOT_REGISTERED': 'Register and vote now',
  'VOTES.STATUS_ACTIVE_REGISTERED.VOTE': 'Vote active until',
  'VOTES.STATUS_ACTIVE_REGISTERED.SURVEY': 'Survey active until',
  'VOTES.STATUS_WARRANT_REGISTERED': 'Grant power of attorney',
  'VOTES.STATUS_WARRANT_COMPLETED': 'Completed with power of attorney',
  'VOTES.STATUS_COMPLETED.VOTE': 'Vote completed successfully',
  'VOTES.STATUS_COMPLETED.SURVEY': 'Survey completed successfully',
  'VOTES.STATUS_NOT_STARTED_NOT_REGISTERED': 'Register now',
  'VOTES.STATUS_NOT_STARTED_REGISTERED.VOTE': 'Voting begins on',
  'VOTES.STATUS_NOT_STARTED_REGISTERED.SURVEY': 'Survey begins on',
  'VOTES.STATUS_INACTIVE.VOTE': 'Vote no longer active',
  'VOTES.STATUS_INACTIVE.SURVEY': 'Survey no longer active',
  'VOTES.STAFF_ALL_VOTES_IN_ADMIN_APP': 'Within the ImmoApp, only the votes of the last 2 years are displayed for employees. To see older votes, please open the admin app.',

  'VOTE.STATUS_NOT_ALLOWED.LABEL': 'You are not authorized to vote. This can happen if a vote has already been submitted for your owner number.',
  'VOTE.STATUS_NOT_STARTED_NOT_REGISTERED.LABEL.VOTE': 'Register now and receive an automatic reminder when the vote begins.',
  'VOTE.STATUS_NOT_STARTED_NOT_REGISTERED.LABEL.SURVEY': 'Register now and receive an automatic reminder when the survey begins.',
  'VOTE.STATUS_NOT_STARTED_NOT_REGISTERED_LIVE.LABEL': 'Register now',
  'VOTE.STATUS_NOT_STARTED_NOT_REGISTERED.BUTTON.VOTE': 'Registration for vote',
  'VOTE.STATUS_NOT_STARTED_NOT_REGISTERED.BUTTON.SURVEY': 'Registration for survey',
  'VOTE.STATUS_NOT_STARTED_REGISTERED.LABEL.VOTE': 'Voting starts on {{dateStart}}. You are registered and will be notified.',
  'VOTE.STATUS_NOT_STARTED_REGISTERED.LABEL.SURVEY': 'Survey starts on {{dateStart}}. You are registered and will be notified.',
  'VOTE.STATUS_NOT_STARTED_REGISTERED_LIVE.LABEL.VOTE': 'Voting starts on {{dateStart}}. You are registered.',
  'VOTE.STATUS_NOT_STARTED_REGISTERED_LIVE.LABEL.SURVEY': 'Survey starts on {{dateStart}}. You are registered.',
  'VOTE.STATUS_NOT_STARTED_REGISTERED.BUTTON': 'Vote',
  'VOTE.STATUS_ACTIVE_NOT_REGISTERED.LABEL': 'Register now and vote immediately.',
  'VOTE.STATUS_ACTIVE_NOT_REGISTERED.BUTTON.VOTE': 'Registration for vote',
  'VOTE.STATUS_ACTIVE_NOT_REGISTERED.BUTTON.SURVEY': 'Registration for survey',
  'VOTE.STATUS_ACTIVE_NOT_REGISTERED.BUTTON_LIVE.VOTE': 'Registration for live vote',
  'VOTE.STATUS_ACTIVE_NOT_REGISTERED.BUTTON_LIVE.SURVEY': 'Registration for live survey',
  'VOTE.STATUS_ACTIVE_REGISTERED.BUTTON': 'Vote now',
  'VOTE.STATUS_COMPLETED.BUTTON': 'View your selection',
  'VOTE.STATUS_WARRANT_COMPLETED.BUTTON': 'View selection',
  'VOTE.STATUS_COMPLETED_LIVE.LABEL': 'You can still change your selection until the end of the live voting.',
  'VOTE.STATUS_COMPLETED_LIVE.BUTTON': 'Change selection',
  'VOTE.STATUS_COMPLETED_ONLINE_NOW_LIVE.LABEL': 'You have submitted your vote in the online vote. If you would like to take part in the live vote, you can register again. In this case, your previous submission will be deleted.',
  'VOTE.STATUS_INACTIVE.REGISTER_MODAL.DESCRIPTION': 'From {{date}} onwards, the motions for "{{title}}" can be voted on electronically.',
  'VOTE.STATUS_INACTIVE.REGISTER_MODAL.BOLD_TEXT': 'Receive a reminder and access code via email.',
  'VOTE.STATUS_ACTIVE.REGISTER_MODAL.DESCRIPTION': 'Since {{date}}, the motions for "{{title}}" can be voted on electronically.',
  'VOTE.STATUS_ACTIVE.REGISTER_MODAL.LIVE.BOLD_TEXT': 'Register now and vote immediately.',
  'VOTE.STATUS_ACTIVE.REGISTER_MODAL.ONLINE.BOLD_TEXT': 'Get your access code via email.',
  'VOTE.CODE_RANGE_VALIDATION': 'The code must be a number between 1000 and 9999.',
  'VOTE.CODE_REQUIRED': 'Please enter your code.',
  'VOTE.CODE_INVALID': 'Invalid code. Please try again.',
  'VOTE.CODE_MODAL.TITLE': 'Enter code',
  'VOTE.CODE_MODAL.DESCRIPTION_1': 'Please enter your access code here.',
  'VOTE.CODE_MODAL.DESCRIPTION_2_LIVE': 'For a live vote, you receive the code directly from your administration.',
  'VOTE.CODE_MODAL.DESCRIPTION_2_ONLINE': 'You can find the code in your email inbox.',
  'VOTE.CODE_MODAL.REQUEST_ACCESS_CODE_AGAIN': 'Request access code again',
  'VOTE.CODE_MODAL.REQUEST_ACCESS_CODE_AGAIN.SUCCESS': 'The access code has been resent to you by email. Please also check your spam folder.',
  'VOTE.CODE_MODAL.VOTE_NOW': 'Vote now',
  'VOTE.CODE_MODAL.VOTING_PERIOD.VOTE': 'Online vote is available until {{date}}.',
  'VOTE.CODE_MODAL.VOTING_PERIOD.SURVEY': 'Online survey is available until {{date}}.',
  'VOTE.REGISTER_MODAL.UNREGISTERED.TITLE': 'Registration',
  'VOTE.REGISTER_MODAL.UNREGISTERED.BUTTON': 'Register now',
  'VOTE.REGISTER_MODAL.REGISTERED.TITLE': 'Registration successful',
  'VOTE.REGISTER_MODAL.START_SOON': 'Only a few minutes left',
  'VOTE.REGISTER_MODAL.DAYS_UNTIL_START': {
    none: '{{count}} days',
    singular: '{{count}} day',
    plural: '{{count}} days',
  },
  'VOTE.REGISTER_MODAL.HOURS_UNTIL_START': {
    none: 'and {{count}} hours',
    singular: 'and {{count}} hour',
    plural: 'and {{count}} hours',
  },
  'VOTE.REGISTER_MODAL.PART_2.VOTE': 'until the vote starts.',
  'VOTE.REGISTER_MODAL.PART_2.SURVEY': 'until the survey starts.',
  'VOTE.WARRANT.LOADING': 'Power of attorney is being loaded...',
  'VOTE.WARRANT.EMAIL_NAME_PLACEHOLDER': 'Enter last name / email...',
  'VOTE.WARRANT.BANNER': 'You have been granted power of attorney. Your vote also applies to',
  'VOTE.WARRANT.ASSIGNED_TO': 'Power of attorney granted to {{receiver}}',
  'VOTE.WARRANT.SHOW': 'Show power of attorney',
  'VOTE.WARRANT.REVOKE': 'Revoke power of attorney',
  'VOTE.WARRANT.REVOKE_DESCRIPTION': 'If you confirm with OK, the power of attorney for {{receiver}} will be revoked.',
  'VOTE.WARRANT_MODAL.STEP_1.DESCRIPTION': 'In the field below, enter the last name or email address of the recipient. The system then checks whether an owner with this data exists in your property.',
  'VOTE.WARRANT_MODAL.STEP_2.DESCRIPTION': 'The Warrantor, <b>{{warrantGiverName}}</b>, hereby authorizes <b>{{warrantReceiverName}} ({{warrantReceiverEmail}})</b> to represent his/her interests in the <b>{{voteTitle}}</b>.\n\nThe recipient of the power of attorney is authorized to vote on my behalf at the aforesaid meeting. All steps taken by the recipient at this meeting are legally binding on me.',
  'VOTE.WARRANT_MODAL.NO_ENTRIES': 'No owners found.',
  'VOTE.WARRANT_MODAL.SUBMIT_BUTTON': 'Grant power of attorney',
  'VOTE.WARRANT_MODAL.SUCCESS_TEXT': 'The power of attorney was successfully granted. A copy has been emailed to you and the recipient.',

  'VOTE.REVIEW.SIGNATURE_NOTE': "If you're working on a touchscreen device, you can use a pen or your finger to write your signature in the space above. If your device doesn't support touch input, you can use your mouse to draw your signature.",
  'VOTE.REVIEW.LEGAL_NOTE.VOTE': 'You hereby authorize the {{administration}} to represent your interests (according to the selection above) in the "{{title}}". Please review your selection carefully. The vote submitted here is legally binding.',
  'VOTE.REVIEW.LEGAL_NOTE.SURVEY': 'Please review your selection carefully. The vote cast here can no longer be changed.',
  'VOTE.REVIEW.PLACEHOLDER_VOTE': 'Vote',
  'VOTE.REVIEW.PLACEHOLDER_ADMINISTRATION': 'Administration',
  'VOTE.SUBMIT.SIGNATURE_MISSING': 'Please enter your signature first.',
  'VOTE.SUBMIT.SIGNATURE_MISSING_REVIEW': 'You can find the signature field in the current screen below "Your selection".',
  'VOTE.SUBMIT.IN_PROGRESS': 'Your selection is being submitted...',
  'VOTE.SUBMIT.SUCCESS.DEFAULT': 'Your selection has been successfully submitted. A copy will be emailed to you.',
  'VOTE.SUBMIT.SUCCESS.WARRANT': 'Your selection has been successfully submitted and the power of attorney created. A copy will be emailed to you.',

  'VOTE.LIST.CONFIRM_VOTES.BUTTON': 'Confirm selection',
  'VOTE.LIST.FOOTER.TEXT_LIVE': 'Your selection will be transmitted in real time (LIVE) to the administration. You can still change your selection up until the end of each agenda item.',
  'VOTE.LIST.FOOTER.TEXT_ONLINE': 'Please vote on all agenda items first.',
  'VOTE.LIST.MOTIONS.VOTE': 'Vote items',
  'VOTE.LIST.MOTIONS.SURVEY': 'Survey items',
  'VOTE.LIST.NO_MOTIONS_AVAILABLE': 'No vote items available.',
  'VOTE.LIST.ITEM.INACTIVE': 'Agenda item not active.',
  'VOTE.LIST.ITEM.PAUSED': 'Paused',
  'VOTE.LIST.ITEM.COMPLETED': 'Completed',

  'VOTE.REVIEW.SIGNATURE': 'Signature',
  'VOTE.REVIEW.YOUR_SELECTION': 'Your selection',
  'VOTE.REVIEW.SUBMIT_VOTES': 'Submit vote',
  'VOTE.REVIEW.VIEW_SELECTION_MANUAL': 'Your selection was recorded by the administration on {{date}}.',
  'VOTE.REVIEW.VIEW_SELECTION_WARRANT': 'The selection was submitted via power of attorney on {{date}}.',
  'VOTE.REVIEW.VIEW_SELECTION_ONLINE.VOTE': 'You submitted your results on {{date}} in the online vote.',
  'VOTE.REVIEW.VIEW_SELECTION_ONLINE.SURVEY': 'You submitted your results on {{date}} in the online survey.',
  'VOTE.REVIEW.VIEW_SELECTION_LIVE.VOTE': 'You submitted your results on {{date}} in the live vote.',
  'VOTE.REVIEW.VIEW_SELECTION_LIVE.SURVEY': 'You submitted your results on {{date}} in the live survey.',
  'VOTE.REVIEW.CHANGES_NOT_ALLOWED.VOTE': 'Subsequent changes are not legally permitted.',
  'VOTE.REVIEW.CHANGES_NOT_ALLOWED.SURVEY': 'Subsequent changes are not permitted.',

  'DASHBOARD.PROPERTIES.TITLE': 'Properties & Contacts',
  'DASHBOARD.PROPERTIES.CONTACT': 'Contact',
  'DASHBOARD.PROPERTIES.CONTACTS': 'Contacts',
  'DASHBOARD.PROPERTIES.USEFUL_INFORMATION': 'Useful information',
  'DASHBOARD.PROPERTIES.MANDATE.INACTIVE': 'Inactive',

  'DASHBOARD.PROPERTIES.DETAIL.EMAIL': 'Email: ',
  'DASHBOARD.PROPERTIES.DETAIL.COMPANY': 'Company: ',
  'DASHBOARD.PROPERTIES.DETAIL.TEL_NR': 'Phone nr: ',
  'DASHBOARD.PROPERTIES.DETAIL.MISSING_INFORMATION': 'Contact information is missing',

  'DASHBOARD.OVERVIEW.HERO.BUTTON.MULTIPLE': 'Properties & contacts',
  'DASHBOARD.OVERVIEW.HERO.BUTTON.SINGLE': 'Important contacts',
  'DASHBOARD.OVERVIEW.REPAIR_REPORT.BUTTON': 'Repair report',
  'DASHBOARD.OVERVIEW.REPORTS_LIST.TITLE': 'News in',
  'DASHBOARD.OVERVIEW.REPORTS_LIST.ALL': 'All\nentries',
  'DASHBOARD.OVERVIEW.REPORTS_LIST.MINE': 'My\nentries',
  'DASHBOARD.OVERVIEW.REPORTS_LIST.MESSAGES': 'Messages',
  'DASHBOARD.OVERVIEW.REPORTS_LIST.PINBOARD': 'Pinboard',
  'DASHBOARD.OVERVIEW.REPORTS_LIST.REPAIRS': 'Repairs',
  'DASHBOARD.OVERVIEW.COMMENT': 'Comment',
  'DASHBOARD.OVERVIEW.COMMENTS': 'Comments',
  'DASHBOARD.OVERVIEW.ATTACHMENT': 'Attachment',
  'DASHBOARD.OVERVIEW.LOAD_MORE_ENTRIES': 'Load more entries',

  'DASHBOARD.REPORT.SUBSCRIBE': 'You will be notified of all updates and comments.',
  'DASHBOARD.REPORT.UNSUBSCRIBE': 'Notifications disabled.',
  'DASHBOARD.REPORT.YOUR_ADMINISTRATION': 'your administration',
  'DASHBOARD.REPORT.ADD_STATUS.DESCRIPTION': 'Description',
  'DASHBOARD.REPORT.ADD_STATUS.TEXT': 'Text',
  'DASHBOARD.REPORT.ADD_STATUS.DESCRIPTION.PLACEHOLDER': 'Enter description',
  'DASHBOARD.REPORT.ADD_STATUS.STATUS': 'Status',
  'DASHBOARD.REPORT.ADD_STATUS.STATUS.PLACEHOLDER': 'Select status',
  'DASHBOARD.REPORT.ADD_STATUS.TITLE': 'Add status',
  'DASHBOARD.REPORT.ADD_STATUS.SUCCESS': 'Status was successfully added to ticket.',
  'DASHBOARD.REPORT.ADD_STATUS.PUSH.DESCRIPTION': 'Should a push message be sent? Only users who have activated push notifications will receive the message.',
  'DASHBOARD.REPORT.ADD_STATUS.EMAIL.DESCRIPTION': 'Should an email be sent? Only users who have activated email delivery will receive the email. (Disabled by default)',
  'DASHBOARD.REPORT.ADD_YAROWA.TITLE': 'Create Yarowa process',
  'DASHBOARD.REPORT.ADD_YAROWA.ALERT.MESSAGE': 'If you confirm with OK, you will be redirected to the Yarowa AG portal and can hire a service provider.',
  'DASHBOARD.REPORT.PENDING_YAROWA.TITLE': 'Process is created...',
  'DASHBOARD.REPORT.PENDING_YAROWA.DESCRIPTION': 'Please complete the process on the Yarowa site. Once the process is created, this window will automatically close.',
  'DASHBOARD.REPORT.GO_TO_YAROWA.TITLE': 'Open Yarowa process',
  'DASHBOARD.REPORT.REMOVE_YAROWA.TITLE': 'Remove Yarowa process',
  'DASHBOARD.REPORT.REMOVE_YAROWA.ALERT.MESSAGE': 'If you confirm with OK, the connection to the Yarowa process will be removed. If you would like to delete the process completely, please remove it from within the Yarowa platform.',
  'DASHBOARD.REPORT.REMOVE_YAROWA.COMPLETED': 'Yarowa process was successfully removed.',
  'DASHBOARD.REPORT.RECEIVERS.PUBLIC_EXTERNAL_TICKET': 'External ticket: Only visible to administrators and sender.',
  'DASHBOARD.REPORT.RECEIVERS.PRIVATE_EXTERNAL_TICKET': 'Private external ticket: Only visible to administrators and sender.',
  'DASHBOARD.REPORT.RECEIVERS.PRIVATE_INTERNAL_TICKET': 'Private ticket: Only visible to janitors, administrators and sender.',
  'DASHBOARD.REPORT.COMMENTS.TITLE': 'Comments',
  'DASHBOARD.REPORT.COMMENTS.PLACEHOLDER': 'Enter new comment here...',
  'DASHBOARD.REPORT.COMMENTS.SUBMIT': 'Send comment',
  'DASHBOARD.REPORT.COMMENTS.DELETE.HEADER': 'Delete comment',
  'DASHBOARD.REPORT.COMMENTS.DELETE.CONFIRMATION': 'Do you want to delete this comment?',
  'DASHBOARD.REPORT.COMMENTS.STATUS.EDITED': 'Edited',
  'DASHBOARD.REPORT.COMMENTS.NA_YET': 'No comments have been entered yet.',
  'DASHBOARD.REPORT.COMMENTS.STATUS.DELETED': 'Comment has been deleted.',
  'DASHBOARD.REPORT.COMMENTS.CREATE.PROGRESS': 'Comment is being sent...',
  'DASHBOARD.REPORT.COMMENTS.CREATE.SUCCESS': 'Comment was successfully created.',
  'DASHBOARD.REPORT.COMMENTS.EDIT.SUCCESS': 'Comment was successfully updated.',
  'DASHBOARD.REPORT.COMMENTS.DELETE.SUCCESS': 'Comment was successfully deleted.',
  'DASHBOARD.REPORT.COMMENTS.RESTORED.SUCCESS': 'Comment was successfully restored.',
  'DASHBOARD.REPORT.COMMENTS.DISABLED_USER_INFO': 'Comments disabled.',
  'DASHBOARD.REPORT.PERSONAL_MESSAGE': 'Personal message',
  'DASHBOARD.REPORT.CONTACT_INFOS_FOR_REPORTING_COMMENT': 'Report this comment to the administration.',
  'DASHBOARD.REPORT.CONTACT_INFOS_ONLY_VISIBLE_FOR_ADMINISTRATION': 'This contact information is only visible to the administration.',
  'DASHBOARD.REPORT.ERROR.NOT_AVAILABLE': 'The entry is not available.',
  'DASHBOARD.REPORT.SHARE': 'Share via email',
  'DASHBOARD.REPORT.SHARE.HEADER': 'Share via email',
  'DASHBOARD.REPORT.SHARE.MESSAGE': 'Who should receive the email?',
  'DASHBOARD.REPORT.SHARE.HEADER.EMAIL': 'Email address',
  'DASHBOARD.REPORT.SHARE.INVALID_EMAIL': 'Invalid email address.',
  'DASHBOARD.REPORT.SHARE.SUCCESS': 'Email was sent successfully.',
  'DASHBOARD.REPORT.SHARE.SEND': 'Send',
  'DASHBOARD.REPORT.SCREENSHOT_TAKE': 'Take screenshot',
  'DASHBOARD.REPORT.SCREENSHOT_CREATING': 'Screenshot is created...',

  'LOCATION.ALL_PROPERTIES': 'All properties',
  'LOCATION.DISABLED.PINBOARD': 'The pinboard is disabled for this property',

  'DOCUMENTS.TITLE': 'Documents',
  'DOCUMENTS.SEARCH.TITLE': 'Documents search',
  'DOCUMENTS.SEARCH.PLACEHOLDER': 'Search for documents...',
  'DOCUMENTS.SEARCH.TOO_FEW_CHARACTERS': 'At least 3 characters required.',
  'DOCUMENTS.INACTIVE_ONLY_VISIBLE_TO_ADMINS': 'Inactive property - Only visible to admins',
  'DOCUMENTS.ALL': 'All documents',
  'DOCUMENTS.RECENT': 'Recently added documents',
  'DOCUMENTS.NO_ENTRIES': 'No files found',
  'DOCUMENTS.MORE_SEARCH_RESULTS': 'More search results available. Please refine your search.',
  'DOCUMENTS.PERSONAL_FOLDER': 'Personal folder',
  'DOCUMENTS.DOWNLOAD_ALL.NO_FILES': 'There are no files in this folder.',
  'DOCUMENTS.DOWNLOAD_ALL.TITLE': 'Download all',
  'DOCUMENTS.DOWNLOAD_ALL_FILES.MESSAGE': 'Do you want to download all files in this folder?',
  'DOCUMENTS.DOWNLOAD_ALL_ATTACHMENTS.MESSAGE': 'Do you want to download all attachments?',

  'PAGE_DENIED.TITLE': 'Access denied',
  'PAGE_DENIED.SUBTITLE': 'Access denied',
  'PAGE_DENIED.BACK_HOME': 'Back',
  'PAGE_NOT_FOUND.TITLE': 'Page not found',
  'PAGE_NOT_FOUND.SUBTITLE': 'Page not found',
  'PAGE_NOT_FOUND.BACK_HOME': 'Back',
  'PAGE_ERROR.TITLE': 'Unexpected error',
  'PAGE_ERROR.SUBTITLE': 'Unexpected error - Please check your internet connection.',
  'PAGE_ERROR.BACK_HOME': 'Back',

  'FILE_LOADER.NEW_PHOTO': 'Take a new photo',
  'FILE_LOADER.PHOTOS': {
    none: 'Select photos',
    singular: 'Select photo',
    plural: 'Select photos',
  },
  'FILE_LOADER.VIDEOS': 'Select videos',
  'FILE_LOADER.DOCUMENTS': 'Select documents',
  'FILE_LOADER.NOT_SUPPORTED': 'Your device or browser does not support downloading files.',
  'FILE_LOADER.SINGLE.DOWNLOAD_ERROR': 'The file could not be downloaded.',
  'FILE_LOADER.SINGLE.DOWNLOAD_SUCCESS': 'The file was successfully downloaded.',
  'FILE_LOADER.MULTIPLE.DOWNLOADING': 'The files are downloading...',
  'FILE_LOADER.MULTIPLE.DOWNLOAD_ERROR': 'The files could not be downloaded.',
  'FILE_LOADER.MULTIPLE.DOWNLOAD_SUCCESS': 'The files have been downloaded successfully.',
  'FILE_LOADER.NO_FILE_SELECTED': 'No file selected.',
  'FILE_LOADER.MAX_1_VIDEO': 'Maximum 1 video allowed.',
  'FILE_LOADER.MAX_MB': 'File is too big. Max: {{size}} MB.',
  'FILE_LOADER.LIMIT_TO_X': {
    none: 'Please do not choose more than {{count}} files.',
    singular: 'Please do not choose more than {{count}} file.',
    plural: 'Please do not choose more than {{count}} files.',
  },

  'FILE_VIEWER.ERROR.SHARE_NOT_SUPPORTED': 'The share functionality is not supported.',
  'FILE_VIEWER.ERROR.FILE_CANNOT_BE_SHARED': 'The file cannot be shared.',
  'FILE_VIEWER.ERROR.FILE_CANNOT_BE_DISPLAYED': 'The file cannot be displayed.',
  'FILE_VIEWER.ERROR.FILE_CANNOT_BE_DISPLAYED_OR_DOWNLOADED': 'The file cannot be displayed or downloaded.',
  'FILE_VIEWER.X_RESULTS': {
    none: 'No results',
    singular: '{{count}} result',
    plural: '{{count}} results',
  },
  'FILE_VIEWER.FILE_IS_LOADING': 'The file is loading...',

  'IMAGE_ALT.APP.LOGO': 'App logo',
  'IMAGE_ALT.DASHBOARD_HEADER.BACKGROUND': 'Property',
  'IMAGE_ALT.USER': 'Logged user',
  'IMAGE_ALT.REPORT.COMMENT.OWNER': 'Comment sender',
  'IMAGE_ALT.PROFILE.USER': 'User profile',
  'IMAGE_ALT.SIGN_IN': 'Sign in page',
  'IMAGE_ALT.SIGN_UP': 'Sign up page',
  'IMAGE_ALT.VOTE.REGISTER_MODAL': 'Register to vote',

  'INFOCENTER.SEGMENT.TICKET': 'Report a concern',
  'INFOCENTER.SEGMENT.FAQS': 'FAQs',
  'INFOCENTER.SEGMENT.DOCUMENTS': 'Documents',
  'INFOCENTER.WEBSITE.TITLE': 'Visit the {{adminName}} website',
  'INFOCENTER.WEBSITE.TEXT': 'Visit our website for all further information about {{adminName}}.',
  'INFOCENTER.WEBSITE.BUTTON': 'To the website',
  'INFOCENTER.LOAD.TITLE': 'Stay informed with the ImmoApp',
  'INFOCENTER.LOAD.TEXT': 'With the ImmoApp you are always well informed about all the latest news about your property. You can also see all the messages you have sent along with their current status.',
  'INFOCENTER.FAQ.SECTION.ADD': 'New section',
  'INFOCENTER.FAQ.SECTION.TITLE': 'New Section',
  'INFOCENTER.FAQ.ITEM.HEAD_TITLE': 'New Item Head',
  'INFOCENTER.FAQ.ITEM.BODY_TITLE': 'New Item Body',
  'INFOCENTER.FAQ.SECTION.TRANSLATE_DE': 'Translate to German',
  'INFOCENTER.FAQ.SECTION.TRANSLATE_EN': 'Translate to English',
  'INFOCENTER.FAQ.SECTION.TRANSLATE_FR': 'Translate to French',
  'INFOCENTER.FAQ.SECTION.TRANSLATE_IT': 'Translate to Italian',
  'INFOCENTER.FAQ.SECTION.DELETED': 'FAQ section deleted.',
  'INFOCENTER.FAQ.DATA_UPDATED': 'FAQs successfully updated.',
  'INFOCENTER.FAQ.DELETE_POPOVER.TITLE': 'Unable to delete',
  'INFOCENTER.FAQ.DELETE_POPOVER.CONTENT': 'Please delete all items in this section first.',
  'INFOCENTER.DOCUMENTS.NONE': 'No documents available.',
  'INFOCENTER.TRANSLATE_MODAL.TITLE': 'Translate texts',
  'INFOCENTER.TRANSLATE_MODAL.SUBTITLE': 'Current translation',
  'INFOCENTER.TRANSLATE_MODAL.INPUT_PLACEHOLDER': 'Enter translation',
  'INFOCENTER.TRANSLATE_MODAL.DELETE': 'Delete translation',
  'INFOCENTER.NOT_ACTIVATED_YET': 'The Info Center is not yet activated for your administration.',

  STARTING_BACKEND_KEYS: '---',

  'PUSH.NEW_ASSIGNMENT': 'New assignment',
  'PUSH.NEW_COMMENT': 'New comment',
  'PUSH.NEW_MANUAL_REGISTRATION': 'New manual registration',
  'PUSH.STATUS': 'Status',
  'PUSH.MESSAGE': 'Message',
  'PUSH.REPAIR': 'Repair report',
  'PUSH.KEYORDER': 'Key or card order',
  'PUSH.NAMEPLATE': 'Name plate order',
  'PUSH.DOCUMENT': 'Document or form order',
  'PUSH.OTHER': 'Other message',
  'PUSH.PINBOARD_ENTRY': 'Pinboard entry',
  'PUSH.NEW_VOTE_ITEM': 'New vote item',
  'PUSH.NEW_DOCUMENTS': 'New documents',
  'PUSH.NEW_DOCUMENTS_REMAINING_one': '+ {{count}} other document',
  'PUSH.NEW_DOCUMENTS_REMAINING_other': '+ {{count}} other documents',
  'PUSH.APPROVAL': 'Approval',
  'PUSH.VOTE': 'Vote',
  'PUSH.NEW_VOTE': 'New vote',
  'PUSH.SURVEY': 'Survey',
  'PUSH.VOTE_SUBMITTED_FROM': 'Vote submitted from',
  'PUSH.VOTE_AVAILABLE_NOW': 'The vote "{{voteTitle}}" is now available. Cast your vote digitally in the ImmoApp.',

  'EMAIL.HEADER.GREETING': 'Dear Mr/Mrs {{name}}',
  'EMAIL.HEADER.ACCOUNT_ACTIVATED': 'Your account was activated',
  'EMAIL.HEADER.INVITED_BY_OWNER': 'Your landlord invited you',
  'EMAIL.HEADER.IMMOAPP_ACCESS': 'The {{appName}} (access and introduction)',
  'EMAIL.HEADER.ADMIN_ACCESS': 'Access to the administration area',
  'EMAIL.HEADER.NEW_PRIVATE_REPAIR': 'New private damage report',
  'EMAIL.HEADER.NEW_MESSAGE': 'New message',
  'EMAIL.HEADER.NEW_REPAIR': 'New repair report',
  'EMAIL.HEADER.NEW_KEYORDER': 'New key or card order',
  'EMAIL.HEADER.NEW_DOCUMENT': 'New document or form order',
  'EMAIL.HEADER.NEW_OTHER': 'New other message',
  'EMAIL.HEADER.NEW_NAMEPLATE': 'New nameplate order',
  'EMAIL.HEADER.NEW_PINBOARD': 'New pinboard entry',
  'EMAIL.HEADER.NEW_VOTEITEM': 'New vote item',
  'EMAIL.HEADER.FORWARDED_MESSAGE': 'Forwarded message',
  'EMAIL.HEADER.FORWARDED_REPAIR': 'Forwarded damage report',
  'EMAIL.HEADER.FORWARDED_KEYORDER': 'Forwarded key or card order',
  'EMAIL.HEADER.FORWARDED_DOCUMENT': 'Forwarded document or form order',
  'EMAIL.HEADER.FORWARDED_OTHER': 'Forwarded other message',
  'EMAIL.HEADER.FORWARDED_NAMEPLATE': 'Forwarded nameplate order',
  'EMAIL.HEADER.FORWARDED_PINBOARD': 'Forwarded pinboard entry',
  'EMAIL.HEADER.FORWARDED_VOTEITEM': 'Forwarded vote item',
  'EMAIL.HEADER.REPAIR_UPDATED': 'Damage report "{{entryTitle}}" in {{locationName}} was updated to "{{status}}"',
  'EMAIL.HEADER.KEYORDER_UPDATED': 'Key or card order "{{entryTitle}}" by {{senderName}} was updated to "{{status}}"',
  'EMAIL.HEADER.NAMEPLATE_UPDATED': 'Name plate order "{{entryTitle}}" from {{senderName}} has been updated to "{{status}}"',
  'EMAIL.HEADER.DOCUMENT_UPDATED': 'Document or form order "{{entryTitle}}" from {{senderName}} has been updated to "{{status}}"',
  'EMAIL.HEADER.OTHER_UPDATED': 'Other message "{{entryTitle}}" from {{senderName}} has been updated to "{{status}}"',
  'EMAIL.HEADER.NEW_COMMENT_MESSAGE': 'New comment for message',
  'EMAIL.HEADER.NEW_COMMENT_REPAIR': 'New comment for damage report',
  'EMAIL.HEADER.NEW_COMMENT_KEYORDER': 'New comment for key or card order',
  'EMAIL.HEADER.NEW_COMMENT_NAMEPLATE': 'New comment on the name plate order',
  'EMAIL.HEADER.NEW_COMMENT_DOCUMENT': 'New comment on document or form order',
  'EMAIL.HEADER.NEW_COMMENT_OTHER': 'New comment on other message',
  'EMAIL.HEADER.NEW_COMMENT_PINBOARD': 'New comment for pinboard entry',
  'EMAIL.HEADER.ACCESS_CODE_FOR.VOTE': 'Your access code for the vote',
  'EMAIL.HEADER.ACCESS_CODE_FOR.SURVEY': 'Your access code for the survey',
  'EMAIL.HEADER.WARRANT.GIVEN': 'Granted power of attorney for vote',
  'EMAIL.HEADER.WARRANT.RECEIVED': 'Received power of attorney for vote',
  'EMAIL.HEADER.VOTE_RESULTS.DEFAULT': 'Your vote results for',
  'EMAIL.HEADER.VOTE_RESULTS.WARRANT': 'Your warrant for',
  'EMAIL.HEADER.INVITE.FUTURE': 'Admin update ImmoApp: Users will be invited soon',
  'EMAIL.HEADER.INVITE.PAST': 'Admin update ImmoApp: Users have been invited',
  'EMAIL.HEADER.EXTERNAL': 'New message from the ImmoApp',
  'EMAIL.HEADER.EMAIL_CHANGE': 'Security notice: Email address has been changed',
  'EMAIL.HEADER.PASSWORD_CHANGE': 'Security notice: Password has been changed',

  'EMAIL.CONTENT.ACCOUNT_ACTIVATED': 'Your account for the {{appName}} was activated.',
  'EMAIL.CONTENT.DIRECT_ACCOUNT_LOGIN': 'You can now log in with your email address and the password you have chosen.',
  'EMAIL.CONTENT.VERIFY_ACCOUNT_LOGIN_1': 'Please confirm your e-mail address by clicking on the following link',
  'EMAIL.CONTENT.VERIFY_ACCOUNT_LOGIN_2A': 'You can then log in with your e-mail address and the password you have chosen.',
  'EMAIL.CONTENT.VERIFY_ACCOUNT_LOGIN_2B': 'You can then log in with your email address and the following password',
  'EMAIL.CONTENT.IMMOAPP_AVAILABILITY': 'The ImmoApp is available free of charge in the app stores and as a web application',
  'EMAIL.CONTENT.WEB_BROWSER': 'Web / browser',
  'EMAIL.CONTENT.IMMOAPP_INTRODUCTION': 'Here you find a short introduction to the ImmoApp',
  'EMAIL.CONTENT.INVITED_BY_OWNER': 'Your landlord invited you to use the {{appName}}.',
  'EMAIL.CONTENT.IMMOAPP_LINKS': 'You can use the ImmoApp free of charge via the following links',
  'EMAIL.CONTENT.IMMOAPP_AVAILABLE': 'As of today, you can use the {{appName}} on your smartphone, tablet and computer.',
  'EMAIL.CONTENT.FUNCTIONALITIES_INTRO': 'Via the app you can now',
  'EMAIL.CONTENT.FUNCTIONALITIES_REPAIRS': 'Send damage reports',
  'EMAIL.CONTENT.FUNCTIONALITIES_KEYORDER': 'Place an order for keys or access cards',
  'EMAIL.CONTENT.FUNCTIONALITIES_NAMEPLATE': 'Order a name plate',
  'EMAIL.CONTENT.FUNCTIONALITIES_DOCUMENTORDER': 'Order documents and forms',
  'EMAIL.CONTENT.FUNCTIONALITIES_NEWS': 'Receive news about your property',
  'EMAIL.CONTENT.FUNCTIONALITIES_PINBOARD': 'Write pinboard entries for your neighbours',
  'EMAIL.CONTENT.FUNCTIONALITIES_DOCUMENTS': 'View documents of the property online',
  'EMAIL.CONTENT.FUNCTIONALITIES_VOTES': 'Participate in owners meetings',
  'EMAIL.CONTENT.FUNCTIONALITIES_TENANTS': 'Add and remove tenants independently to the ImmoApp. This gives tenants limited access to news, damage reports, pinboard entries and selected documents.',
  'EMAIL.CONTENT.ACCOUNT_ALREADY_CREATED': 'An account has already been created for you. You can log in with the following login data',
  'EMAIL.CONTENT.RECOMMENDATION_PASSWORD_CHANGE': 'We recommend that you change your password the next time you log in via the menu item "Settings".',
  'EMAIL.CONTENT.SEND_EMAIL_TO': 'Please send an email to {{contact}}',
  'EMAIL.CONTENT.INVITED_TO_ADMIN_AREA': 'You have been invited to the {{appName}} administration area.',
  'EMAIL.CONTENT.ADMIN_LOGIN': 'You can login with the following login data in the administration area and the ImmoApp (see below).',
  'EMAIL.CONTENT.ADMIN_SALUTATION': 'Hello admin',
  'EMAIL.CONTENT.HOW_TO_CHANGE_PASSWORD_Q': 'How can I change my password?',
  'EMAIL.CONTENT.HOW_TO_CHANGE_PASSWORD_A': 'This can be done in the app via the menu item "Settings" and then selecting "Reset password". We recommend that you change your password the next time you log in.',
  'EMAIL.CONTENT.NEW_MESSAGE': 'A new message has been created.',
  'EMAIL.CONTENT.NEW_REPAIR': 'A new damage report has been created.',
  'EMAIL.CONTENT.NEW_KEYORDER': 'A new key or card order has been created.',
  'EMAIL.CONTENT.NEW_NAMEPLATE': 'A new name plate order has been submitted.',
  'EMAIL.CONTENT.NEW_DOCUMENT': 'A new document or form order has been submitted.',
  'EMAIL.CONTENT.NEW_OTHER': 'A new other message has been submitted.',
  'EMAIL.CONTENT.NEW_PINBOARD': 'A new pinboard entry was created.',
  'EMAIL.CONTENT.NEW_VOTEITEM': 'A new vote item was created. You can easily add this to an existing or new vote in the Admin-App.',
  'EMAIL.CONTENT.EXTERNAL.SUBMITTED': 'The entry was submitted using the publicly available contact form.',
  'EMAIL.CONTENT.EXTERNAL.VISIBLE': 'Only visible to administrators until a property is assigned.',
  'EMAIL.CONTENT.FORWARDED_MESSAGE': 'A message from the ImmoApp has been forwarded to you.',
  'EMAIL.CONTENT.FORWARDED_REPAIR': 'A damage report from the ImmoApp has been forwarded to you.',
  'EMAIL.CONTENT.FORWARDED_KEYORDER': 'A key or card order from the ImmoApp has been forwarded to you.',
  'EMAIL.CONTENT.FORWARDED_NAMEPLATE': 'A name plate order from the ImmoApp has been forwarded to you.',
  'EMAIL.CONTENT.FORWARDED_DOCUMENT': 'A document or form order from the ImmoApp has been forwarded to you.',
  'EMAIL.CONTENT.FORWARDED_OTHER': 'A miscellaneous message from the ImmoApp has been forwarded to you.',
  'EMAIL.CONTENT.FORWARDED_PINBOARD': 'A pinboard entry from the ImmoApp has been forwarded to you.',
  'EMAIL.CONTENT.FORWARDED_VOTEITEM': 'An vote item from the ImmoApp has been forwarded to you.',
  'EMAIL.CONTENT.REPAIR_UPDATED': 'The repair report "{{entryTitle}}" has been updated.',
  'EMAIL.CONTENT.KEYORDER_UPDATED': 'The key or card order "{{entryTitle}}" has been updated.',
  'EMAIL.CONTENT.NAMEPLATE_UPDATED': 'The name plate order "{{entryTitle}}" has been updated.',
  'EMAIL.CONTENT.DOCUMENT_UPDATED': 'The document or form order "{{entryTitle}}" has been updated.',
  'EMAIL.CONTENT.OTHER_UPDATED': 'The miscellaneous message "{{entryTitle}}" has been updated.',
  'EMAIL.CONTENT.NEW_STATUS': 'New status',
  'EMAIL.CONTENT.NO_DESCRIPTION': 'Without description',
  'EMAIL.CONTENT.INITIAL_SENDER': 'Original sender',
  'EMAIL.CONTENT.NEW_COMMENT_MESSAGE': 'A new comment was added to the message "{{entryTitle}}".',
  'EMAIL.CONTENT.NEW_COMMENT_REPAIR': 'A new comment was added to the damage report "{{entryTitle}}".',
  'EMAIL.CONTENT.NEW_COMMENT_KEYORDER': 'A new comment was added to the key or card order "{{entryTitle}}".',
  'EMAIL.CONTENT.NEW_COMMENT_NAMEPLATE': 'A new comment was added to the name plate order "{{entryTitle}}".',
  'EMAIL.CONTENT.NEW_COMMENT_DOCUMENT': 'A new comment was added to the document or form order "{{entryTitle}}".',
  'EMAIL.CONTENT.NEW_COMMENT_OTHER': 'A new comment has been added to the miscellaneous message "{{entryTitle}}".',
  'EMAIL.CONTENT.NEW_COMMENT_PINBOARD': 'A new comment was added to the pinboard entry "{{entryTitle}}".',
  'EMAIL.CONTENT.NOW_AVAILABLE.VOTE': 'The vote "{{title}}" is now available.',
  'EMAIL.CONTENT.NOW_AVAILABLE.SURVEY': 'The survey "{{title}}" is now available.',
  'EMAIL.CONTENT.VOTE_WITH_CODE': 'You can now vote with the following access code',
  'EMAIL.CONTENT.SUBMIT_THANK_YOU.VOTE': 'Thank you for taking part in the vote',
  'EMAIL.CONTENT.SUBMIT_THANK_YOU.SURVEY': 'Thank you for taking part in the survey',
  'EMAIL.CONTENT.SUBMIT_RESULTS.DEFAULT': 'Attached is a copy of your selection.',
  'EMAIL.CONTENT.SUBMIT_RESULTS.WARRANT': 'Attached is a copy your warrant.',
  'EMAIL.CONTENT.VOTE_RESULT_PDF_TITLE.DEFAULT': 'Vote results',
  'EMAIL.CONTENT.VOTE_RESULT_PDF_TITLE.WARRANT': 'Warrant',
  'EMAIL.CONTENT.KEYORDER.QUANTITY': 'Quantity',
  'EMAIL.CONTENT.KEYORDER.LABEL': 'Key label',
  'EMAIL.CONTENT.NAMEPLATE.ROW1': 'Line 1',
  'EMAIL.CONTENT.NAMEPLATE.ROW2': 'Line 2',
  'EMAIL.CONTENT.ATTACHMENTS_REMOVED': 'The attachments added by the sender exceed the 25MB limit and have therefore been removed from this email. You can view the attachments in the ImmoApp.',
  'EMAIL.CONTENT.WARRANT.GIVEN': 'You have granted power of attorney to {{warrantPartnerName}} ({{warrantPartnerEmail}}) for the vote "{{voteTitle}}" ({{voteMandateName}}).',
  'EMAIL.CONTENT.WARRANT.RECEIVED': 'You have received a power of attorney from {{warrantPartnerName}} ({{warrantPartnerEmail}}) for the vote "{{voteTitle}}" ({{voteMandateName}}).',
  'EMAIL.CONTENT.WARRANT.ATTACHED_COPY': 'Attached is a copy of the power of attorney.',
  'EMAIL.CONTENT.INVITE.FUTURE.1': 'The following list shows all {{quantity}} users who will be invited at {{time}} (CET).',
  'EMAIL.CONTENT.INVITE.FUTURE.2': 'Note: If you do not want this, you can deactivate the automatic sending of invitations in the WebApp under "Manage platform" > "ImmoApp" > "ERP connection" > "Create user automatically". In this case, please also contact ImmoDigi AG Tech Support so that the necessary adjustments can be made.',
  'EMAIL.CONTENT.INVITE.PAST.1': 'The following list shows all {{quantity}} users who have just been successfully created and invited.',
  'EMAIL.CONTENT.INVITE.SKIPPED': '({{quantity}} users were skipped. This limit exists to avoid possible import errors. Run the action again in the web app so that the remaining users also receive an invitation.)',
  'EMAIL.CONTENT.EXTERNAL': 'Your administration has recorded a new message in the ImmoApp.',
  'EMAIL.CONTENT.PREVIEW': 'Note: This email is just a preview and therefore does not contain any attachments.',
  'EMAIL.CONTENT.TITLE_OF_MESSAGE': 'Message title',
  'EMAIL.CONTENT.TICKET_ASSIGNMENT1': 'You have been assigned a new task from {{administrationName}}.',
  'EMAIL.CONTENT.TICKET_ASSIGNMENT2': 'Click {{link}} to open the task in the ImmoApp.',
  'EMAIL.CONTENT.EMAIL_CHANGE_1': 'We would like to inform you that your email address for your ImmoApp account has just been changed to "{{newEmail}}".',
  'EMAIL.CONTENT.PASSWORD_CHANGE_1': 'We would like to inform you that your password for your ImmoApp account has just been changed.',
  'EMAIL.CONTENT.EMAIL_PASSWORD_CHANGE_2': 'If you have not made this change, please contact our support team immediately at {{supportEmail}}.',
  'EMAIL.CONTENT.EMAIL_PASSWORD_CHANGE_3': 'Thank you for keeping your ImmoApp account secure.',

  'EMAIL.FOOTER.UNSUBSCRIBE.INTRO': 'This email is automatically sent to ImmoApp users on behalf of {{contact}}.',
  'EMAIL.FOOTER.UNSUBSCRIBE.PUBLIC': 'If you no longer wish to receive these emails, write to us at: {{contact}}.',
  'EMAIL.FOOTER.UNSUBSCRIBE.SETTINGS': 'If you no longer wish to receive these e-mails, you can deactivate them in the {{immoappLink}} settings.',
  'EMAIL.FOOTER.GREETING': 'Best regards',
  'EMAIL.FOOTER.LEGAL': 'Software provided by ImmoDigi AG.',
  'EMAIL.FOOTER.EXTERNAL': 'Open the message directly in the ImmoApp. With the ImmoApp you receive all the latest news about your property directly on your smartphone. You can download the ImmoApp from {{appStoreLink}} and {{playStoreLink}} or open it directly in the {{browserLink}}. You have already received your personal access data from your administration via email. If you no longer have these, you can use the "Password reset" function in the ImmoApp.<br/>If you no longer wish to receive these emails, please log in to the ImmoApp and change the notification settings.',

  'INTRO_PDF.TITLE': 'Introduction to the ImmoApp',
  'INTRO_PDF.WELCOME.TITLE': 'Introduction',
  'INTRO_PDF.WELCOME.TEXT': 'Welcome and thank you for your interest in the ImmoApp. In the following document you will find lots of information and explanations for a successful start with the ImmoApp. If you have any questions, please feel free to contact us at any time',
  'INTRO_PDF.SEE_VIDEO': 'For a very brief overview, you are also welcome to watch our one and a half minute info video',
  'INTRO_PDF.DISCOVER_IMMOAPP': 'Discover the ImmoApp',
  'INTRO_PDF.DOWNLOAD_IMMOAPP': 'Download and launch ImmoApp',
  'INTRO_PDF.LINKS.INTRO': 'You can use the ImmoApp via the following links',
  'INTRO_PDF.LINKS.IOS': 'For iOS',
  'INTRO_PDF.LINKS.ANDROID': 'For Android',
  'INTRO_PDF.LINKS.BROWSER': 'For Web / Browser',
  'INTRO_PDF.START_LOGIN': 'Start the ImmoApp and log in with the credentials we sent you by email.',
  'INTRO_PDF.HOME.TITLE': 'Home Screen',
  'INTRO_PDF.HOME.OVERVIEW': 'Once you start the ImmoApp, you will see the home screen with the following sections',
  'INTRO_PDF.HOME.A.TEXT': 'A) The start area shows your profile picture on the left, as well as an info text and picture for one of your properties.',
  'INTRO_PDF.HOME.A.CLICK1': 'A click on the round white button will take you to an overview of all your properties.',
  'INTRO_PDF.HOME.A.CLICK2': 'A click on the button "Damage report", opens a new window for entering the damage report.',
  'INTRO_PDF.HOME.B.TITLE': 'B) All messages, pinboard entries and damage reports, sorted by month.',
  'INTRO_PDF.HOME.B.CLICK': 'A click on an entry opens a new view with more details.',
  'INTRO_PDF.IMPORTANT_CONTACTS.TITLE': 'Important Contacts',
  'INTRO_PDF.IMPORTANT_CONTACTS.TEXT': 'A click on the round white button from A) opens an overview of all your properties. Select a property and you will be taken to a detail page where all the important contact details are listed.',
  'INTRO_PDF.REPORT.TITLE': 'Detail view of a report',
  'INTRO_PDF.REPORT.TEXT_1': 'Now click on an entry from the home screen to see the detail view.',
  'INTRO_PDF.REPORT.TEXT_2': 'In the upper area you can see the attached images, if any. By swiping left and right, you can view more images.',
  'INTRO_PDF.REPORT.TEXT_3': 'If it is a ticket, all previous progress is displayed in a clear history: from creation to successful completion, tenants and owners remain well informed at all times.',
  'INTRO_PDF.REPORT.TEXT_4': 'The bottom area contains attachments and comments added to the message.',
  'INTRO_PDF.REPORT.TEXT_5': 'You can edit or delete a submitted comment if necessary.',
  'INTRO_PDF.DOCUMENTS.TITLE': 'Documents',
  'INTRO_PDF.DOCUMENTS.TEXT_1': 'You can switch to documents through the navigation bar (at the bottom of the ImmoApp).',
  'INTRO_PDF.DOCUMENTS.TEXT_2': 'If you already know what you are looking for, you can enter the file name in the search bar on the starting page.',
  'INTRO_PDF.DOCUMENTS.TEXT_3': 'Otherwise, click on a property to navigate to its main folder. Click through the file system until you get to the document you are looking for and open it with a click.',
  'INTRO_PDF.DOCUMENTS.TEXT_4': 'Tip: If the documents are a little too small to read, you can also export them and view them on a larger screen.',
  'INTRO_PDF.ADD_REPORT.TITLE': 'Add message',
  'INTRO_PDF.ADD_REPORT.TEXT_1': 'Using the colored round button at the bottom center of the ImmoApp, you can access a selection of reports that you can submit to the administration.',
  'INTRO_PDF.ADD_REPORT.TEXT_2': 'Select one of the options and a new window will open where you can enter information such as location, title and description. In addition, images, videos and documents can be optionally added.',
  'INTRO_PDF.ADD_REPORT.TEXT_3': 'In case of a damage report, you can also specify whether the message should be public, and thus all tenants and owners of the property will be automatically notified, or whether the message will only be visible to you and the administration.',
  'INTRO_PDF.VOTES.TITLE': 'Votes',
  'INTRO_PDF.VOTES.A': 'A) Overview: Click on a vote.',
  'INTRO_PDF.VOTES.B': 'B) Detail view: Register here.',
  'INTRO_PDF.VOTES.C': 'C) Access code: Once the voting is active, you can enter the code.',
  'INTRO_PDF.VOTES.D': 'D) Agenda: Overview of all voting items.',
  'INTRO_PDF.VOTES.E': 'E) Voting: Click on each voting item and select an option.',
  'INTRO_PDF.VOTES.F': 'F) Submit: Once everything is selected, you can submit the vote.',
  'INTRO_PDF.PROFILE.TITLE': 'Profile',
  'INTRO_PDF.PROFILE.TEXT_1': 'Through the navigation bar (at the bottom of the ImmoApp) you can go to your profile.',
  'INTRO_PDF.PROFILE.TEXT_2': 'With a click on the round image at the top, you can add a profile picture to your account.',
  'INTRO_PDF.PROFILE.TEXT_3': 'This profile picture will be displayed next to comments you create.',
  'INTRO_PDF.PROFILE.TEXT_4': 'Within the profile screen you will also find a lot of useful information such as re-accessing this guide and administration contact details.',
  'INTRO_PDF.APP_SETTINGS.TITLE': 'App settings',
  'INTRO_PDF.APP_SETTINGS.TEXT_1': 'In the "Profile -> App settings" screen, you can change the language of the ImmoApp and turn notifications on and off',
  'INTRO_PDF.APP_SETTINGS.TEXT_2': 'Push notifications (enabled by default).',
  'INTRO_PDF.APP_SETTINGS.TEXT_3': 'Email notifications (disabled by default).',
  'INTRO_PDF.CONTACT.TITLE': 'Contact',
  'INTRO_PDF.CONTACT.TEXT_1': 'We hope you enjoyed the "tour" of the ImmoApp and we were able to spark your interest.',
  'INTRO_PDF.CONTACT.TEXT_2': 'If you have any other questions, please feel free to contact us at any time',
  'INTRO_PDF.CONTACT.TEXT_3': 'Kind regards',
  'INTRO_PDF.CONTACT.TEXT_4': 'Your {{administration}} team',
  'INTRO_PDF.FOOTER.PAGE': 'Page',

  STARTING_ADMINAPP_KEYS: '---',

  'global.interest': 'Interest',
  'global.inflation': 'Inflation',
  'global.from': 'from',
  'global.until': 'until',
  'global.months': 'months',
  'global.years': 'years',
  'global.days': 'days',
  'global.hours': 'hours',
  'global.minutes': 'minutes',
  'global.seconds': 'seconds',
  'global.updateData': 'Update data',
  'global.allEntries': 'All entries.',
  'global.entriesFromTimeLimit': 'Entries from the last {time} months.',
  'global.search': 'Search...',
  'global.loadAll': 'Load all',
  'global.choices': 'Choices',
  'global.pleaseWait': 'Please wait...',
  'global.portal': 'Portal',
  'global.currency': 'CHF',
  'global.dashboard': 'Dashboard',
  'global.flexible': 'Flexible',
  'global.mandate': 'Mandate',
  'global.mandates': 'Mandates',
  'global.property': 'Property',
  'global.properties': 'Properties',
  'global.account': 'Account',
  'global.object': 'Object',
  'global.objects': 'Objects',
  'global.apartments': 'Apartments',
  'global.residents': 'Residents',
  'global.message': 'Message',
  'global.messages': 'Messages',
  'global.repair': 'Ticket',
  'global.repairs': 'Tickets',
  'global.report': 'Report',
  'global.pinboard': 'Pinboard',
  'global.pinboardEntry': 'Pinboard entry',
  'global.pinboardEntries': 'Pinboard entries',
  'global.formGuide': 'Report assistant',
  'global.user': 'User',
  'global.voteChoice': 'Vote',
  'global.voteChoices': 'Votes',
  'global.vote': 'Vote',
  'global.votes': 'Votes',
  'global.survey': 'Survey',
  'global.person': 'Person',
  'global.persons': 'Persons',
  'global.settings': 'Settings',
  'global.info': 'Info',
  'global.confirm': 'Confirm',
  'global.hide': 'Hide',
  'global.hidden': 'Hidden',
  'global.yes': 'Yes',
  'global.no': 'No',
  'global.undecided': 'Does not matter',
  'global.abstain': 'Abstain',
  'global.none': 'None',
  'global.ok': 'OK',
  'global.cancel': 'Cancel',
  'global.forward': 'Next',
  'global.back': 'Back',
  'global.inDelivery': 'In delivery',
  'global.sent': 'Sent',
  'global.noErrors': 'No errors',
  'global.error': 'Error',
  'global.showAll': 'Show all',
  'global.userroles': 'User roles',
  'global.recipientlist': 'Recipient list for {messageTypeString}',
  'global.norecipients': 'No recipients.',
  'global.generalErrorMessage': 'An error has occurred. Please try again.',
  'global.generalErrorMessageLong': 'There was an unexpected error! Please try again by refreshing the page.',
  'global.emailExistsErrorMessage': 'Error: A user with the specified email address already exists.',
  'global.previewemail.success': 'Email preview was successfully sent to {email}. Please note that it may take up to 5 minutes for the email to appear in your inbox.',
  'global.previewemail.sending': 'Email preview is being sent...',
  'global.previewemail.send': 'Send email preview to {email}',
  'global.previewemail.whoreceives': 'Which user roles receive the message? (Note: Admins always receive the message.)',
  'global.previewemail.warning': 'The following users will not receive a {messageTypeString} because of their personal app settings',
  'global.action': 'Action',
  'global.done': 'Done',
  'global.reset': 'Reset',
  'global.synced': 'Synced',
  'global.missing': 'Missing',
  'global.infoMissing': 'Information missing',
  'global.loading': 'Loading...',
  'global.actionInProgress': 'Action in progress...',
  'global.private': 'Private',
  'global.active': 'Active',
  'global.inactive': 'Inactive',
  'global.scheduled': 'Scheduled',
  'global.completed': 'Completed',
  'global.registered': 'Registered',
  'global.voting': 'Voting',
  'global.upload': 'Upload',
  'global.uploadShort': 'Upload',
  'global.replace': 'Replace',
  'global.save': 'Save',
  'global.tenant': 'Tenant',
  'global.tenants': 'Tenants',
  'global.tenantRestricted': 'Tenant restricted',
  'global.owner': 'Owner',
  'global.owners': 'Owners',
  'global.ownerFkstwe': 'Members',
  'global.noOwnersFound': 'No owners found.',
  'global.janitor': 'Janitor',
  'global.management': 'Management',
  'global.manager': 'Manager',
  'global.admin': 'Manager (Admin)',
  'global.adminRights': 'Admin rights',
  'global.name': 'Name',
  'global.address': 'Address',
  'global.email': 'Email',
  'global.emailAddress': 'Email address',
  'global.close': 'Close',
  'global.closeWindow': 'Close window',
  'global.password': 'Password',
  'global.repeat': 'Repeat',
  'global.sender': 'Sender',
  'global.receiver': 'Recipient',
  'global.noDataFound': 'No data available',
  'global.noFilesAvailable': 'No files available.',
  'global.noFilesUploaded': 'No files uploaded.',
  'global.data': 'Data',
  'global.import': 'Import',
  'global.statistics': 'Statistics',
  'global.document': 'Document',
  'global.documents': 'Documents',
  'global.status': 'Status',
  'global.selectStatus': 'Select status',
  'global.condition': 'Condition',
  'global.timeInfix': 'at',
  'global.timeSuffix': 'hours',
  'global.system': 'System',
  'global.cloudStorage': 'Cloud storage',
  'global.folder': 'Folder',
  'global.title': 'Title',
  'global.description': 'Description',
  'global.usefulInfosAbbr': 'Useful info',
  'global.usefulInfos': 'Useful info',
  'global.sendDate': 'Send date',
  'global.date': 'Date',
  'global.empty': 'Empty',
  'global.image': 'Image',
  'global.images': 'Images',
  'global.content': 'Content',
  'global.file': 'File',
  'global.files': 'Files',
  'global.fileName': 'File name',
  'global.fileNameRequired': 'File name required',
  'global.evaluation': 'Evaluation',
  'global.details': 'Details',
  'global.linkDetailPage': 'To the detail page',
  'global.copy': 'Copy',
  'global.duplicate': 'Duplicate',
  'global.edit': 'Edit',
  'global.utilize': 'Use',
  'global.view': 'View',
  'global.delete': 'Delete',
  'global.reactivate': 'Reactivate',
  'global.deactivate': 'Deactivate',
  'global.verified': 'Verified',
  'global.not': 'Not',
  'global.notVerifiedYet': 'Not yet verified',
  'global.approve': 'Approve',
  'global.reject': 'Reject',
  'global.push': 'Push',
  'global.news': 'News',
  'global.all': 'All',
  'global.type': 'Type',
  'global.city': 'City',
  'global.id': 'ID',
  'global.add': 'Add',
  'global.translate': 'Translate',
  'global.constructionYear': 'Year of construction',
  'global.public': 'Public',
  'global.publicText': 'Should the ticket be publicly visible?',
  'global.pushToggle': 'Push message',
  'global.pushText': 'Should a push message be sent? Only users who have activated push messages will receive the message. (Activated by default)',
  'global.defaultActivated': 'Activated by default',
  'global.defaultDeactivated': 'Deactivated by default',
  'global.emailInternalToggle': 'Email to ImmoApp users',
  'global.emailExternalToggle': 'Email to Non-ImmoApp users',
  'global.emailExternalText': 'Should all users of the selected properties/properties who have already been invited to ImmoApp but are not yet actively using it also receive an email with the information in this message? Note: There is a link within the email to unsubscribe from future emails.',
  'global.emailToggle': 'Email',
  'global.emailInternToggle': 'Internal email',
  'global.emailExternToggle': 'External email',
  'global.emailText': 'Should an email be sent? Only users who have activated email delivery will receive the email.',
  'global.staff': 'Employees',
  'global.category': 'Category',
  'global.firstName': 'First name',
  'global.lastName': 'Last name',
  'global.dateOfBirth': 'Date of birth',
  'global.filter': 'Filter',
  'global.total': 'Total',
  'global.quantity': 'Quantity',
  'global.complement': 'Complement',
  'global.lastUpdate': 'Updated',
  'global.update': 'Update',
  'global.sort': 'Sort',
  'global.updated': 'Updated',
  'global.mode': 'Mode',
  'global.new': 'New',
  'global.na': 'N/A',
  'global.communication': 'Communication',
  'global.logout': 'Log out',
  'global.enterTitle': 'Enter title',
  'global.enterMessage': 'Enter message',
  'global.enterDescription': 'Enter description',
  'global.enterInfos': 'Enter information',
  'global.typeDropdown': 'Please select the type',
  'global.enterLastnameEmail': 'Enter last name / email',
  'global.receiverDropdown': 'Please select the receiver',
  'global.mandateDropdown': 'Please select a mandate',
  'global.propertyDropdown': 'Please select a property',
  'global.propertiesDropdown': 'Please select one or more properties',
  'global.version': 'Version',
  'global.errorGeneric': 'Field is required',
  'global.errorTitle': 'Title is required',
  'global.errorDescription': 'Description is required',
  'global.errorMandateDropdown': 'Mandate is required',
  'global.errorPropertyDropdown': 'Property is required',
  'global.deleteConfirmLong': 'Do you really want to delete this record?',
  'global.deleteConfirmShort': 'Really delete?',
  'global.code': 'Code',
  'global.optional': 'Optional',
  'global.fieldRequired': 'This field is required.',
  'global.selectComponentType': 'Please select the component type.',
  'global.numbersOnly': 'Only numbers are allowed.',
  'global.max4': 'Maximum of 4 characters allowed.',
  'global.max10': 'Maximum 10 characters allowed.',
  'global.max50': 'Maximum 50 characters allowed.',
  'global.min4': 'At least 4 characters required.',
  'global.enterProperValue': 'Enter the correct value.',
  'global.valueBelow100': 'Enter a value below 100.',
  'global.onlyIntegers': 'Please only enter whole numbers.',
  'global.validEmail': 'Please enter a valid email address.',
  'global.passwordCharacterValidation': 'The password should be at least 6 characters long.',
  'global.repeatPasswordMismatch': 'Passwords are not identical.',
  'global.street': 'Street',
  'global.houseNumber': 'House nr',
  'global.street_and_nr': 'Street & house nr',
  'global.zipCode': 'Zipcode',
  'global.reusableFile': 'Reusable file',
  'global.reusableFiles': 'Reusable files',
  'global.enterSender': 'Enter sender',
  'global.uploadRestrictionsFiles': 'Only files up to max. 20 MB allowed.',
  'global.uploadRestrictionsImages': 'Only images up to max. 20 MB allowed.',
  'global.dateOfIncident': 'Date of the damage',
  'global.selectDate': 'Select date',
  'global.selectTime': 'Select time',
  'global.errorDate': 'Date is required',
  'global.errorUser': 'Sender is required',
  'global.access': 'Access',
  'global.accesses': 'Accesses',
  'global.accessRights': 'Access rights',
  'global.noAccessForTool': 'You currently do not have access to this tool.',
  'global.actionConfirm': 'Execute action?',
  'global.zip': 'Zipcode',
  'global.withoutAreaCode': 'without area code',
  'global.terminationProcess': 'Termination process',
  'global.home': 'Home',
  'global.removedDisallowedCharacter': 'Disallowed special character has been removed.',
  'global.automatic': 'Automatic',
  'global.manual': 'Manual',
  'global.loadingIssue': 'There is a loading problem',
  'global.agendaItem': 'Agenda item',
  'global.phoneNumberShort': 'Phone number',
  'global.phoneNumberLong': 'Phone number',
  'global.extraInfo': 'Extra info',
  'global.comment': 'Comment',
  'global.comments': 'Comments',
  'global.commentsToggleText': 'Allow comments?',
  'global.addComment': 'Add comment',
  'global.commentAdded': 'Comment was successfully added.',
  'global.moreInfo': 'More info',
  'global.fileUploaded': 'File was successfully uploaded.',
  'global.filesUploaded': 'Files have been successfully uploaded.',
  'global.filesUpdated': 'Files have been successfully updated.',
  'global.downloadResults': 'Download voting results?',
  'global.download': 'Download',
  'global.downloadAgain': 'Download again',
  'global.downloadSuccess': 'Download successful',
  'global.downloadInProgress': 'Download in progress',
  'global.role': 'Role',
  'global.selectRole': 'Select role',
  'global.allProperties': 'All properties',
  'global.noObjectConnection': 'Without connection to objects',
  'global.dateAndDetails': 'Date & Details',
  'global.attachment': 'Attachment',
  'global.attachments': 'Attachments',
  'global.handyman': 'Handyman',
  'global.notification': 'Notification',
  'error.page500message': 'An unknown error has occurred. The developers have been informed and will fix the problem soon. Please try again later.',
  'menu.manageEmployees': 'Manage employees',
  'menu.managePlatform': 'Manage platform',
  'business.maxTwoComponents': 'The demo version allows max. 2 building components',
  'auth.userNotFound': 'There is no user with the entered email address. The user may have been deleted.',
  'auth.wrongPassword': 'The password is invalid.',
  'auth.invalidPassword': 'The password is invalid or the user does not have a password.',
  'auth.invalidEmail': 'The email address is formatted incorrectly.',
  'auth.unsupportedPersistence': 'Your browser does not seem to allow access to cookies. Please enable them and try again.',
  'auth.unsupportedApp': 'Please switch to the new browser version of the ImmoApp at {webappUrl}',
  'auth.fillFields': 'Please enter your email address and password.',
  'auth.weakPassword': 'The password should consist of at least 6 characters.',
  'auth.emailExistsTenantTitle': 'Tenant already exists',
  'auth.emailExistsTenantText': 'The specified email address is used by an existing tenant. Please confirm that you wish to start a termination process for this tenant.',
  'auth.emailExistsOwnerStaffTitle': 'User already exists',
  'auth.emailExistsOwnerStaffText': "The specified email address is used by an existing user. As this user does not have the status 'Tenant', you cannot start a termination process for this user.",
  'auth.emailNotVerifiedTitle': 'Your account is not yet verified.',
  'auth.emailNotVerifiedRepeat': ' again',
  'auth.emailNotVerifiedText': 'A verification email has been sent to you{repeat}. Please check your mailbox.',
  'auth.noPermissionsLong': 'Unfortunately, you do not have permission to access this page.',
  'auth.noPermissionsShort': 'Missing or insufficient permissions',
  'auth.invalidZipCode': 'Invalid zip code',
  'auth.userRegisteredSuccess': 'User has been successfully registered.',
  'auth.userRegisteredError': 'User could not be registered. Please try again later.',
  'message.email.internal.showlist': 'Show recipient list for internal email',
  'message.email.external.showlist': 'Show recipient list for external email',
  'message.notification.showlist': 'Show recipient list for push notification',
  'mandate.pageSubTitle': 'Your mandates at a glance',
  'mandate.pageSearchbox': 'Search in mandates...',
  'mandate.filterButtonThree': 'Floor area',
  'mandate.filterButtonFour': '< 100,000 CHF',
  'mandate.sublabelMandateStatus': 'Only active mandates are available in the ImmoApp and are counted towards your subscription quota.',
  'mandate.sublabelMandateImage': 'Upload an image of the mandate here.',
  'mandate.sublabelPinboard': 'Can new pinboard entries be published for this mandate?',
  'mandate.sublabelFormGuide': 'Can the report assistant be used for this property?',
  'mandate.addMandateImage': 'Enter a valid image',
  'mandate.tablecolumnTwo': 'Name + address',
  'mandate.StatusFilterNotSync': 'Out of sync',
  'mandate.deleted': 'Mandate was successfully deleted.',
  'mandate.updated': 'Mandate has been successfully updated.',
  'mandate.facility': 'Facility',
  'mandate.form.error': 'Please select at least one mandate or property.',
  'ticket.repair': 'Repair report',
  'ticket.keyorder': 'Order: Key or card',
  'ticket.nameplate': 'Order: Name tag',
  'ticket.document': 'Order: Document or form',
  'ticket.other': 'Other message',
  'page.signInRememberMe': 'Stay logged in',
  'page.signInButton': 'Log in',
  'page.signInForgotPass': 'Forgot your password',
  'repairs.contactInfo.noEmail': 'Email not available',
  'repairs.contactInfo.noPhone': 'Phone not available',
  'signintools.accesses': 'Accesses',
  'signintools.adminAccessStaff': 'Administrative access',
  'signintools.adminAccessImmoMove': 'Login for the termination process',
  'signintools.signIn': 'Sign in',
  'signintools.registerQuestion': 'No account yet?',
  'signintools.register': 'Registration',
  'signintools.registerAction': 'Register',
  'signintools.registerActionNow': 'Register now',
  'signintools.registerSuccessTitle': 'Registration successful!',
  'signintools.registerSuccessTextMain': 'Thank you for your registration. Please confirm your email address. A verification email has been sent to you.',
  'signintools.registerSuccessTextConditional': 'Note: The administration will first check your account before you have access to it. You will be informed about the approval by email.',
  'signintools.registerErrorTitle': 'Registration failed!',
  'signintools.registerErrorTextEmailExists': 'The email address you entered is already in use. Please try again. If you need help, please contact your administration.',
  'signintools.registerErrorTextGeneral': 'Please try again. If the error still occurs, please contact your administration.',
  'signintools.forgotPassword': 'Forgot your password?',
  'signintools.forgotLogin': 'Forgot your login details?',
  'signintools.legal': 'Legal',
  'signintools.immoDigi': 'ImmoDigi AG',
  'signintools.contact': 'Contact',
  'signintools.faq': 'FAQs',
  'signintools.termsOfUse': 'Terms of use',
  'signintools.website': 'Website',
  'signintools.moreAboutImmoDigi': 'More about ImmoDigi',
  'signintools.moreAboutImmoApp': 'More about ImmoApp',
  'signintools.imprint': 'Imprint',
  'signintools.dataProtection': 'Data privacy',
  'signintools.accessCode': 'Access code',
  'signintools.wrongInput': 'Incorrect email address or incorrect zip code.',
  'signintools.resetPasswordTitle': 'Reset password',
  'signintools.resetPasswordText': 'If you confirm with OK, instructions on how to change your password will be sent to you by email.',
  'signintools.resendCodeText': 'If you confirm with OK, the access code will be sent to your email again.',
  'themeSwitcher.settings': 'Settings',
  'topbar.help': 'Help',
  'dashboard.pageTitle1': 'Your mandates',
  'dashboard.pageTitle2': 'All news',
  'dashboard.pageTitle3': 'Status overview',
  'dashboard.importantContacts': 'Important contacts',
  'dashboard.actionEmail': 'Send an email',
  'dashboard.actionPhone': 'Call',
  'dashboard.repairsPending': '{repairs} pending',
  'dashboard.repairsInProgress': '{repairs} in progress',
  'dashboard.repairsCompleted': '{repairs} completed',
  'dashboard.noContactsAvailable': 'No contacts available.',
  'dashboard.entriesFromTimeLimit': 'Entries from the last {time} months taken into account.',
  'dashboard.allEntriesTaken': 'All entries taken into account.',
  'mandateDetails.pageSearchbox': 'Search in users...',
  'mandateDetails.cardTitleOne': 'General information',
  'mandateDetails.cardSubTitleThree': 'Statistics',
  'mandateDetails.updateImage': 'Update image',
  'person.pageSubTitle': 'List of all users',
  'person.pageSearchbox': 'Search in users...',
  'person.addButton': 'Add entry',
  'person.addPerson': 'Add person',
  'person.addNewPerson': 'Add new person',
  'person.updatePerson': 'Update person',
  'person.companyName': 'Name of the company',
  'person.addMandateImage': 'Enter a valid image',
  'person.addCompanyName': 'Enter company name',
  'person.addOwnerName': 'Enter the name of the owner',
  'person.userData': 'User data',
  'person.staffData': 'Staff data',
  'person.importedData': 'Imported data',
  'person.emailExternal': 'External email',
  'person.company': 'Company',
  'person.phoneNumber': 'Phone no.',
  'person.mobile': 'Mobile no.',
  'person.phoneHome': 'Phone private',
  'person.phoneWork': 'Phone work',
  'person.fax': 'Fax',
  'person.city': 'City',
  'person.salutation': 'Salutation',
  'person.street': 'Street',
  'person.website': 'Website',
  'person.deleted': 'User deleted',
  'person.edited': 'User updated',
  'person.emailAndVerified': 'Email + verification',
  'person.showContactTitle': 'Show as important contact?',
  'person.showContactText': 'The employee is displayed in the ImmoApp and the WebApp as a contact for all assigned properties.',
  'person.userRestrictedTitle': 'Restrict communication?',
  'person.userRestrictedText': 'If activated, the user is not allowed to post messages or comments.',
  'person.isContact': 'Listed as a contact',
  'person.isNotContact': 'Not listed as a contact',
  'upload.size': 'Size',
  'upload.inprogress': 'Upload is in progress...',
  'upload.progress': 'Upload progress',
  'upload.errorFileCount': 'Too many files selected.',
  'upload.errorFileSize': 'The file size must not exceed 20 MB.',
  'upload.errorFileType': 'File format is not supported.',
  'upload.errorFileTypeImages': 'The file must be of type: .jpg, .jpeg, .png',
  'upload.delete': 'Delete file?',
  'repair.externalTitle': 'Externally submitted ticket',
  'repair.externalDescription': 'Only visible to administrators until a property is assigned.',
  'repair.moreTypesInImmoApp': 'If a different type is required, please enter the ticket in the {immoappLink}.',
  'repair.pageSubTitle': 'Currently pending tickets',
  'repair.pageSearchbox': 'Search in tickets...',
  'repair.addButton': 'Add ticket',
  'repair.updateRepair': 'Update ticket',
  'repair.addRepair': 'Add ticket',
  'repair.viewRepair': 'Ticket (reading mode)',
  'repair.addFileError': 'The selected file is not valid.',
  'repair.tablecolumnOne': 'Report',
  'repair.statusZero': 'Pending',
  'repair.statusOne': 'In progress',
  'repair.statusTwo': 'Completed',
  'repair.selectHandyman': 'Select handyman',
  'repair.handymanHireTitle': 'Hire handyman',
  'repair.handymanNotificationText': 'Should the handyman be notified of the task assignment via email and push?',
  'repair.handymanIndividualMessage': 'Individual message for the handyman',
  'repair.handymanMessageNote': 'Note: The email automatically contains all existing information about the ticket (title, description, recipient, status, sender, files, etc).',
  'repair.handymanAddStatusText': 'Would you also like to add a status to the ticket?',
  'repair.errorHandymanDropdown': 'Handyman is required',
  'repair.errorStatusDropdown': 'Status is required',
  'repair.addressProvided': 'Address provided',
  'repair.deleted': 'Ticket was successfully deleted.',
  'repair.updated': 'Ticket has been successfully updated.',
  'repair.yarowa_removed': 'Yarowa process was successfully removed.',
  'repair.locationDetails': 'More details about the location (optional)',
  'repair.moreInformation': 'More information',
  'repair.enterLocationDetails': 'Enter details (optional)',
  'repair.timelineMeta': 'From {name} on {date}',
  'repair.timelineUpdated': 'Timeline has been successfully updated.',
  'repair.inserted': 'Ticket was successfully added.',
  'repair.pushTextPrivateCreate': 'If activated, a push message will be sent to all responsible employees.',
  'repair.emailTextPrivateCreate': 'If activated, an email will be sent to all responsible employees.',
  'repair.pushTextPrivateUpdate': 'If activated, a push message will be sent to all responsible employees and to the user who submitted the message.',
  'repair.emailTextPrivateUpdate': 'If activated, an email will be sent to all responsible employees and to the user who submitted the message.',
  'repair.history': 'History',
  'repair.addStatus': 'Add status',
  'repair.updateStatus': 'Update status',
  'repair.withoutMandate': 'Without assigned mandate',
  'repair.withoutMandatePropertyInfo': 'First select a mandate',
  'repair.withoutProperty': 'Without assigned property',
  'repair.withoutSender': 'Without assigned sender',
  'repair.submittedContactDetails': 'Contact details submitted',
  'repair.addHandymanInfo': 'You can assign a handyman under actions.',
  'repair.contactInfo.noInfo': 'No contact details available.',
  'repair.contactInfo.emailSubject': 'Repair report',
  'messages.errorsFor': 'Errors for',
  'messages.pageSubTitle': 'Your messages at a glance',
  'messages.pageSearchbox': 'Search in messages...',
  'messages.addButton': 'Add message',
  'messages.addMessage': 'Add message',
  'messages.updateMessage': 'Update message',
  'messages.viewMessage': 'Message (reading mode)',
  'messages.addMessageImage': 'Enter a valid image',
  'messages.allMandates': 'All mandates',
  'messages.pushStatusDefault': '[no status]',
  'messages.pushStatusNoPush': 'No push message',
  'messages.pushStatusSendingOut': 'Sending...',
  'messages.pushStatusSentOut': 'Sent',
  'messages.propertyNotice': 'Note: Messages to properties can be seen by all residents of a mandate. However, only the residents of the property will be notified via push notification.',
  'messages.errorSelectValue': 'Please select a value',
  'messages.errorDate': 'Please select a date',
  'messages.errorCategory': 'Category is required',
  'messages.fileProgress': 'Files are being uploaded...',
  'messages.previewEmailSuccess': 'Email was sent successfully.',
  'messages.inserted': 'Message was successfully added.',
  'messages.updated': 'Message was successfully updated.',
  'messages.deleted': 'Message was successfully deleted.',
  'messages.entriesFromTimeLimit': 'Upcoming & entries of the last {time} months.',
  'documents.noticeHeader': 'A user has access to this folder if at least one condition applies:',
  'documents.noticeBullet1': "The user's role is part of the 'User groups' field.",
  'documents.noticeBullet2': "The user has been added to the 'Individual users' field.",
  'documents.noticeBullet3': 'The user has been granted access rights to the folder via import.',
  'documents.noticeBullet4': "The user has the role 'Admin'.",
  'documents.noticeBullet4abaimmo': "The user has the role 'Manager' or 'Admin'.",
  'documents.noticeReset': 'Note: if you remove all user groups, the folder is reset to the default access rights.',
  'documents.accessGroups': 'User groups',
  'documents.accessUser': 'Individual users',
  'documents.accessUserImport': 'Imported users',
  'documents.selectGroup': 'Select user group (optional)',
  'documents.selectUser': 'Select user (optional)',
  'documents.noImportedUsers': 'No imported users',
  'documents.accessRightsUpdated': 'Access rights have been successfully updated.',
  'documents.mandate': 'Mandate',
  'documents.property': 'Property',
  'documents.object': 'Object',
  'documents.person': 'Person',
  'settings.tabCorporateIdentity': 'Coporate Identity',
  'settings.tabInfocenter': 'Infocenter',
  'settings.tabImmoApp': 'ImmoApp',
  'settings.tabImmoMove': 'ImmoMove',
  'settings.newManuallyRegisteredUsers': 'New manually registered users',
  'settings.erpConnection': 'ERP connection',
  'settings.staffExisting': 'Existing',
  'settings.changeLogo': 'Change logo',
  'settings.selectLogo': 'Select logo',
  'settings.syncmeTitle': 'SyncMe synchronization',
  'settings.defaultAccessGroupNotice': 'Which user groups should have access to folders by default?',
  'settings.syncmeText': 'You are currently using the "SyncMe" software to synchronize your files with the ImmoApp.',
  'settings.documentsSync': 'Document synchronization',
  'settings.documentsSyncButtonText': 'Synchronize now',
  'settings.documentsSynced': 'Synchronization started successfully.',
  'settings.documentsSyncing': 'Synchronization is starting...',
  'settings.documentsAlreadySyncing': 'Synchronization has already started. Please be patient for a moment.',
  'settings.dataReset': 'Reset data',
  'settings.dataResetButtonText': 'Reset now',
  'settings.dataResetting': 'Data is resetting...',
  'settings.dataResetted': 'Data has been successfully reset.',
  'settings.colorUpdate': 'Change accent color',
  'settings.colorUpdateTextCurrent': 'Current color',
  'settings.colorUpdateTextNew': 'New color',
  'settings.colorUpdateButtonText': 'Change color',
  'settings.colorUpdated': 'Accent color has been successfully updated.',
  'settings.inviteEmailTitle': 'Invitation email test',
  'settings.inviteEmailDescription': 'To check the invitation email including instructions for tenants, owners, janitors and managers, you can send yourself a test email here. Please note: sending the same email too often to the same address may result in a delay of up to 15 minutes.',
  'settings.exampleMandateTitle': 'Example mandate',
  'settings.exampleMandateDescription': 'With the example mandate you can test the functions of the ImmoApp without changing real data or accidentally sending emails to users. The example mandate receives the ID 9999 and the example owners the IDs 99900 to 99999.',
  'settings.exampleMandateCreate': 'Add',
  'settings.exampleMandateReset': 'Reset data',
  'settings.exampleMandateDelete': 'Delete',
  'settings.exampleMandateCreateSuccess': 'Example mandate was successfully added.',
  'settings.exampleMandateResetSuccess': 'Example mandate was successfully reset.',
  'settings.exampleMandateDeleteSuccess': 'Example mandate was successfully deleted.',
  'settings.inviteEmailButton': 'Send test email',
  'settings.inviteEmailSuccess': 'Email was successfully sent.',
  'settings.createUsersTitle': 'Create accounts',
  'settings.createUsersButton': 'Show accounts',
  'settings.createUsersText': 'Create an ImmoApp account for all imported persons and send an invitation email. In the first step, the applicable accounts for all selected mandates are displayed. These accounts will only be created after you confirm again. Note: only active mandates can be selected.',
  'settings.language.invitation': 'Language of the invitation',
  'settings.language.all': 'Language of the invitation and ImmoApp',
  'settings.dataLastUpdatedOn': 'Last updated',
  'settings.defaultFolderAccess': 'Default access to folders',
  'settings.createUsersNotificationEmptyTitle': 'Note',
  'settings.createUsersNotificationEmptyText': 'All imported persons already have an account.',
  'settings.createUsersNotificationConfirmTitle': 'Confirmation',
  'settings.createUsersNotificationConfirmText': 'If you confirm, a new account will be created for the following {personCountTotal} users and an invitation email will be sent.',
  'settings.createUsersNotificationNoInviteText': 'The following {personCountTotal} users will not receive an invitation.',
  'settings.createUsersNotificationConfirmNotice': 'Note: A total of {personCountTotal} imported users do not yet have an account. However, the maximum number for an import is 100 users. Please repeat the process until all users have been successfully imported.',
  'settings.createUsersNotificationWarningTitle': 'Warning',
  'settings.createUsersNotificationWarningText': 'Please activate the option "Create users automatically" before starting the import.',
  'settings.createUsersInProgress': 'All users will receive an account and an invitation email with the access data. This process may take a few minutes, please be patient.',
  'settings.createUsersSuccess': 'All users have been successfully created and have received an invitation email.',
  'settings.logoUpdated': 'Logo updated',
  'settings.filesCommonUpdated': 'Files successfully updated.',
  'settings.dataCommonUpdated': 'Data successfully updated.',
  'settings.settingsFieldUpdated': 'The setting has been successfully updated.',
  'settings.inviteCodeForImmoApp': 'Invitation code for the ImmoApp',
  'settings.autoPermitUsers': 'Automatically activate users?',
  'settings.autoPermitUsersExtra': 'If activated, users are automatically approved for the ImmoApp after manual registration if they already exist in your ERP system.',
  'settings.autoInviteUsers': 'Create users automatically?',
  'settings.autoInviteUsersExtra': 'If activated, an account is automatically created in the ImmoApp for all imported persons and an invitation email is sent (if not already sent). Max {count} new accounts per day. For initial activation, please contact ImmoDigi.',
  'settings.autoDeactivateUsers': 'Automatically deactivate users?',
  'settings.autoDeactivateUsersExtra': 'If activated, a user account is automatically deactivated if it no longer exists in your ERP system.',
  'settings.staffInviteMailTitle': 'Automatically send access data',
  'settings.staffInviteMailText': 'Should the new employee automatically receive their access data by email?',
  'settings.systemEmails': 'Send emails to ImmoApp users?',
  'settings.systemEmailsExtra': 'Attention: if you deactivate this option, no more automatic invitations, reminders, voting codes or similar emails will be sent.',
  'settings.systemPush': 'Send push messages to ImmoApp users?',
  'settings.systemPushExtra': 'Attention: if you deactivate this option, automatic push notifications for new users, messages, repair notifications or similar will no longer be sent.',
  'settings.newDocumentsPush': 'Notify about newly added documents once a day at {time} via push message?',
  'account.notifications': 'Notifications',
  'account.language': 'Language',
  'account.loginAndSecurity': 'Login & Security',
  'account.communicationEmailAdminMessageTitle': 'Email: Messages',
  'account.communicationEmailAdminMessageDescription': 'Receive an email for new messages?',
  'account.communicationEmailAdminRepairTitle': 'Email: Tickets new',
  'account.communicationEmailAdminRepairDescription': 'Receive an email for new tickets?',
  'account.communicationEmailAdminRepairUpdateTitle': 'Email: Ticket update',
  'account.communicationEmailAdminRepairUpdateDescription': 'Receive an email when the ticket status changes?',
  'account.communicationEmailAdminPinboardTitle': 'Email: Pinboard entries',
  'account.communicationEmailAdminPinboardDescription': 'Receive an email for new pinboard entries?',
  'account.communicationEmailAdminCommentTitle': 'Email: Comments',
  'account.communicationEmailAdminCommentDescription': 'Receive an email for new comments on own posts?',
  'account.communicationEmailAdminVoteItemTitle': 'Email: Vote agenda item (only for employees)',
  'account.communicationEmailAdminVoteItemDescription': 'Receive an email for new agenda items?',
  'account.changeEmail': 'Change email',
  'account.newEmail': 'New email address',
  'account.changePassword': 'Change password',
  'account.currentPassword': 'Current password',
  'account.newPassword': 'New password',
  'account.modify': 'Change',
  'account.updateNotification': 'Update notifications',
  'account.notificationSettingsUpdated': 'Notification settings updated',
  'account.passwordChanged': 'Password has been successfully updated.',
  'account.emailChanged': 'Email address has been successfully updated.',
  'pdf.successfullyDownloaded': 'The requested PDF was successfully generated and downloaded.',
  'pdf.errorParameters': 'The transferred data is invalid. Please call up the link from your email again.',
  'pdf.openInBrowser': 'Open directly in the browser',
  'switcher.changeLanguage': 'Change language',
  'staff.addStaff': 'Add staff',
  'staff.addStaffToMandate': 'Select staff member...',
  'staff.errorStaffDropdown': 'Please select a value',
  'staff.added': 'Staff member has been successfully added.',
  'staff.updated': 'Staff member has been successfully updated.',
  'staff.deleted': 'Staff member was successfully deleted.',
  'staff.updateStaff': 'Update staff member',
  'staff.viewStaff': 'Staff member (reading mode)',
  'staff.confirmDelete': 'Do you really want to delete this employee?',
  'user.dataNotAutomaticallySynced': 'Some data is not automatically synchronized from the ERP system.',
  'user.approveUser': 'Approve user',
  'user.viewUser': 'User (reading mode)',
  'user.deleted': 'User was successfully deleted.',
  'user.deactivated': 'User was successfully deactivated.',
  'user.reactivated': 'User was successfully reactivated.',
  'user.edited': 'User has been successfully updated.',
  'user.updateUser': 'Update user',
  'user.confirmDelete': 'Do you really want to delete this user?',
  'user.confirmDeactivate': 'Do you really want to deactivate this user?',
  'user.confirmReactivate': 'Do you really want to reactivate this user?',
  'user.confirmReject': 'Do you really want to reject this user?',
  'user.resentAccess': 'Resend access data',
  'user.resentAccessNotice': 'The new password has been successfully sent to {email}. If you have any questions or login problems, you can share the password displayed above directly with the user.',
  'user.resentAccessConfirm': 'If you confirm, <strong>{user_name} ({user_email})</strong> will be sent another invitation email with a new randomly generated password.',
  'user.resentAccessSuccess': 'Access data has been sent successfully.',
  'user.lastLogin': 'Last login',
  'user.dataRefreshOnceADay': 'The data is updated once a day.',
  'user.invitedOrRegistered': 'The user has been invited or has registered manually.',
  'user.invited': 'Invited',
  'user.invitedBy': 'Invited by',
  'user.login': 'Login',
  'user.didLogin': 'Has already logged in',
  'user.didNeverLogin': 'Has not yet logged in',
  'users.withoutERP': 'User without ERP connection',
  'users.withERP': 'User with ERP connection',
  'users.deactivated': 'Deactivated users',
  'users.connectWithERPInfo': 'The following ImmoApp users are not currently connected to an ERP account. In order to be able to use all ImmoApp functionalities, we recommend creating a connection for as many users as possible. This is done automatically (once a day) if the email address of the ImmoApp user matches that of an ERP account. (Your current ERP system is {erpSystem}.)',
  'source.immoapp_register': 'Registered in ImmoApp',
  'source.webapp_register': 'Registered in WebApp',
  'source.immoapp_tenant': 'Invited by owner',
  'source.immotop_system': 'Invited by system',
  'source.webapp_admin': 'Invited by employee',
  'source.webapp_immomove': 'ImmoMove',
  'footer.allRightsReserved': 'All rights reserved.',
  'notification.FormSubmit': 'Form submitted',
  'notification.MessageForm': 'Message form data was successfully submitted',
  'pagination.of': 'of',
  'pagination.results': 'results',
  'filter.before': 'before',
  'filter.equal': 'equal',
  'filter.after': 'after',
  'filter.less': 'less',
  'filter.more': 'more',
  'filter.submitButton': 'Apply',
  'filter.selectValue': 'Select value',
  'filter.selectType': 'Select type',
  'filter.verification': 'Verification',
  'immovable.deleting': 'Mandate is being deleted...',
  'immovable.copying': 'Mandate is being copied...',
  'immovable.pageSubTitle': 'Overview of all mandates',
  'immovable.insuranceValue': 'Insurance value',
  'immovable.constructionYear': 'Year of construction',
  'immovable.newImmovable': 'New property',
  'immovable.newAccount': 'New account',
  'immovable.updateAccount': 'Update account',
  'immovable.copyAccount': 'Copy account',
  'immovable.viewAccount': 'View account',
  'immovable.bankTitle': 'Bank accounts',
  'immovable.pickExistingImmovable': 'Select an existing property?',
  'immovable.objectCategories': 'Properties category',
  'immovable.selectImmovableType': 'Category',
  'immovable.added': 'Property has been successfully added.',
  'immovable.groundArea': 'Property area',
  'immovable.cubicGvz': 'Cubic capacity GVZ',
  'immovable.renewalFund': 'Renewal fund',
  'immovable.calculated': 'Calculated',
  'immovable.entered': 'Entered',
  'immovable.currentStatus': 'Current status',
  'immovable.accountBalanceOptimal': 'Optimal account balance',
  'immovable.accountBalanceCurrent': 'Current account balance',
  'immovable.depositCurrent': 'Deposit Current',
  'immovable.depositOptimal': 'Optimal deposit',
  'immovable.depositOptimal.sublabel1': 'Without interest and inflation, until',
  'immovable.depositOptimal.sublabel2': 'Without interest and inflation, from',
  'immovable.depositRegulations': 'Deposit according to regulations',
  'immovable.minimumThreshold': 'Base amount',
  'immovable.yearlyCorrectionAmount': 'Annual correction amount',
  'immovable.depositForThisYearReceived': "This year's deposit already included",
  'immovable.regulationsGvz': 'Regulations of GVZ (in %)',
  'immovable.interest': 'Interest rate',
  'immovable.inflation': 'Inflation',
  'immovable.interestAndRisingPrices': 'Interest & inflation',
  'immovable.forecastRenewalFund': 'Renewal fund forecast',
  'immovable.numbersRenewalFund': 'Renewal fund key figures',
  'immovable.communityComponents': 'Community components',
  'immovable.component': 'Component',
  'immovable.components': 'Components',
  'immovable.replaceCost': 'Replacement costs',
  'immovable.remainServiceTime': 'Remaining useful life',
  'immovable.accountBalance': 'Account balance',
  'immovable.accountName': 'Account name',
  'immovable.bankBalance': 'Account balance',
  'immovable.iban': 'IBAN',
  'immovable.bankName': 'Bank name',
  'immovable.newComponent': 'New component',
  'immovable.updateComponent': 'Update component',
  'immovable.copyComponent': 'Copy component',
  'immovable.addComponentLabel': 'Please enter the data of the new component. (This can all be flexibly adapted at a later date.)',
  'immovable.componentName': 'Name of the component',
  'immovable.componentCalculationBase': 'Calculation base',
  'immovable.componentBase': 'Base',
  'immovable.componentFactor': 'Factor',
  'immovable.componentRenewalDate': 'Renewal year',
  'immovable.componentRenovationDate': 'Year of construction / last renewal',
  'immovable.higherRenewalDate': 'The renewal year should be higher than the current year',
  'immovable.lowerRenovationDate': 'The year of construction should be lower than the renewal date',
  'immovable.lastUpdated': 'Data last updated before: ',
  'immovable.componentAdded': 'Component was successfully added.',
  'immovable.componentCopied': 'Component was successfully copied.',
  'immovable.bankAccountAdded': 'Account was successfully added.',
  'immovable.bankAccountCopied': 'Account was successfully copied.',
  'immovable.bankAccountUpdated': 'Account has been successfully updated.',
  'immovable.year': 'Year',
  'immovable.years': 'Years',
  'immovable.noDescription': '[No description available]',
  'immovable.generateReport': 'Generate report',
  'immovable.reportInclInterestAndInflation': 'Should the report include interest and inflation?',
  'immovable.reportInclCompImages': 'Should the report contain images of the components?',
  'immovable.reportSelectChoiceDescription': 'Please select the desired parts for the report',
  'immovable.reportSelectChoice': 'Select parts',
  'immovable.selectChoiceRequired': 'Select the parts',
  'immovable.progressNotificationTitle': 'Export progress',
  'immovable.progressNotificationFailedTitle': 'Export failed',
  'immovable.progressNotificationDescription': 'The report is being created. This may take a few seconds, please be patient.',
  'immovable.progressNotificationFailedDescription': 'Please try again.',
  'immovable.pdfSizeErrorTitle': 'Error: PDF report too large',
  'immovable.pdfSizeErrorDescription': 'The report is larger than 10 MB and therefore could not be downloaded. Please remove a few images or select fewer building parts for the report.',
  'immovable.duplicate': 'Do you really want to duplicate the data set?',
  'immovable.duplicated': 'Property was copied successfully.',
  'immovable.buildingKeyFigures': 'Building metrics',
  'immovable.bankDeleted': 'Account was successfully deleted.',
  'immovable.infoTextGraph': '<strong>EF Current:</strong>\nThis line shows the current status of your renewal fund and the development in the coming years, based on the current deposit you receive from the owners each year. \n\n<strong>EF Optimal:</strong>\nThis line shows the optimal level of your renewal fund if you replace your current deposit with the calculated optimal deposit.\n\n<strong>EF Perfect:</strong> \nThis line shows the perfect balance of your renewal fund, starting with the perfect account balance and the optimal deposit. This line ends with the correction period, since from this point onwards the optimal EF corresponds to the perfect EF.\n\n<strong>Correction period:</strong>\nIf your current account balance is larger or smaller than the optimal account balance, an adjustment will occur over the initial years based on the set annual correction amount until the optimal level of the renewal fund is reached.',
  'immovable.infoTextNumbersCalculated': '<strong>Optimal balance EF:</strong>\nThe account balance that your renewal fund should have at the current time in order never to fall below the entered base amount.\n\n<strong>Optimal deposit:</strong>\nThe required annual optimal deposit so that it never falls below the entered base amount. The values ​​are divided into before and after the correction period.\n\n<strong>Deposit according to regulations:</strong>\nThe annual deposit that would be incurred according to the legal requirements.',
  'immovable.infoTextNumbersEntered': '<strong>Deposit Current:</strong>\nThe annual deposit amount that you currently receive from your owners.\n\n<strong>Base Amount:</strong>\nThe minimum account balance under your Renewal fund should never fall.\n\n<strong>Annual correction amount:</strong>\nIf your current account balance is larger or smaller than the optimal account balance, there will be an adjustment over the initial years based on the set annual correction amount until the optimal one The level of the renewal fund has been reached.',
  'immovable.condominium': 'Condominium',
  'immovable.tower': 'Skyscraper',
  'immovable.house': 'Detached house',
  'immovable.stweg': 'Condominium community',
  'immovable.joint_ownership': 'Co-ownership',
  'component.elevator_stops': 'Elevator stops',
  'component.electricity_usage': 'Electricity Usage',
  'component.quantity': 'Quantity',
  'component.insurance_value': 'Insurance value',
  'component.offer': 'Lump sum (offer)',
  'component.estimate': 'Cost estimate',
  'component.sqmeter': 'm2 price',
  'ifwizard.all_available_components': 'All available components',
  'ifwizard.chosen_components': 'Selected components',
  'ifwizard.infoTextChosenComponents': 'The components listed here serve as a template to make your work easier. As soon as the property has been created, you can adjust all the details of the components and also add completely new components.',
  'ifwizard.component.type.facade': 'Facade',
  'ifwizard.component.type.building_isolation': 'Building insulation',
  'ifwizard.component.type.roof': 'Roof',
  'ifwizard.component.type.electrical_installations': 'Electrical installations',
  'ifwizard.component.type.sanitary_lines': 'Risers / sanitary lines',
  'ifwizard.component.type.heater': 'Heating',
  'ifwizard.component.type.windows': 'Windows (in communal rooms)',
  'ifwizard.component.type.stockroom': 'Stockroom',
  'ifwizard.component.type.common_room': 'Common room',
  'ifwizard.component.type.visitor_parking': 'Visitor parking spaces',
  'ifwizard.component.type.shelter': 'Shelter',
  'ifwizard.component.type.driveway': 'Access road',
  'ifwizard.component.type.elevator': 'Lift',
  'ifwizard.component.type.bicycle_space': 'Bicycle parking space',
  'ifwizard.component.type.paintwork': 'Painting work',
  'ifwizard.component.type.hot_water_boiler': 'Hot water boiler',
  'ifwizard.component.type.solar_collector': 'Solar collector',
  'ifwizard.component.type.water_softening_system': 'Water softening system',
  'ifwizard.component.type.washing_machine_dryer': 'Washer and dryer',
  'ifwizard.component.type.playground': 'Playground',
  'ifwizard.component.type.winter_garden': 'Winter garden',
  'ifwizard.component.type.balcony': 'Balcony',
  'ifwizard.component.type.flooring': 'Flooring',
  'ifwizard.component.type.doors': 'Doors',
  'ifwizard.component.type.fence_and_wall': 'Fence and wall',
  'ifwizard.component.type.ventilation_ducts': 'Ventilation ducts',
  'ifwizard.component.type.slat_blinds': 'Slat blinds',
  'ifwizard.component.type.terrace_floor': 'Terrace floor',
  'ifwizard.component.type.garden': 'Garden',
  'ifwizard.component.type.fireplace': 'Fireplace',
  'ifwizard.component.subtype.small': 'Small',
  'ifwizard.component.subtype.medium': 'Medium',
  'ifwizard.component.subtype.big': 'Big',
  'ifwizard.component.subtype.minor': 'Minor',
  'ifwizard.component.subtype.extensive': 'Large area',
  'ifwizard.component.subtype.wood': 'Wood',
  'ifwizard.component.subtype.plastic': 'Plastic',
  'ifwizard.component.subtype.low_quality': 'Low quality',
  'ifwizard.component.subtype.mid_quality': 'Medium quality',
  'ifwizard.component.subtype.high_quality': 'High quality',
  'ifwizard.component.subtype.metal': 'Metal',
  'ifwizard.component.subtype.aluminium': 'Steel/aluminum',
  'ifwizard.component.subtype.laminate': 'Laminate',
  'ifwizard.component.subtype.pvc': 'PVC',
  'ifwizard.component.subtype.tiling': 'Tiles',
  'ifwizard.component.subtype.parquet': 'Parquet',
  'ifwizard.component.subtype.burner': 'Burner',
  'ifwizard.component.subtype.heat_pumps': 'Heat pumps',
  'ifwizard.component.subtype.steel_boiler': 'Steel boiler',
  'ifwizard.component.subtype.underfloor_heating': 'Underfloor heating',
  'ifwizard.component.subtype.radiators': 'Radiators/radiators',
  'ifwizard.component.subtype.flat_with_grit': 'Flat roof with gravel',
  'ifwizard.component.subtype.pitched_with_bricks': 'Pitched roof with bricks',
  'ifwizard.component.subtype.plastered': 'Plastered',
  'ifwizard.component.subtype.painted': 'Painted',
  'ifwizard.component.subtype.styrofoam': 'Styrofoam',
  'ifwizard.component.subtype.mineral_wool_insulation': 'Mineral wool insulation boards',
  'votes.pageSubTitle': 'List of all votes',
  'votes.begin': 'Start',
  'votes.end': 'End',
  'votes.addButton': 'Add vote',
  'votes.add': 'Add vote',
  'votes.view': 'Vote (reading mode)',
  'votes.subtasks.vote': 'Voting items',
  'votes.subtasks.survey': 'Survey items',
  'votes.task': 'Item_',
  'votes.noSubtask.vote': 'No voting items.',
  'votes.noSubtask.survey': 'No survey items.',
  'votes.itemError': 'Error during input',
  'votes.dateStart.vote': 'Start of the vote',
  'votes.dateStart.survey': 'Start of the survey',
  'votes.dateEnd.vote': 'End of the vote',
  'votes.dateEnd.survey': 'End of the survey',
  'votes.taskFileRequired': 'Select the correct files',
  'votes.errorDateStart': 'Start date is required',
  'votes.errorDateEnd': 'End date is required',
  'votes.inserted': 'Vote was successfully added.',
  'votes.copied': 'The vote was successfully copied.',
  'votes.selectFiles': 'Select files',
  'votes.submittedItems': 'Submitted agenda items',
  'votes.charts': 'Statistics',
  'votes.updated': 'Vote has been successfully updated.',
  'votes.updateData': 'Update all data (as if a new vote was being created)?',
  'votes.participantsUpdated': 'Participants have been successfully updated.',
  'votes.participantUpdated': 'Participant has been successfully updated.',
  'votes.pleaseEnterNumber': 'Please enter a number.',
  'votes.updateVote.vote': 'Update vote',
  'votes.updateVote.survey': 'Update survey',
  'votes.deleted': 'Vote was successfully deleted.',
  'votes.choice': 'Selection',
  'votes.considerForResults': 'Consider for results',
  'votes.consideredYes': 'Considered',
  'votes.consideredPending': 'As soon as submitted',
  'votes.consideredNo': 'Not considered',
  'votes.exportParticipants': 'Export list of participants as PDF',
  'votes.startEditAllFields': 'Edit all fields',
  'votes.endEditAllFields': 'Exit edit mode',
  'votes.voteResults': 'Results',
  'votes.withoutResults': 'Pending vote',
  'votes.activeParticipants': 'Active participants',
  'votes.allOwnersAndQuotas': 'List of participants & value quotas',
  'votes.allOwnersAndQuotasFkstwe': 'List of members & value quotas',
  'votes.valueQuotaPersistentNotice': 'Note: The value quotas entered are also automatically taken into account for future votes.',
  'votes.relationshipId': 'Owner number',
  'votes.relationshipIdFkstwe': 'Member number',
  'votes.relationshipIds': 'Owner numbers',
  'votes.relationshipIdsFkstwe': 'Member numbers',
  'votes.relationshipIdAbbr': 'No',
  'votes.addObject': 'Add object',
  'votes.relationshipIdAlreadyInUse': 'The owner number is already in use.',
  'votes.considerVoteUserWithoutValidRelId': 'To be able to consider this vote for the results, you must first assign a valid owner number. To do this, click on the edit icon on the right.',
  'votes.warrant': 'Power of attorney',
  'votes.type.subLabel': 'Enter the last name or email address of the power of attorney recipient in the field below. The system then checks whether an owner with this data exists in the property.',
  'votes.manuallyAdded': 'Manually added',
  'votes.manuallyAdd': 'Add manually',
  'votes.manuallyEditVoteUser': 'Update participant',
  'votes.manuallyAddVoteUser': 'Add participant manually',
  'votes.manuallyAddVoteUser.label': 'What would you like to record?',
  'votes.manuallyAddVoteUser.sublabel': 'Please select a owner number first.',
  'votes.manuallyAddVoteUser.optionA': 'Vote',
  'votes.manuallyAddVoteUser.optionB': 'Power of attorney',
  'votes.manuallyAddParticipant': 'Add participant manually',
  'votes.voteManuallyAddedSuccess': 'Vote was successfully added.',
  'votes.countNoticeSingular': '{vote_count} vote will not be considered',
  'votes.countNoticePlural': '{vote_count} votes will not be considered',
  'votes.participantsEligible': 'Eligible participants',
  'votes.participantsEligibleLong': 'Owner communities',
  'votes.participantsEligibleLongFkstwe': 'Company members',
  'votes.participantsMore': 'Other participants',
  'votes.chooseOrAddOwnerTitle': 'Owner',
  'votes.chooseOrAddOwnerText': 'Select an existing owner or create a new one.',
  'votes.chooseOrAddQuorumText': 'Predefined or individual?',
  'votes.quorumsPredefined': 'Predefined quorums',
  'votes.predefined': 'Predefined',
  'votes.individual': 'Individual',
  'votes.majority': 'Majority',
  'votes.majorityAt': 'Majority from {value}',
  'votes.majorityPercVariable': '{perc} majority',
  'votes.inclAllTrue': 'All owners',
  'votes.inclAllFalse': 'Only those present',
  'votes.inclQuotaTrue': 'With value quota',
  'votes.inclQuotaFalse': 'Without value quota',
  'votes.inclAbstTrue': 'With abstentions',
  'votes.inclAbstFalse': 'Without abstentions',
  'votes.inclTieTrue': 'Accepted in the event of a tie',
  'votes.inclTieFalse': 'Rejected in the event of a tie',
  'votes.filterProperty': 'Filter by property',
  'votes.quorumMajorityPercentage': 'Required majority in percent',
  'votes.quorumInclTieTitle': 'Tie',
  'votes.quorumInclTieText': 'If there is a tie (e.g. 10 YES and 10 NO with a 50% majority), is the item on the agenda accepted?',
  'votes.quorumInclAbstTitle': 'Abstentions',
  'votes.quorumInclAbstText': 'Do abstentions count as NO?',
  'votes.quorumInclAllTitle': 'All owners',
  'votes.quorumInclAllText': 'Include all owners of the property or only those present?',
  'votes.quorumInclQuotaTitle': 'Value quota',
  'votes.quorumInclQuotaText': 'Consider value quota for the results?',
  'votes.quorumMinParticipantsTitle': 'Minimum number of participants',
  'votes.quorumMinParticipantsText': 'What is the minimum number of votes that must be cast?',
  'votes.selectUser': 'Select user',
  'votes.participantsMoreNotice1': 'You can add or remove votes for the evaluation using the tick on the left.',
  'votes.participantsMoreNotice2': 'Note: Users without an active ERP connection are initially not automatically included in the results.',
  'votes.resultDownloadError': 'Internal error. Please try again.',
  'votes.userStatusUpdated': 'User status has been successfully updated.',
  'votes.voteDeleted': 'Vote was successfully deleted.',
  'votes.registeredAndEmailSent': 'Access code sent',
  'votes.pageSearchbox': 'Search in votes...',
  'votes.votingPossibilityInfo': 'Note: Participation in the vote is only possible in the ImmoApp, not via the administration area.',
  'votes.planning': 'Planning',
  'votes.beforehand': 'In advance',
  'votes.live': 'Live',
  'votes.online': 'Online',
  'votes.onlineOnly': 'Online only',
  'votes.onlineAndLive': 'Online vote + live',
  'votes.liveOnly': 'Live only',
  'votes.changeToLive.vote': 'Change to live voting',
  'votes.changeToLive.survey': 'Change to live survey',
  'votes.changeToLiveConfirm.vote': 'If you confirm, the online vote is ended and the live vote is started. This cannot be undone.',
  'votes.changeToLiveConfirm.survey': 'If you confirm, the online survey is ended and the live survey is started. This cannot be undone.',
  'votes.finishEarly.vote': 'Finish the vote early',
  'votes.finishEarly.survey': 'Finish the survey early',
  'votes.finishConfirm.vote': 'Are you sure you want to end the vote?',
  'votes.finishConfirm.survey': 'Are you sure you want to end the survey?',
  'votes.buttonProtocol': 'Protocol',
  'votes.confirmProtocolFileType': 'Please select the desired file type.',
  'votes.pdfResults': 'All ballot papers',
  'votes.participatingProperties': 'Participating properties',
  'votes.editPropertiesInfo': 'Here you can select the properties that may participate for the agenda item.',
  'votes.valueQuota': 'Value quota',
  'votes.yes': 'Yes',
  'votes.no': 'No',
  'votes.abstain': 'Abstain',
  'votes.notActiveYet': 'Not yet active',
  'votes.active.vote': 'Voting in progress...',
  'votes.active.survey': 'Survey in progress...',
  'votes.completed': 'Completed',
  'votes.liveNowNoticeTitle.vote': 'Live voting is active',
  'votes.liveNowNoticeTitle.survey': 'Live survey is active',
  'votes.liveFutureNoticeTitle.vote': 'Live vote is scheduled',
  'votes.liveFutureNoticeTitle.survey': 'Live survey is planned',
  'votes.beforehandNowNoticeTitle.vote': 'Online vote is active',
  'votes.beforehandNowNoticeTitle.survey': 'Online survey is active',
  'votes.beforehandFutureNoticeTitle.vote': 'Online vote is planned',
  'votes.beforehandFutureNoticeTitle.survey': 'Online survey is planned',
  'votes.onlineNowNoticeTitle.vote': 'Online voting is active',
  'votes.onlineNowNoticeTitle.survey': 'Online survey is active',
  'votes.onlineFutureNoticeTitle.vote': 'Online vote is planned',
  'votes.onlineFutureNoticeTitle.survey': 'Online survey is planned',
  'votes.finishedNoticeTitle.vote': 'The vote is finished.',
  'votes.finishedNoticeTitle.survey': 'The survey is finished.',
  'votes.activeNoticeStart.vote': 'The vote will start as scheduled on {date_start}.',
  'votes.activeNoticeStart.survey': 'The survey will start as scheduled on {date_start}.',
  'votes.finishNoticeText': 'Note: The ImmoApp supports you with a standardized evaluation of the vote results. Please check the accuracy of the results carefully.',
  'votes.liveVoteResultsInclude': 'The results take into account all votes with the status "Completed" and "Voting" (Live).',
  'votes.onlineVoteResultsInclude': 'The results take into account all votes with the status "Completed".',
  'votes.activeNoticeEnd.vote': 'The vote ends as scheduled on {date_end}.',
  'votes.activeNoticeEnd.survey': 'The survey ends as scheduled on {date_end}.',
  'votes.pastNoticeEnd.vote': 'The vote ended on {date_end}.',
  'votes.pastNoticeEnd.survey': 'The survey ended on {date_end}.',
  'votes.liveNoticeCode.vote': 'The invitation code for the live vote is',
  'votes.liveNoticeCode.survey': 'The invitation code for the live survey is',
  'votes.mode': 'Mode',
  'votes.modePlaceholder': 'Please select voting mode',
  'votes.itemActiveTitle': 'Agenda item active',
  'votes.itemActiveDescription': 'If you end the agenda item, the owners can no longer cast a vote.',
  'votes.newFilesNotice': 'Note: New files can be added to the vote via editing.',
  'votes.reactivateVoteItem': 'Reactivate agenda item',
  'votes.updateVoteItem': 'Update agenda item',
  'votes.finishVoteItem': 'End agenda item',
  'votes.viewVoteItem': 'Agenda item (reading mode)',
  'votes.agendaItemUpdated': 'Agenda item has been successfully updated.',
  'votes.agendaItemModalHeader': 'Agenda item "{title}"',
  'votes.agendaItemModalNoticeHeader': 'Select a vote to which the agenda item should be added.',
  'votes.agendaItemModalNoticeHeaderOLD': 'As soon as you add the agenda item to a vote, the owner receives an email indicating which vote the agenda item is used for.',
  'votes.quorum': 'Quorum',
  'votes.quorumDropdown': 'Please select the quorum',
  'votes.simpleMajorityNoAbstention': 'Simple majority (abstention is not counted)',
  'votes.simpleMajorityWithAbstention': 'Simple majority (abstention counts as no)',
  'votes.qualifiedMajorityNoAbstention': 'Qualified majority (abstention is not counted)',
  'votes.qualifiedMajorityWithAbstention': 'Qualified majority (abstention counts as no)',
  'votes.simpleTotalMajority': 'Simple majority of all owners',
  'votes.qualifiedTotalMajority': 'Qualified majority of all owners',
  'votes.unanimityAttendees': 'Unanimity of those present',
  'votes.unanimityTotal': 'Unanimity of all owners',
  'votes.accepted': 'Accepted',
  'votes.rejected': 'Rejected',
  'votes.paused': 'Paused',
  'votes.noValidQuorum': 'Not quorate',
  'votes.validQuorum': 'Quorate',
  'votes.regulation': 'Regulation',
  'votes.voteCountRule': 'Votes regulation',
  'votes.voteCountDropdown': 'Please select the voting regulation',
  'votes.countQuota': 'Voting quota',
  'votes.countQuotaAbbr': 'SQ',
  'votes.valueQuotaAbbr': 'WQ',
  'votes.valueHeadCountAbbr': 'KS',
  'votes.valueObjectCountAbbr': 'OS',
  'votes.voteCount': 'Number of votes',
  'votes.objectName': 'Object name',
  'votes.headCount': 'Head count',
  'votes.objectCount': 'Object count',
  'voteResults.quorum': 'Quorum',
  'votes.setAvailable': 'Activate',
  'votes.setPaused': 'Pause',
  'votes.setCompleted': 'Finish',
  'votes.signature.label': 'Signature required',
  'votes.signature.description': 'Do participants have to sign a signature within the ImmoApp before submitting their results? (Does not apply to live voting.)',
  'votes.visibility.label': 'Vote visible before it begins?',
  'votes.visibility.description': 'Should the vote be visible in the ImmoApp before it begins?',
  'votes.notification.sublabel': 'Should owners be informed via push notification as soon as voting begins?',
  'votes.errorSelectAllItems': 'Please select all voting items first.',
  'votes.errorSelectWarrantReceiver': 'Please select a power of attorney recipient first.',
  'voteResults.sublabelUserStatus': 'Change user status',

  'survey.online': 'Online',
  'survey.selectChoices': 'Specify choices',

  'component.calculationBasis': 'Calculation basis',
  'component.description': 'Description',
  'component.value': 'Value',
  'component.assessment': 'Assessment',
  'component.broken': 'Defect',
  'component.badlyDamaged': 'Heavily damaged',
  'component.noticeableDamaged': 'Noticeably damaged',
  'component.slightlyDamaged': 'Slightly damaged',
  'component.goodCondition': 'Good condition',
  'component.likeNew': 'As good as new',
  'component.amortisation': 'Amortization',
  'component.remainingUsefulAmount': 'Remaining useful amount',
  'component.usefulLife': 'Useful life',
  'component.remainingLife': 'Remaining useful life',
  'component.costOverview': 'Cost overview',
  'component.use': 'Usage',
  'component.deposit': 'Deposits',
  'component.currentCoverageAmount': 'Current coverage amount',
  'component.openCoverageAmount': 'Open coverage amount',
  'component.forAction': 'Need for action',
  'component.lifeExpectancy': 'Life expectancy',
  'component.untilRenewal': 'Until renewal',
  'component.depositOptimalPerYear': 'Deposit optimal per year',
  'component.deleted': 'Component was successfully deleted.',
  'component.updated': 'Component was successfully updated.',
  'component.sublabelComponentStatus': 'Only active components are taken into account in the calculation.',
  'component.statusAutomaticAdjustmentTitle': 'Automation status',
  'component.statusAutomaticAdjustmentDescription': 'Automatically adjust status over time',
  'image.gridView': 'Grid',
  'image.listView': 'List',
  'pdfexport.overviewImmovable': 'Overview - Property',
  'pdfexport.overviewCalculations': 'Overview - Calculations',
  'pdfexport.overviewComponents': 'Overview - Components',
  'immoMoveHome.created': 'Created',
  'immoMoveHome.reminded': 'Reminded',
  'immoMoveHome.accessed': 'Login',
  'immoMoveHome.answered': 'Answer',
  'immoMoveHome.archived': 'Archive',
  'immoMoveHome.createNewTerminations': 'New termination',
  'immoMoveHome.updateTerminations': 'Update termination',
  'immoMoveHome.pageSubTitle': 'Overview of all terminations',
  'immoMoveHome.terminations': 'Terminations',
  'immoMoveHome.searchTermination': 'Search in terminations...',
  'immoMoveHome.rating': 'Rating',
  'immoMoveHome.civilstand': 'Civil status',
  'immoMoveHome.staff': 'Landlord',
  'immoMoveHome.timeCreated': 'Created on',
  'immoMoveHome.newTerminationAdding': 'Termination is created...',
  'immoMoveHome.newTerminationCreated': 'Termination was successfully added and the tenant was informed.',
  'immoMoveHome.terminationUpdated': 'Termination has been successfully updated.',
  'immoMoveHome.terminationDeleted': 'Termination was successfully deleted.',
  'immoMoveHome.emailExists': 'The termination with the same email address exists. Would you like to overwrite previous user details?',
  'newTermination.terminationType': 'Type of termination',
  'newTermination.salutation': 'Salutation',
  'newTermination.mieterFirstName': 'Tenant first name',
  'newTermination.mieterLastName': 'Tenant last name',
  'newTermination.mieterEmail': 'Tenant email address',
  'terminationType.regular': 'Termination by date',
  'terminationType.irregular': 'Termination outside of term',
  'salutation.male': 'Mr.',
  'salutation.female': 'Mrs.',
  'pinboard.pageSubTitle': 'Your pinboard at a glance',
  'pinboard.pageSearchbox': 'Search in pinboard...',
  'pinboard.updatePinboard': 'Update entry',
  'pinboard.copyPinboard': 'Copy entry',
  'pinboard.addPinboard': 'Add entry',
  'pinboard.viewPinboard': 'Pinboard entry (reading mode)',
  'pinboard.inserted': 'Pinboard entry was successfully added.',
  'pinboard.updated': 'Pinboard entry was successfully updated.',
  'pinboard.deleted': 'Pinboard entry was successfully deleted.',
  'process.evaluationHouse': 'Feedback house',
  'process.evaluationAdmin': 'Feedback administration',
  'process.resendingAccessCode': 'Access code is being sent...',
  'process.resendAccessCodeConfirm': 'Resend access code',
  'process.resendAccessCodeSuccess': 'Access code sent successfully.',
  'process.resendAccessCodeError': 'Error sending the access code.',
  'process.resetEmailSuccess': 'An email was sent to you to reset your password.',
  'process.resetEmailNotFound': 'No user with the entered email address was found.',
  'process.resetEmailError': 'Error sending the email. Please try again.',
  'process.overView': 'Overview',
  'process.address': 'Address',
  'process.invitationSentOn': 'Invitation sent on',
  'process.lastUpdatedOn': 'Last updated on',
  'process.voucherRequestedOn': 'Vouchers requested on',
  'process.attachments': 'Attachments',
  'process.feedbackManagement': 'Feedback management',
  'process.noAnswersYet': 'No answer received yet...',
  'process.questionnaire': 'Questionnaire',
  'process.replies': 'Replies',
  'start.home': 'Terminations',
  'start.weArePleased': 'We are very pleased that you are taking the time to complete the digital termination process.',
  'start.clickToSurvey': 'Start survey',
  'start.continueSurvey': 'Continue survey',
  'start.contactUs': 'I will be happy to answer any questions you may have:',
  'start.greetingsMister': 'Dear Sir',
  'start.greetingsMisses': 'Dear Mrs.',
  'questionnaire.almostDone': 'Almost done',
  'questionnaire.completeTheSurvey': 'Fill in the remaining {remainingCount} questions to complete the survey.',
  'questionnaire.submitSurveyText': 'You have answered all questions.',
  'questionnaire.questionsAlreadyAnswered': 'Questions already answered',
  'questionnaire.updateSurvey': 'Update questionnaire',
  'questionnaire.submitSurvey': 'Submit questionnaire',
  'questionnaire.objectQuestions': 'Questions about the apartment',
  'questionnaire.adminQuestions': 'Questions about the administration',
  'questionnaire.addDate': 'Add date',
  'questionnaire.addPerson': 'Add person',
  'questionnaire.accountOwner': 'Account holder',
  'questionnaire.bankLocation': 'Location of the bank',
  'questionnaire.questionNotAnsweredYet': 'Question has not yet been answered',
  'questionnaire.q1': 'Why are you giving notice on your apartment?',
  'questionnaire.q1Note': '(Please select all that apply)',
  'questionnaire.q1heading1': 'Private reasons:',
  'questionnaire.q1heading2': 'Reasons for the property:',
  'questionnaire.q1heading3': 'Reasons for the location:',
  'questionnaire.q1heading4': 'Other reasons:',
  'questionnaire.professional_change': 'Job change / commute',
  'questionnaire.need_smaller_apartment': 'Need smaller apartment',
  'questionnaire.need_larger_apartment': 'Need larger apartment',
  'questionnaire.homestead': 'Own home',
  'questionnaire.separation': 'Separation',
  'questionnaire.moving_in_together': 'Moving in with (spouse) partner',
  'questionnaire.children_born': 'offspring',
  'questionnaire.death': 'Death',
  'questionnaire.facilities_dated': 'Outdated facilities',
  'questionnaire.existing_flaw': 'Existing deficiency:',
  'questionnaire.inconvenient_layout': 'Impractical layout',
  'questionnaire.not_age_appropriate': 'Not age-appropriate',
  'questionnaire.facilities_missing': 'Missing facilities:',
  'questionnaire.high_house_rent': 'Rent too high',
  'questionnaire.poor_appearance': 'Poor appearance',
  'questionnaire.noise_immission': 'Noise immissions',
  'questionnaire.not_child_friendly': 'Not child-friendly',
  'questionnaire.no_parking': 'No parking space',
  'questionnaire.no_visitor_parking': 'No/too few visitor parking spaces',
  'questionnaire.distance_main_roads': 'Long distance to main roads/highways',
  'questionnaire.bad_connection_public_transport': 'Unfavorable connection to public transport',
  'questionnaire.distance_groceries': 'No nearby shopping facilities',
  'questionnaire.discrepancies_within_neighborhood': 'Discrepancies within the neighborhood',
  'questionnaire.discrepancies_with_janitor': 'Discrepancies with the janitor',
  'questionnaire.discrepancies_with_admin': 'Discrepancies with the administration/owner',
  'questionnaire.q2': 'What did you particularly like about your apartment?',
  'questionnaire.q2Note': '(Please select all that apply)',
  'questionnaire.q2heading1': 'At the object:',
  'questionnaire.q2heading2': 'At the location:',
  'questionnaire.good_facilities': 'Good facilities',
  'questionnaire.convenient_layout': 'Practical floor plan',
  'questionnaire.age_appropriate': 'Age-appropriate',
  'questionnaire.fair_house_rent': 'Fair rent',
  'questionnaire.child_friendly': 'Child-friendly',
  'questionnaire.pleasant_neighborhood': 'Pleasant neighborhood',
  'questionnaire.reliable_janitor': 'Reliable janitor',
  'questionnaire.competent_administration': 'Competent administration',
  'questionnaire.quiet_area': 'Quiet residential area',
  'questionnaire.proximity_main_roads': 'Proximity to main roads/highways',
  'questionnaire.good_connection_public_transport': 'Good connection to public transport',
  'questionnaire.proximity_groceries': 'Nearby shopping facilities',
  'questionnaire.q3': 'Would you like our help in finding a new tenant? We would be happy to place an internet advertisement for you for a flat rate of CHF 100.00 (incl. VAT) plus CHF 8.00 (plus VAT) per day of advertising.',
  'questionnaire.q3o1': 'Yes, Flatfox',
  'questionnaire.q3o2': 'Yes, Flatfox and Homegate',
  'questionnaire.q4': '<span>We are dependent on your help for re-letting </span>May we give your telephone number to the interested parties for the apartment viewing and if so, which one?',
  'questionnaire.q5': 'Alternatively, fixed viewing appointments can also be arranged. Please give us some suggestions for dates in good time:',
  'questionnaire.q5Note': '(We will confirm definitely communicated dates in advance.)',
  'questionnaire.q6': 'When can your apartment be inspected in advance by the management to take photos and record any defects? Please give us some suggestions for dates in good time:',
  'questionnaire.q6Note': '(We will confirm the definitive date)',
  'questionnaire.q7': 'What is your new address?',
  'questionnaire.q8': 'What are your current bank details?',
  'questionnaire.q9': 'Which persons are currently registered in the apartment?',
  'questionnaire.q10': 'What is your current marital status?',
  'questionnaire.single': 'single',
  'questionnaire.married': 'Married / Registered partnership',
  'questionnaire.judicially_separated': 'Judicially separated',
  'questionnaire.divorced': 'Divorced',
  'questionnaire.widowed': 'Widowed',
  'questionnaire.q11': 'Which TV/Internet connection did you use?',
  'questionnaire.none': 'None',
  'questionnaire.swisscom': 'Swisscom',
  'questionnaire.upc_cablecom': 'UPC Cablecom',
  'questionnaire.sunrise': 'Sunrise',
  'questionnaire.salt': 'Salt',
  'questionnaire.glattnet': 'Glattnet',
  'questionnaire.gga_maur': 'GGA Maur',
  'questionnaire.lkw': 'LKW',
  'questionnaire.ew_rumlang': 'EW Rümlang',
  'questionnaire.q12': 'Do you already have a preferred date for handing over your apartment?',
  'questionnaire.q13': 'Are you aware of any defects or repair work in your apartment that should be commissioned by the management?',
  'questionnaire.13o1': 'Yes, the following defects:',
  'questionnaire.13o2': 'No defects',
  'questionnaire.q14': 'How did you find working with<span> the</span> {administrationName} or with your landlord/landlady?',
  'questionnaire.14o1': 'Accessibility:',
  'questionnaire.14o2': 'Friendliness:',
  'questionnaire.14o3': 'Response time:',
  'questionnaire.14o4': 'Professional competence:',
  'questionnaire.q15': 'Do you have any suggestions for improving<span> the</span> {administrationName}?',
  'questionnaire.15o1': 'Yes, the following suggestions:',
  'questionnaire.q16': 'Would you be happy to rent an apartment managed by {administrationName} again?',
  'questionnaire.q17': 'Do you have any personal feedback for<span> the</span> {administrationName} or your responsible manager?',
  'questionnaire.17o1': 'Yes, the following feedback:',
  'questionnaire.17o2': 'No',
  'questionnaire.q18': 'If you are looking for accommodation, we will be happy to help you by providing your potential new landlord with reference information about the existing tenancy. We need your consent for this.',
  'questionnaire.18o1': 'Give consent for reference information',
  'questionnaire.18o1a': 'Consent given for reference information',
  'questionnaire.18o2': 'No thanks',
  'questionnaire.nothingKnown': 'Nothing known',
  'questionnaire.notKnownYet': 'Not yet known',
  'questionnaire.other': 'other:',
  'questionnaire.informingLater': 'Information will follow at a later date.',
  'questionnaire.answersUploadedToDatabase': 'Your answers have been successfully submitted.',
  'questionnaire.answerError': 'Please answer all questions first.',
  'questionnaire.errorPrivacyPolicy': 'Please read and accept the privacy policy first.',
  'questionnaire.ratingModalTitle': 'Thank you very much',
  'questionnaire.ratingModalBody': 'Great that you are satisfied with us! A positive Google rating would make us very happy.',
  'questionnaire.ratingModalButton': 'Link to Google',
  'questionnaire.bottomPopupText': 'Answered',
  'end.thankYou': 'Thank you very much',
  'end.usefulDocumentsText': 'At the bottom of this page you will find some useful documents for your extract. We recommend that you download them. Otherwise, you can log in to this website again at any time and access them.',
  'end.voucherText': 'As a small thank you for your efforts, we offer you vouchers for a successful move.',
  'end.requestVoucherButton': 'Request vouchers',
  'end.requestVoucherText': 'Would you like to request the vouchers?',
  'end.remainingAnswers': 'Answer open questions ({questionsCount})',
  'end.deferredQuestions': 'Answer deferred questions',
  'end.send': 'Send',
  'end.logoutConfirmText': 'Are you sure you want to log out?',
  'end.voucherRequestSending': 'Request is being sent...',
  'end.voucherRequestSent': 'Vouchers have been successfully requested.',
  'end.answerDeferredQuestions': 'Would you like to answer your outstanding questions now?',
  'end.allQuestionsAnswered': 'You have answered all questions.',
  'end.timeVoucherRequested': 'You have requested the vouchers on {timeVoucherRequested}. Your contact person will get back to you shortly by email with the vouchers.',
  'document.pageSubtitle': 'Your documents at a glance',
  'document.fileSize': 'File size',
  'document.rootFolder': 'Main directory',
  'document.disabledInParentFolder': 'Disabled in a parent folder',
  'document.inactiveMandate': 'Inactive mandate - only visible for admins',
  'document.personalFolder': 'Personal folder',
  'statistics.tabImmoApp': 'ImmoApp',
  'statistics.tabImmoMove': 'ImmoMove',
  'statistics.totalCreatedProcesses': 'Created termination processes',
  'statistics.totalFinishedProcesses': 'Completed termination processes',
  'statistics.rating': '{rating} of 5',
  'statistics.totalRating': 'Received a rating',
  'statistics.totalRatings': '{count} ratings received',
  'statistics.evaluationAdministration': 'Evaluation administration',
  'statistics.evaluationObject': 'Evaluation object',
  'statistics.filterObjects': 'Filter by address',
  'statistics.filterStaffMember': 'Filter by administrator',
  'statistics.suggestions': 'Suggestions for improvement',
  'statistics.feedback': 'Personal feedback',
  'statistics.linkTerminationProcess': 'To the termination process',
  'statistics.users_30days': 'Users (active in the last 30 days)',
  'statistics.users_365days': 'Users (active in the last 365 days)',
  'statistics.users_total': 'Users (total)',
  'statistics.communication_emails_7days': 'Emails (in the last 7 days)',
  'statistics.communication_push_7days': 'Push (in the last 7 days)',
  'statistics.communication_docs_7days': 'Docs accessed (in the last 7 days)',
  'statistics.content_messages_7days': 'Messages (in the last 7 days)',
  'statistics.content_repairs_7days': 'Repair reports (in the last 7 days)',
  'statistics.content_pinboards_7days': 'Pinboard entries (in the last 7 days)',
  'statistics.agreeing': 'Agreeing',
  'statistics.refusing': 'Rejecting',
  'statistics.undecided': 'Undecided',
  'license.limit': 'Subscription quota',
  'license.limitExceededTitle': 'Subscription quota exceeded',
  'license.limitExceededText1': 'You are currently using {activeObjects} from {allowedObjects} booked objects.',
  'license.limitExceededText2': 'Please reduce the number of objects by deactivating individual properties or contact us to request an increase in the subscription quota.',

  STARTING_FORMGUIDE_KEYS: '---',

  'FORM.GO_TO_FORM': 'Go directly to the form',
  'FORM.REPAIR.TITLE.WHERE': 'Where do you need a repair?',
  'FORM.REPAIR.TITLE.WHAT': 'What is broken?',
  'FORM.REPAIR.DETAILS.TITLE': 'Damage assessment',
  'FORM.REPAIR.APARTMENT': 'Apartment',
  'FORM.REPAIR.APARTMENT.DESCRIPTION': 'Kitchen, bathroom, living room',
  'FORM.REPAIR.COMMONSPACE': 'Common areas',
  'FORM.REPAIR.COMMONSPACE.DESCRIPTION': 'Elevator, garage, basement',
  'FORM.REPAIR.COMMERCIAL': 'Commercial',
  'FORM.REPAIR.COMMERCIAL.DESCRIPTION': 'Office, stores, gastronomy',
  'FORM.REPAIR.EXTERIOR': 'Exterior',
  'FORM.REPAIR.EXTERIOR.DESCRIPTION': 'Green areas, facade',

  'FORM.REPAIR.1X': '1',
  'FORM.REPAIR.2X': '2',
  'FORM.REPAIR.3X': '3',
  'FORM.REPAIR.4X': '4',
  'FORM.REPAIR.5X': '5',
  'FORM.REPAIR.6X': '6',
  'FORM.REPAIR.7X': '7',
  'FORM.REPAIR.8X': '8',
  'FORM.REPAIR.ALL': 'All',
  'FORM.REPAIR.APARTMENT.WHOLE': 'Whole apartment',
  'FORM.REPAIR.APARTMENTDOOR': 'Apartment door',
  'FORM.REPAIR.ATTIC': 'Attic',
  'FORM.REPAIR.ATTICDOOR': 'Attic door',
  'FORM.REPAIR.ATTICLADDER': 'Attic hatch, ladder',
  'FORM.REPAIR.ATTICLADDER.BROKEN': 'Broken',
  'FORM.REPAIR.ATTICLADDER.CEILINGFLAP': 'Ceiling flap',
  'FORM.REPAIR.ATTICLADDER.CEILINGFLAP.CROOKED': 'Crooked, slanted',
  'FORM.REPAIR.ATTICLADDER.CEILINGFLAP.HINGEDEFECT': 'Hinges defective, torn out',
  'FORM.REPAIR.ATTICLADDER.FOLD': 'Fold, unfold',
  'FORM.REPAIR.ATTICLADDER.FOLD.NOTCLOSES': 'Closes badly',
  'FORM.REPAIR.ATTICLADDER.FOLD.NOTOPENS': 'Does not open properly',
  'FORM.REPAIR.ATTICLADDER.FOLD.DIFFICULTY': 'Opens, closes with difficulty',
  'FORM.REPAIR.ATTICLADDER.GRINDS': 'Grazes, stops',
  'FORM.REPAIR.ATTICLADDER.HANDRAIL': 'Handrail',
  'FORM.REPAIR.ATTICLADDER.LOCK': 'Lock, closure',
  'FORM.REPAIR.ATTICLADDER.LOCK.NOTCLOSES': 'Does not stay closed',
  'FORM.REPAIR.ATTICLADDER.LOCK.TORN': 'Torn out',
  'FORM.REPAIR.ATTICLADDER.LOOSE': 'Loose, wobbly',
  'FORM.REPAIR.ATTICLADDER.STAIRS': 'Ladder, stairs',
  'FORM.REPAIR.ATTICLADDER.STAIRS.ROPETORN': 'Wire rope broken',
  'FORM.REPAIR.ATTICLADDER.STAIRS.SPRINGDEFECT': 'Spring defective',
  'FORM.REPAIR.ATTICLADDER.STAIRS.STEPLOOSE': 'Step loose, loose',
  'FORM.REPAIR.ATTICLADDER.STAIRS.TORN': 'Stage torn out',
  'FORM.REPAIR.BALCONY': 'Balcony',
  'FORM.REPAIR.BALCONY.BALCONYDOOR': 'Balcony, terrace door',
  'FORM.REPAIR.BATHROOM': 'Bathroom',
  'FORM.REPAIR.BATHROOM.FURNITURE': 'Bathroom furniture',
  'FORM.REPAIR.BATHROOM.FURNITURE.BROKEN': 'Broken, cracked',
  'FORM.REPAIR.BATHROOM.FURNITURE.CISTERN': 'Cistern furniture defective',
  'FORM.REPAIR.BATHROOM.FURNITURE.EDGE': 'Edge of the washbasin',
  'FORM.REPAIR.BATHROOM.FURNITURE.EDGE.CRACK': 'Crack',
  'FORM.REPAIR.BATHROOM.FURNITURE.EDGE.FRAYED': 'Frayed',
  'FORM.REPAIR.BATHROOM.FURNITURE.EDGE.PEELING': 'Peeling coating',
  'FORM.REPAIR.BATHROOM.FURNITURE.LOOSE': 'Loose, wobbly',
  'FORM.REPAIR.BATHROOM.FURNITURE.MIRROR': 'Mirror',
  'FORM.REPAIR.BATHROOM.FURNITURE.MIRROR.FALLEN': 'Fallen off',
  'FORM.REPAIR.BATHROOM.FURNITURE.MIRROR.SCRATCHED': 'Scratched',
  'FORM.REPAIR.BATHROOM.FURNITURE.MIRROR.STAINS': 'Has stains, is tarnished',
  'FORM.REPAIR.BATHROOM.FURNITURE.MIRRORCABINET': 'Mirror cabinet',
  'FORM.REPAIR.BATHROOM.FURNITURE.MIRRORCABINET.LAMPCRACKS': 'Lamp cracks',
  'FORM.REPAIR.BATHROOM.FURNITURE.MIRRORCABINET.LAMPDEFECT': 'Lamp defective',
  'FORM.REPAIR.BATHROOM.FURNITURE.MIRRORCABINET.LIGHTSHIELDDEFECT': 'Light shields defective',
  'FORM.REPAIR.BATHROOM.FURNITURE.MIRRORCABINET.SHELFDEFECT': 'Shelf defective',
  'FORM.REPAIR.BATHROOM.FURNITURE.MIRRORCABINET.SHELFHOLDERDEFECT': 'Shelf holder defective',
  'FORM.REPAIR.BATHROOM.FURNITURE.MIRRORCABINET.SHELFMISSING': 'Shelf missing',
  'FORM.REPAIR.BATHROOM.FURNITURE.MIRRORCABINET.SOCKETDEFECT': 'Socket defective',
  'FORM.REPAIR.BATHROOM.FURNITURE.MIRRORCABINETDOOR': 'Mirror cabinet door',
  'FORM.REPAIR.BATHROOM.FURNITURE.MIRRORCABINETDOOR.HANDLEDEFECT': 'Handle defective',
  'FORM.REPAIR.BATHROOM.FURNITURE.MIRRORCABINETDOOR.HANGSDOWN': 'Hangs down',
  'FORM.REPAIR.BATHROOM.FURNITURE.MIRRORCABINETDOOR.HINGEDEFECT': 'Hinges fallen off',
  'FORM.REPAIR.BATHROOM.FURNITURE.MIRRORCABINETDOOR.HINGEFALLEN': 'Hinges fallen off',
  'FORM.REPAIR.BATHROOM.FURNITURE.MIRRORCABINETDOOR.RUBBERMISSING': 'Rubber buffer missing',
  'FORM.REPAIR.BATHROOM.FURNITURE.SINK': 'Washbasin furniture',
  'FORM.REPAIR.BATHROOM.FURNITURE.SINK.CHAFINGPOINT': 'chafing point',
  'FORM.REPAIR.BATHROOM.FURNITURE.SINK.HOLE': 'Hole',
  'FORM.REPAIR.BATHROOM.FURNITURE.SINK.STAINS': 'Stains, discoloration',
  'FORM.REPAIR.BATHROOM.FURNITURE.SWOLLEN': 'Swollen',
  'FORM.REPAIR.BATHROOM.TOILET': 'WC, toilet',
  'FORM.REPAIR.BATHROOM.TOILET.BOWL': 'Toilet bowl',
  'FORM.REPAIR.BATHROOM.TOILET.BOWL.HANDRAILDEFECT': 'Handrail wobbles, torn out',
  'FORM.REPAIR.BATHROOM.TOILET.BOWL.ROLLHOLDERDEFECT': 'Toilet roll holder defective',
  'FORM.REPAIR.BATHROOM.TOILET.BROKEN': 'Broken, cracked',
  'FORM.REPAIR.BATHROOM.TOILET.DRAIN': 'Flushing, draining, etc.',
  'FORM.REPAIR.BATHROOM.TOILET.DRAIN.CLOGGED': 'Clogged',
  'FORM.REPAIR.BATHROOM.TOILET.DRAIN.DRAINSSLOW': 'Drains slowly',
  'FORM.REPAIR.BATHROOM.TOILET.DRAIN.LITTLEPRESSURE': 'Little water pressure',
  'FORM.REPAIR.BATHROOM.TOILET.DRAIN.NOWATER': 'No or little water',
  'FORM.REPAIR.BATHROOM.TOILET.DRAIN.OVERFLOW': 'Runs over',
  'FORM.REPAIR.BATHROOM.TOILET.DRAIN.SEWAGE': 'Dirty water',
  'FORM.REPAIR.BATHROOM.TOILET.DRAIN.WATERRINSEDEFECT': 'Water flush is running',
  'FORM.REPAIR.BATHROOM.TOILET.LOOSE': 'Loose, wobbles',
  'FORM.REPAIR.BATHROOM.TOILET.SEAT': 'Toilet seat',
  'FORM.REPAIR.BATHROOM.SHOWER': 'Bathtub, shower',
  'FORM.REPAIR.BATHROOM.SHOWER.BACKWATER': 'Waste water pushes out of drain',
  'FORM.REPAIR.BATHROOM.SHOWER.BATHTUB': 'Bathtub',
  'FORM.REPAIR.BATHROOM.SHOWER.CHALKY': 'Limey',
  'FORM.REPAIR.BATHROOM.SHOWER.CLOGGED': 'Clogged',
  'FORM.REPAIR.BATHROOM.SHOWER.CRACK': 'Crack',
  'FORM.REPAIR.BATHROOM.SHOWER.CURTAIN': 'Curtain, shower cubicle',
  'FORM.REPAIR.BATHROOM.SHOWER.CURTAIN.RODDEFECT': 'Curtain rod defective',
  'FORM.REPAIR.BATHROOM.SHOWER.CURTAIN.GLIDERDEFECT': 'Curtain glider defective',
  'FORM.REPAIR.BATHROOM.SHOWER.CURTAIN.CUBICLEDEFECT': 'Shower cubicle defective',
  'FORM.REPAIR.BATHROOM.SHOWER.CURTAIN.SEALDEFECT': 'Door rubber seal defective',
  'FORM.REPAIR.BATHROOM.SHOWER.DIRTY': 'Dirty',
  'FORM.REPAIR.BATHROOM.SHOWER.FITTINGS': 'Fittings',
  'FORM.REPAIR.BATHROOM.SHOWER.HANDRAIL': 'Handrail',
  'FORM.REPAIR.BATHROOM.SHOWER.HANDRAIL.BROKEN': 'Broken, jumped',
  'FORM.REPAIR.BATHROOM.SHOWER.HANDRAIL.TOINSTALL': 'To be installed',
  'FORM.REPAIR.BATHROOM.SHOWER.HOLE': 'Hole',
  'FORM.REPAIR.BATHROOM.SHOWER.JOINT': 'Joints',
  'FORM.REPAIR.BATHROOM.SHOWER.JOINT.DISSOLVES': 'Dissolves',
  'FORM.REPAIR.BATHROOM.SHOWER.JOINTDEFECT': 'Silicone joint leaking',
  'FORM.REPAIR.BATHROOM.SHOWER.LEAKY': 'Leaking',
  'FORM.REPAIR.BATHROOM.SHOWER.LOOSE': 'Loose, wobbly',
  'FORM.REPAIR.BATHROOM.SHOWER.MOLDY': 'Moldy',
  'FORM.REPAIR.BATHROOM.SHOWER.NOISE': 'Gurgles / noises',
  'FORM.REPAIR.BATHROOM.SHOWER.NOTDRAINING': 'Hardly drains',
  'FORM.REPAIR.BATHROOM.SHOWER.QUIRK': 'Quirk',
  'FORM.REPAIR.BATHROOM.SHOWER.SHOWERHEAD': 'Shower, hose',
  'FORM.REPAIR.BATHROOM.SHOWER.SHOWERHEAD.DRIPS': 'Drips',
  'FORM.REPAIR.BATHROOM.SHOWER.SHOWERHEAD.SPLASHES': 'Splashes',
  'FORM.REPAIR.BATHROOM.SHOWER.SHOWERTRAY': 'Shower tray',
  'FORM.REPAIR.BATHROOM.SHOWER.SMELLS': 'Stinks',
  'FORM.REPAIR.BATHROOM.SHOWER.STAINS': 'Has stains, is tarnished',
  'FORM.REPAIR.BATHROOM.SHOWER.WORN': 'Worn out',
  'FORM.REPAIR.BEDROOM': 'Bedroom',
  'FORM.REPAIR.BENCH': 'Park bench',
  'FORM.REPAIR.BENCH.LOOSE': 'Loose, wobbly',
  'FORM.REPAIR.BENCH.DIRTY': 'Dirty, worn',
  'FORM.REPAIR.BENCH.FALLINGAPART': 'Falls apart',
  'FORM.REPAIR.BIKEROOM': 'Bike room',
  'FORM.REPAIR.BIKESTAND': 'Bike stand',
  'FORM.REPAIR.BIKESTAND.DEFECT': 'Defect',
  'FORM.REPAIR.BIKESTAND.LOOSE': 'Loose, wobbly',
  'FORM.REPAIR.BIKESTAND.MISSING': 'Missing',
  'FORM.REPAIR.BIKESHELTER': 'Bicycle stand, box',
  'FORM.REPAIR.BIKESHELTER.BIKEBOX': 'Bike shelter',
  'FORM.REPAIR.BIKESHELTER.BIKEBOX.BROKENROOF': 'Roof defective',
  'FORM.REPAIR.BIKESHELTER.BIKEBOX.DOORDEFECT': 'Door defective',
  'FORM.REPAIR.BIKESHELTER.BIKEBOX.LOOSE': 'Loose, wobbly',
  'FORM.REPAIR.BIKESHELTER.BIKEBOX.NOTDRAINING': 'Rainwater not draining',
  'FORM.REPAIR.BOILERROOM': 'Boiler room',
  'FORM.REPAIR.BUILDINGENTRANCE': 'House entrance',
  'FORM.REPAIR.CABINET': 'Built-in cabinet',
  'FORM.REPAIR.CABINET.BASE': 'Base',
  'FORM.REPAIR.CABINET.BASE.BROKEN': 'Broken, cracked',
  'FORM.REPAIR.CABINET.BASE.JOINTLEAKS': 'Joint leaking, coming loose',
  'FORM.REPAIR.CABINET.BRACKETDEFECT': 'Bracket defective',
  'FORM.REPAIR.CABINET.BRACKETMISSING': 'Bracket missing',
  'FORM.REPAIR.CABINET.DOOR': 'Cabinet door',
  'FORM.REPAIR.CABINET.DOOR.EDGESDEFECT': 'Edges defective',
  'FORM.REPAIR.CABINET.DOOR.EDGESMISSING': 'Edge missing, broken',
  'FORM.REPAIR.CABINET.DOOR.GRINDS': 'Grazes, pending',
  'FORM.REPAIR.CABINET.DOOR.HINGEBROKEN': 'Hinges defective, torn out',
  'FORM.REPAIR.CABINET.DRAWER': 'Drawer',
  'FORM.REPAIR.CABINET.DRAWER.NOTOPEN': 'Does not open properly',
  'FORM.REPAIR.CABINET.DRAWER.REMAINSOPEN': 'Closes poorly',
  'FORM.REPAIR.CABINET.DRAWER.RUSTED': 'Rusted',
  'FORM.REPAIR.CABINET.DRAWER.WORN': 'Torn',
  'FORM.REPAIR.CABINET.HANDLEDEFECT': 'Handle defective',
  'FORM.REPAIR.CABINET.HANGSDOWN': 'Hangs down',
  'FORM.REPAIR.CABINET.LOOSE': 'Loose, wobbles',
  'FORM.REPAIR.CABINET.MISSING': 'Missing',
  'FORM.REPAIR.CABINET.MOLD': 'Moldy',
  'FORM.REPAIR.CABINET.RAIL': 'Clothes rail',
  'FORM.REPAIR.CABINET.RAIL.DEFECT': 'Defect',
  'FORM.REPAIR.CABINET.SHELF': 'Shelf',
  'FORM.REPAIR.CABINET.SHELF.CROOKED': 'Crooked',
  'FORM.REPAIR.CABINET.SHELF.PEELING': 'Peeling coating',
  'FORM.REPAIR.CABINET.SHELF.WORN': 'Worn',
  'FORM.REPAIR.CABINET.SWOLLEN': 'Swollen',
  'FORM.REPAIR.CABINET.WALLSFLOOR': 'Walls, floor, ...',
  'FORM.REPAIR.CABINET.WALLSFLOOR.REARWALLBROKEN': 'Rear wall broken',
  'FORM.REPAIR.CABINET.WALLSFLOOR.REARWALLBACK': 'Rear wall pushed back',
  'FORM.REPAIR.CABINET.WALLSFLOOR.WORN': 'Worn',
  'FORM.REPAIR.CANOPY': 'Canopy',
  'FORM.REPAIR.CANOPY.GLASSBROKEN': 'Glass canopy broken',
  'FORM.REPAIR.CANOPY.NOTDRAINING': 'Rainwater does not run off',
  'FORM.REPAIR.CANOPY.OVERFLOWS': 'Rainwater is overflowing',
  'FORM.REPAIR.CEILING': 'Ceiling',
  'FORM.REPAIR.CELLAR': 'Cellar',
  'FORM.REPAIR.CELLAR.COMPARTMENTDOOR': 'Cellar compartment door',
  'FORM.REPAIR.CELLARCOMPARTMENT': 'Cellar compartment',
  'FORM.REPAIR.CELLARDOOR': 'Cellar door',
  'FORM.REPAIR.CLEANING': 'Cleaning',
  'FORM.REPAIR.COLORDISSOLVING': 'Color comes off',
  'FORM.REPAIR.COMMONSPACE.OTHER': 'Other place',
  'FORM.REPAIR.CONTAINERROOM': 'Container space',
  'FORM.REPAIR.CORRIDOR.APARTMENT': 'Hallway, apartment entrance',
  'FORM.REPAIR.CORRIDOR.COMMERCIAL': 'Hallway, entrance area',
  'FORM.REPAIR.CRACK': 'Crack',
  'FORM.REPAIR.DAMAGED': 'Damaged',
  'FORM.REPAIR.DISPLAYDEFECT': 'Display defective',
  'FORM.REPAIR.DRAIN': 'Garage floor drain',
  'FORM.REPAIR.DOOR': 'Room door',
  'FORM.REPAIR.DOOR.OPENINGCLOSING': 'Open & close',
  'FORM.REPAIR.DOOR.OPENINGCLOSING.BADCLOSING': 'Closes badly',
  'FORM.REPAIR.DOOR.OPENINGCLOSING.DIFFICULTCLOSING': 'Opens, closes with difficulty',
  'FORM.REPAIR.DOOR.OPENINGCLOSING.GRINDS': 'Grazes, stops',
  'FORM.REPAIR.DOOR.OPENINGCLOSING.REMAINSOPEN': 'Does not stay closed',
  'FORM.REPAIR.DOOR.DOOR': 'Door',
  'FORM.REPAIR.DOOR.DOOR.BROKEN': 'Broken open',
  'FORM.REPAIR.DOOR.DOOR.GLASSPANELDEFECT': 'Glass pane defective',
  'FORM.REPAIR.DOOR.DOOR.HINGEBROKEN': 'Hinges defective, torn out',
  'FORM.REPAIR.DOOR.DOOR.HOLE': 'Hole',
  'FORM.REPAIR.DOOR.DOOR.LIGHT': 'Incidence of light',
  'FORM.REPAIR.DOOR.DOOR.SPLIT': 'Split',
  'FORM.REPAIR.DOOR.DOORBELL': 'Doorbell',
  'FORM.REPAIR.DOOR.DOORBELL.CHANGENAME': 'Nameplate change',
  'FORM.REPAIR.DOOR.DOORBELL.DEFECT': 'Doorbell defective',
  'FORM.REPAIR.DOOR.DOORBELL.MISSING': 'Nameplate missing',
  'FORM.REPAIR.DOOR.DOORSTEP': 'Door threshold',
  'FORM.REPAIR.DOOR.DOORSTEP.BARBROKEN': 'Broken transition profile',
  'FORM.REPAIR.DOOR.DOORSTEP.BARLOOSE': 'Transition profile loose',
  'FORM.REPAIR.DOOR.DOORSTEP.BARMISSING': 'Transition profile missing',
  'FORM.REPAIR.DOOR.DOORSTEP.SEALINGDEFECT': 'Silicone joint defective',
  'FORM.REPAIR.DOOR.FRAME': 'Door frame',
  'FORM.REPAIR.DOOR.FRAME.BROKEN': 'Broken open',
  'FORM.REPAIR.DOOR.FRAME.HINGEBROKEN': 'Hinges defective, torn out',
  'FORM.REPAIR.DOOR.FRAME.SEALINGDEFECT': 'Seal defective',
  'FORM.REPAIR.DOOR.FRAME.SEALINGMISSING': 'Seal missing',
  'FORM.REPAIR.DOOR.FRONTDOOR': 'Front door',
  'FORM.REPAIR.DOOR.KNOB': 'Door handle',
  'FORM.REPAIR.DOOR.KNOB.FALLSAPART': 'Falls apart',
  'FORM.REPAIR.DOOR.KNOB.HANGSDOWN': 'Hangs down',
  'FORM.REPAIR.DOOR.KNOB.LOOSE': 'Loose, wobbles',
  'FORM.REPAIR.DOOR.KNOB.MISSING': 'Missing',
  'FORM.REPAIR.DOOR.KNOB.WORN': 'Heavily worn',
  'FORM.REPAIR.DOOR.LOCK': 'Lock',
  'FORM.REPAIR.DOOR.LOCK.BOLTBLOCKS': 'Bolt blocked',
  'FORM.REPAIR.DOOR.LOCK.BROKEN': 'Broken open',
  'FORM.REPAIR.DOOR.LOCK.BROKENKEY': 'Key broken off',
  'FORM.REPAIR.DOOR.LOCK.COVERMISSING': 'Lock covers missing',
  'FORM.REPAIR.DOOR.LOCK.LATCHBLOCKS': 'Spring latch blocked',
  'FORM.REPAIR.DOOR.LOCK.MISSINGKEY': 'Key missing',
  'FORM.REPAIR.DOOR.LOCK.NOTWORKING': 'Unlocking, locking defective',
  'FORM.REPAIR.DOOR.LOCK.PLATELOOSE': 'Strike plate loose',
  'FORM.REPAIR.DOOR.RAMPDEFECT': 'Ramp defective',
  'FORM.REPAIR.DOOR.TRAPPINGDEFECT': 'Dirt trap defective',
  'FORM.REPAIR.ELECTRICITY': 'Power, light',
  'FORM.REPAIR.ELECTRICITY.ENTRANCELIGHT': 'Entrance lighting',
  'FORM.REPAIR.ELECTRICITY.EXTERIORLIGHT': 'Exterior lighting',
  'FORM.REPAIR.ELECTRICITY.EXTERIORLIGHT.DAMAGED': 'Damaged',
  'FORM.REPAIR.ELECTRICITY.EXTERIORLIGHT.LIGHTSDURINGDAY': 'Lights up during the day',
  'FORM.REPAIR.ELECTRICITY.EXTERIORLIGHT.LOOSE': 'Loose, wobbly',
  'FORM.REPAIR.ELECTRICITY.EXTERIORLIGHT.SCREENDEFECT': 'Light shades defective',
  'FORM.REPAIR.ELECTRICITY.FUSEOUT': 'Fuse blows',
  'FORM.REPAIR.ELECTRICITY.GARAGELIGHT': 'Garage lighting',
  'FORM.REPAIR.ELECTRICITY.GARAGELIGHT.AUTOSWITCHONDEFECT': 'Automatic switch-on function defective',
  'FORM.REPAIR.ELECTRICITY.GARAGELIGHT.AUTOSWITCHOFFDEFECT': 'Automatic switch-off defective',
  'FORM.REPAIR.ELECTRICITY.INTERIORLIGHT': 'Interior lighting',
  'FORM.REPAIR.ELECTRICITY.INTERIORLIGHT.LOOSE': 'Loose, wobbly',
  'FORM.REPAIR.ELECTRICITY.INTERIORLIGHT.SCREENDEFECT': 'Light shades defective',
  'FORM.REPAIR.ELECTRICITY.LIGHT.BURNMARK': 'Burn mark',
  'FORM.REPAIR.ELECTRICITY.LIGHT.DEFECT': 'Light source defective',
  'FORM.REPAIR.ELECTRICITY.LIGHT.FLICKERING': 'Flickering',
  'FORM.REPAIR.ELECTRICITY.LIGHT.SMELLS': 'Smells scorched',
  'FORM.REPAIR.ELECTRICITY.NOPOWER': 'No power',
  'FORM.REPAIR.ELECTRICITY.NOPOWER.APARTMENT': 'Entire apartment / commercial space',
  'FORM.REPAIR.ELECTRICITY.NOPOWER.BUILDING': 'Entire building',
  'FORM.REPAIR.ELECTRICITY.NOPOWER.ONLYROOM': 'Only one room or one power group',
  'FORM.REPAIR.ELECTRICITY.POWER': 'Power',
  'FORM.REPAIR.ELECTRICITY.SOCKET': 'Socket',
  'FORM.REPAIR.ELECTRICITY.SOCKET.BROKEN': 'Frame broken',
  'FORM.REPAIR.ELECTRICITY.SOCKET.DIRTY': 'Dirty',
  'FORM.REPAIR.ELECTRICITY.SOCKET.LOOSE': 'Loose, wobbly',
  'FORM.REPAIR.ELECTRICITY.SOCKET.SMELLS': 'Smells scorched',
  'FORM.REPAIR.ELECTRICITY.STAIRWELLLIGHT': 'Stair house lighting',
  'FORM.REPAIR.ELECTRICITY.SWITCH': 'Light switch, dimmer',
  'FORM.REPAIR.ELECTRICITY.SWITCH.BROKEN': 'Frame broken',
  'FORM.REPAIR.ELECTRICITY.SWITCH.MALFUNCTION': 'Defect',
  'FORM.REPAIR.ELECTRICITY.SWITCH.SMELLS': 'Smells scorched',
  'FORM.REPAIR.ELECTRICITY.WIRE': 'Cable',
  'FORM.REPAIR.ELECTRICITY.WIRE.HANGSDOWN': 'Hangs down',
  'FORM.REPAIR.ELECTRICITY.WIRE.SMELLS': 'Smells scorched',
  'FORM.REPAIR.ERROR': 'Error message',
  'FORM.REPAIR.EXTERIORSHAFT': 'Shaft cover (outside)',
  'FORM.REPAIR.FACADE': 'Facade, roof',
  'FORM.REPAIR.FACADE.BRICK': 'Brick',
  'FORM.REPAIR.FACADE.BRICK.BROKEN': 'Broken',
  'FORM.REPAIR.FACADE.BRICK.MISSING': 'Missing',
  'FORM.REPAIR.FACADE.BRICK.POSTPONED': 'Postponed',
  'FORM.REPAIR.FACADE.FACADEWALL': 'Facade',
  'FORM.REPAIR.FACADE.FACADEWALL.MOLD': 'Mold on the facade',
  'FORM.REPAIR.FACADE.FACADEWALL.PEELING': 'Façade is peeling',
  'FORM.REPAIR.FACADE.RAINGUTTER': 'Rain gutter',
  'FORM.REPAIR.FACADE.RAINGUTTER.CLOGGED': 'Clogged',
  'FORM.REPAIR.FACADE.RAINGUTTER.DEFECT': 'Defect',
  'FORM.REPAIR.FACADE.RAINGUTTER.DOWNPIPECLOGGED': 'Downpipe blocked',
  'FORM.REPAIR.FACADE.RAINGUTTER.DOWNPIPEDEFECT': 'Downpipe defective',
  'FORM.REPAIR.FACADE.RAINGUTTER.LICKS': 'Leaks',
  'FORM.REPAIR.FILLINGDRILLHOLE': 'Close drill hole',
  'FORM.REPAIR.FIREPROTECTION': 'Fire protection',
  'FORM.REPAIR.FIREPROTECTION.SMOKESENSOR': 'Smoke detector',
  'FORM.REPAIR.FLOORDOOR': 'Floor door',
  'FORM.REPAIR.FLOOR': 'Floor',
  'FORM.REPAIR.FLOOR.SURFACE': 'Floor covering, surface',
  'FORM.REPAIR.FLOOR.SURFACE.BULGES': 'Bulges upwards',
  'FORM.REPAIR.FLOOR.SURFACE.BURNMARK': 'Burn mark',
  'FORM.REPAIR.FLOOR.SURFACE.CHAFEMARK': 'Scuff mark',
  'FORM.REPAIR.FLOOR.SURFACE.DISSOLVING': 'Removing coating',
  'FORM.REPAIR.FLOOR.SURFACE.LIGHTSHADE': 'Light shadow: light, dark',
  'FORM.REPAIR.FLOOR.SURFACE.LOOSE': 'Loose blocks',
  'FORM.REPAIR.FLOOR.SURFACE.QUIRK': 'Quirk',
  'FORM.REPAIR.FLOOR.SURFACE.SCRATCH': 'Scratched',
  'FORM.REPAIR.FLOOR.SURFACE.STAINS': 'Stains, discoloration',
  'FORM.REPAIR.FLOOR.SURFACE.WATERDAMAGE': 'Water damage',
  'FORM.REPAIR.FLOOR.SURFACE.WORNOUT': 'Torn',
  'FORM.REPAIR.FLOOR.TILES': 'Tiles',
  'FORM.REPAIR.FLOOR.TILES.BROKEN': 'Broken',
  'FORM.REPAIR.FLOOR.TILES.JOINTSDEFECT': 'Joint defective',
  'FORM.REPAIR.FLOOR.TILES.LOOSE': 'Loose, wobbly',
  'FORM.REPAIR.FLOOR.TILES.WORNOUT': 'Dirty, worn',
  'FORM.REPAIR.FLOOR.SKIRTINGBOARDS': 'Skirting board',
  'FORM.REPAIR.FLOOR.SKIRTINGBOARDS.GAP': 'Gap to the floor',
  'FORM.REPAIR.FLOOR.SKIRTINGBOARDS.LOOSE': 'Loose, wobbly',
  'FORM.REPAIR.FLOOR.SKIRTINGBOARDS.MISSING': 'Missing',
  'FORM.REPAIR.FLOOR.SKIRTINGBOARDS.WORNOUT': 'Torn',
  'FORM.REPAIR.FLOOR.JOINTS': 'Floor joints',
  'FORM.REPAIR.FLOOR.JOINTS.DISSOLVING': 'Disintegrating',
  'FORM.REPAIR.FLOOR.JOINTS.MISSING': 'Missing',
  'FORM.REPAIR.FLOOR.JOINTS.MOLD': 'Moldy',
  'FORM.REPAIR.FLOOR.DOORSTEP': 'Doorstep, landing',
  'FORM.REPAIR.FLOOR.DOORSTEP.BARBROKEN': 'Transition profile broken',
  'FORM.REPAIR.FLOOR.DOORSTEP.BARLOOSE': 'Transition profile loose',
  'FORM.REPAIR.FLOOR.DOORSTEP.BARMISSING': 'Transition profile missing',
  'FORM.REPAIR.FLOOR.DOORSTEP.WORNOUT': 'Torn',
  'FORM.REPAIR.FLOOR.DRAIN': 'Floor drain (inside)',
  'FORM.REPAIR.GARAGE': 'Garage',
  'FORM.REPAIR.GARAGEDOOR': 'Garage door',
  'FORM.REPAIR.GARAGEGATE': 'Garage gate',
  'FORM.REPAIR.GARAGEGATE.DRAINDEFECT': 'Rain drain defective',
  'FORM.REPAIR.GARAGEGATE.ENGINE': 'Engine',
  'FORM.REPAIR.GARAGEGATE.ENGINE.DEFECT': 'Defect',
  'FORM.REPAIR.GARAGEGATE.ENGINE.NOISE': 'Unusual noise',
  'FORM.REPAIR.GARAGEGATE.GATE': 'Garage door, suspension',
  'FORM.REPAIR.GARAGEGATE.GATE.BENT': 'Bent',
  'FORM.REPAIR.GARAGEGATE.GATE.BROKEN': 'Broken open',
  'FORM.REPAIR.GARAGEGATE.GATE.DISENGAGED': 'Unhinged',
  'FORM.REPAIR.GARAGEGATE.GATE.LOOSE': 'Loose, wobbly',
  'FORM.REPAIR.GARAGEGATE.GATE.NOISE': 'Makes unusual noises',
  'FORM.REPAIR.GARAGEGATE.OPENINGCLOSING.KEYDEFECT': 'Key opening defective',
  'FORM.REPAIR.GARAGEGATE.OPENINGCLOSING.KEYPOSTDEFECT': 'Key opening post defective',
  'FORM.REPAIR.GARAGEGATE.OPENINGCLOSING.NOOPENS': 'No longer opens',
  'FORM.REPAIR.GARAGEGATE.OPENINGCLOSING.NOTCLOSES': 'Does not close',
  'FORM.REPAIR.GARAGEGATE.OPENINGCLOSING.REMOTEDEFECT': 'Remote opening does not work',
  'FORM.REPAIR.GARAGEGATE.OPENINGCLOSING.STUCK': 'Stuck',
  'FORM.REPAIR.GARAGEGATE.RUSTEDDOORSTEP': 'Threshold rusted',
  'FORM.REPAIR.GARBAGE': 'Garbage place',
  'FORM.REPAIR.GARBAGE.BROKEN': 'Trashbin, container defective',
  'FORM.REPAIR.GARDEN': 'Green areas, tenant garden',
  'FORM.REPAIR.GREENAREA': 'Greenery',
  'FORM.REPAIR.GREENAREA.BRANCHESFELL': 'Branches fallen down',
  'FORM.REPAIR.GREENAREA.BRANCHESHANG': 'Branches hanging down',
  'FORM.REPAIR.GREENAREA.HEDGESUNCUT': 'Hedges uncut',
  'FORM.REPAIR.GREENAREA.UNMOWED': 'Lawn not mowed',
  'FORM.REPAIR.GUESTAREA': 'Guest room',
  'FORM.REPAIR.HEATING': 'Building heating',
  'FORM.REPAIR.HEATING.DEFECT': 'Failed',
  'FORM.REPAIR.HEATING.INSUFFICIENT': 'Heats insufficiently',
  'FORM.REPAIR.HEATING.NOISE': 'Noise (e.g. knocking)',
  'FORM.REPAIR.HEATING.OILEMPTY': 'Oil tank: almost empty or empty',
  'FORM.REPAIR.HEATING.PELLETEMPTY': 'Pellet store: almost empty or empty',
  'FORM.REPAIR.HEATING.WOODCHIPEMPTY': 'Woodchip storage: almost empty or empty',
  'FORM.REPAIR.HEATINGVENTILATION': 'Heating, ventilation',
  'FORM.REPAIR.HEATINGVENTILATION.FLOORHEATING': 'Floor heating',
  'FORM.REPAIR.HEATINGVENTILATION.FLOORHEATING.COLD': 'Remains cold',
  'FORM.REPAIR.HEATINGVENTILATION.FLOORHEATING.CONTROLLERDEFECT': 'Controller defective',
  'FORM.REPAIR.HEATINGVENTILATION.FLOORHEATING.NOTWARMINGUP': 'Does not heat the entire floor',
  'FORM.REPAIR.HEATINGVENTILATION.FLOORHEATING.WATERDAMAGE': 'Water damage',
  'FORM.REPAIR.HEATINGVENTILATION.RADIATOR': 'Radiator',
  'FORM.REPAIR.HEATINGVENTILATION.RADIATOR.COLD': 'Stays cold',
  'FORM.REPAIR.HEATINGVENTILATION.RADIATOR.COLORDISSOLVING': 'Paint is peeling off',
  'FORM.REPAIR.HEATINGVENTILATION.RADIATOR.CONTROLLERDEFECT': 'Controller defective',
  'FORM.REPAIR.HEATINGVENTILATION.RADIATOR.DRIPPING': 'Dripping',
  'FORM.REPAIR.HEATINGVENTILATION.RADIATOR.GROUNDWATERDAMAGE': 'Water damage on the floor',
  'FORM.REPAIR.HEATINGVENTILATION.RADIATOR.NOTWARMINGUP': 'No longer warms properly',
  'FORM.REPAIR.HEATINGVENTILATION.RADIATOR.NOISE': 'Makes noise',
  'FORM.REPAIR.HEATINGVENTILATION.THERMOSTAT': 'Thermostat',
  'FORM.REPAIR.HEATINGVENTILATION.THERMOSTAT.DEFECT': 'No longer works',
  'FORM.REPAIR.HEATINGVENTILATION.THERMOSTAT.HANGSDOWN': 'Hangs down',
  'FORM.REPAIR.HEATINGVENTILATION.VENTILATION': 'Ventilation',
  'FORM.REPAIR.HEATINGVENTILATION.VENTILATION.DEFECT': 'No longer works',
  'FORM.REPAIR.HEATINGVENTILATION.VENTILATION.LOWPERFORMANCE': 'Performance too low',
  'FORM.REPAIR.HEATINGVENTILATION.VENTILATION.NOISE': 'Makes noise',
  'FORM.REPAIR.HOLE': 'Hole',
  'FORM.REPAIR.HOMESTAIRS': 'Stairs (in the apartment)',
  'FORM.REPAIR.HOTWATER': 'Hot water',
  'FORM.REPAIR.HOTWATER.NOHOTWATER': 'No hot water',
  'FORM.REPAIR.HOTWATER.NOHOTWATER.ONECONNECTION': 'Only one connection',
  'FORM.REPAIR.HOTWATER.NOHOTWATER.TITLE': 'How many connections are not receiving hot water?',
  'FORM.REPAIR.HOTWATER.NOWARMWATER': 'Water not really hot',
  'FORM.REPAIR.HOTWATER.NOWARMWATER.TITLE': 'How many connections only have hot water?',
  'FORM.REPAIR.HOUSEDOOR': 'House entrance door',
  'FORM.REPAIR.INNERSHAFT': 'Manhole cover (inside)',
  'FORM.REPAIR.KITCHEN': 'Kitchen',
  'FORM.REPAIR.KITCHEN.APPLIANCES': 'Kitchen appliances',
  'FORM.REPAIR.KITCHEN.APPLIANCES.BROKENLIGHT': 'Light broken',
  'FORM.REPAIR.KITCHEN.APPLIANCES.COOKER': 'Cooker',
  'FORM.REPAIR.KITCHEN.APPLIANCES.COOKER.HOB': 'Hob only',
  'FORM.REPAIR.KITCHEN.APPLIANCES.COOKER.HOBOVEN': 'Cooktop & oven',
  'FORM.REPAIR.KITCHEN.APPLIANCES.COOKER.HOBOVEN.LOOSE': 'Loose, wobbly',
  'FORM.REPAIR.KITCHEN.APPLIANCES.COOKER.HOBOVEN.SWITCHONDEFECT': 'Cannot switch on',
  'FORM.REPAIR.KITCHEN.APPLIANCES.COOKER.OVEN': 'Oven only',
  'FORM.REPAIR.KITCHEN.APPLIANCES.DISHWASHER': 'Dishwasher',
  'FORM.REPAIR.KITCHEN.APPLIANCES.DISHWASHER.BACKWATER': 'Waste water pushes out of drain',
  'FORM.REPAIR.KITCHEN.APPLIANCES.DISHWASHER.BASKETDEFECT': 'Cutlery basket defective',
  'FORM.REPAIR.KITCHEN.APPLIANCES.DISHWASHER.FALLSOFF': 'Front falls off',
  'FORM.REPAIR.KITCHEN.APPLIANCES.DISHWASHER.FOAMS': 'Foams',
  'FORM.REPAIR.KITCHEN.APPLIANCES.DISHWASHER.GRINDS': 'Front brushes, stops',
  'FORM.REPAIR.KITCHEN.APPLIANCES.DISHWASHER.LEAKING': 'Leaking: water',
  'FORM.REPAIR.KITCHEN.APPLIANCES.DISHWASHER.LOUD': 'Humming loudly',
  'FORM.REPAIR.KITCHEN.APPLIANCES.DISHWASHER.NOTCLEAN': 'Does not wash clean',
  'FORM.REPAIR.KITCHEN.APPLIANCES.DISHWASHER.NOTDRIES': 'No longer dries',
  'FORM.REPAIR.KITCHEN.APPLIANCES.DISHWASHER.WATERINMACHINE': 'Water is standing in machine',
  'FORM.REPAIR.KITCHEN.APPLIANCES.EXTRACTOR': 'Extractor fan',
  'FORM.REPAIR.KITCHEN.APPLIANCES.EXTRACTOR.CLOGGED': 'Filter clogged',
  'FORM.REPAIR.KITCHEN.APPLIANCES.EXTRACTOR.DRIPS': 'Dripping',
  'FORM.REPAIR.KITCHEN.APPLIANCES.EXTRACTOR.LOUD': 'Beeps / too loud / makes noises',
  'FORM.REPAIR.KITCHEN.APPLIANCES.EXTRACTOR.POWERDEFECT': 'Does not extract / too weak',
  'FORM.REPAIR.KITCHEN.APPLIANCES.EXTRACTOR.REGULATIONDEFECT': 'Cannot be regulated',
  'FORM.REPAIR.KITCHEN.APPLIANCES.EXTRACTOR.SMELLS': 'Stinks',
  'FORM.REPAIR.KITCHEN.APPLIANCES.FREEZER': 'Freezer',
  'FORM.REPAIR.KITCHEN.APPLIANCES.HOB.BROKEN': 'Broken, cracked',
  'FORM.REPAIR.KITCHEN.APPLIANCES.HOB.BROKENFRAME': 'Frame broken',
  'FORM.REPAIR.KITCHEN.APPLIANCES.HOB.BURNMARK': 'Burn mark',
  'FORM.REPAIR.KITCHEN.APPLIANCES.HOB.JOINTLEAKS': 'Joint leaking, coming loose',
  'FORM.REPAIR.KITCHEN.APPLIANCES.HOB.LOOSE': 'Loose, wobbles',
  'FORM.REPAIR.KITCHEN.APPLIANCES.HOB.SWITCHDEFECT': 'Switching on does not work',
  'FORM.REPAIR.KITCHEN.APPLIANCES.HOB.WORN': 'Worn out',
  'FORM.REPAIR.KITCHEN.APPLIANCES.MICROWAVE': 'Microwave',
  'FORM.REPAIR.KITCHEN.APPLIANCES.MICROWAVE.HANDLEDEFECT': 'Handle defective',
  'FORM.REPAIR.KITCHEN.APPLIANCES.MICROWAVE.LOCKDEFECT': 'Door lock damaged',
  'FORM.REPAIR.KITCHEN.APPLIANCES.MICROWAVE.TURNTABLEDEFECT': 'Turntable defective',
  'FORM.REPAIR.KITCHEN.APPLIANCES.NOELECTRICITY': 'No power',
  'FORM.REPAIR.KITCHEN.APPLIANCES.NOTHOT': 'Does not heat up',
  'FORM.REPAIR.KITCHEN.APPLIANCES.OVEN': 'Oven',
  'FORM.REPAIR.KITCHEN.APPLIANCES.OVEN.GLASSDEFECT': 'Glass pane defective',
  'FORM.REPAIR.KITCHEN.APPLIANCES.OVEN.REPLACEACCESSORIES': 'Replace / add accessories',
  'FORM.REPAIR.KITCHEN.APPLIANCES.OVEN.SEALDEFECT': 'Seal porous, defective',
  'FORM.REPAIR.KITCHEN.APPLIANCES.OVEN.SMELL': 'Strong odor',
  'FORM.REPAIR.KITCHEN.APPLIANCES.REFRIGERATOR': 'Refrigerator',
  'FORM.REPAIR.KITCHEN.APPLIANCES.REFRIGERATOR.CONDENSATION': 'Condensation',
  'FORM.REPAIR.KITCHEN.APPLIANCES.REFRIGERATOR.GLASSDEFECT': 'Glass shelf, shelf defective',
  'FORM.REPAIR.KITCHEN.APPLIANCES.REFRIGERATOR.DOOR': 'Door',
  'FORM.REPAIR.KITCHEN.APPLIANCES.REFRIGERATOR.DOOR.BOTTLEHOLDERDEFECT': 'Defective bottle holder',
  'FORM.REPAIR.KITCHEN.APPLIANCES.REFRIGERATOR.DOOR.CRACKS': 'Cracks',
  'FORM.REPAIR.KITCHEN.APPLIANCES.REFRIGERATOR.DOOR.GRINDS': 'Strikes, stops',
  'FORM.REPAIR.KITCHEN.APPLIANCES.REFRIGERATOR.DOOR.HANGSDOWN': 'Front hangs down',
  'FORM.REPAIR.KITCHEN.APPLIANCES.REFRIGERATOR.DOOR.HANDLEDEFECT': 'Handle defective',
  'FORM.REPAIR.KITCHEN.APPLIANCES.REFRIGERATOR.DOOR.NOTCLOSE': 'Does not stay closed',
  'FORM.REPAIR.KITCHEN.APPLIANCES.REFRIGERATOR.DOORCOMPARTMENTSDEFECT': 'Defective door compartments',
  'FORM.REPAIR.KITCHEN.APPLIANCES.REFRIGERATOR.DRAWERDEFECT': 'Defective vegetable drawer, compartment',
  'FORM.REPAIR.KITCHEN.APPLIANCES.REFRIGERATOR.FREEZERDOORDEFECT': 'Freezer compartment door defective',
  'FORM.REPAIR.KITCHEN.APPLIANCES.REFRIGERATOR.FREEZES': 'Frequently iced up',
  'FORM.REPAIR.KITCHEN.APPLIANCES.REFRIGERATOR.LOUD': 'Hums loudly',
  'FORM.REPAIR.KITCHEN.APPLIANCES.REFRIGERATOR.NOCOOLS': 'No longer cools',
  'FORM.REPAIR.KITCHEN.APPLIANCES.REFRIGERATOR.SEALDEFECT': 'Seal does not hold',
  'FORM.REPAIR.KITCHEN.APPLIANCES.STEAMER': 'Steamer',
  'FORM.REPAIR.KITCHEN.APPLIANCES.STEAMER.LEAKING': 'Leaking: water',
  'FORM.REPAIR.KITCHEN.APPLIANCES.STEAMER.TANKDEFECT': 'Water tank defective',
  'FORM.REPAIR.KITCHEN.APPLIANCES.SWITCHDEFECT': 'Switch defective',
  'FORM.REPAIR.KITCHEN.HOB': 'Hob',
  'FORM.REPAIR.KITCHEN.WORKTOP': 'Worktop',
  'FORM.REPAIR.KITCHEN.WORKTOP.EDGE': 'Edge',
  'FORM.REPAIR.KITCHEN.WORKTOP.EDGE.CRACK': 'crack',
  'FORM.REPAIR.KITCHEN.WORKTOP.EDGE.FRAYS': 'Fringe out',
  'FORM.REPAIR.KITCHEN.WORKTOP.JOINT': 'JOINT',
  'FORM.REPAIR.KITCHEN.WORKTOP.JOINT.DISSOLVES': 'Dissolves',
  'FORM.REPAIR.KITCHEN.WORKTOP.JOINT.MOLDY': 'Moldy',
  'FORM.REPAIR.KITCHEN.WORKTOP.SURFACE': 'Surface',
  'FORM.REPAIR.KITCHEN.WORKTOP.SURFACE.BROKEN': 'Broken',
  'FORM.REPAIR.KITCHEN.WORKTOP.SURFACE.BURNMARK': 'Burn mark',
  'FORM.REPAIR.KITCHEN.WORKTOP.SURFACE.CHAFING': 'scuff mark',
  'FORM.REPAIR.KITCHEN.WORKTOP.SURFACE.HOLE': 'Hole',
  'FORM.REPAIR.KITCHEN.WORKTOP.SURFACE.WATERSTAINS': 'water stains',
  'FORM.REPAIR.KITCHEN.WORKTOP.SWOLLEN': 'Swollen',
  'FORM.REPAIR.KITCHEN.FURNITURE': 'Kitchen furniture',
  'FORM.REPAIR.KITCHEN.FURNITURE.APOTHECARYCABINET': 'Apothecary cabinet',
  'FORM.REPAIR.KITCHEN.FURNITURE.AUTOFEEDDEFECT': 'Automatic feed defective',
  'FORM.REPAIR.KITCHEN.FURNITURE.BASE': 'Base',
  'FORM.REPAIR.KITCHEN.FURNITURE.BASE.BROKEN': 'Broken',
  'FORM.REPAIR.KITCHEN.FURNITURE.BASE.DEPRESSED': 'Pressed in',
  'FORM.REPAIR.KITCHEN.FURNITURE.BASE.JOINTDEFECT': 'Joint defective',
  'FORM.REPAIR.KITCHEN.FURNITURE.DRAWER': 'Drawer',
  'FORM.REPAIR.KITCHEN.FURNITURE.DRAWER.INSERTMISSING': 'Cutlery tray missing',
  'FORM.REPAIR.KITCHEN.FURNITURE.DOORS': 'Doors, fronts',
  'FORM.REPAIR.KITCHEN.FURNITURE.DOORS.CUSHIONINGDEFECT': 'Cushioning defective',
  'FORM.REPAIR.KITCHEN.FURNITURE.DOORS.EDGEDEFECT': 'Edges defective',
  'FORM.REPAIR.KITCHEN.FURNITURE.DOORS.EDGEMISSING': 'Edge missing',
  'FORM.REPAIR.KITCHEN.FURNITURE.DOORS.GRINDS': 'Strips, stops',
  'FORM.REPAIR.KITCHEN.FURNITURE.DOORS.HINGEDEFECT': 'Hinge defective',
  'FORM.REPAIR.KITCHEN.FURNITURE.DOORS.SNAPPERDEFECT': 'Latch defective',
  'FORM.REPAIR.KITCHEN.FURNITURE.HANDLEDEFECT': 'Handle defective',
  'FORM.REPAIR.KITCHEN.FURNITURE.HANGSDOWN': 'Hangs down',
  'FORM.REPAIR.KITCHEN.FURNITURE.LOOSE': 'Loose, wobbles',
  'FORM.REPAIR.KITCHEN.FURNITURE.MOLDY': 'Moldy',
  'FORM.REPAIR.KITCHEN.FURNITURE.NOTOPENS': 'Does not open properly',
  'FORM.REPAIR.KITCHEN.FURNITURE.NOTCLOSES': 'Does not close properly',
  'FORM.REPAIR.KITCHEN.FURNITURE.PANSPULLOUT': 'Pan, bottle pullout',
  'FORM.REPAIR.KITCHEN.FURNITURE.SHELF': 'Shelf',
  'FORM.REPAIR.KITCHEN.FURNITURE.SHELF.BRACKETDEFECT': 'Fixture defective',
  'FORM.REPAIR.KITCHEN.FURNITURE.SHELF.BRACKETMISSING': 'Bracket missing',
  'FORM.REPAIR.KITCHEN.FURNITURE.SHELF.CROOKED': 'Crooked',
  'FORM.REPAIR.KITCHEN.FURNITURE.SHELF.MISSING': 'Missing',
  'FORM.REPAIR.KITCHEN.FURNITURE.SHELF.SURFACEDEFECT': 'Surface defective',
  'FORM.REPAIR.KITCHEN.FURNITURE.SWOLLEN': 'Swollen',
  'FORM.REPAIR.KITCHEN.FURNITURE.WALLS': 'Walls, floor, ...',
  'FORM.REPAIR.KITCHEN.FURNITURE.WALLS.BROKEN': 'Back wall broken',
  'FORM.REPAIR.KITCHEN.FURNITURE.WALLS.WALLPRESSED': 'Back wall pressed in',
  'FORM.REPAIR.KITCHEN.FURNITURE.WORN': 'Worn',
  'FORM.REPAIR.KITCHEN.WASTE': 'Waste system',
  'FORM.REPAIR.KITCHEN.WASTE.TRASHBIN': 'Trashbin',
  'FORM.REPAIR.KITCHEN.WASTE.TRASHBIN.BRACKETTORN': 'Floor bracket torn out',
  'FORM.REPAIR.KITCHEN.WASTE.TRASHBIN.CONNECTIONDEFECT': 'Door connection defective',
  'FORM.REPAIR.KITCHEN.WASTE.TRASHBIN.LIDHOLDERDEFECT': 'Lid holder defective',
  'FORM.REPAIR.KITCHEN.WASTE.TRASHBIN.LIDTORN': 'Lid, inclined shelf torn',
  'FORM.REPAIR.KITCHEN.WASTE.TRASHBIN.MISSING': 'Missing',
  'FORM.REPAIR.KITCHEN.WASTE.TRASHBIN.WORN': 'Worn',
  'FORM.REPAIR.KITCHEN.WASTE.FLOOR': 'Furniture floor waste bin',
  'FORM.REPAIR.KITCHEN.WASTE.FLOOR.EDGEDEFECT': 'Edges defective',
  'FORM.REPAIR.KITCHEN.WASTE.FLOOR.MOLDY': 'Moldy',
  'FORM.REPAIR.KITCHEN.WASTE.FLOOR.SWOLLEN': 'Swollen',
  'FORM.REPAIR.KITCHEN.WASTE.FLOOR.WORN': 'Torn',
  'FORM.REPAIR.KITCHEN.WASTE.SUSPENSION': 'Suspension',
  'FORM.REPAIR.KITCHEN.WASTE.SUSPENSION.BROKEN': 'Broken, cracked',
  'FORM.REPAIR.KITCHEN.WASTE.SUSPENSION.LOOSE': 'Loose, wobbly',
  'FORM.REPAIR.KITCHEN.WASTE.DOOR': 'Pull-out or door',
  'FORM.REPAIR.KITCHEN.WASTE.DOOR.AUTOFEEDDEFECT': 'Automatic drawer defective',
  'FORM.REPAIR.KITCHEN.WASTE.DOOR.GRINDS': 'Grazes, stops',
  'FORM.REPAIR.KITCHEN.WASTE.DOOR.HANDLEDEFECT': 'Handle defective',
  'FORM.REPAIR.KITCHEN.WASTE.DOOR.NOTCLOSES': 'Closes poorly',
  'FORM.REPAIR.KITCHEN.WASTE.DOOR.NOTOPENS': 'Does not open properly',
  'FORM.REPAIR.KITCHEN.SINK': 'Sink',
  'FORM.REPAIR.KITCHEN.SINK.BACKWATER': 'Waste water pushes out of drain',
  'FORM.REPAIR.KITCHEN.SINK.CLOGGED': 'Clogged',
  'FORM.REPAIR.KITCHEN.SINK.DRAIN': 'Drain',
  'FORM.REPAIR.KITCHEN.SINK.DRAIN.DRAINSSLOW': 'Drains slowly',
  'FORM.REPAIR.KITCHEN.SINK.DRAIN.NOISE': 'Gurgles / noises',
  'FORM.REPAIR.KITCHEN.SINK.DRIPS': 'Drips',
  'FORM.REPAIR.KITCHEN.SINK.FITTINGS': 'Fittings',
  'FORM.REPAIR.KITCHEN.SINK.FITTINGS.CHALKY': 'Limey',
  'FORM.REPAIR.KITCHEN.SINK.FITTINGS.LITTLEPRESSURE': 'Little water pressure',
  'FORM.REPAIR.KITCHEN.SINK.FITTINGS.SEALDEFECT': 'Seal porous, defective',
  'FORM.REPAIR.KITCHEN.SINK.FITTINGS.SPLASHES': 'Splashes',
  'FORM.REPAIR.KITCHEN.SINK.JOINT': 'Joint',
  'FORM.REPAIR.KITCHEN.SINK.JOINT.DIRTY': 'Dirty',
  'FORM.REPAIR.KITCHEN.SINK.JOINT.DISSOLVES': 'Dissolves',
  'FORM.REPAIR.KITCHEN.SINK.JOINT.MOLDY': 'Moldy',
  'FORM.REPAIR.KITCHEN.SINK.LEAKY': 'Leaky',
  'FORM.REPAIR.KITCHEN.SINK.LOOSE': 'Loose, wobbly',
  'FORM.REPAIR.KITCHEN.SINK.NOPLUG': 'Stopper missing',
  'FORM.REPAIR.KITCHEN.SINK.PIPES': 'Siphon, drain pipes',
  'FORM.REPAIR.KITCHEN.SINK.PIPES.LOOSE': 'Loose, not connected',
  'FORM.REPAIR.KITCHEN.SINK.SUPPLYLINES': 'Supply pipes',
  'FORM.REPAIR.KITCHEN.SINK.SINKBASIN': 'Sink',
  'FORM.REPAIR.KITCHEN.SINK.SINKBASIN.GRAZES': 'Drainer missing / defective',
  'FORM.REPAIR.KITCHEN.SINK.SINKBASIN.WORN': 'Worn out',
  'FORM.REPAIR.KITCHEN.SINK.SMELLS': 'Stinks',
  'FORM.REPAIR.LAUNDRYROOM': 'Washing and drying room',
  'FORM.REPAIR.LIFT': 'Lift',
  'FORM.REPAIR.LIFT.ALARMBUTTON': 'Emergency call button defective',
  'FORM.REPAIR.LIFT.BURNINGSMELL': 'Smell of burning',
  'FORM.REPAIR.LIFT.CALLBUTTON': 'Call button (outside)',
  'FORM.REPAIR.LIFT.CONTROLPANEL': 'Car control panel',
  'FORM.REPAIR.LIFT.DOOR': 'Door',
  'FORM.REPAIR.LIFT.DOOR.CLOSESSLOWLY': 'Closes too slowly',
  'FORM.REPAIR.LIFT.DOOR.GRINDS': 'Grinds',
  'FORM.REPAIR.LIFT.DOOR.NOTCLOSING': 'Does not close',
  'FORM.REPAIR.LIFT.DOOR.SLAMS': 'Slams shut (slams shut)',
  'FORM.REPAIR.LIFT.DOOR.SQUEAKS': 'Squeaks',
  'FORM.REPAIR.LIFT.DOOR.STUCK': 'Clamps',
  'FORM.REPAIR.LIFT.FAILURE': 'Failure during operation',
  'FORM.REPAIR.LIFT.FAILURE.COMPLETE': 'Complete failure',
  'FORM.REPAIR.LIFT.FAILURE.JERKING': 'Jerking',
  'FORM.REPAIR.LIFT.FAILURE.NOISE': 'Makes noise',
  'FORM.REPAIR.LIFT.FAILURE.SPORADICALLY': 'Fails sporadically',
  'FORM.REPAIR.LIFT.FAILURE.STOPS': 'Stops at the wrong floor',
  'FORM.REPAIR.LIFT.INDICATOR': 'Floor indicator anteroom',
  'FORM.REPAIR.LIFT.LIGHT': 'Light defective',
  'FORM.REPAIR.LIFT.OBJECTINSHAFT': 'Object in shaft',
  'FORM.REPAIR.LIFT.OUTSIDEPANEL': 'Outside panel',
  'FORM.REPAIR.LIFT.POSITION': 'Unaligned cabin position',
  'FORM.REPAIR.LIFT.WATERDAMAGE': 'Water ingress',
  'FORM.REPAIR.LIVINGROOM': 'Living room',
  'FORM.REPAIR.MAILBOX': 'Mailbox',
  'FORM.REPAIR.MAILBOX.DOORDEFECT': 'Parcel compartment door defective',
  'FORM.REPAIR.MAILBOX.FLAPDEFECT': 'Defective flap',
  'FORM.REPAIR.MAILBOX.LOCKDEFECT': 'Lock defective',
  'FORM.REPAIR.MAILBOX.LOOSE': 'Loose, wobbly',
  'FORM.REPAIR.MAILBOX.LOSTKEY': 'Key lost',
  'FORM.REPAIR.MAILBOX.TAGCHANGE': 'Change name tag',
  'FORM.REPAIR.MAILBOX.TAGMISSING': 'Name tag missing',
  'FORM.REPAIR.MOIST': 'Moist',
  'FORM.REPAIR.MOLD': 'Mold, fungus',
  'FORM.REPAIR.MULTIMEDIA': 'Multimedia service (TV, Internet, fiber optics, ...)',
  'FORM.REPAIR.MULTIMEDIA.FIBERFAIL': 'Fiber optic connection failed',
  'FORM.REPAIR.MULTIMEDIA.SEALCONNECTION': 'Sealing of the cable connection',
  'FORM.REPAIR.MULTIMEDIA.UNSEALCONNECTION': 'Unseal the cable connection',
  'FORM.REPAIR.NURSERY': "Children's room",
  'FORM.REPAIR.OFFICE': 'Office',
  'FORM.REPAIR.OTHER': 'Other damage',
  'FORM.REPAIR.OUTSIDEFLOOR': 'Outside floor',
  'FORM.REPAIR.OUTSIDEFLOOR.BIGJOINTS': 'Joints too big',
  'FORM.REPAIR.OUTSIDEFLOOR.BROKEN': 'Broken, missing',
  'FORM.REPAIR.OUTSIDEFLOOR.DIRTY': 'Discolored, stained, dirty',
  'FORM.REPAIR.OUTSIDEFLOOR.LOOSE': 'Loose',
  'FORM.REPAIR.OUTSIDEFLOOR.UNEVEN': 'Uneven surface',
  'FORM.REPAIR.OUTSIDEFLOOR.WEEDSINJOINTS': 'Weeds in joints',
  'FORM.REPAIR.PAINTING': 'Painting',
  'FORM.REPAIR.PAINTING.DURATION.TITLE': 'When was the last time you painted?',
  'FORM.REPAIR.PAINTING.MINUSFIVE': 'Less than 5 years old',
  'FORM.REPAIR.PAINTING.NONSMOKERS': 'Non-smokers',
  'FORM.REPAIR.PAINTING.PLUSFIVE': 'Older than 5 years',
  'FORM.REPAIR.PAINTING.SMOKERS': 'Smokers',
  'FORM.REPAIR.PAINTING.SMOKING.TITLE': 'Have you smoked?',
  'FORM.REPAIR.PARKING': 'Parking lot',
  'FORM.REPAIR.PARKING.TIREBOX': 'Tire cabinet, tire box',
  'FORM.REPAIR.PARKING.TIREBOX.LOOSE': 'Loose, wobbly',
  'FORM.REPAIR.PARKING.TIREBOX.LOCKDEFECT': 'Lock defective',
  'FORM.REPAIR.PARKING.TIREBOX.MISSINGKEY': 'Key missing',
  'FORM.REPAIR.PARKING.TIREBOX.BOXDEFECT': 'Box defective',
  'FORM.REPAIR.PLAYGROUND': 'Playground',
  'FORM.REPAIR.PLAYGROUND.EQUIPMENT': 'Playground equipment',
  'FORM.REPAIR.PLAYGROUND.EQUIPMENT.FRAMEDEFECT': 'Climbing frame defective',
  'FORM.REPAIR.PLAYGROUND.EQUIPMENT.OTHERDEFECT': 'Other play equipment defective',
  'FORM.REPAIR.PLAYGROUND.EQUIPMENT.SANDPITDEFECT': 'Sandpit defective',
  'FORM.REPAIR.PLAYGROUND.EQUIPMENT.SLIDEDEFECT': 'Slide defective',
  'FORM.REPAIR.PESTS': 'Pests',
  'FORM.REPAIR.PESTS.ANTS': 'Ants',
  'FORM.REPAIR.PESTS.BEDBUGS': 'bedbugs',
  'FORM.REPAIR.PESTS.BIRDS': 'Pigeons / sparrows / birds',
  'FORM.REPAIR.PESTS.COCKROACHES': 'Cockroaches',
  'FORM.REPAIR.PESTS.MICE': 'Mice / rats',
  'FORM.REPAIR.PESTS.OTHER': 'Other pest',
  'FORM.REPAIR.PESTS.SILVERFISH': 'Silverfish',
  'FORM.REPAIR.PESTS.WASPS': 'Wasps',
  'FORM.REPAIR.RAILINGS': 'Railings',
  'FORM.REPAIR.RAILINGS.BROKEN': 'Broken',
  'FORM.REPAIR.RAILINGS.LOOSE': 'Loose, wobbly',
  'FORM.REPAIR.RAILINGS.RUSTY': 'Rusty',
  'FORM.REPAIR.RAILINGS.STACKSDEFECT': 'Stack defective',
  'FORM.REPAIR.SIDEWALK': 'Sidewalk',
  'FORM.REPAIR.SINGLEGARAGE': 'Single garage',
  'FORM.REPAIR.SHADES': 'Shading and darkening',
  'FORM.REPAIR.SHADES.BLINDS': 'Window shutter',
  'FORM.REPAIR.SHADES.BLINDS.BRACKETBROKEN': 'Retention broken',
  'FORM.REPAIR.SHADES.BLINDS.GRINDS': 'Grazes, stands on',
  'FORM.REPAIR.SHADES.BLINDS.HANGSDOWN': 'Hangs down',
  'FORM.REPAIR.SHADES.BLINDS.HINGEDEFECT': 'Hinge defective',
  'FORM.REPAIR.SHADES.BLINDS.HOLDERDEFECT': 'Holder defective',
  'FORM.REPAIR.SHADES.BLINDS.JAMS': 'Jammed',
  'FORM.REPAIR.SHADES.BLINDS.LOOSE': 'Loose, wobbly',
  'FORM.REPAIR.SHADES.BLINDS.REMAINSOPEN': 'Does not close',
  'FORM.REPAIR.SHADES.BLINDS.SLATSDEFECT': 'Slats defective',
  'FORM.REPAIR.SHADES.BLINDS.WEATHERED': 'Weathered',
  'FORM.REPAIR.SHADES.CANOPEN': 'Yes, windows can be opened',
  'FORM.REPAIR.SHADES.CANTOPEN': 'No, windows cannot be opened',
  'FORM.REPAIR.SHADES.CONTROLLINGQUANTITY.TITLE': 'How many blinds are controlled simultaneously with the switch?',
  'FORM.REPAIR.SHADES.CRANK': 'Crank',
  'FORM.REPAIR.SHADES.CRANKJOINT.TITLE': 'Is the crank joint intact?',
  'FORM.REPAIR.SHADES.CRANKTURN.TITLE': 'Does the crank turn?',
  'FORM.REPAIR.SHADES.CROOKED': 'Hangs crooked',
  'FORM.REPAIR.SHADES.CURTAINRAIL': 'Curtain rail',
  'FORM.REPAIR.SHADES.CURTAINRAIL.MISSING': 'Missing',
  'FORM.REPAIR.SHADES.CURTAINRAIL.RIPPED': 'Ripped out',
  'FORM.REPAIR.SHADES.CURTAINRAIL.STUCK': 'Glider, curtain does not run',
  'FORM.REPAIR.SHADES.DETACHED': 'Out of the guide rail',
  'FORM.REPAIR.SHADES.ELECTRICALREMOTE': 'Electric (remote control)',
  'FORM.REPAIR.SHADES.ELECTRICALREMOTE.BATTERIES.TITLE': 'Have you tested new batteries for the remote control?',
  'FORM.REPAIR.SHADES.ELECTRICALREMOTE.NEWBATTERIES': 'New',
  'FORM.REPAIR.SHADES.ELECTRICALREMOTE.OLDBATTERIES': 'Old',
  'FORM.REPAIR.SHADES.ELECTRICALWALL': 'Electrical (wall switch)',
  'FORM.REPAIR.SHADES.ENGINESOUND': 'Yes, a motor noise',
  'FORM.REPAIR.SHADES.FABRICBLINDS': 'Fabric blinds',
  'FORM.REPAIR.SHADES.FABRICBLINDS.DEFECT': 'Fabric rod defective',
  'FORM.REPAIR.SHADES.FABRICBLINDS.KIND.TITLE': 'What kind of fabric blind is it?',
  'FORM.REPAIR.SHADES.FABRICBLINDS.RIPPED': 'Fabric torn or loose',
  'FORM.REPAIR.SHADES.FABRICBLINDS.UNROLLS': 'Fabric rolls off, blinds do not close',
  'FORM.REPAIR.SHADES.HAILDAMAGE': 'Hail damage',
  'FORM.REPAIR.SHADES.HANDCRANK': 'Hand crank',
  'FORM.REPAIR.SHADES.HANDCRANK.NOTOKAY': 'Not ok',
  'FORM.REPAIR.SHADES.HANDCRANK.NOTTURN': 'Does not turn',
  'FORM.REPAIR.SHADES.HANDCRANK.OKAY': 'ok',
  'FORM.REPAIR.SHADES.HANDCRANK.TURNSEMPTY': 'Turns empty',
  'FORM.REPAIR.SHADES.INSTALLATIONAGE.TITLE': 'How old is the installation?',
  'FORM.REPAIR.SHADES.KIND.TITLE': 'What type of slat blind is it?',
  'FORM.REPAIR.SHADES.LOOSE': 'Guide rail loose',
  'FORM.REPAIR.SHADES.METALBLINDS': 'All-metal blinds',
  'FORM.REPAIR.SHADES.METALBLINDS.KIND.TITLE': 'What kind of slat blind is it?',
  'FORM.REPAIR.SHADES.MINUS2YEARS': 'Less than 2 years old',
  'FORM.REPAIR.SHADES.MINUS3M': 'Narrower than 3m',
  'FORM.REPAIR.SHADES.NOISE': 'Makes noise',
  'FORM.REPAIR.SHADES.NOSOUND': 'No, no motor noise',
  'FORM.REPAIR.SHADES.NOTPULLDOWNUP': 'No longer goes up / down',
  'FORM.REPAIR.SHADES.NOTSHUTOFF': 'Can no longer be switched off',
  'FORM.REPAIR.SHADES.NOTTURN': 'No longer tilts',
  'FORM.REPAIR.SHADES.ONE': '1',
  'FORM.REPAIR.SHADES.PLUS2YEARS': 'Older than 2 years',
  'FORM.REPAIR.SHADES.PLUS3M': 'Wider than 3m',
  'FORM.REPAIR.SHADES.QUANTITY.TITLE': 'How many blinds are affected?',
  'FORM.REPAIR.SHADES.SEVERAL': 'Multiple',
  'FORM.REPAIR.SHADES.SHUTTER': 'Roller shutter',
  'FORM.REPAIR.SHADES.SHUTTER.BELT': 'Roller shutter belt',
  'FORM.REPAIR.SHADES.SHUTTER.COVER': 'Roller shutter cover',
  'FORM.REPAIR.SHADES.SHUTTER.FALLSDOWN': 'Does not hold, falls down',
  'FORM.REPAIR.SHADES.SHUTTER.NOTFULLYUP': 'Does not pull up completely',
  'FORM.REPAIR.SHADES.SHUTTER.REMAINSOPEN': 'Does not close completely',
  'FORM.REPAIR.SHADES.SHUTTER.STUCK': 'Stuck',
  'FORM.REPAIR.SHADES.SLATBLINDS': 'Slat blinds',
  'FORM.REPAIR.SHADES.SLATBLINDS.CORDTORN': 'Cord torn',
  'FORM.REPAIR.SHADES.SOUND.TITLE': 'When you operate the switch, do you hear a sound?',
  'FORM.REPAIR.SHADES.SQUEAKING': 'Yes, grinding or squeaking',
  'FORM.REPAIR.SHADES.TWO': '2',
  'FORM.REPAIR.SHADES.WIDTHSTOREN.TITLE': 'How wide is the blind?',
  'FORM.REPAIR.SHADES.WINDOWSOPEN.TITLE': 'Can the windows be opened?',
  'FORM.REPAIR.SHAFT.BACKWATER': 'Waste water is pushing out of the drain',
  'FORM.REPAIR.SHAFT.CLOGGED': 'Clogged',
  'FORM.REPAIR.SHAFT.DIRTY': 'Dirty',
  'FORM.REPAIR.SHAFT.LOOSE': 'Wobbles',
  'FORM.REPAIR.SHAFT.SMELLS': 'Stinks',
  'FORM.REPAIR.SHOWROOM': 'Showroom',
  'FORM.REPAIR.SHOWWINDOW': 'Shop window',
  'FORM.REPAIR.SHOWWINDOW.FRAME': 'Window frame',
  'FORM.REPAIR.SHOWWINDOW.FRAME.JOINTLEAK': 'Glass joint leaking, loosening',
  'FORM.REPAIR.SHOWWINDOW.FRAME.LEAK': 'Leaking',
  'FORM.REPAIR.SHOWWINDOW.GLASS': 'Window pane',
  'FORM.REPAIR.SHOWWINDOW.RAILS': 'Curtain rail',
  'FORM.REPAIR.SHOWWINDOW.RAILS.MISSING': 'Missing',
  'FORM.REPAIR.SHOWWINDOW.RAILS.STUCK': 'Glider, curtain does not run',
  'FORM.REPAIR.SHOWWINDOW.RAILS.TORN': 'Torn out',
  'FORM.REPAIR.SHOWWINDOW.SHUTTER': 'Roller shutter, roller grille',
  'FORM.REPAIR.SHOWWINDOW.SHUTTER.BELT': 'Roller shutter belt',
  'FORM.REPAIR.SHOWWINDOW.SHUTTER.CRANK': 'Roller shutter crank',
  'FORM.REPAIR.SHOWWINDOW.SHUTTER.CRANKBRACKET': 'Roller shutter crank bracket',
  'FORM.REPAIR.SHOWWINDOW.SHUTTER.GRILLES': 'Roller grille',
  'FORM.REPAIR.SHOWWINDOW.SHUTTER.LID': 'Roller shutter lid',
  'FORM.REPAIR.SHOWWINDOW.SHUTTER.ROLLER': 'Roller shutter',
  'FORM.REPAIR.SHOWWINDOW.SILL': 'Window sill, ledge',
  'FORM.REPAIR.SMARTCONTROL': 'Intelligent control',
  'FORM.REPAIR.SMARTCONTROL.BLINDS': 'Blinds control defective',
  'FORM.REPAIR.SMARTCONTROL.DISPLAY': 'Display defective (hardware)',
  'FORM.REPAIR.SMARTCONTROL.HEATINGCOOLING': 'Heating / cooling control defective',
  'FORM.REPAIR.SMARTCONTROL.OTHER': 'Other problem',
  'FORM.REPAIR.SMARTCONTROL.USERGUIDE': 'Question about usage',
  'FORM.REPAIR.SMARTCONTROL.VIDEO': 'Video intercom system defective',
  'FORM.REPAIR.SNOWREMOVAL': 'Snow removal',
  'FORM.REPAIR.STAIRWELL': 'Stairwell',
  'FORM.REPAIR.STAIRS': 'Stairs',
  'FORM.REPAIR.STAIRS.STEPS': 'Stairs, step',
  'FORM.REPAIR.STAIRS.STEPS.BROKEN': 'Broken',
  'FORM.REPAIR.STAIRS.STEPS.LOOSE': 'Loose, wobbly',
  'FORM.REPAIR.STAIRS.STEPS.WORN': 'Torn surface',
  'FORM.REPAIR.STAIRS.HANDRAIL': 'Handrail',
  'FORM.REPAIR.STAIRS.HANDRAIL.COATINGLOOSE': 'Coating loose',
  'FORM.REPAIR.STAIRS.HANDRAIL.LOOSE': 'Loose, wobbly',
  'FORM.REPAIR.STAIRS.HANDRAIL.STACKSDEFECT': 'Stack defective',
  'FORM.REPAIR.STAIRS.HANDRAIL.TORN': 'Torn out',
  'FORM.REPAIR.STORAGE': 'Storage room',
  'FORM.REPAIR.TECHNICALROOM': 'Technical room',
  'FORM.REPAIR.TREATMENTROOM': 'Treatment room',
  'FORM.REPAIR.VENTILATOR': 'Ventilator',
  'FORM.REPAIR.VENTILATOR.FILTERDEFECT': 'Filter defective',
  'FORM.REPAIR.VENTILATOR.LOWPERFORMANCE': 'No longer draws properly',
  'FORM.REPAIR.VENTILATOR.NOISE': 'Noisy, whistling',
  'FORM.REPAIR.VENTILATOR.NOTSWITCHINGOFFAUTO': 'Does not switch off automatically',
  'FORM.REPAIR.VENTILATOR.NOTSWITCHINGON': 'No longer switches on',
  'FORM.REPAIR.WALL': 'Wall',
  'FORM.REPAIR.WALL.COMESOFF': 'Coloring off',
  'FORM.REPAIR.WALL.PLASTER': 'Plaster, plaster',
  'FORM.REPAIR.WALL.PLASTER.CRUMBLES': 'Crumbles off',
  'FORM.REPAIR.WALL.STAINS': 'Has stains',
  'FORM.REPAIR.WALL.WALLPAPER': 'Wallpaper',
  'FORM.REPAIR.WALL.WALLPAPER.DISSOLVING': 'Peels off',
  'FORM.REPAIR.WALL.YELLOWSPOTS': 'Yellow spots, yellow wall',
  'FORM.REPAIR.WASHBASIN': 'Washbasin',
  'FORM.REPAIR.WASHBASIN.BASIN': 'Washbasin',
  'FORM.REPAIR.WASHBASIN.BASIN.BACKWATER': 'Waste water pushes out of drain',
  'FORM.REPAIR.WASHBASIN.BASIN.CRACK': 'Crack',
  'FORM.REPAIR.WASHBASIN.BASIN.HOLE': 'Hole',
  'FORM.REPAIR.WASHBASIN.BASIN.QUIRK': 'quirk',
  'FORM.REPAIR.WASHBASIN.BASIN.SOAPDISHDEFECT': 'Defective soap dish',
  'FORM.REPAIR.WASHBASIN.BASIN.SOAPDISHHOLDERDEFECT': 'Soap dish holder defective',
  'FORM.REPAIR.WASHBASIN.BASIN.TOOTHBRUSHCUPDEFECT': 'Toothbrush cup defective',
  'FORM.REPAIR.WASHBASIN.BASIN.TOOTHBRUSHCUPHOLDERDEFECT': 'Toothbrush cup holder defective',
  'FORM.REPAIR.WASHBASIN.CLOGGED': 'Clogged',
  'FORM.REPAIR.WASHBASIN.DRAIN': 'Drain, siphon',
  'FORM.REPAIR.WASHBASIN.DRAIN.DRAINSSLOW': 'Drains slowly',
  'FORM.REPAIR.WASHBASIN.DRAIN.NOISE': 'Gurgles / noises',
  'FORM.REPAIR.WASHBASIN.FITTINGS': 'Fittings',
  'FORM.REPAIR.WASHBASIN.FITTINGS.CLAMPS': 'Clamps',
  'FORM.REPAIR.WASHBASIN.FITTINGS.DRIPS': 'Drips',
  'FORM.REPAIR.WASHBASIN.FITTINGS.MOLDY': 'Moldy',
  'FORM.REPAIR.WASHBASIN.FITTINGS.SPLASHES': 'Splashes',
  'FORM.REPAIR.WASHBASIN.FITTINGS.STAINS': 'Has stains, is tarnished',
  'FORM.REPAIR.WASHBASIN.LEAKY': 'Leaking',
  'FORM.REPAIR.WASHBASIN.LOOSE': 'Loose, wobbly',
  'FORM.REPAIR.WASHBASIN.NOTCONNECTED': 'Loose, not connected',
  'FORM.REPAIR.WASHBASIN.PIPES': 'Drain pipes',
  'FORM.REPAIR.WASHBASIN.SMELLS': 'Stinks',
  'FORM.REPAIR.WASHBASIN.SUPPLYLINES': 'Supply lines',
  'FORM.REPAIR.WASHBASIN.TOWELRAIL': 'Towel holder',
  'FORM.REPAIR.WASHBASIN.TOWELRAIL.BROKEN': 'Broken',
  'FORM.REPAIR.WASHBASIN.TOWELRAIL.TORN': 'Torn out',
  'FORM.REPAIR.WASHBASIN.TOWELRAILHEATER': 'Towel heater',
  'FORM.REPAIR.WASHBASIN.TOWELRAILHEATER.NOTWARM': 'No longer gets warm',
  'FORM.REPAIR.WASHBASIN.TOWELRAILHEATER.PAINTOFF': 'Paint is peeling off',
  'FORM.REPAIR.WASHERDRYER': 'Washing machine, dryer',
  'FORM.REPAIR.WASHERDRYER.DRYER': 'Dryer',
  'FORM.REPAIR.WASHERDRYER.DRYER.NOISE': 'Unusual noises',
  'FORM.REPAIR.WASHERDRYER.DRYER.NOTDRIES': 'No longer dries',
  'FORM.REPAIR.WASHERDRYER.HANGER': 'Laundry hanging',
  'FORM.REPAIR.WASHERDRYER.HANGER.LINELOOSE': 'Clothesline loose',
  'FORM.REPAIR.WASHERDRYER.HANGER.LINETORN': 'Clothesline torn',
  'FORM.REPAIR.WASHERDRYER.HANGER.LOOSE': 'Attachment wobbles, loose',
  'FORM.REPAIR.WASHERDRYER.LEAKING': 'Leaking: water',
  'FORM.REPAIR.WASHERDRYER.NOELECTRICITY': 'No power',
  'FORM.REPAIR.WASHERDRYER.NOTHEATUP': 'Does not heat up',
  'FORM.REPAIR.WASHERDRYER.SMELLS': 'Stinks',
  'FORM.REPAIR.WASHERDRYER.SWITCHDEFECT': 'Switch defective',
  'FORM.REPAIR.WASHERDRYER.WASHER': 'Washing machine',
  'FORM.REPAIR.WASHERDRYER.WASHER.CARDNOTWORKING': 'Washing card does not work',
  'FORM.REPAIR.WASHERDRYER.WASHER.NOTPUMPOUTWATER': 'Does not pump out water',
  'FORM.REPAIR.WASHERDRYER.WASHER.NOISE': 'Makes unusual noises',
  'FORM.REPAIR.WASHERDRYER.WASHER.NOTFLINGING': 'No longer spins',
  'FORM.REPAIR.WASHERDRYER.WASHER.NOOPENS': 'No longer opens',
  'FORM.REPAIR.WASTE': 'Trashbin',
  'FORM.REPAIR.WASTE.CROWDED': 'Overfilled, not emptied',
  'FORM.REPAIR.WASTE.DEFECT': 'Trashbin defective',
  'FORM.REPAIR.WASTEDUMP': 'Garbage dump',
  'FORM.REPAIR.WATERDAMAGE': 'Water damage, mold, stains',
  'FORM.REPAIR.WATERDAMAGE.5CHFCOIN': 'About the size of five francs pieces',
  'FORM.REPAIR.WATERDAMAGE.BULGES': 'Bottom lifts',
  'FORM.REPAIR.WATERDAMAGE.CEILING': 'Ceiling',
  'FORM.REPAIR.WATERDAMAGE.CEILINGPITCHEDROOF': 'Ceiling pitched roof',
  'FORM.REPAIR.WATERDAMAGE.CEILINGWITHSKYLIGHT': 'Ceiling: Close to a skylight',
  'FORM.REPAIR.WATERDAMAGE.DOUBLEDORMORE': 'Doubled or even larger',
  'FORM.REPAIR.WATERDAMAGE.DROPPING': 'Water dripping on the wall or ceiling',
  'FORM.REPAIR.WATERDAMAGE.DURATION': 'How long has the damage been visible?',
  'FORM.REPAIR.WATERDAMAGE.FLOODING': 'Puddle of water or flooding',
  'FORM.REPAIR.WATERDAMAGE.JOINTSMOLD': 'Joints on the floor moldy',
  'FORM.REPAIR.WATERDAMAGE.LARGERNOTDOUBLED': 'Larger, but not doubled',
  'FORM.REPAIR.WATERDAMAGE.LOWERHALF': 'Wall: Lower half',
  'FORM.REPAIR.WATERDAMAGE.MINUS24HOURS': 'Within the last 24 hours',
  'FORM.REPAIR.WATERDAMAGE.MINUS5CHFCOIN': 'Less than a five franc piece',
  'FORM.REPAIR.WATERDAMAGE.MINUS7DAYS': 'Within the last 7 days',
  'FORM.REPAIR.WATERDAMAGE.MOLD': 'Mold on wall or ceiling',
  'FORM.REPAIR.WATERDAMAGE.NODROPPING': 'No',
  'FORM.REPAIR.WATERDAMAGE.NOSIZECHANGE': 'Not noticeable',
  'FORM.REPAIR.WATERDAMAGE.PLUS5CHFCOIN': 'Significantly larger than a five-franc coin',
  'FORM.REPAIR.WATERDAMAGE.PLUS7DAYS': 'Already longer',
  'FORM.REPAIR.WATERDAMAGE.SIZE': 'How big is the damage?',
  'FORM.REPAIR.WATERDAMAGE.SIZECHANGE': 'How has the size changed within 24 hours?',
  'FORM.REPAIR.WATERDAMAGE.UPPERHALF': 'Wall: Upper half',
  'FORM.REPAIR.WATERDAMAGE.VISIBILITY': 'Can drops be seen?',
  'FORM.REPAIR.WATERDAMAGE.WALLCEILING': 'Wall and ceiling',
  'FORM.REPAIR.WATERDAMAGE.WALLCEILINGFLOOR': 'Wall, ceiling and floor',
  'FORM.REPAIR.WATERDAMAGE.WHERE': 'Where is the damage?',
  'FORM.REPAIR.WATERDAMAGE.WINDOW': 'Wall: At a window',
  'FORM.REPAIR.WATERDAMAGE.WINDOWMOLD': 'Mold on the window',
  'FORM.REPAIR.WATERDAMAGE.WINDOWSAFFECTED': 'How many windows are affected?',
  'FORM.REPAIR.WATERDAMAGE.YELLOWSTAINS': 'Yellow stains on wall or ceiling',
  'FORM.REPAIR.WATERDAMAGE.YESDROPPING': 'Yes',
  'FORM.REPAIR.WATERSTAINS': 'Water stains',
  'FORM.REPAIR.WATERWASTE': 'Waste water gutter (outside)',
  'FORM.REPAIR.WHOLE': 'Entire surface',
  'FORM.REPAIR.WINDOW': 'Window',
  'FORM.REPAIR.WINDOW.CASEMENT': 'Casement frame',
  'FORM.REPAIR.WINDOW.FRAME': 'Window frame',
  'FORM.REPAIR.WINDOW.FRAME.BROKEN': 'Broken open',
  'FORM.REPAIR.WINDOW.FRAME.MOLD': 'Moldy',
  'FORM.REPAIR.WINDOW.FRAME.ROTTEN': 'Rotten / rusty',
  'FORM.REPAIR.WINDOW.GLASS': 'Window pane',
  'FORM.REPAIR.WINDOW.GLASS.BLIND': 'Blind',
  'FORM.REPAIR.WINDOW.GLASS.BROKEN': 'Broken',
  'FORM.REPAIR.WINDOW.GLASS.CONDENSATION': 'Condensation on window pane',
  'FORM.REPAIR.WINDOW.GLASS.CONDENSATIONBETWEEN': 'Condensation between panes',
  'FORM.REPAIR.WINDOW.GLASS.CATFLAPDEFECT': 'Cat door defective',
  'FORM.REPAIR.WINDOW.GLASS.CATFLAPINSTALL': 'Install cat door',
  'FORM.REPAIR.WINDOW.GLASS.CATFLAPREMOVE': 'Remove cat door',
  'FORM.REPAIR.WINDOW.GLASS.CRACKED': 'Cracked',
  'FORM.REPAIR.WINDOW.GLASS.SMASHED': 'Smashed in',
  'FORM.REPAIR.WINDOW.GLASS.STONECHIP': 'Stone chip',
  'FORM.REPAIR.WINDOW.HANDLE': 'Window handle',
  'FORM.REPAIR.WINDOW.HANDLE.COVERMISSING': 'Handle cover defective, missing',
  'FORM.REPAIR.WINDOW.HANDLE.FALLEN': 'Fallen off',
  'FORM.REPAIR.WINDOW.HANDLE.HARDTURN': 'Turns hard',
  'FORM.REPAIR.WINDOW.HANDLE.IDLE': 'Turns empty',
  'FORM.REPAIR.WINDOW.HANDLE.JAMS': 'Jams',
  'FORM.REPAIR.WINDOW.HANDLE.KEYBROKEN': 'Key broken',
  'FORM.REPAIR.WINDOW.HANDLE.KEYMISSING': 'Key missing',
  'FORM.REPAIR.WINDOW.HANDLE.LOOSE': 'Loose, wobbly',
  'FORM.REPAIR.WINDOW.HANDLE.STAINS': 'Has stains, is tarnished',
  'FORM.REPAIR.WINDOW.HANDLE.VERTICALTURN': 'Does not rotate vertically',
  'FORM.REPAIR.WINDOW.JOINTMOLD': 'Glass joint is moldy',
  'FORM.REPAIR.WINDOW.LEAKING': 'Leaking',
  'FORM.REPAIR.WINDOW.LEAKING.CONDENSATION': 'Condensation',
  'FORM.REPAIR.WINDOW.LEAKING.DRAFTS': 'Leaky draughts',
  'FORM.REPAIR.WINDOW.LEAKING.HEAVYRAIN': 'Leaks in heavy rain',
  'FORM.REPAIR.WINDOW.LEAKING.SEALDEFECT': 'Rubber seal defective',
  'FORM.REPAIR.WINDOW.OPENINGCLOSING': 'Open & close',
  'FORM.REPAIR.WINDOW.OPENINGCLOSING.AUTOMATICDEFECT': 'Automatic operation defective',
  'FORM.REPAIR.WINDOW.OPENINGCLOSING.BROKEN': 'Broken open',
  'FORM.REPAIR.WINDOW.OPENINGCLOSING.GRINDS': 'Grazes, stops',
  'FORM.REPAIR.WINDOW.OPENINGCLOSING.HUNGOUT': 'Unhooked',
  'FORM.REPAIR.WINDOW.OPENINGCLOSING.JAMS': 'Jammed',
  'FORM.REPAIR.WINDOW.OPENINGCLOSING.LIMITER': 'Opening limiter',
  'FORM.REPAIR.WINDOW.OPENINGCLOSING.MECHANISMDEFECT': 'Opening mechanism defective',
  'FORM.REPAIR.WINDOW.OPENINGCLOSING.ONLYTILTED': 'Only tilting possible',
  'FORM.REPAIR.WINDOW.OPENINGCLOSING.REMAINSOPEN': 'Does not close',
  'FORM.REPAIR.WINDOW.OPENINGCLOSING.SECURITY': 'Window security',
  'FORM.REPAIR.WINDOW.SEAL': 'Seals',
  'FORM.REPAIR.WINDOW.SEAL.DEFECT': 'Defective rubber seal',
  'FORM.REPAIR.WINDOW.SEAL.JOINTLEAK': 'Glass joint leaking, coming loose',
  'FORM.REPAIR.WINDOW.SEAL.MISSING': 'Rubber seal missing',
  'FORM.REPAIR.WINDOW.SEAL.OVERLAPDEFECT': 'Overlap seal defective',
  'FORM.REPAIR.WINDOW.SEAL.OVERLAPMOLD': 'Overlap seal moldy',
  'FORM.REPAIR.WINDOW.SILL': 'Window sill, sill',
  'FORM.REPAIR.WINDOW.SILL.BROKEN': 'Broken, cracked',
  'FORM.REPAIR.WINDOW.SILL.EDGEDEFECT': 'Edges defective',
  'FORM.REPAIR.WINDOW.SILL.LOOSE': 'Loose, wobbly',
  'FORM.REPAIR.WINDOW.SILL.RELEASING': 'Dissolving surfaces',
  'FORM.REPAIR.WINDOW.SILL.WATERSTAINS': 'Water stains',

  'FORM.KEYORDER.DETAILS.TITLE': 'Reason for the order',
  'FORM.KEYORDER.TITLE': 'What is the reason for the order?',
  'FORM.KEYORDER.LOST': 'Key lost',
  'FORM.KEYORDER.KEY.TITLE': 'Key from',
  'FORM.KEYORDER.APARTMENTDOOR': 'Apartment door',
  'FORM.KEYORDER.BUILDINGENTRANCE': 'House entrance',
  'FORM.KEYORDER.BASEMENT': 'Basement',
  'FORM.KEYORDER.MAILBOX': 'Mailbox',
  'FORM.KEYORDER.OTHER': 'Other key',
  'FORM.KEYORDER.ALL': 'For all: apartment door, house entrance, cellar, mailbox',
  'FORM.KEYORDER.EXTRA': 'Extra key',
  'FORM.KEYORDER.REPLACE': 'Replace cylinder and key',
  'FORM.KEYORDER.WASHCARD': 'New wash card',
  'FORM.KEYORDER.SIPA': 'New SIPA socket key',
  'FORM.KEYORDER.SIPA.TITLE': 'What color is the key?',
  'FORM.KEYORDER.SIPA.BLUE': 'Blue',
  'FORM.KEYORDER.SIPA.GREEN': 'Green',
  'FORM.KEYORDER.SIPA.RED': 'Red',
  'FORM.KEYORDER.SIPA.YELLOW': 'Yellow',
  'FORM.KEYORDER.PARKINGTRADITIONAL': 'New parking card',
  'FORM.KEYORDER.PARKINGWIFI': 'New wireless parking card',

  'FORM.NAMEPLATE.TITLE': 'Where do you need a new name tag?',
  'FORM.NAMEPLATE.DETAILS.TITLE': 'Where is the nameplate needed',
  'FORM.NAMEPLATE.DOOROUTSIDE': 'Doorbell',
  'FORM.NAMEPLATE.DOORINSIDE': 'Apartment door',
  'FORM.NAMEPLATE.MAILBOX': 'Mailbox',
  'FORM.NAMEPLATE.ALL': 'For all: Doorbell, apartment door & mailbox',

  'FORM.DOCUMENT.TITLE': 'Which document do you need?',
  'FORM.DOCUMENT.DETAILS.TITLE': 'Document or form',
  'FORM.DOCUMENT.HANDOVER': 'Copy of the housing handover protocol',
  'FORM.DOCUMENT.RENTALCONTRACT': 'Copy of rental agreement',
  'FORM.DOCUMENT.UTILITYBILL': 'Copy of service charge statement',
  'FORM.DOCUMENT.APPLICATIONPET': 'Application for pet keeping',
  'FORM.DOCUMENT.REMODELLING': 'Application for tenant improvements',
  'FORM.DOCUMENT.SUBLEASING': 'Application for subletting',
  'FORM.DOCUMENT.INSURANCE': 'Proof of building insurance',
  'FORM.DOCUMENT.OTHER': 'Other document',

  'FORM.OTHER.TITLE': 'What is your request?',
  'FORM.OTHER.DETAILS.TITLE': 'Request',
  'FORM.OTHER.PROPERTY': 'General information about the property',
  'FORM.OTHER.NAMECHANGE': 'Name change, marriage',
  'FORM.OTHER.EMAILCHANGE': 'Change of e-mail address',
  'FORM.OTHER.BANKACCOUNTCHANGE': 'Change of bank details',
  'FORM.OTHER.SUBLEASING': 'Subletting',
  'FORM.OTHER.TERMOFNOTICE': 'Notice period',
  'FORM.OTHER.OTHER': 'Something else',
};
