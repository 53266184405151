import { notification, Progress } from 'antd';
import { intl } from 'components/Utility/IntlMessages';
import { DB, ID, ImmoFondsConstants } from 'definitions/constants-fe';
import { saveAs as savePdf } from 'file-saver';
import html2canvas from 'html2canvas';
import Firebase from 'library/firebase';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import actionsApp from 'redux/global/app/actions';
import actions from 'redux/immofonds/actions';

class Notification extends Component {
  constructor(props) {
    super(props);
    // Note: This is on purpose not in state because it changes very fast
    this.pdfProgressDisplayed = 0;
    this.state = {
      pdfError: null,
      pdfProgressReference: 0,
      interestToggleInitialValue: this.props.interestToggle,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.rx_notificationStatus !== prevProps.rx_notificationStatus &&
      this.props.rx_notificationStatus === ID.if_pdf_notification
    ) {
      this.handleSubmit();
    }
    if (
      this.props.rx_error !== prevProps.rx_error &&
      this.props.rx_error != null
    ) {
      this.setState({ pdfError: this.props.rx_error }, () => {
        this.notificationClose(5000);
      });
    }

    // rx_pdfData can be the progress and the final pdf response
    if (
      this.props.rx_pdfData !== prevProps.rx_pdfData &&
      this.props.rx_pdfData != null
    ) {
      if (isNaN(this.props.rx_pdfData)) {
        this.updatePdfProgressReference(100);
        savePdf(
          this.props.rx_pdfData.data,
          decodeURIComponent(this.props.rx_pdfData['headers']['file-title']),
        );
        this.notificationClose(2000);
      } else {
        this.updatePdfProgressReference(this.props.rx_pdfData);
      }
    }
  }

  handleSubmit = async () => {
    const { rx_notificationData } = this.props;
    const databaseListener = Firebase.database
      .collection(DB.if_immovables)
      .doc(rx_notificationData.immovable_ref)
      .onSnapshot((doc) => {
        const immovableData = doc.data();
        if (immovableData?.pdf_export) {
          this.updatePdfProgressReference(
            ImmoFondsConstants.formula.adding_value +
              immovableData.pdf_export *
                ImmoFondsConstants.formula.multiplication_value,
          );
        }
      });

    // set notification content
    this.setState({
      databaseListener: databaseListener,
      pdfProgressReference: 10,
    });

    // Start interval to make the progress smooth
    this.pdfProgressDisplayed = 0;
    this.pdfInterval = setInterval(() => {
      this.updateNotification();
    }, 100);

    // Get graph as a image and start cloud function
    // const intAndInfl = this.props.pdfSelectionObject.inclInterest;
    const graphEle = document.getElementById('graph');
    // const toggleButton = document.getElementById('graphToggle');
    // const toggleStateBefore = toggleButton.getAttribute('aria-checked');
    // this.setState({ interestToggleInitialValue: this.props.interestToggle });
    // await this.props.toggleInterest(false);
    html2canvas(graphEle).then((graph_canvas) => {
      const data = {
        pdf_selection_object: rx_notificationData.pdfSelectionObject,
        immovable_ref: rx_notificationData.immovable_ref,
        graph_base_64: graph_canvas.toDataURL(),
      };
      this.props.downloadPdf(data);
      // this.props.toggleInterest(this.state.interestToggleInitialValue);
    });
  };
  componentWillUnmount() {
    this.unsubscribeFromListener();
  }
  unsubscribeFromListener = () => {
    const { databaseListener } = this.state;
    if (databaseListener != null) {
      databaseListener();
    }
  };
  notificationClose = (waitTime) => {
    const { pdfError } = this.state;
    this.pdfProgressDisplayed = 100;
    this.props.setNotification(ID.none);
    this.unsubscribeFromListener();
    setTimeout(() => {
      clearInterval(this.pdfInterval);
      // the below condition ensures that if the size error occurs, we don't close the notification by itself.
      if (pdfError?.sizeError !== true) {
        notification.destroy(ID.progressbar_notification);
        this.setState({ pdfError: null });
      }
    }, waitTime);
  };
  updatePdfProgressReference = (value) => {
    this.setState({ pdfProgressReference: value });
    if (value === 100) {
      if (this.pdfInterval !== null) {
        clearInterval(this.pdfInterval);
      }
      this.pdfProgressDisplayed = 100;
      this.updateNotification();
    }
  };

  getTitleAndDescription = () => {
    const { pdfError } = this.state;
    let titleAndDescription = {};
    if (pdfError == null) {
      titleAndDescription = {
        title: 'immovable.progressNotificationTitle',
        description: 'immovable.progressNotificationDescription',
        showClose: false,
      };
    } else {
      if (pdfError.sizeError) {
        titleAndDescription = {
          title: 'immovable.pdfSizeErrorTitle',
          description: 'immovable.pdfSizeErrorDescription',
          showClose: true,
        };
      } else {
        titleAndDescription = {
          title: 'immovable.progressNotificationFailedTitle',
          description: 'immovable.progressNotificationFailedDescription',
          showClose: false,
        };
      }
    }
    return titleAndDescription;
  };

  updateNotification = () => {
    const { pdfProgressReference, pdfError } = this.state;
    const titleAndDescription = this.getTitleAndDescription();
    const diff = Math.max(0, pdfProgressReference - this.pdfProgressDisplayed);
    if (this.pdfProgressDisplayed < 100) {
      this.pdfProgressDisplayed = Math.min(
        99,
        this.pdfProgressDisplayed + diff * 0.05 + 0.1,
      );
    }
    notification.open({
      className: `${titleAndDescription.showClose ? '' : 'hide-close-button'}`,
      key: ID.progressbar_notification,
      message: intl.formatMessage({
        id: titleAndDescription.title,
      }),
      description: [
        intl.formatMessage({
          id: titleAndDescription.description,
        }),
        <Progress
          key={ID.progressbar_notification}
          style={{ margin: '18px 0 6px 0', display: 'block' }}
          type="circle"
          status={pdfError ? 'exception' : null}
          percent={parseFloat(this.pdfProgressDisplayed.toFixed(0))}
        />,
      ],
      duration: 0,
      onClose: () => this.setState({ pdfError: null }),
    });
  };

  render() {
    return null;
  }
}

export default connect(
  (state) => ({
    ...state.ImmoFonds,
    rx_notificationStatus: state.GL_App.rx_notificationStatus,
    rx_notificationData: state.GL_App.rx_notificationData,
  }),
  { ...actions, ...actionsApp },
)(Notification);
