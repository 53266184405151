import { FileUploadProps, ID } from 'definitions/constants-fe';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import 'firebase/compat/remote-config';
import 'firebase/compat/storage';
import { getFileName, imgToBlob, isImageExtention } from 'library/fileHelper';
import { Reporting } from 'library/sentry/reporting';
import ReduxSagaFirebase from 'redux-saga-firebase';

const config = {
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FB_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FB_APP_ID,
};

class Firebase {
  EMAIL = 'email';
  FACEBOOK = 'facebook';
  GOOGLE = 'google';
  GITHUB = 'github';
  TWITTER = 'twitter';

  constructor() {
    const firebaseApp = firebase.initializeApp(config);
    const secondApp = firebase.initializeApp(config, 'secondApp');

    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.database = firebase.firestore();
    this.remoteConfig = firebaseApp.remoteConfig();
    this.storageRef = firebaseApp.storage().ref();
    this.firstAppAuth = firebaseApp.auth();
    this.secondAppAuth = secondApp.auth();
    this.rsf = new ReduxSagaFirebase(firebaseApp, firebase.firestore());
    this.rsfFirestore = this.rsf.firestore;
    this.refFireFunction = firebaseApp.functions(
      process.env.REACT_APP_CLOUD_FUNCTION_LOCATION,
    );
    this.EmailAuthProvider = firebase.auth.EmailAuthProvider;
    this.FieldValue = firebase.firestore.FieldValue;
    this.FieldPath = firebase.firestore.FieldPath;
    this.Timestamp = firebase.firestore.Timestamp;
  }

  createBatch = () => {
    return this.database.batch();
  };

  async login(provider, info, remember_me = false) {
    const persistence = remember_me
      ? firebase.auth.Auth.Persistence.LOCAL
      : firebase.auth.Auth.Persistence.SESSION;
    await this.firstAppAuth.setPersistence(persistence);
    switch (provider) {
      case this.EMAIL:
        return this.firstAppAuth.signInWithEmailAndPassword(
          info.email,
          info.password,
        );
      case this.FACEBOOK:
        return this.firstAppAuth.FacebookAuthProvider();
      case this.GOOGLE:
        return this.firstAppAuth.GoogleAuthProvider();
      case this.GITHUB:
        return this.firstAppAuth.GithubAuthProvider();
      case this.TWITTER:
        return this.firstAppAuth.TwitterAuthProvider();
      default:
    }
  }

  sendVerificationEmail() {
    return this.firstAppAuth.currentUser.sendEmailVerification();
  }

  logout() {
    return this.firstAppAuth.signOut();
  }

  getAuthToken = async () => {
    try {
      const token = await this.firstAppAuth.currentUser.getIdToken();
      return token;
    } catch (err) {
      Reporting.Error(err);
      return null;
    }
  };

  resetPassword(email) {
    return this.firstAppAuth.sendPasswordResetEmail(email);
  }

  snapshotAsObject(snapshot) {
    let data = {};
    snapshot.forEach((doc) => {
      data = {
        ...data,
        [doc.id]: doc.data(),
      };
    });
    return data;
  }

  snapshotAsArray(snapshot, withKeyField = false) {
    if (withKeyField) {
      return snapshot.docs.map((doc) => {
        return { key: doc.id, ...doc.data() };
      });
    }
    return snapshot.docs.map((x) => x.data());
  }

  getFiles = (files, getThumbUrl = true) => {
    const fileRecords = files.map(async (file, index) => {
      const pathExt = file.name.split('.').pop();
      let mainFile = '';
      try {
        mainFile = await this.storageRef.child(file.path).getDownloadURL();
      } catch (exception) {
        return false;
      }
      const fileObject = {
        mainUrl: mainFile,
        name: file.name,
        fullName: file.path,
        uid: 'rc-upload-' + new Date().getTime() + index,
      };
      if (pathExt !== 'pdf' && getThumbUrl) {
        try {
          fileObject.thumbUrl = await this.storageRef
            .child(file.thumb)
            .getDownloadURL();
        } catch (exception) {
          fileObject.thumbUrl = mainFile;
        }
      } else {
        fileObject.thumbUrl = mainFile;
      }
      if (file.id) {
        fileObject.id = file.id;
      }
      if (file.time_created) {
        fileObject.time_created = file.time_created;
      }
      if (file.time_updated) {
        fileObject.time_updated = file.time_updated;
      }
      return fileObject;
    });

    return Promise.all(fileRecords).then((lists) => {
      const filesArray = lists.filter((list) => {
        return list !== false;
      });
      return filesArray;
    });
  };

  uploadFilePromise = async (base, fileName, file) => {
    return new Promise((resolve, reject) => {
      const uploadTask = this.storageRef.child(`${base}/${fileName}`).put(file);
      uploadTask.on(
        ID.state_changed,
        () => {
          // any required action
        },
        function error(err) {
          Reporting.Error(err);
          reject();
        },
        function complete() {
          resolve(true);
        },
      );
    });
  };

  fileAction = async (
    storagePath,
    filesArray,
    otherProps = FileUploadProps,
  ) => {
    const records = filesArray.map(async (file, index) => {
      let fileName = getFileName(file.name, index);
      const extention = fileName.split('.').pop().toLowerCase();
      const string =
        file.fullName &&
        file.fullName.indexOf(
          `${
            otherProps.imageFromDifferentFolder
              ? otherProps.imageFromDifferentFolder
              : storagePath
          }/`,
        ) !== -1;
      let fileObj = {};
      if (string && !file.isEdited) {
        if (otherProps.isCopy || otherProps.imageFromDifferentFolder) {
          const blob = await imgToBlob(file.mainUrl);
          await this.uploadFilePromise(storagePath, fileName, blob);
          fileObj = { name: file.name, path: `${storagePath}/${fileName}` };
        } else {
          fileObj = { name: file.name, path: file.fullName };
        }
      } else {
        if (otherProps.useFileName) {
          fileName = otherProps.useFileName;
        }
        await this.uploadFilePromise(storagePath, fileName, file.originFileObj);
        fileObj = {
          name: file.name,
          path: `${storagePath}/${fileName}`,
        };
      }
      if (file.id) {
        fileObj.id = file.id;
      }
      if (otherProps.addUid && file.id == null) {
        fileObj.id = file.uid;
      }
      if (isImageExtention(extention)) {
        const thumb =
          otherProps.isCopy || otherProps.imageFromDifferentFolder
            ? fileName
            : fileObj.path.split('/').pop();
        fileObj.thumb = `${storagePath}_thumb/thumb_${thumb}`;
      }
      if (file.time_created) {
        fileObj.time_created = file.time_created;
      }
      if (file.time_updated) {
        fileObj.time_updated = file.time_updated;
      }
      return fileObj;
    });
    return Promise.all(records);
  };

  loadSingleFile = async (path) => {
    const mainFile = {};
    mainFile.url = await this.storageRef.child(path).getDownloadURL();
    mainFile.uid = 'rc-upload-' + new Date().getTime();
    return mainFile;
  };

  refreshRemoteConfig = async () => {
    this.remoteConfig.settings = {
      // Instantly
      minimumFetchIntervalMillis: 0,
    };
    await this.remoteConfig.fetchAndActivate();
  };

  //
  getRemoteConfigString = (key) => {
    return this.remoteConfig.getString(key);
  };
}

export default new Firebase();
