import { IntlMessages } from 'components/Utility/IntlMessages';
import React from 'react';
import packageJson from '../../package.json';

export default {
  siteName: process.env.REACT_APP_PLATFORM_TITLE,
  siteIcon: 'ion-flash',
  footerText: (
    <span>
      <a href="https://immodigi.ch" target="_blank" rel="noopener noreferrer">
        ImmoDigi AG
      </a>
      {' - '}
      <IntlMessages id="footer.allRightsReserved" />
      <p>
        (<IntlMessages id="global.version" /> {packageJson.version})
      </p>
    </span>
  ),
};
