export const TRANSLATIONS_IT = {
  'GLOBAL.ENGLISH': 'Inglese',
  'GLOBAL.GERMAN': 'Tedesco',
  'GLOBAL.FRENCH': 'Francese',
  'GLOBAL.ITALIAN': 'Italiano',
  'GLOBAL.STEP_X_OF_Y': 'Passaggio {{current}} di {{total}}',
  'GLOBAL.PUSH_NOTIFICATION': 'Messaggio push',
  'GLOBAL.MESSAGE': 'Messaggio',
  'GLOBAL.PHONE': 'Numero di telefono',
  'GLOBAL.TITLE': 'Titolo',
  'GLOBAL.EMAIL': 'E-mail',
  'GLOBAL.PASSWORD': 'Password',
  'GLOBAL.SAVE': 'Salvare',
  'GLOBAL.DESCRIPTION': 'Descrizione',
  'GLOBAL.PERIOD': 'Periodo',
  'GLOBAL.ERROR': 'Errore',
  'GLOBAL.ERROR.EMAIL_EXISTS': "L'indirizzo e-mail specificato è già in uso.",
  'GLOBAL.TRANSLATION_MISSING': 'Manca la traduzione',
  'GLOBAL.SUCCESS': 'Successo',
  'GLOBAL.LOADING': 'Caricamento...',
  'GLOBAL.LOADING_LOGIN': 'Accesso...',
  'GLOBAL.MAX_100_CHARS': 'Massimo 100 caratteri consentiti.',
  'GLOBAL.YES': 'Sì',
  'GLOBAL.NO': 'No',
  'GLOBAL.ACCEPT': 'Accettare',
  'GLOBAL.LATER': 'Più tardi',
  'GLOBAL.ABSTAIN': 'Astenersi',
  'GLOBAL.CANCEL': 'Annulla',
  'GLOBAL.OK': 'OK',
  'GLOBAL.UPDATE': 'Aggiorna',
  'GLOBAL.UPDATING': 'Aggiornamento...',
  'GLOBAL.PROPERTY': 'Proprietà',
  'GLOBAL.MANDATE': 'Immobile',
  'GLOBAL.MANDATES': 'Immobili',
  'GLOBAL.EXTERNAL': 'Esterno',
  'GLOBAL.NAME': 'Nome',
  'GLOBAL.FIRSTNAME': 'Nome',
  'GLOBAL.LASTNAME': 'Cognome',
  'GLOBAL.NO_ENTRIES': 'Nessuna voce disponibile.',
  'GLOBAL.NO_MORE_ENTRIES': 'Non esistono altre voci.',
  'GLOBAL.PRIVATE': 'Privato',
  'GLOBAL.PRIVATE_E': 'Privato',
  'GLOBAL.ATTACHMENTS': 'Allegati',
  'GLOBAL.UNDO': 'Disfare',
  'GLOBAL.FROM': 'Da',
  'GLOBAL.NEXT': 'Avanti',
  'GLOBAL.EMAIL_PLACEHOLDER': "Inserire l'e-mail...",
  'GLOBAL.EMAIL_REQUIRED': "L'e-mail è obbligatoria.",
  'GLOBAL.EMAIL_INVALID': 'Inserire un indirizzo e-mail valido',
  'GLOBAL.FIELD_REQUIRED': 'Il campo è obbligatiorio.',
  'GLOBAL.FIELD_MAX_LENGTH': 'Il testo può contenere al massimo {{length}} caratteri.',
  'GLOBAL.FIELD_MIN_NUMBER': 'Minimo: {{number}}',
  'GLOBAL.FIELD_MAX_NUMBER': 'Massimo: {{number}}',
  'GLOBAL.ONLY_NUMBER': 'Per favore inserisci un numero.',
  'GLOBAL.ONLY_NUMBERS': 'Per favore inserisci solo numeri.',
  'GLOBAL.RESET': 'Ripristina',
  'GLOBAL.STREET': 'Via',
  'GLOBAL.ZIP': 'ZIP',
  'GLOBAL.CITY': 'Città',
  'GLOBAL.OPTIONAL': '(opzionale)',
  'GLOBAL.CHOOSE_NOW': 'Seleziona ora',
  'GLOBAL.CONTACTDATA': 'Dettagli del contatto',
  'GLOBAL.VISIBILITY': 'Visibilità',
  'GLOBAL.COMMENT': 'Commento',
  'GLOBAL.PUBLIC': 'Pubblico',
  'GLOBAL.SENDER': 'Mittente',
  'GLOBAL.LOCATION': 'Posizione',
  'GLOBAL.ADMINISTRATION': 'Amministrazione',
  'GLOBAL.LINK': 'Collegamento',
  'GLOBAL.INTRODUCTION': 'Introduzione',
  'GLOBAL.IN': 'In',
  'GLOBAL.QUESTIONS': 'Domande?',
  'GLOBAL.CODE': 'Codice',
  'GLOBAL.TO': 'A',
  'GLOBAL.FOR': 'Per',
  'GLOBAL.PERSONALLY_FOR_YOU': 'Personalmente per te',
  'GLOBAL.FROM_THE_IMMOAPP': "dall'ImmoApp",
  'GLOBAL.START': 'Inizio',
  'GLOBAL.HERE': 'Qui',
  'GLOBAL.PARTIES': 'Partiti',
  'GLOBAL.THE': "l'",
  'GLOBAL.RESTRICTED.COMMENT': 'Al momento non hai il permesso di inserire commenti. Se hai domande, contatta la tua amministrazione.',
  'GLOBAL.RESTRICTED.REPORT': "Al momento non hai l'autorizzazione per registrare i rapporti. Se hai domande, contatta la tua amministrazione.",

  'ERROR.UNEXPECTED_RETRY_WITH_EXPLANATION': 'Si è verificato un errore inatteso: {{error}}. Riprovare.',
  'ERROR.UNEXPECTED_RETRY_SIMPLE': 'Si è verificato un errore inatteso. Riprovare.',

  'DATE.SHORT': 'd. LLL',
  'DATE.LONG': "dd.MM.YYYY 'alle' HH:mm",
  'DATE.FULL': "dd. MMMM YYYY 'alle' HH:mm",
  'DATE.HOUR': 'HH',

  'ACCOUNT_ROLE.TENANT_RESTRICTED': 'Inquilino (limitato)',
  'ACCOUNT_ROLE.TENANT': 'Inquilino',
  'ACCOUNT_ROLE.OWNER': 'Proprietario',
  'ACCOUNT_ROLE.JANITOR': 'Portiere',
  'ACCOUNT_ROLE.MANAGER': 'Gestore',
  'ACCOUNT_ROLE.ADMIN': 'Amministratore',

  'APP.WEB_PUSH_NA.TEXT': "Purtroppo il tuo browser non supporta i messaggi push. Per essere sempre ben informato su tutte le novità, ti consigliamo di scaricare l'App per iOS o Android.",
  'APP.WEB_PUSH_NA.BUTTON.HIDE': 'Nascondi',
  'APP.UPDATE.AVAILABLE': "Aggiornamento dell'app disponibile",
  'APP.UPDATE.UPDATE_NOW_QUESTION': "Volete aggiornare subito all'ultima versione?",
  'APP.UPDATE.LOADING': "La nuova versione dell'app è in fase di caricamento...",
  'APP.UPDATE.INSTALLING': "È in corso l'installazione della nuova versione dell'app...",
  'APP.NO_INTERNET_CONNECTION.TEXT': 'Nessuna connessione a Internet.',
  'APP.BACKBUTTON_EXIT_WARNING': 'Premi di nuovo il pulsante Indietro per uscire da ImmoApp.',
  'APP.UPDATE.NEEDED.HEADER': 'Aggiornamento Necessario',
  'APP.UPDATE.NEEDED.MESSAGE': 'È disponibile un importante aggiornamento sulla sicurezza. Per continuare a utilizzare ImmoApp, aggiorna alla versione più recente.',
  'APP.UPDATE.NEEDED.BUTTON': 'Aggiorna ora',

  'TABS.HOME': 'Casa',
  'TABS.DOCUMENTS': 'Documenti',
  'TABS.VOTES': 'Voto',
  'TABS.PROFILE': 'Profilo',

  'REPAIR.STATUS_0': 'In attesa',
  'REPAIR.STATUS_1': 'In corso',
  'REPAIR.STATUS_2': 'Completato',

  'AUTHENTICATION.LOGIN': 'Accesso',
  'AUTHENTICATION.FORGOT_PASSWORD': 'Hai dimenticato la password?',
  'AUTHENTICATION.REGISTER': 'Registrati',
  'AUTHENTICATION.NO_ACCOUNT': 'Non hai ancora un account?',
  'AUTHENTICATION.INSTRUCTIONS': "Inserire i dati dell'account",
  'AUTHENTICATION.PASSWORD_PLACEHOLDER': 'Inserire la password...',
  'AUTHENTICATION.PASSWORD_REQUIRED': 'È richiesta la password.',
  'AUTHENTICATION.PASSWORD_LENGTH': 'La password deve essere lunga almeno 6 caratteri.',
  'AUTHENTICATION.PASSWORDS_NOT_THE_SAME': 'Le due password non sono uguali',
  'AUTHENTICATION.WRONG_PASSWORD': 'La password inserita non è corretta. Si prega di riprovare.',
  'AUTHENTICATION.TOO_MANY_REQUEST': "Troppi tentativi di accesso non riusciti. L'accesso al tuo account è stato bloccato per un breve periodo. Per favore riprova più tardi.",
  'AUTHENTICATION.EMAIL_NOT_VERIFIED': 'Il vostro indirizzo e-mail non è ancora stato verificato. Sono state inviate istruzioni al tuo indirizzo e-mail.',
  'AUTHENTICATION.USER_NOT_FOUND': 'Non è stato possibile trovare un utente con questo indirizzo e-mail. Si prega di riprovare.',
  'AUTHENTICATION.NO_RIGHTS': "L'utente non dispone dei diritti di accesso. Se si sospetta che si tratti di un errore, contattare l'amministrazione.",
  'AUTHENTICATION.SELECT_SPACE_FIRST': "Selezionare prima un'amministrazione.",
  'AUTHENTICATION.SIGN_IN_FAILED': 'Accesso fallito.',
  'AUTHENTICATION.RESET_PASSWORD_FAILED': 'Reimpostazione della password non riuscita.',
  'AUTHENTICATION.NO_SPACES_AVAILABLE': "L'indirizzo e-mail inserito non è assegnato a un'amministrazione.",
  'AUTHENTICATION.OTHER_ERROR': 'Accesso non riuscito. Riprovare.',
  'AUTHENTICATION.SELECT_ADMINISTRATION': "Selezionare l'amministrazione",
  'AUTHENTICATION.UPDATE_PASSWORD_ALERT.HEADER': 'ImmoApp versione 2',
  'AUTHENTICATION.UPDATE_PASSWORD_ALERT.MESSAGE': "L'ImmoApp è stata aggiornata. Per motivi di sicurezza, vi chiediamo di impostare una nuova password",
  'AUTHENTICATION.UPDATE_PASSWORD_ALERT.NEW_PASSWORD': 'Nuova password...',
  'AUTHENTICATION.UPDATE_PASSWORD_ALERT.NEW_PASSWORD_REPEAT': 'Ripeti la nuova password...',

  'AUTHENTICATION.SIGNUP.STEP_1_TITLE': 'Informazioni',
  'AUTHENTICATION.SIGNUP.STEP_2_TITLE': 'Email e codice di accesso',
  'AUTHENTICATION.SIGNUP.STEP_3_TITLE': 'Nome e password',
  'AUTHENTICATION.SIGNUP.STEP_1_DESCRIPTION': 'Se avete già ricevuto i dati di accesso personali dalla vostra amministrazione, potete accedere direttamente alla schermata iniziale. In questo caso non è necessaria la registrazione.',
  'AUTHENTICATION.SIGNUP.STEP_2_DESCRIPTION': 'Inserite la vostra e-mail e il codice di accesso a 6 cifre che avete ricevuto dalla vostra amministrazione',
  'AUTHENTICATION.SIGNUP.STEP_3_DESCRIPTION': 'Inserire il proprio nome e una password di propria scelta.',
  'AUTHENTICATION.SIGNUP.STEP_4_DESCRIPTION_A': 'La registrazione è avvenuta con successo. Grazie per la registrazione.',
  'AUTHENTICATION.SIGNUP.STEP_4_DESCRIPTION_B': "Confermi il suo indirizzo e-mail. A tal fine le è stata inviata un'e-mail di verifica.",
  'AUTHENTICATION.SIGNUP.STEP_4_DESCRIPTION_C': "Nota: l'amministrazione verifica innanzitutto l'account prima di concedere l'accesso all'ImmoApp. L'approvazione dell'account viene notificata via e-mail.",
  'AUTHENTICATION.SIGNUP.FINISH': 'Fatto',
  'AUTHENTICATION.SIGNUP.TERMS': 'Accetto le norme sulla protezione dei dati.',
  'AUTHENTICATION.SIGNUP.ERROR.INVITE_CODE_REQUIRED': 'È richiesto un codice di accesso.',
  'AUTHENTICATION.SIGNUP.ERROR.FIRST_NAME_REQUIRED': 'È richiesto il nome.',
  'AUTHENTICATION.SIGNUP.ERROR.LAST_NAME_REQUIRED': 'È richiesto il cognome.',
  'AUTHENTICATION.SIGNUP.ERROR.INVITE_CODE_INVALID': "Il codice di accesso inserito non è valido. Riprovare o contattare l'amministrazione.",
  'AUTHENTICATION.SIGNUP.ERROR.DOMAIN_INVALID': "Non è stato possibile verificare il dominio del vostro indirizzo e-mail ({{domain}}). Verificare la presenza di errori di battitura e riprovare. Se l'errore persiste, contattare l'amministrazione.",
  'AUTHENTICATION.SIGNUP.INVITE_CODE_PLACEHOLDER': 'Codice di accesso',
  'AUTHENTICATION.INVITE_CODE_LENGTH': 'Il codice di accesso deve essere lungo 6 caratteri.',

  'AUTHENTICATION.RECOVER_PASSWORD.TITLE': 'Reimpostare la password',
  'AUTHENTICATION.RECOVER_PASSWORD.DESCRIPTION': "Inserisci il tuo indirizzo e-mail e ti invieremo un'e-mail per reimpostare la password.",
  'AUTHENTICATION.RECOVER_PASSWORD.BUTTON': 'Reimposta password',
  'AUTHENTICATION.RECOVER_PASSWORD.SUCCESS_MESSAGE': "Un'e-mail con le istruzioni è stata inviata con successo.",

  'PROFILE.TITLE': 'Profilo',
  'PROFILE.TENANT.TITLE': 'Inquilino',
  'PROFILE.TENANT.TITLE_LONG': 'Amministrazione inquilino',
  'PROFILE.TENANT.ADMINISTER_TITLE': 'Amministrazione inquilino',
  'PROFILE.TENANT.ADMINISTER_DESCRIPTION': 'inquilini',
  'PROFILE.TENANT.INVITED_ON': 'Invitato il',
  'PROFILE.TENANT.NO_TENANTS_AVAILABLE': 'Al momento non gestisce alcun inquilino.',
  'PROFILE.TENANT.ADD.TITLE': 'Aggiungi inquilino',
  'PROFILE.TENANT.ADD.MANDATE.PLACEHOLDER': 'Seleziona una proprietà',
  'PROFILE.TENANT.ADD.FIRSTNAME': 'Nome',
  'PROFILE.TENANT.ADD.FIRSTNAME.PLACEHOLDER': 'Nome',
  'PROFILE.TENANT.ADD.LASTNAME': 'Cognome',
  'PROFILE.TENANT.ADD.LASTNAME.PLACEHOLDER': 'Cognome',
  'PROFILE.TENANT.ADD.EMAIL_REQUIRED': "L'e-mail è richiesta.",
  'PROFILE.TENANT.ADD.EMAIL_INVALID': 'Inserire un indirizzo e-mail valido.',
  'PROFILE.TENANT.ADD.MANDATE_REQUIRED': 'È necessario un immobile.',
  'PROFILE.TENANT.ADD.FIRSTNAME_REQUIRED': 'Il nome è obbligatorio.',
  'PROFILE.TENANT.ADD.LASTNAME_REQUIRED': 'Il cognome è obbligatorio.',
  'PROFILE.TENANT.ADD.LOADING_MESSAGE': "L'inquilino è stato creato...",
  'PROFILE.TENANT.ADD.SUCCESS': "L'inquilino è stato creato con successo.",
  'PROFILE.TENANT.ADD.ERROR.EXISTING_EMAIL': "L'indirizzo e-mail inserito è già assegnato a un altro account.",

  'PROFILE.TENANT.EDIT.TITLE': 'Modifica inquilino',
  'PROFILE.TENANT.EDIT.MESSAGE': "Qui è possibile inviare nuovamente i dati di accesso all'inquilino e terminare il contratto di locazione (e quindi l'accesso all'ImmoApp).",
  'PROFILE.TENANT.EDIT.TERMINATE': 'Termina il contratto di locazione',
  'PROFILE.TENANT.EDIT.TERMINATE.LOADING_MESSAGE': 'Il contratto di locazione è terminato...',
  'PROFILE.TENANT.EDIT.TERMINATE.SUCCESS': "L'inquilino è stato rimosso con successo e ora non ha più accesso all'ImmoApp.",
  'PROFILE.TENANT.EDIT.RESEND_ACCESS': "Reinvio dell'accesso",
  'PROFILE.TENANT.EDIT.RESEND_ACCESS.LOADING_MESSAGE': "L'accesso viene inviato...",
  'PROFILE.TENANT.EDIT.RESEND_ACCESS.SUCCESS': "I dati di accesso sono stati inviati con successo all'indirizzo e-mail dell'inquilino.",
  'PROFILE.INFO.TITLE': 'Informazioni',
  'PROFILE.INFO.YOUR_DATA_TITLE': 'I vostri dati',
  'PROFILE.INFO.YOUR_DATA_DESCRIPTION': 'Accesso come',
  'PROFILE.INFO.TERMS_OF_USE_TITLE': 'Termini di utilizzo',
  'PROFILE.INFO.TERMS_OF_USE_DESCRIPTION': 'Regole per ImmoApp',
  'PROFILE.INFO.INSTRUCTION_TITLE': 'Istruzioni',
  'PROFILE.INFO.INSTRUCTION_GENERATING': 'Guida in fase di generazione...',
  'PROFILE.INFO.INSTRUCTION_DESCRIPTION': 'Introduzione a ImmoApp',
  'PROFILE.INFO.CONTACT_TITLE': 'Contatto',
  'PROFILE.INFO.CONTACT_DESCRIPTION': "Contattare direttamente l'amministrazione",
  'PROFILE.INFO.LEGAL_TITLE': 'Legale',
  'PROFILE.INFO.LEGAL_DESCRIPTION': 'Informativa sulla privacy',
  'PROFILE.SETTINGS.TITLE': 'Impostazioni',
  'PROFILE.SETTINGS.ACCOUNT_TITLE': 'Impostazioni account',
  'PROFILE.SETTINGS.ACCOUNT_DESCRIPTION': 'E-mail, password e cancellazione',
  'PROFILE.SETTINGS.APP_TITLE': 'Impostazioni app',
  'PROFILE.SETTINGS.APP_DESCRIPTION': 'Lingua e notifiche',
  'PROFILE.SETTINGS.DELETE_ACCOUNT': 'Elimina account',
  'PROFILE.SETTINGS.DELETE_ACCOUNT.CONFIRM': 'Se confermate con OK, la cancellazione del vostro account sarà ordinata.',
  'PROFILE.SETTINGS.DELETE_ACCOUNT.SUCCESS': "La richiesta è stata inviata con successo. Nelle prossime settimane vi informeremo via e-mail dell'avvenuta cancellazione del vostro account.",
  'PROFILE.SETTINGS.LOGOUT': 'Esci',
  'PROFILE.SETTINGS.LOGOUT.CONFIRM': 'Desidera effettuare il logout?',
  'PROFILE.SETTINGS.LOGOUT.IN_PROGRESS': "L'utente verrà disconnesso. Si prega di pazientare un attimo.",
  'PROFILE.UPDATE_PHOTO.TITLE': 'Modifica foto',
  'PROFILE.UPDATE_PHOTO_SUCCESS': 'La foto è stata aggiornata con successo.',

  'PROFILE.APP_SETTINGS.SELECT_LANGUAGE': 'Seleziona lingua',
  'PROFILE.APP_SETTINGS.PUSH_NOTIFICATIONS': 'Notifiche push',
  'PROFILE.APP_SETTINGS.EMAIL_NOTIFICATIONS': 'Notifiche via e-mail',
  'PROFILE.APP_SETTINGS.LANGUAGE': 'Lingua',

  'PROFILE.APP_SETTINGS.PUSH_PREFERENCES.DOCUMENT.TITLE': 'Documenti',
  'PROFILE.APP_SETTINGS.PUSH_PREFERENCES.DOCUMENT.DESCRIPTION': 'Documenti aggiunti di recente',
  'PROFILE.APP_SETTINGS.PUSH_PREFERENCES.MANDATE.TITLE': 'Notizie',
  'PROFILE.APP_SETTINGS.PUSH_PREFERENCES.MANDATE.DESCRIPTION': 'Notizie e rapporti sui danni',
  'PROFILE.APP_SETTINGS.PUSH_PREFERENCES.PINBOARD.TITLE': 'Bacheca',
  'PROFILE.APP_SETTINGS.PUSH_PREFERENCES.PINBOARD.DESCRIPTION': 'Notizie dai tuoi vicini',
  'PROFILE.APP_SETTINGS.PUSH_PREFERENCES.VOTE.TITLE': 'Voto',
  'PROFILE.APP_SETTINGS.PUSH_PREFERENCES.VOTE.DESCRIPTION': 'Aggiornamenti e promemoria',
  'PROFILE.APP_SETTINGS.PUSH_PREFERENCES.STAFF.TITLE': 'Registrazione (solo per il personale)',
  'PROFILE.APP_SETTINGS.PUSH_PREFERENCES.STAFF.DESCRIPTION': 'Nota per la nuova registrazione',

  'PROFILE.APP_SETTINGS.EMAIL_PREFERENCES.MESSAGE.TITLE': 'Messaggi',
  'PROFILE.APP_SETTINGS.EMAIL_PREFERENCES.MESSAGE.DESCRIPTION': 'Ricezione e-mail su nuovo messaggio',
  'PROFILE.APP_SETTINGS.EMAIL_PREFERENCES.REPAIR.TITLE': 'Nuovi biglietti',
  'PROFILE.APP_SETTINGS.EMAIL_PREFERENCES.REPAIR.DESCRIPTION': "Ricevi un'e-mail per un nuovo biglietto",
  'PROFILE.APP_SETTINGS.EMAIL_PREFERENCES.REPAIR_UPDATE.TITLE': 'Aggiornamenti sui biglietti',
  'PROFILE.APP_SETTINGS.EMAIL_PREFERENCES.REPAIR_UPDATE.DESCRIPTION': "Ricevi un'e-mail per il nuovo stato del ticket",
  'PROFILE.APP_SETTINGS.EMAIL_PREFERENCES.PINBOARD.TITLE': 'Voci in bacheca',
  'PROFILE.APP_SETTINGS.EMAIL_PREFERENCES.PINBOARD.DESCRIPTION': 'Ricezione e-mail su una nuova voce della bacheca',
  'PROFILE.APP_SETTINGS.EMAIL_PREFERENCES.COMMENT.TITLE': 'Commenti',
  'PROFILE.APP_SETTINGS.EMAIL_PREFERENCES.COMMENT.DESCRIPTION': 'Ricezione e-mail su nuovo commento',
  'PROFILE.APP_SETTINGS.EMAIL_PREFERENCES.VOTEITEM.TITLE': "Punto all'ordine del giorno da votare (solo per il personale)",
  'PROFILE.APP_SETTINGS.EMAIL_PREFERENCES.VOTEITEM.DESCRIPTION': "Ricezione e-mail su un nuovo punto all'ordine del giorno",

  'PROFILE.ACCOUNT_SETTINGS.UPDATE_PASSWORD': 'La password è stata aggiornata con successo.',
  'PROFILE.ACCOUNT_SETTINGS.UPDATE_EMAIL': "L'e-mail è stata aggiornata con successo. Per motivi di sicurezza è necessario effettuare nuovamente l'accesso.",
  'PROFILE.ACCOUNT_SETTINGS.UPDATE_PASSWORD_TITLE': 'Modifica della password',
  'PROFILE.ACCOUNT_SETTINGS.UPDATE_EMAIL_TITLE': 'Modifica e-mail',
  'PROFILE.ACCOUNT_SETTINGS.UPDATE_EMAIL_ERP_NOTICE1': 'È necessario apportare una modifica al vostro indirizzo e-mail nel sistema della vostra amministrazione.',
  'PROFILE.ACCOUNT_SETTINGS.UPDATE_EMAIL_ERP_NOTICE2': 'Cliccate qui per contattare la vostra amministrazione via e-mail.',
  'PROFILE.ACCOUNT_SETTINGS.UPDATE_PASSWORD_PLACEHOLDER': 'Inserire una nuova password...',
  'PROFILE.ACCOUNT_SETTINGS.UPDATE_EMAIL_PLACEHOLDER': 'Inserire una nuova e-mail...',
  'PROFILE.ACCOUNT_SETTINGS.UPDATE_EMAIL_INPUT': 'Nuova e-mail',
  'PROFILE.ACCOUNT_SETTINGS.UPDATE_EMAIL_REPEATED': 'Inserire un nuovo indirizzo e-mail',
  'PROFILE.ACCOUNT_SETTINGS.UPDATE_PASSWORD_REPEATED': 'Inserire una nuova password',
  'PROFILE.ACCOUNT_SETTINGS.UPDATE_PASSWORD_INPUT': 'Nuova password',
  'PROFILE.ACCOUNT_SETTINGS.CURRENT_PASSWORD_PLACEHOLDER': 'Inserire la password attuale...',
  'PROFILE.ACCOUNT_SETTINGS.CURRENT_PASSWORD_TITLE': 'Password attuale',
  'PROFILE.ACCOUNT_SETTINGS.CURRENT_PASSWORD_NOTE': 'Per evitare abusi, vi chiediamo di reinserire la vostra password prima di effettuare qualsiasi modifica.',

  'PROFILE.CONTACT.TITLE': 'Contatto',
  'PROFILE.CONTACT.DEVELOPMENT': 'Sviluppato da',

  'PROFILE.PERSON_DATA.STATUS': 'Stato',
  'PROFILE.PERSON_DATA.POST_BOX': 'Casella postale',
  'PROFILE.PERSON_DATA.PRIVATE_PHONE': 'Tel. (privato)',
  'PROFILE.PERSON_DATA.MOBILE_PHONE': 'Tel. (cellulare)',
  'PROFILE.PERSON_DATA.WORK_PHONE': 'Tel. (lavoro)',

  'PROFILE.TERMS_OF_USE.TITLE': 'Condizioni di utilizzo di ImmoApp',
  'PROFILE.TERMS_OF_USE.SUBTITLE': "Lavoriamo insieme per un'esperienza dell'app rispettosa e positiva",
  'PROFILE.TERMS_OF_USE.INTRO_TITLE': 'Cosa ci aspettiamo quando si utilizza ImmoApp',
  'PROFILE.TERMS_OF_USE.INTRO_TEXT1': "Per garantire che tutti i membri della nostra comunità si sentano benvenuti e a proprio agio, abbiamo stabilito alcune regole di base per ImmoApp. Queste regole riflettono i nostri valori condivisi e costituiscono la base per un'esperienza dell'app rispettosa e positiva.",
  'PROFILE.TERMS_OF_USE.INTRO_TEXT2': 'Chiediamo a tutti gli utenti di attenersi alle seguenti linee guida:',
  'PROFILE.TERMS_OF_USE.LIST_1_TITLE': 'Pubblicazione di dati personali',
  'PROFILE.TERMS_OF_USE.LIST_1_TEXT': "Le informazioni personali che potrebbero portare all'identificazione di una persona fisica possono essere pubblicate solo con il consenso esplicito dell'interessato. Questo vale anche per le immagini/video di gruppi di persone o di persone casuali/targhe di auto sullo sfondo.",
  'PROFILE.TERMS_OF_USE.LIST_2_TITLE': 'Molestie e/o incitamento alla violenza',
  'PROFILE.TERMS_OF_USE.LIST_2_TEXT': "Qualsiasi forma di molestia, minaccia o incitamento alla violenza contro individui o gruppi di individui non è consentita nell'ImmoApp.",
  'PROFILE.TERMS_OF_USE.LIST_3_TITLE': 'Discriminazione',
  'PROFILE.TERMS_OF_USE.LIST_3_TEXT': 'Non tolleriamo discriminazioni o discorsi di odio per nessun motivo. Tutti sono i benvenuti nella nostra comunità e vengono accettati per quello che sono. Esprimete le vostre opinioni ed esperienze in modo costruttivo, senza insultare gli altri, generalizzare inutilmente o provocare.',
  'PROFILE.TERMS_OF_USE.LIST_4_TITLE': 'Comportamento dirompente',
  'PROFILE.TERMS_OF_USE.LIST_4_TEXT': "Non è consentito pubblicare ripetutamente lo stesso contenuto o contenuti simili, rispondere in modo inutilmente scortese o disturbare deliberatamente l'esperienza ImmoApp di altri utenti.",
  'PROFILE.TERMS_OF_USE.LIST_5_TITLE': 'Comportamento sessuale invadente',
  'PROFILE.TERMS_OF_USE.LIST_5_TEXT': "Non sono tollerati comportamenti sessuali invadenti o l'offerta di servizi sessuali. Trattate sempre gli altri utenti con rispetto e dignità.",
  'PROFILE.TERMS_OF_USE.LIST_6_TITLE': 'Contenuti sessuali violenti e/o espliciti',
  'PROFILE.TERMS_OF_USE.LIST_6_TEXT': 'Qualsiasi tipo di contenuto sessuale drastico violento e/o esplicito (in forma scritta o visiva) non è consentito. Le immagini di persone sono consentite a condizione che tutte le parti intime del corpo/genitali siano coperte.',
  'PROFILE.TERMS_OF_USE.LIST_7_TITLE': 'Contenuti di terze parti',
  'PROFILE.TERMS_OF_USE.LIST_7_TEXT': "La condivisione di contenuti protetti da copyright non è consentita nell'ImmoApp.",
  'PROFILE.TERMS_OF_USE.LIST_8_TITLE': 'Contenuto commerciale',
  'PROFILE.TERMS_OF_USE.LIST_8_TEXT': 'La pubblicità commerciale non è consentita in ImmoApp. Tuttavia, gli annunci privati vanno bene.',
  'PROFILE.TERMS_OF_USE.LIST_9_TITLE': 'Attività illegali',
  'PROFILE.TERMS_OF_USE.LIST_9_TEXT': 'Non è consentito eseguire, promuovere, pianificare o incitare attività illegali.',
  'PROFILE.TERMS_OF_USE.MODERATION_TITLE': "Moderazione da parte dell'amministrazione",
  'PROFILE.TERMS_OF_USE.MODERATION_TEXT1': "La moderazione dell'ImmoApp è affidata alla rispettiva amministrazione. È loro responsabilità e privilegio assicurare che tutti gli utenti godano dell'app e dei contributi della comunità in un'atmosfera sicura, rispettosa e positiva. L'amministrazione vigila affinché le linee guida di cui sopra siano rispettate e ha il diritto di agire di conseguenza se vengono riscontrate violazioni.",
  'PROFILE.TERMS_OF_USE.MODERATION_TEXT2': "Se incontrate contenuti problematici o avete domande sulla moderazione, contattate direttamente l'amministrazione. Vi ringraziamo per la vostra comprensione e collaborazione nel creare una comunità accogliente e rispettosa su ImmoApp.",

  'DASHBOARD.NO_DESCRIPTION_AVAILABLE': 'Nessuna descrizione disponibile.',

  'REPORT.TICKET.REPAIR.TITLE': 'Rapporto di riparazione',
  'REPORT.TICKET.REPAIR.INTERNAL.DESCRIPTION': "Informa l'amministrazione (e facoltativamente i tuoi vicini) di eventuali danni",
  'REPORT.TICKET.REPAIR.EXTERNAL.DESCRIPTION': "Informa l'amministrazione di eventuali danni",
  'REPORT.TICKET.KEYORDER.TITLE': "Chiave o tessera d'accesso",
  'REPORT.TICKET.KEYORDER.DESCRIPTION': 'Sostituisci le chiavi smarrite, ordina chiavi sostitutive o sostituisci i cilindri',
  'REPORT.TICKET.NAMEPLATE.TITLE': 'Targhetta con nome',
  'REPORT.TICKET.NAMEPLATE.DESCRIPTION': "Nuova targhetta portanome per porta d'ingresso, porta di appartamento o cassetta della posta",
  'REPORT.TICKET.DOCUMENT.TITLE': 'Documento o modulo',
  'REPORT.TICKET.DOCUMENT.DESCRIPTION': "Contratto d'ordine, regolamenti, modulo di domanda e altri documenti",
  'REPORT.TICKET.OTHER.TITLE': 'Altro messaggio',
  'REPORT.TICKET.OTHER.DESCRIPTION': "Informare l'amministrazione di qualcos'altro",
  'REPORT.MESSAGE': 'Messaggio',
  'REPORT.PINBOARD': 'Bacheca',
  'REPORT.VOTE_ITEM': "Punto all'ordine del giorno",
  'REPORT.ADD.TITLE': 'Ciao, come possiamo aiutarti?',
  'REPORT.ADD.SECTION.REPAIR': 'Rapporto sui danni',
  'REPORT.ADD.SECTION.ORDER': 'Ordine',
  'REPORT.ADD.SECTION.OTHER': 'Altre preoccupazioni',
  'REPORT.ADD.NOTE.REPAIR.PUBLIC': 'Stai inviando un rapporto di riparazione pubblico per {{location}}.',
  'REPORT.ADD.NOTE.REPAIR.PRIVATE': 'Stai inviando un rapporto di riparazione privato per {{location}}.',
  'REPORT.ADD.NOTE.KEYORDER': 'Stai inviando un ordine chiave o tessera per {{location}}.',
  'REPORT.ADD.NOTE.NAMEPLATE': 'Stai inviando un ordine targhetta per {{location}}.',
  'REPORT.ADD.NOTE.DOCUMENT': 'Stai inviando un ordine documento o modulo per {{location}}.',
  'REPORT.ADD.NOTE.PINBOARD': 'Stai inviando una voce bacheca pubblica per {{location}}.',
  'REPORT.ADD.NOTE.VOTEITEM': "Stai inviando un elemento all'ordine del giorno per votare per {{location}}.",
  'REPORT.ADD.NOTE.OTHER': 'Stai inviando un altro messaggio privato per {{location}}.',
  'REPORT.ADD.SUBMIT': 'Invia',
  'REPORT.ADD.LIST.PINBOARD.TITLE': 'Ingresso in bacheca',
  'REPORT.ADD.LIST.PINBOARD.DESCRIPTION': 'Condividi informazioni, offerte ed eventi con i tuoi vicini',
  'REPORT.ADD.LIST.VOTE_ITEM.TITLE': 'Voce di voto',
  'REPORT.ADD.LIST.VOTE_ITEM.DESCRIPTION': 'Registra una discussione o un punto di votazione per la riunione successiva',
  'REPORT.ADD.NEW_REPAIR': 'Nuova segnalazione di sinistro',
  'REPORT.ADD.NEW_PINBOARD': 'Nuova voce in bacheca',
  'REPORT.ADD.NEW_KEYORDER': 'Nuovo ordine di chiavi o carte',
  'REPORT.ADD.NEW_NAMEPLATE': 'Nuovo ordine delle targhe',
  'REPORT.ADD.NEW_DOCUMENT': 'Nuovo ordine del documento o del modulo',
  'REPORT.ADD.NEW_VOTE_ITEM': "Nuovo punto all'ordine del giorno",
  'REPORT.ADD.NEW_OTHER': 'Altra richiesta',
  'REPORT.ADD.LOCATION': 'Posizione',
  'REPORT.ADD.LOCATION.PLACEHOLDER': "Selezionare l'immobile...",
  'REPORT.ADD.LOCATION.DETAILS': "Ulteriori dettagli sull'ubicazione",
  'REPORT.ADD.PUBLIC': 'Pubblico',
  'REPORT.ADD.PUBLIC.VISIBILITY': 'Visibile ai vicini',
  'REPORT.ADD.NAMEPLATE.TITLE': 'Targhetta con nome',
  'REPORT.ADD.NAMEPLATE.ROW1': 'Linea 1',
  'REPORT.ADD.NAMEPLATE.ROW2': 'Linea 2',
  'REPORT.ADD.KEYORDER.TITLE': 'Ulteriori informazioni sulla chiave / carta',
  'REPORT.ADD.KEYORDER.QUANTITY': 'Quantità',
  'REPORT.ADD.KEYORDER.LABEL': 'Etichetta chiave',
  'REPORT.ADD.ATTACHMENTS.ADD': 'Aggiungi allegato',
  'REPORT.ADD.ATTACHMENTS.REPAIR': 'Si prega di caricare le foto del danno.',
  'REPORT.ADD.ATTACHMENTS.KEYORDER': "Si prega di caricare un'immagine della chiave / carta.",
  'REPORT.ADD.TITLE_DESCRIPTION': 'Titolo / Messaggio',
  'REPORT.ADD.TITLE_DESCRIPTION.DESCRIPTION': 'Messaggio',
  'REPORT.SUBMIT_REPORT.REPAIR.LOADING': 'Il rapporto sui danni viene trasmesso...',
  'REPORT.SUBMIT_REPORT.ORDER.LOADING': "L'ordine è in fase di invio...",
  'REPORT.SUBMIT_REPORT.OTHER.LOADING': 'Il tuo messaggio è stato inviato...',
  'REPORT.SUBMIT_REPORT.PINBOARD.LOADING': 'È in corso la trasmissione del rapporto sulla bacheca...',
  'REPORT.SUBMIT_REPORT.VOTE_ITEM.LOADING': "È in corso l'invio del punto all'ordine del giorno...",
  'REPORT.SUBMIT_REPORT.REPAIR.SUCCESS': 'Il rapporto sui danni è stato trasmesso con successo.',
  'REPORT.SUBMIT_REPORT.ORDER.SUCCESS': "L'ordine è stato inviato con successo.",
  'REPORT.SUBMIT_REPORT.OTHER.SUCCESS': 'Il tuo messaggio è stato inviato con successo.',
  'REPORT.SUBMIT_REPORT.PINBOARD.SUCCESS': 'La voce in bacheca è stata inviata con successo.',
  'REPORT.SUBMIT_REPORT.VOTE_ITEM.SUCCESS': "Il vostro punto all'ordine del giorno è stato inviato con successo.",
  'REPORT.UPDATE_REPORT.REPAIR.LOADING': 'Il rapporto sui danni viene aggiornato...',
  'REPORT.UPDATE_REPORT.ORDER.LOADING': "L'ordine è in fase di aggiornamento...",
  'REPORT.UPDATE_REPORT.OTHER.LOADING': 'Il messaggio è in aggiornamento...',
  'REPORT.UPDATE_REPORT.PINBOARD.LOADING': 'La voce della bacheca è in fase di aggiornamento...',
  'REPORT.UPDATE_REPORT.VOTE_ITEM.LOADING': "La voce dell'ordine del giorno viene aggiornata...",
  'REPORT.UPDATE_REPORT.REPAIR.SUCCESS': 'Il rapporto di sinistro è stato aggiornato con successo.',
  'REPORT.UPDATE_REPORT.ORDER.SUCCESS': "L'ordine è stato aggiornato con successo.",
  'REPORT.UPDATE_REPORT.OTHER.SUCCESS': 'Il tuo messaggio è stato aggiornato con successo.',
  'REPORT.UPDATE_REPORT.PINBOARD.SUCCESS': 'La voce della bacheca è stata aggiornata con successo.',
  'REPORT.UPDATE_REPORT.VOTE_ITEM.SUCCESS': "La voce dell'ordine del giorno è stata aggiornata con successo.",
  'REPORT.TOO_MANY_REQUESTS': 'Ci sono state troppe richieste in un breve periodo di tempo. Attendi qualche minuto e riprova.',
  'REPORT.UPLOAD_CANCEL': 'Il caricamento è stato annullato.',
  'REPORT.ADD.ACCEPT_PRIVACY_POLICY_WRAPPER': 'Accetto il {{link}}.',
  'REPORT.ADD.ACCEPT_PRIVACY_POLICY_WORD': 'Politica sulla riservatezza',
  'REPORT.ADD.TITLE_REQUIRED': 'Inserire un titolo.',
  'REPORT.ADD.STATUS_REQUIRED': 'Seleziona uno stato.',
  'REPORT.ADD.DESCRIPTION_REQUIRED': 'Inserire una descrizione.',
  'REPORT.ADD.MESSAGE_REQUIRED': 'Inserisci un messaggio.',
  'REPORT.ADD.CAPTCHA_REQUIRED': 'Per favore risolvi prima il CAPTCHA.',
  'REPORT.ADD.PROPERTY_REQUIRED': 'Selezionare una proprietà.',
  'REPORT.ADD.FIRSTNAME_REQUIRED': 'Inserisci un nome.',
  'REPORT.ADD.LASTNAME_REQUIRED': 'Inserisci un cognome.',
  'REPORT.DELETE.TITLE': 'Cancella voce',
  'REPORT.DELETE.DESCRIPTION': 'Se confermate con OK, la voce che avete scritto sarà irrevocabilmente cancellata.',
  'REPORT.DELETE.NOT_POSSIBLE': 'Il ticket non può più essere eliminato perché lo stato è già cambiato.',
  'REPORT.DELETE.SUCCESS': 'La voce è stata cancellata con successo.',

  'REPORT.EDIT.GENERAL': 'Modifica voce',
  'REPORT.EDIT.REPAIR': 'Modifica del rapporto di danno',
  'REPORT.EDIT.PINBOARD': 'Modifica voce in bacheca',
  'REPORT.EDIT.KEYORDER': "Modifica l'ordine delle chiavi o delle carte",
  'REPORT.EDIT.NAMEPLATE': "Modifica l'ordine delle targhette",
  'REPORT.EDIT.DOCUMENT': "Modifica l'ordine del documento o del modulo",
  'REPORT.EDIT.VOTE_ITEM': "Modifica voce dell'ordine del giorno",
  'REPORT.EDIT.CONTACT_DATA': 'Modifica richiesta dati',
  'REPORT.EDIT.OTHER': 'Modifica altra richiesta',

  'VOTES.ITEMS.SUBMITTED_ON': 'Inviato il',
  'VOTES.ITEMS.EDIT_NOT_ALLOWED': "Il punto all'ordine del giorno è già stato utilizzato in una votazione e pertanto non può essere modificato.",

  'VOTES.TITLE': 'Voti',
  'VOTES.MY_VOTE_ITEMS': "Punti all'ordine del giorno",
  'VOTES.DATE_UNTIL': 'fino a',
  'VOTES.LIST.TITLE': 'Voti in',
  'VOTES.DATE_END': 'Fine',
  'VOTES.NO_VOTES_AVAILABLE': 'Nessun voto disponibile.',
  'VOTES.NO_VOTE_ITEMS_SUBMITTED': "L'utente non ha ancora presentato alcun punto all'ordine del giorno.",
  'VOTES.STATUS_ACTIVE_NOT_REGISTERED': 'Registrati e vota ora',
  'VOTES.STATUS_ACTIVE_REGISTERED.VOTE': 'Le votazioni dura fino a',
  'VOTES.STATUS_ACTIVE_REGISTERED.SURVEY': 'Il sondaggio dura fino a',
  'VOTES.STATUS_WARRANT_REGISTERED': 'Conferire procura',
  'VOTES.STATUS_WARRANT_COMPLETED': 'Completato con procura',
  'VOTES.STATUS_COMPLETED.VOTE': 'Votazione completata con successo',
  'VOTES.STATUS_COMPLETED.SURVEY': 'Il sondaggio completata con successo',
  'VOTES.STATUS_NOT_STARTED_NOT_REGISTERED': 'Registrati ora',
  'VOTES.STATUS_NOT_STARTED_REGISTERED.VOTE': 'La votazione è iniziata il',
  'VOTES.STATUS_NOT_STARTED_REGISTERED.SURVEY': 'Il sondaggio è iniziata il',
  'VOTES.STATUS_INACTIVE.VOTE': 'Votazioni non più attive',
  'VOTES.STATUS_INACTIVE.SURVEY': 'Sondaggio non più attivo',
  'VOTES.STAFF_ALL_VOTES_IN_ADMIN_APP': "All'interno dell'ImmoApp vengono visualizzati per i dipendenti solo i voti degli ultimi 2 anni. Per visualizzare i voti più vecchi, aprire l'Admin App.",

  'VOTE.STATUS_NOT_ALLOWED.LABEL': 'Non sei autorizzato a votare. Ciò può accadere se è già stato inviato un voto per il tuo numero di proprietario.',
  'VOTE.STATUS_NOT_STARTED_NOT_REGISTERED.LABEL.VOTE': 'Registrati ora e ricevi un promemoria automatico per iniziare a votare.',
  'VOTE.STATUS_NOT_STARTED_NOT_REGISTERED.LABEL.SURVEY': 'Registrati ora e ricevi un promemoria automatico per iniziare il sondaggio.',
  'VOTE.STATUS_NOT_STARTED_NOT_REGISTERED_LIVE.LABEL': 'Registrati ora',
  'VOTE.STATUS_NOT_STARTED_NOT_REGISTERED.BUTTON.VOTE': 'Registrati per votare',
  'VOTE.STATUS_NOT_STARTED_NOT_REGISTERED.BUTTON.SURVEY': 'Registrati per sondaggio',
  'VOTE.STATUS_NOT_STARTED_REGISTERED.LABEL.VOTE': 'Le votazioni inizieranno il {{dateStart}}. Siete registrati e sarete avvisati.',
  'VOTE.STATUS_NOT_STARTED_REGISTERED.LABEL.SURVEY': 'Il sondaggio inizia il {{dateStart}}. Siete registrati e sarete avvisati.',
  'VOTE.STATUS_NOT_STARTED_REGISTERED_LIVE.LABEL.VOTE': 'Le votazioni inizieranno il {{dateStart}}. Siete registrati.',
  'VOTE.STATUS_NOT_STARTED_REGISTERED_LIVE.LABEL.SURVEY': 'Il sondaggio inizia il {{dateStart}}. Siete registrati.',
  'VOTE.STATUS_NOT_STARTED_REGISTERED.BUTTON': 'Vota',
  'VOTE.STATUS_ACTIVE_NOT_REGISTERED.LABEL': 'Registrati ora e vota subito.',
  'VOTE.STATUS_ACTIVE_NOT_REGISTERED.BUTTON.VOTE': 'Registrati per votare',
  'VOTE.STATUS_ACTIVE_NOT_REGISTERED.BUTTON.SURVEY': 'Registrati per sondaggio',
  'VOTE.STATUS_ACTIVE_NOT_REGISTERED.BUTTON_LIVE.VOTE': 'Registrati per votare dal vivo',
  'VOTE.STATUS_ACTIVE_NOT_REGISTERED.BUTTON_LIVE.SURVEY': 'Registrati per sondaggio dal vivo',
  'VOTE.STATUS_ACTIVE_REGISTERED.BUTTON': 'Vota ora',
  'VOTE.STATUS_COMPLETED.BUTTON': 'Visualizza la tua selezione',
  'VOTE.STATUS_WARRANT_COMPLETED.BUTTON': 'Visualizza la selezione',
  'VOTE.STATUS_COMPLETED_LIVE.LABEL': 'È ancora possibile modificare la selezione fino alla fine della votazione in diretta.',
  'VOTE.STATUS_COMPLETED_LIVE.BUTTON': 'Modifica della selezione',
  'VOTE.STATUS_COMPLETED_ONLINE_NOW_LIVE.LABEL': 'Avete inviato il vostro voto nel pre-voto online. Se decide di partecipare alla votazione dal vivo, può registrarsi nuovamente. In tal caso, il voto precedente sarà cancellato.',
  'VOTE.STATUS_INACTIVE.REGISTER_MODAL.DESCRIPTION': 'A partire da {{date}}, il voto elettronico sulle mozioni "{{title}}" sarà disponibile qui.',
  'VOTE.STATUS_INACTIVE.REGISTER_MODAL.BOLD_TEXT': 'Promemoria e codice di accesso ricevuti via e-mail.',
  'VOTE.STATUS_ACTIVE.REGISTER_MODAL.DESCRIPTION': 'Da {{date}}, è possibile votare elettronicamente sulle mozioni "{{title}}" qui.',
  'VOTE.STATUS_ACTIVE.REGISTER_MODAL.LIVE.BOLD_TEXT': 'Registrati ora e vota subito.',
  'VOTE.STATUS_ACTIVE.REGISTER_MODAL.ONLINE.BOLD_TEXT': 'Ricevi il codice di accesso via e-mail.',
  'VOTE.CODE_RANGE_VALIDATION': 'Il codice deve essere un numero compreso tra 1000 e 9999.',
  'VOTE.CODE_REQUIRED': 'Inserire il codice.',
  'VOTE.CODE_INVALID': 'Codice non valido. Riprovare.',
  'VOTE.CODE_MODAL.TITLE': 'Inserire il codice',
  'VOTE.CODE_MODAL.DESCRIPTION_1': 'Inserire qui il codice di accesso.',
  'VOTE.CODE_MODAL.DESCRIPTION_2_LIVE': "In caso di votazione in diretta, lo riceverete direttamente dall'amministrazione.",
  'VOTE.CODE_MODAL.DESCRIPTION_2_ONLINE': 'Lo troverete nella notifica via e-mail.',
  'VOTE.CODE_MODAL.REQUEST_ACCESS_CODE_AGAIN': 'Richiedi nuovamente il codice di accesso',
  'VOTE.CODE_MODAL.REQUEST_ACCESS_CODE_AGAIN.SUCCESS': 'Il codice di accesso è stato nuovamente inviato via e-mail. Si prega di controllare anche la cartella spam.',
  'VOTE.CODE_MODAL.VOTE_NOW': 'Vota ora',
  'VOTE.CODE_MODAL.VOTING_PERIOD.VOTE': 'Il voto online è disponibile fino a {{date}}.',
  'VOTE.CODE_MODAL.VOTING_PERIOD.SURVEY': 'Il sondaggio online è disponibile fino a {{date}}.',
  'VOTE.REGISTER_MODAL.UNREGISTERED.TITLE': 'Registrazione',
  'VOTE.REGISTER_MODAL.UNREGISTERED.BUTTON': 'Registrati ora',
  'VOTE.REGISTER_MODAL.REGISTERED.TITLE': 'Registrazione riuscita',
  'VOTE.REGISTER_MODAL.START_SOON': 'Mancano pochi minuti',
  'VOTE.REGISTER_MODAL.DAYS_UNTIL_START': {
    none: '{{count}} giorni',
    singular: '{{count}} giorno',
    plural: '{{count}} giorni',
  },
  'VOTE.REGISTER_MODAL.HOURS_UNTIL_START': {
    none: 'e {{count}} ore',
    singular: 'e {{count}} ora',
    plural: 'e {{count}} ore',
  },
  'VOTE.REGISTER_MODAL.PART_2.VOTE': "all'inizio delle votazioni.",
  'VOTE.REGISTER_MODAL.PART_2.SURVEY': "all'inizio del sondaggio.",
  'VOTE.WARRANT.LOADING': 'Procura in fase di caricamento...',
  'VOTE.WARRANT.EMAIL_NAME_PLACEHOLDER': 'Inserisci cognome / e-mail...',
  'VOTE.WARRANT.BANNER': 'Ti è stata conferita la procura. Il tuo voto vale anche per il',
  'VOTE.WARRANT.ASSIGNED_TO': 'Procura conferita a {{receiver}}',
  'VOTE.WARRANT.SHOW': 'Mostra procura',
  'VOTE.WARRANT.REVOKE': 'Revocare la procura',
  'VOTE.WARRANT.REVOKE_DESCRIPTION': 'Se confermi con OK, la procura per {{receiver}} verrà revocata.',
  'VOTE.WARRANT_MODAL.STEP_1.DESCRIPTION': "Nel campo sottostante, inserisci il cognome o l'indirizzo email del destinatario della procura. Il sistema controlla quindi se esiste un proprietario con questi dati nella tua proprietà.",
  'VOTE.WARRANT_MODAL.STEP_2.DESCRIPTION': 'Il Garante, <b>{{warrantGiverName}}</b>, con la presente autorizza <b>{{warrantReceiverName}} ({{warrantReceiverEmail}})</b> a rappresentare i propri interessi nel <b>{{voteTitle}}</b>.\n\nIl destinatario della procura è autorizzato a votare per me nella predetta assemblea. Tutti i passi compiuti dal destinatario in questa riunione sono legalmente vincolanti per me.',
  'VOTE.WARRANT_MODAL.NO_ENTRIES': 'Nessun proprietario trovato.',
  'VOTE.WARRANT_MODAL.SUBMIT_BUTTON': 'Concedere procura',
  'VOTE.WARRANT_MODAL.SUCCESS_TEXT': 'La procura è stata concessa con successo. Una copia è stata inviata via email a te e al destinatario.',

  'VOTE.REVIEW.SIGNATURE_NOTE': "Se stai lavorando su un dispositivo touchscreen, puoi usare una penna o il dito per scrivere la tua firma nello spazio sopra. Se il tuo dispositivo non supporta l'input tattile, puoi usare il mouse per disegnare la tua firma.",
  'VOTE.REVIEW.LEGAL_NOTE.VOTE': 'Con la presente si autorizza l\'{{administration}} a rappresentare i propri interessi (come da voto sopra riportato) al "{{title}}". Si prega di controllare attentamente la selezione effettuata. Il voto espresso qui è legalmente vincolante.',
  'VOTE.REVIEW.LEGAL_NOTE.SURVEY': 'Si prega di controllare attentamente la selezione effettuata. Il voto espresso qui non può più essere modificato.',
  'VOTE.REVIEW.PLACEHOLDER_VOTE': 'Vota',
  'VOTE.REVIEW.PLACEHOLDER_ADMINISTRATION': 'Amministrazione',
  'VOTE.SUBMIT.SIGNATURE_MISSING': 'Inserisci prima la tua firma.',
  'VOTE.SUBMIT.SIGNATURE_MISSING_REVIEW': 'Puoi trovare il campo della firma nella schermata corrente sotto "Il vostro voto".',
  'VOTE.SUBMIT.IN_PROGRESS': 'La vostra selezione sarà inviata...',
  'VOTE.SUBMIT.SUCCESS.DEFAULT': 'La tua selezione è stata inviata con successo. Una copia ti sarà inviata via email.',
  'VOTE.SUBMIT.SUCCESS.WARRANT': 'La tua selezione è stata inviata con successo e la procura è stata creata. Una copia ti sarà inviata via email.',

  'VOTE.LIST.CONFIRM_VOTES.BUTTON': 'Controlla la tua selezione',
  'VOTE.LIST.FOOTER.TEXT_LIVE': "La vostra selezione sarà trasmessa all'amministrazione in tempo reale (LIVE). È possibile modificare la selezione fino alla fine di ogni punto all'ordine del giorno.",
  'VOTE.LIST.FOOTER.TEXT_ONLINE': 'Si prega di votare prima tutte le mozioni.',
  'VOTE.LIST.MOTIONS.VOTE': 'Mozioni di voto',
  'VOTE.LIST.MOTIONS.SURVEY': 'Punti del sondaggio',
  'VOTE.LIST.NO_MOTIONS_AVAILABLE': 'Nessuna mozione disponibile.',
  'VOTE.LIST.ITEM.INACTIVE': 'Voce di voto non attiva.',
  'VOTE.LIST.ITEM.PAUSED': 'In pausa',
  'VOTE.LIST.ITEM.COMPLETED': 'Completato',

  'VOTE.REVIEW.SIGNATURE': 'Firma',
  'VOTE.REVIEW.YOUR_SELECTION': 'Il vostro voto',
  'VOTE.REVIEW.SUBMIT_VOTES': 'Invia il tuo voto',
  'VOTE.REVIEW.VIEW_SELECTION_MANUAL': "La vostra selezione è stata registrata dall'amministrazione il {{date}}.",
  'VOTE.REVIEW.VIEW_SELECTION_WARRANT': 'La selezione è stata presentata tramite procura il {{date}}.',
  'VOTE.REVIEW.VIEW_SELECTION_ONLINE.VOTE': 'La selezione è stata presentata il {{date}} nel pre-voto online.',
  'VOTE.REVIEW.VIEW_SELECTION_ONLINE.SURVEY': 'La selezione è stata presentata il {{date}} nel sondaggio online.',
  'VOTE.REVIEW.VIEW_SELECTION_LIVE.VOTE': 'Hai presentato la tua selezione il {{date}} nella votazione dal vivo.',
  'VOTE.REVIEW.VIEW_SELECTION_LIVE.SURVEY': 'Hai presentato la tua selezione il {{date}} nel sondaggio dal vivo.',
  'VOTE.REVIEW.CHANGES_NOT_ALLOWED.VOTE': 'La modifica successiva non è consentita dalla legge.',
  'VOTE.REVIEW.CHANGES_NOT_ALLOWED.SURVEY': 'Non sono consentite modifiche successive.',

  'DASHBOARD.PROPERTIES.TITLE': 'Proprietà e contatti',
  'DASHBOARD.PROPERTIES.CONTACT': 'Contatto',
  'DASHBOARD.PROPERTIES.CONTACTS': 'Contatti',
  'DASHBOARD.PROPERTIES.USEFUL_INFORMATION': 'Informazioni utili',
  'DASHBOARD.PROPERTIES.MANDATE.INACTIVE': 'Inattivo',

  'DASHBOARD.PROPERTIES.DETAIL.EMAIL': 'Email: ',
  'DASHBOARD.PROPERTIES.DETAIL.COMPANY': 'Azienda: ',
  'DASHBOARD.PROPERTIES.DETAIL.TEL_NR': 'Numero di telefono: ',
  'DASHBOARD.PROPERTIES.DETAIL.MISSING_INFORMATION': 'Informazioni di contatto mancanti',

  'DASHBOARD.OVERVIEW.HERO.BUTTON.MULTIPLE': 'Proprietà & contatti',
  'DASHBOARD.OVERVIEW.HERO.BUTTON.SINGLE': 'Contatti importanti',
  'DASHBOARD.OVERVIEW.REPAIR_REPORT.BUTTON': 'Segnalazione danni',
  'DASHBOARD.OVERVIEW.REPORTS_LIST.TITLE': 'Notizie nel',
  'DASHBOARD.OVERVIEW.REPORTS_LIST.ALL': 'Tutte\nle voci',
  'DASHBOARD.OVERVIEW.REPORTS_LIST.MINE': 'Le mie\nvoci',
  'DASHBOARD.OVERVIEW.REPORTS_LIST.MESSAGES': 'Notizie',
  'DASHBOARD.OVERVIEW.REPORTS_LIST.PINBOARD': 'Bacheca',
  'DASHBOARD.OVERVIEW.REPORTS_LIST.REPAIRS': 'Riparazioni',
  'DASHBOARD.OVERVIEW.COMMENT': 'Commento',
  'DASHBOARD.OVERVIEW.COMMENTS': 'Commenti',
  'DASHBOARD.OVERVIEW.ATTACHMENT': 'Allegato',
  'DASHBOARD.OVERVIEW.LOAD_MORE_ENTRIES': 'Carica altre voci',

  'DASHBOARD.REPORT.SUBSCRIBE': 'Riceverai una notifica di tutti gli aggiornamenti e commenti.',
  'DASHBOARD.REPORT.UNSUBSCRIBE': 'Notifiche disabilitate.',
  'DASHBOARD.REPORT.YOUR_ADMINISTRATION': 'la tua amministrazione',
  'DASHBOARD.REPORT.ADD_STATUS.DESCRIPTION': 'Descrizione',
  'DASHBOARD.REPORT.ADD_STATUS.TEXT': 'Testo',
  'DASHBOARD.REPORT.ADD_STATUS.DESCRIPTION.PLACEHOLDER': 'Inserire la descrizione',
  'DASHBOARD.REPORT.ADD_STATUS.STATUS': 'Stato',
  'DASHBOARD.REPORT.ADD_STATUS.STATUS.PLACEHOLDER': 'Selezionare lo stato',
  'DASHBOARD.REPORT.ADD_STATUS.TITLE': 'Aggiungi stato',
  'DASHBOARD.REPORT.ADD_STATUS.SUCCESS': 'Lo stato è stato aggiunto con successo al ticket.',
  'DASHBOARD.REPORT.ADD_STATUS.PUSH.DESCRIPTION': 'Deve essere inviato un messaggio push? Solo gli utenti che hanno attivato i messaggi push riceveranno il messaggio.',
  'DASHBOARD.REPORT.ADD_STATUS.EMAIL.DESCRIPTION': "Si deve inviare un'e-mail? Solo gli utenti che hanno abilitato l'invio di e-mail riceveranno il messaggio. (Disabilitato per impostazione predefinita)",
  'DASHBOARD.REPORT.ADD_YAROWA.TITLE': 'Crea il processo Yarowa',
  'DASHBOARD.REPORT.ADD_YAROWA.ALERT.MESSAGE': 'Se confermi con OK verrai reindirizzato al portale Yarowa AG e potrai assumere un fornitore di servizi.',
  'DASHBOARD.REPORT.PENDING_YAROWA.TITLE': 'Il processo viene creato...',
  'DASHBOARD.REPORT.PENDING_YAROWA.DESCRIPTION': 'Si prega di completare la procedura sul sito Yarowa. Una volta creato il processo, questa finestra si chiuderà automaticamente.',
  'DASHBOARD.REPORT.GO_TO_YAROWA.TITLE': 'Apri il processo Yarowa',
  'DASHBOARD.REPORT.REMOVE_YAROWA.TITLE': 'Rimuovere il processo Yarowa',
  'DASHBOARD.REPORT.REMOVE_YAROWA.ALERT.MESSAGE': 'Se confermi con OK, la connessione al processo Yarowa verrà rimossa. Se desideri eliminare completamente il processo, rimuovilo dalla piattaforma Yarowa.',
  'DASHBOARD.REPORT.REMOVE_YAROWA.COMPLETED': 'Il processo Yarowa è stato rimosso con successo.',
  'DASHBOARD.REPORT.RECEIVERS.PUBLIC_EXTERNAL_TICKET': 'Ticket esterno: visibile solo agli amministratori e al mittente.',
  'DASHBOARD.REPORT.RECEIVERS.PRIVATE_EXTERNAL_TICKET': 'Ticket esterno privato: visibile solo agli amministratori e al mittente.',
  'DASHBOARD.REPORT.RECEIVERS.PRIVATE_INTERNAL_TICKET': 'Ticket privato: visibile solo ai bidelli, agli amministratori e al mittente.',
  'DASHBOARD.REPORT.COMMENTS.TITLE': 'Commenti',
  'DASHBOARD.REPORT.COMMENTS.PLACEHOLDER': 'Inserire un nuovo commento qui...',
  'DASHBOARD.REPORT.COMMENTS.SUBMIT': 'Invia commento',
  'DASHBOARD.REPORT.COMMENTS.DELETE.HEADER': 'Elimina commento',
  'DASHBOARD.REPORT.COMMENTS.DELETE.CONFIRMATION': 'Vuoi cancellare questo commento?',
  'DASHBOARD.REPORT.COMMENTS.STATUS.EDITED': 'Modificato',
  'DASHBOARD.REPORT.COMMENTS.NA_YET': 'Non sono ancora stati registrati commenti.',
  'DASHBOARD.REPORT.COMMENTS.STATUS.DELETED': 'Il commento è stato eliminato.',
  'DASHBOARD.REPORT.COMMENTS.CREATE.PROGRESS': 'Il commento è stato inviato...',
  'DASHBOARD.REPORT.COMMENTS.CREATE.SUCCESS': 'Il commento è stato creato con successo.',
  'DASHBOARD.REPORT.COMMENTS.EDIT.SUCCESS': 'Il commento è stato aggiornato con successo.',
  'DASHBOARD.REPORT.COMMENTS.DELETE.SUCCESS': 'Il commento è stato eliminato con successo.',
  'DASHBOARD.REPORT.COMMENTS.RESTORED.SUCCESS': 'Il commento è stato ripristinato con successo.',
  'DASHBOARD.REPORT.COMMENTS.DISABLED_USER_INFO': 'Commenti disabilitati.',
  'DASHBOARD.REPORT.PERSONAL_MESSAGE': 'Messaggio personale',
  'DASHBOARD.REPORT.CONTACT_INFOS_FOR_REPORTING_COMMENT': "Segnala questo commento all'amministrazione.",
  'DASHBOARD.REPORT.CONTACT_INFOS_ONLY_VISIBLE_FOR_ADMINISTRATION': "Queste informazioni di contatto sono visibili solo all'amministrazione.",
  'DASHBOARD.REPORT.ERROR.NOT_AVAILABLE': 'La voce non è disponibile.',
  'DASHBOARD.REPORT.SHARE': 'Condividi tramite e-mail',
  'DASHBOARD.REPORT.SHARE.HEADER': 'Condividi tramite e-mail',
  'DASHBOARD.REPORT.SHARE.MESSAGE': "Chi deve ricevere l'e-mail?",
  'DASHBOARD.REPORT.SHARE.HEADER.EMAIL': 'Indirizzo e-mail',
  'DASHBOARD.REPORT.SHARE.INVALID_EMAIL': 'Indirizzo email non valido.',
  'DASHBOARD.REPORT.SHARE.SUCCESS': 'Email inviata correttamente.',
  'DASHBOARD.REPORT.SHARE.SEND': 'Inviare',
  'DASHBOARD.REPORT.SCREENSHOT_TAKE': 'Fai uno screenshot',
  'DASHBOARD.REPORT.SCREENSHOT_CREATING': 'Lo screenshot è stato creato...',

  'LOCATION.ALL_PROPERTIES': 'Tutte le proprietà',
  'LOCATION.DISABLED.PINBOARD': 'La bacheca è disabilitata per questa struttura',

  'DOCUMENTS.TITLE': 'Documenti',
  'DOCUMENTS.SEARCH.TITLE': 'Ricerca documenti',
  'DOCUMENTS.SEARCH.PLACEHOLDER': 'Ricerca documenti...',
  'DOCUMENTS.SEARCH.TOO_FEW_CHARACTERS': 'Sono richiesti almeno 3 caratteri.',
  'DOCUMENTS.INACTIVE_ONLY_VISIBLE_TO_ADMINS': 'Immobile inattivo - Visibile solo agli amministratori',
  'DOCUMENTS.ALL': 'Tutti i documenti',
  'DOCUMENTS.RECENT': 'Documenti aggiunti di recente',
  'DOCUMENTS.NO_ENTRIES': 'Nessun file trovato',
  'DOCUMENTS.MORE_SEARCH_RESULTS': 'Sono disponibili altri risultati di ricerca. Si prega di affinare la ricerca.',
  'DOCUMENTS.PERSONAL_FOLDER': 'Cartella personale',
  'DOCUMENTS.DOWNLOAD_ALL.NO_FILES': 'Non ci sono file in questa cartella.',
  'DOCUMENTS.DOWNLOAD_ALL.TITLE': 'Scarica tutto',
  'DOCUMENTS.DOWNLOAD_ALL_FILES.MESSAGE': 'Vuoi scaricare tutti i file in questa cartella?',
  'DOCUMENTS.DOWNLOAD_ALL_ATTACHMENTS.MESSAGE': 'Vuoi scaricare tutti gli allegati?',

  'PAGE_DENIED.TITLE': 'Accesso negato',
  'PAGE_DENIED.SUBTITLE': 'Accesso negato',
  'PAGE_DENIED.BACK_HOME': 'Indietro',
  'PAGE_NOT_FOUND.TITLE': 'Pagina non trovata',
  'PAGE_NOT_FOUND.SUBTITLE': 'Pagina non trovata',
  'PAGE_NOT_FOUND.BACK_HOME': 'Indietro',
  'PAGE_ERROR.TITLE': 'Errore inatteso',
  'PAGE_ERROR.SUBTITLE': 'Errore inatteso - Per favore controlla la tua connessione Internet.',
  'PAGE_ERROR.BACK_HOME': 'Indietro',

  'FILE_LOADER.NEW_PHOTO': 'Scatta una nuova foto',
  'FILE_LOADER.PHOTOS': {
    none: 'Seleziona foto',
    singular: 'Seleziona foto',
    plural: 'Seleziona foto',
  },
  'FILE_LOADER.VIDEOS': 'Seleziona video',
  'FILE_LOADER.DOCUMENTS': 'Seleziona documenti',
  'FILE_LOADER.NOT_SUPPORTED': 'Il tuo dispositivo o browser non supporta il download di file.',
  'FILE_LOADER.SINGLE.DOWNLOAD_ERROR': 'Non è stato possibile scaricare il file.',
  'FILE_LOADER.SINGLE.DOWNLOAD_SUCCESS': 'Il file è stato scaricato con successo.',
  'FILE_LOADER.MULTIPLE.DOWNLOADING': 'I file sono in fase di download...',
  'FILE_LOADER.MULTIPLE.DOWNLOAD_ERROR': 'Impossibile scaricare i file.',
  'FILE_LOADER.MULTIPLE.DOWNLOAD_SUCCESS': 'I file sono stati scaricati con successo.',
  'FILE_LOADER.NO_FILE_SELECTED': 'Nessun file selezionato.',
  'FILE_LOADER.MAX_1_VIDEO': 'È consentito massimo 1 video.',
  'FILE_LOADER.MAX_MB': 'Il file è troppo grande. Massimo: {{size}} MB.',
  'FILE_LOADER.LIMIT_TO_X': {
    none: 'Si prega di non scegliere più di {{count}} file.',
    singular: 'Si prega di non scegliere più di {{count}} file.',
    plural: 'Si prega di non scegliere più di {{count}} file.',
  },

  'FILE_VIEWER.ERROR.SHARE_NOT_SUPPORTED': 'La funzionalità di condivisione non è supportata.',
  'FILE_VIEWER.ERROR.FILE_CANNOT_BE_SHARED': 'Il file non può essere condiviso.',
  'FILE_VIEWER.ERROR.FILE_CANNOT_BE_DISPLAYED': 'Il file non può essere visualizzato.',
  'FILE_VIEWER.ERROR.FILE_CANNOT_BE_DISPLAYED_OR_DOWNLOADED': 'Il file non può essere visualizzato o scaricato.',
  'FILE_VIEWER.X_RESULTS': {
    none: 'Nessun risultato',
    singular: '{{count}} risultato',
    plural: '{{count}} risultati',
  },
  'FILE_VIEWER.FILE_IS_LOADING': 'Il file si sta caricando...',

  'IMAGE_ALT.APP.LOGO': "Logo dell'app",
  'IMAGE_ALT.DASHBOARD_HEADER.BACKGROUND': 'Immobile',
  'IMAGE_ALT.USER': 'Utente registrato',
  'IMAGE_ALT.REPORT.COMMENT.OWNER': 'Mittente del commento',
  'IMAGE_ALT.PROFILE.USER': 'Profilo utente',
  'IMAGE_ALT.SIGN_IN': 'Pagina di accesso',
  'IMAGE_ALT.SIGN_UP': 'Pagina di iscrizione',
  'IMAGE_ALT.VOTE.REGISTER_MODAL': 'Registrati per votare',

  'INFOCENTER.SEGMENT.TICKET': 'Segnala un problema',
  'INFOCENTER.SEGMENT.FAQS': 'FAQ',
  'INFOCENTER.SEGMENT.DOCUMENTS': 'Documenti',
  'INFOCENTER.WEBSITE.TITLE': 'Visita il sito web di {{adminName}}',
  'INFOCENTER.WEBSITE.TEXT': 'Visita il nostro sito web per tutte le ulteriori informazioni su {{adminName}}.',
  'INFOCENTER.WEBSITE.BUTTON': 'Al sito web',
  'INFOCENTER.LOAD.TITLE': 'Rimani informato con ImmoApp',
  'INFOCENTER.LOAD.TEXT': 'Con ImmoApp sarai sempre ben informato su tutte le ultime novità relative al tuo immobile. Vedrai anche tutti i messaggi che hai inviato con il loro stato attuale.',
  'INFOCENTER.FAQ.SECTION.ADD': 'Nuova sezione',
  'INFOCENTER.FAQ.SECTION.TITLE': 'Nuova Sezione',
  'INFOCENTER.FAQ.ITEM.HEAD_TITLE': "Nuovo titolo dell'articolo",
  'INFOCENTER.FAQ.ITEM.BODY_TITLE': "Nuovo corpo dell'articolo",
  'INFOCENTER.FAQ.SECTION.TRANSLATE_DE': 'Tradurre in tedesco',
  'INFOCENTER.FAQ.SECTION.TRANSLATE_EN': 'Tradurre in inglese',
  'INFOCENTER.FAQ.SECTION.TRANSLATE_FR': 'Tradurre in francese',
  'INFOCENTER.FAQ.SECTION.TRANSLATE_IT': 'Tradurre in italiano',
  'INFOCENTER.FAQ.SECTION.DELETED': 'Sezione FAQ eliminata.',
  'INFOCENTER.FAQ.DATA_UPDATED': 'FAQ aggiornate con successo.',
  'INFOCENTER.FAQ.DELETE_POPOVER.TITLE': 'Impossibile eliminare',
  'INFOCENTER.FAQ.DELETE_POPOVER.CONTENT': 'Elimina prima tutti gli elementi in questa sezione.',
  'INFOCENTER.DOCUMENTS.NONE': 'Nessun documento disponibile.',
  'INFOCENTER.TRANSLATE_MODAL.TITLE': 'Tradurre i testi',
  'INFOCENTER.TRANSLATE_MODAL.SUBTITLE': 'Traduzione attuale',
  'INFOCENTER.TRANSLATE_MODAL.INPUT_PLACEHOLDER': 'Inserire la traduzione',
  'INFOCENTER.TRANSLATE_MODAL.DELETE': 'Elimina traduzione',
  'INFOCENTER.NOT_ACTIVATED_YET': 'Il centro informazioni non è ancora attivato per la tua amministrazione.',

  STARTING_BACKEND_KEYS: '---',

  'PUSH.NEW_ASSIGNMENT': 'Nuovo incarico',
  'PUSH.NEW_COMMENT': 'Nuovo commento',
  'PUSH.NEW_MANUAL_REGISTRATION': 'Nuova registrazione manuale',
  'PUSH.STATUS': 'Stato',
  'PUSH.MESSAGE': 'Messaggio',
  'PUSH.REPAIR': 'Rapporto di riparazione',
  'PUSH.KEYORDER': 'Ordine di chiavi o carte',
  'PUSH.NAMEPLATE': 'Ordine dei tag con il nome',
  'PUSH.DOCUMENT': 'Ordine di un documento o modulo',
  'PUSH.OTHER': 'Altro messaggio',
  'PUSH.PINBOARD_ENTRY': 'Voce bacheca',
  'PUSH.NEW_VOTE_ITEM': 'Nuova elemento di voto',
  'PUSH.NEW_DOCUMENTS': 'Nuovi documenti',
  'PUSH.NEW_DOCUMENTS_REMAINING_one': '+ {{count}} altro documento',
  'PUSH.NEW_DOCUMENTS_REMAINING_other': '+ {{count}} altri documenti',
  'PUSH.APPROVAL': 'Approvazione',
  'PUSH.VOTE': 'Voto',
  'PUSH.NEW_VOTE': 'Nuovo voto',
  'PUSH.SURVEY': 'Sondaggio',
  'PUSH.VOTE_SUBMITTED_FROM': 'Voto inviato da',
  'PUSH.VOTE_AVAILABLE_NOW': 'Il voto "{{voteTitle}}" è ora disponibile. Votate digitalmente nella ImmoApp.',

  'EMAIL.HEADER.GREETING': 'Gentile signor/signora {{name}}',
  'EMAIL.HEADER.ACCOUNT_ACTIVATED': 'Il suo account è stato attivato',
  'EMAIL.HEADER.INVITED_BY_OWNER': 'Il tuo proprietario ti ha invitato',
  'EMAIL.HEADER.IMMOAPP_ACCESS': 'Il {{appName}} (accesso e introduzione)',
  'EMAIL.HEADER.ADMIN_ACCESS': "Accesso all'area di amministrazione",
  'EMAIL.HEADER.NEW_PRIVATE_REPAIR': 'Nuovo rapporto sui danni privati',
  'EMAIL.HEADER.NEW_MESSAGE': 'Nuovo messaggio',
  'EMAIL.HEADER.NEW_REPAIR': 'Nuovo rapporto sui danni',
  'EMAIL.HEADER.NEW_KEYORDER': 'Nuovo ordine di chiavi o carte',
  'EMAIL.HEADER.NEW_DOCUMENT': 'Nuovo ordine del documento o del modulo',
  'EMAIL.HEADER.NEW_OTHER': 'Nuovo altro messaggio',
  'EMAIL.HEADER.NEW_NAMEPLATE': 'Nuovo ordine delle targhe',
  'EMAIL.HEADER.NEW_PINBOARD': 'Nuova voce bacheca',
  'EMAIL.HEADER.NEW_VOTEITEM': 'Nuova voce di voto',
  'EMAIL.HEADER.FORWARDED_MESSAGE': 'Messaggio inoltrato',
  'EMAIL.HEADER.FORWARDED_REPAIR': 'Rapporto sui danni inoltrata',
  'EMAIL.HEADER.FORWARDED_KEYORDER': 'Ordine di chiavi o carte inoltrato',
  'EMAIL.HEADER.FORWARDED_DOCUMENT': 'Inoltrato ordine del documento o del modulo',
  'EMAIL.HEADER.FORWARDED_OTHER': 'Inoltrato altro messaggio',
  'EMAIL.HEADER.FORWARDED_NAMEPLATE': 'Inoltrato ordine delle targhe',
  'EMAIL.HEADER.FORWARDED_PINBOARD': 'Ingresso muro inoltrato',
  'EMAIL.HEADER.FORWARDED_VOTEITEM': 'Voce di voto inoltrato',
  'EMAIL.HEADER.REPAIR_UPDATED': 'La segnalazione del danno "{{entryTitle}}" in {{locationName}} è stata aggiornata a "{{status}}"',
  'EMAIL.HEADER.KEYORDER_UPDATED': 'L\'ordine di chiavi o carte "{{entryTitle}}" da {{senderName}} è stata aggiornata a "{{status}}"',
  'EMAIL.HEADER.NAMEPLATE_UPDATED': 'L\'ordine delle etichette nome "{{entryTitle}}" da {{senderName}} è stato aggiornato a "{{status}}"',
  'EMAIL.HEADER.DOCUMENT_UPDATED': 'L\'ordine del documento o del modulo "{{entryTitle}}" di {{senderName}} è stato aggiornato a "{{status}}"',
  'EMAIL.HEADER.OTHER_UPDATED': 'Un altro messaggio "{{entryTitle}}" da {{senderName}} è stato aggiornato a "{{status}}"',
  'EMAIL.HEADER.NEW_COMMENT_MESSAGE': 'Nuovo commento per il messaggio',
  'EMAIL.HEADER.NEW_COMMENT_REPAIR': 'Nuovo commento per la segnalazione di un danno',
  'EMAIL.HEADER.NEW_COMMENT_KEYORDER': "Nuovo commento per l'ordine di chiavi o carte",
  'EMAIL.HEADER.NEW_COMMENT_NAMEPLATE': "Nuovo commento sull'ordine delle targhette",
  'EMAIL.HEADER.NEW_COMMENT_DOCUMENT': "Nuovo commento sull'ordine del documento o del modulo",
  'EMAIL.HEADER.NEW_COMMENT_OTHER': "Nuovo commento sull'altro messaggio",
  'EMAIL.HEADER.NEW_COMMENT_PINBOARD': 'Nuovo commento per una voce in bacheca',
  'EMAIL.HEADER.ACCESS_CODE_FOR.VOTE': 'Il vostro codice di accesso per la votazione',
  'EMAIL.HEADER.ACCESS_CODE_FOR.SURVEY': 'Il vostro codice di accesso per il sondaggio',
  'EMAIL.HEADER.WARRANT.GIVEN': 'Procura concessa per il voto',
  'EMAIL.HEADER.WARRANT.RECEIVED': 'Ricevuta la procura per il voto',
  'EMAIL.HEADER.VOTE_RESULTS.DEFAULT': 'Risultati della votazione per',
  'EMAIL.HEADER.VOTE_RESULTS.WARRANT': 'La tua procura per',
  'EMAIL.HEADER.INVITE.FUTURE': 'Aggiornamento amministratore ImmoApp: gli utenti saranno presto invitati',
  'EMAIL.HEADER.INVITE.PAST': 'Aggiornamento amministratore ImmoApp: gli utenti sono stati invitati',
  'EMAIL.HEADER.EXTERNAL': 'Nuovo messaggio dalla ImmoApp',
  'EMAIL.HEADER.EMAIL_CHANGE': "Avviso di sicurezza: L'indirizzo email è stato modificato",
  'EMAIL.HEADER.PASSWORD_CHANGE': 'Avviso di sicurezza: La password è stata cambiata',

  'EMAIL.CONTENT.ACCOUNT_ACTIVATED': 'Il tuo account per il {{appName}} è stato attivato.',
  'EMAIL.CONTENT.DIRECT_ACCOUNT_LOGIN': 'Ora puoi accedere con il tuo indirizzo e-mail e la password che hai scelto.',
  'EMAIL.CONTENT.VERIFY_ACCOUNT_LOGIN_1': 'Confermate il vostro indirizzo e-mail cliccando sul seguente link',
  'EMAIL.CONTENT.VERIFY_ACCOUNT_LOGIN_2A': 'Potete quindi effettuare il login con il vostro indirizzo e-mail e la password che avete scelto.',
  'EMAIL.CONTENT.VERIFY_ACCOUNT_LOGIN_2B': 'È quindi possibile accedere con il proprio indirizzo e-mail e la seguente password',
  'EMAIL.CONTENT.IMMOAPP_AVAILABILITY': 'ImmoApp è disponibile gratuitamente negli app store e come applicazione web',
  'EMAIL.CONTENT.WEB_BROWSER': 'Web / browser',
  'EMAIL.CONTENT.IMMOAPP_INTRODUCTION': "Qui trovate una breve introduzione all'ImmoApp",
  'EMAIL.CONTENT.INVITED_BY_OWNER': 'Il tuo proprietario ti ha invitato a utilizzare la {{appName}}.',
  'EMAIL.CONTENT.IMMOAPP_LINKS': "È possibile utilizzare gratuitamente l'ImmoApp tramite i seguenti link",
  'EMAIL.CONTENT.IMMOAPP_AVAILABLE': 'Da oggi è possibile utilizzare la {{appName}} su smartphone, tablet e computer.',
  'EMAIL.CONTENT.FUNCTIONALITIES_INTRO': "Tramite l'app è ora possibile",
  'EMAIL.CONTENT.FUNCTIONALITIES_REPAIRS': 'Inviare segnalazioni di danni',
  'EMAIL.CONTENT.FUNCTIONALITIES_KEYORDER': 'Effettua un ordine di chiavi o carte',
  'EMAIL.CONTENT.FUNCTIONALITIES_NAMEPLATE': 'Ordina una targhetta con il nome',
  'EMAIL.CONTENT.FUNCTIONALITIES_DOCUMENTORDER': 'Ordinare documenti e moduli',
  'EMAIL.CONTENT.FUNCTIONALITIES_NEWS': 'Ricevere notizie sulla vostra proprietà',
  'EMAIL.CONTENT.FUNCTIONALITIES_PINBOARD': 'Scrivi voci in bacheca per i tuoi vicini',
  'EMAIL.CONTENT.FUNCTIONALITIES_DOCUMENTS': "Visualizza i documenti dell'immobile online",
  'EMAIL.CONTENT.FUNCTIONALITIES_VOTES': 'Partecipa alle riunioni dei proprietari',
  'EMAIL.CONTENT.FUNCTIONALITIES_TENANTS': 'Aggiungete e rimuovete gli inquilini in modo indipendente sulla ImmoApp. In questo modo gli inquilini hanno un accesso limitato alle notizie, ai rapporti sui danni, alle voci della bacheca e a documenti selezionati.',
  'EMAIL.CONTENT.ACCOUNT_ALREADY_CREATED': 'È già stato creato un account per lei. È possibile accedere con i seguenti dati di accesso',
  'EMAIL.CONTENT.RECOMMENDATION_PASSWORD_CHANGE': 'Si consiglia di cambiare la password al prossimo accesso tramite la voce di menu "Impostazioni".',
  'EMAIL.CONTENT.SEND_EMAIL_TO': "Si prega di inviare un'e-mail a {{contact}}",
  'EMAIL.CONTENT.INVITED_TO_ADMIN_AREA': "Sei stato invitato all'area di amministrazione di {{appName}}.",
  'EMAIL.CONTENT.ADMIN_LOGIN': "È possibile effettuare il login con i seguenti dati di accesso nell'area di amministrazione e nell'ImmoApp (vedere sotto).",
  'EMAIL.CONTENT.ADMIN_SALUTATION': 'Ciao amministratore',
  'EMAIL.CONTENT.HOW_TO_CHANGE_PASSWORD_Q': 'Come posso cambiare la mia password?',
  'EMAIL.CONTENT.HOW_TO_CHANGE_PASSWORD_A': 'Questo può essere fatto nell\'app tramite la voce di menu "Impostazioni" e poi selezionando "Ripristina password". Si consiglia di cambiare la password al successivo accesso.',
  'EMAIL.CONTENT.NEW_MESSAGE': 'È stato creato un nuovo messaggio.',
  'EMAIL.CONTENT.NEW_REPAIR': 'È stata creata una nuova segnalazione di danno.',
  'EMAIL.CONTENT.NEW_KEYORDER': 'È stato creato un nuovo ordine di chiavi o carte.',
  'EMAIL.CONTENT.NEW_NAMEPLATE': 'È stato inviato un nuovo ordine di targa.',
  'EMAIL.CONTENT.NEW_DOCUMENT': "È stato inviato un nuovo documento o modulo d'ordine.",
  'EMAIL.CONTENT.NEW_OTHER': 'È stato registrato un nuovo messaggio vario.',
  'EMAIL.CONTENT.NEW_PINBOARD': 'È stata creata una nuova voce in bacheca.',
  'EMAIL.CONTENT.NEW_VOTEITEM': "È stata creata una nuova voce di voto. È possibile aggiungerlo facilmente a una votazione esistente o nuova nell'Admin-App.",
  'EMAIL.CONTENT.EXTERNAL.SUBMITTED': 'La voce è stata inviata utilizzando il modulo di contatto disponibile pubblicamente.',
  'EMAIL.CONTENT.EXTERNAL.VISIBLE': 'Visibile solo agli amministratori finché non viene assegnata una proprietà.',
  'EMAIL.CONTENT.FORWARDED_MESSAGE': 'Ti è stato inoltrato un messaggio da ImmoApp.',
  'EMAIL.CONTENT.FORWARDED_REPAIR': "Ti è stato inoltrato un rapporto sui danni dell'ImmoApp.",
  'EMAIL.CONTENT.FORWARDED_KEYORDER': 'Ti è stato inoltrato un ordine di chiavi o carte da ImmoApp.',
  'EMAIL.CONTENT.FORWARDED_NAMEPLATE': 'Ti è stato inoltrato un ordine di targhette con il nome dalla ImmoApp.',
  'EMAIL.CONTENT.FORWARDED_DOCUMENT': "Ti è stato inoltrato un ordine d'un documento o d'un modulo della ImmoApp.",
  'EMAIL.CONTENT.FORWARDED_OTHER': "Ti è stato inoltrato un messaggio vario dall'ImmoApp.",
  'EMAIL.CONTENT.FORWARDED_PINBOARD': 'Ti è stata inoltrata una voce in bacheca da ImmoApp.',
  'EMAIL.CONTENT.FORWARDED_VOTEITEM': "Ti è stato inoltrato un punto all'ordine del giorno dell'ImmoApp.",
  'EMAIL.CONTENT.REPAIR_UPDATED': 'Il rapporto sui danni "{{entryTitle}}" è stato aggiornato.',
  'EMAIL.CONTENT.KEYORDER_UPDATED': 'L\'ordine di chiavi o carte "{{entryTitle}}" è stato aggiornato.',
  'EMAIL.CONTENT.NAMEPLATE_UPDATED': 'L\'ordine dei tag "{{entryTitle}}" è stato aggiornato.',
  'EMAIL.CONTENT.DOCUMENT_UPDATED': 'L\'ordine del documento o del modulo "{{entryTitle}}" è stato aggiornato.',
  'EMAIL.CONTENT.OTHER_UPDATED': 'Il messaggio vario "{{entryTitle}}" è stato aggiornato.',
  'EMAIL.CONTENT.NEW_STATUS': 'Nuovo stato',
  'EMAIL.CONTENT.NO_DESCRIPTION': 'Senza descrizione',
  'EMAIL.CONTENT.INITIAL_SENDER': 'Mittente originale',
  'EMAIL.CONTENT.NEW_COMMENT_MESSAGE': 'È stato aggiunto un nuovo commento al messaggio "{{entryTitle}}".',
  'EMAIL.CONTENT.NEW_COMMENT_REPAIR': 'È stato aggiunto un nuovo commento al rapporto sui danni "{{entryTitle}}".',
  'EMAIL.CONTENT.NEW_COMMENT_KEYORDER': 'È stato aggiunto un nuovo commento all\'ordine di chiavi o carte "{{entryTitle}}".',
  'EMAIL.CONTENT.NEW_COMMENT_NAMEPLATE': 'È stato aggiunto un nuovo commento all\'ordine dei tag "{{entryTitle}}".',
  'EMAIL.CONTENT.NEW_COMMENT_DOCUMENT': "È stato inserito un nuovo commento all'ordine d'un documento o d'un modulo \"{{entryTitle}}\".",
  'EMAIL.CONTENT.NEW_COMMENT_OTHER': 'Un nuovo commento è stato aggiunto al messaggio vario "{{entryTitle}}".',
  'EMAIL.CONTENT.NEW_COMMENT_PINBOARD': 'È stato aggiunto un nuovo commento alla voce in bacheca "{{entryTitle}}".',
  'EMAIL.CONTENT.NOW_AVAILABLE.VOTE': 'Il voto "{{title}}" è ora disponibile.',
  'EMAIL.CONTENT.NOW_AVAILABLE.SURVEY': 'Il sondaggio "{{title}}" è ora disponibile.',
  'EMAIL.CONTENT.VOTE_WITH_CODE': 'È ora possibile votare con il seguente codice di accesso',
  'EMAIL.CONTENT.SUBMIT_THANK_YOU.VOTE': 'Grazie per aver partecipato alla votazione',
  'EMAIL.CONTENT.SUBMIT_THANK_YOU.SURVEY': 'Grazie per aver partecipato al sondaggio',
  'EMAIL.CONTENT.SUBMIT_RESULTS.DEFAULT': 'In allegato una copia dei risultati della votazione.',
  'EMAIL.CONTENT.SUBMIT_RESULTS.WARRANT': 'In allegato una copia della vostra procura.',
  'EMAIL.CONTENT.VOTE_RESULT_PDF_TITLE.DEFAULT': 'Risultati della votazione',
  'EMAIL.CONTENT.VOTE_RESULT_PDF_TITLE.WARRANT': 'Procura',
  'EMAIL.CONTENT.KEYORDER.QUANTITY': 'Quantità',
  'EMAIL.CONTENT.KEYORDER.LABEL': 'Etichetta chiave',
  'EMAIL.CONTENT.NAMEPLATE.ROW1': 'Linea 1',
  'EMAIL.CONTENT.NAMEPLATE.ROW2': 'Linea 2',
  'EMAIL.CONTENT.ATTACHMENTS_REMOVED': "Gli allegati aggiunti dal mittente superano il limite di 25 MB e sono stati pertanto rimossi da questa e-mail. È possibile visualizzare gli allegati nell'ImmoApp.",
  'EMAIL.CONTENT.WARRANT.GIVEN': 'Hai conferito procura a {{warrantPartnerName}} ({{warrantPartnerEmail}}) per il voto "{{voteTitle}}" ({{voteMandateName}}).',
  'EMAIL.CONTENT.WARRANT.RECEIVED': 'Hai ricevuto una procura da {{warrantPartnerName}} ({{warrantPartnerEmail}}) per il voto "{{voteTitle}}" ({{voteMandateName}}).',
  'EMAIL.CONTENT.WARRANT.ATTACHED_COPY': 'In allegato copia della procura.',
  'EMAIL.CONTENT.INVITE.FUTURE.1': "L'elenco seguente mostra tutti gli utenti {{quantity}} che saranno invitati alle {{time}} (CET).",
  'EMAIL.CONTENT.INVITE.FUTURE.2': 'Nota: se non si desidera ciò, è possibile disattivare l\'invio automatico degli inviti nella WebApp in "Gestisci piattaforma" > "ImmoApp" > "Connessione ERP" > "Crea utente automaticamente". In questo caso rivolgersi anche al supporto tecnico della ImmoDigi AG per apportare le modifiche necessarie.',
  'EMAIL.CONTENT.INVITE.PAST.1': "L'elenco seguente mostra tutti gli utenti {{quantity}} che sono stati appena creati e invitati con successo.",
  'EMAIL.CONTENT.INVITE.SKIPPED': "({{quantity}} utenti sono stati saltati. Questo limite esiste per evitare possibili errori di importazione. Esegui nuovamente l'azione nell'app Web in modo che anche gli utenti rimanenti ricevano un invito.)",
  'EMAIL.CONTENT.EXTERNAL': "La tua amministrazione ha registrato un nuovo messaggio nell'ImmoApp.",
  'EMAIL.CONTENT.PREVIEW': "Nota: questa email è solo un'anteprima e pertanto non contiene allegati.",
  'EMAIL.CONTENT.TITLE_OF_MESSAGE': 'Titolo del messaggio',
  'EMAIL.CONTENT.TICKET_ASSIGNMENT1': 'Ti è stata assegnata una nuova attività da {{administrationName}}.',
  'EMAIL.CONTENT.TICKET_ASSIGNMENT2': "Clicca {{link}} per aprire l'ordine nell'ImmoApp.",
  'EMAIL.CONTENT.EMAIL_CHANGE_1': 'Desideriamo informarti che il tuo indirizzo email per il tuo account ImmoApp è stato appena cambiato in "{{newEmail}}".',
  'EMAIL.CONTENT.PASSWORD_CHANGE_1': 'Desideriamo informarti che la tua password per il tuo account ImmoApp è stata appena cambiata.',
  'EMAIL.CONTENT.EMAIL_PASSWORD_CHANGE_2': "Se non hai apportato questa modifica, contatta immediatamente il nostro team di supporto all'indirizzo {{supportEmail}}.",
  'EMAIL.CONTENT.EMAIL_PASSWORD_CHANGE_3': 'Grazie per aver prestato attenzione alla sicurezza del tuo account ImmoApp.',

  'EMAIL.FOOTER.UNSUBSCRIBE.INTRO': 'Questa e-mail viene inviata automaticamente agli utenti di ImmoApp per conto di {{contact}}.',
  'EMAIL.FOOTER.UNSUBSCRIBE.PUBLIC': 'Se non desideri più ricevere queste e-mail, scrivici a: {{contact}}.',
  'EMAIL.FOOTER.UNSUBSCRIBE.SETTINGS': 'Se non si desidera più ricevere queste e-mail, è possibile disattivarle nelle impostazioni di {{immoappLink}}.',
  'EMAIL.FOOTER.GREETING': 'Cordiali saluti',
  'EMAIL.FOOTER.LEGAL': 'Software fornito da ImmoDigi AG.',
  'EMAIL.FOOTER.EXTERNAL': "Apri il messaggio direttamente nell'ImmoApp. Con ImmoApp ricevi tutte le ultime notizie sul tuo immobile direttamente sul tuo smartphone. Puoi scaricare l'ImmoApp da {{appStoreLink}} e {{playStoreLink}} o aprirla direttamente nel {{browserLink}}. Hai già ricevuto i tuoi dati di accesso personali dalla tua amministrazione via e-mail. Se non le possiedi più, puoi utilizzare la funzione \"Reimposta password\" nell'ImmoApp.<br/>Se non desideri più ricevere queste e-mail, accedi a ImmoApp e modifica le impostazioni di notifica.",

  'INTRO_PDF.TITLE': "Introduzione all'ImmoApp",
  'INTRO_PDF.WELCOME.TITLE': 'Introduzione',
  'INTRO_PDF.WELCOME.TEXT': "Benvenuti e grazie per il vostro interesse per l'ImmoApp. Nel seguente documento troverete molte informazioni e spiegazioni per iniziare con successo l'utilizzo dell'ImmoApp. Per qualsiasi domanda, non esiti a contattarci in qualsiasi momento",
  'INTRO_PDF.SEE_VIDEO': 'Per una panoramica molto breve, vi invitiamo a guardare anche il nostro video informativo di un minuto e mezzo',
  'INTRO_PDF.DISCOVER_IMMOAPP': "Scopri l'ImmoApp",
  'INTRO_PDF.DOWNLOAD_IMMOAPP': 'Scarica e lancia ImmoApp',
  'INTRO_PDF.LINKS.INTRO': "È possibile utilizzare l'ImmoApp tramite i seguenti link",
  'INTRO_PDF.LINKS.IOS': 'Per iOS',
  'INTRO_PDF.LINKS.ANDROID': 'Per Android',
  'INTRO_PDF.LINKS.BROWSER': 'Per Web / Browser',
  'INTRO_PDF.START_LOGIN': "Avvia l'ImmoApp e accedi con le credenziali che ti abbiamo inviato via e-mail.",
  'INTRO_PDF.HOME.TITLE': 'Schermata iniziale',
  'INTRO_PDF.HOME.OVERVIEW': "Una volta avviata l'ImmoApp, verrà visualizzata la schermata iniziale con le seguenti sezioni",
  'INTRO_PDF.HOME.A.TEXT': "A) L'area iniziale mostra l'immagine del vostro profilo sulla sinistra, nonché un testo informativo e un'immagine per uno dei vostri immobili.",
  'INTRO_PDF.HOME.A.CLICK1': 'Facendo clic sul pulsante bianco rotondo si accede a una panoramica di tutte le proprietà.',
  'INTRO_PDF.HOME.A.CLICK2': 'Facendo clic sul pulsante "Rapporto sui danni", si apre una nuova finestra per l\'inserimento del rapporto sui danni.',
  'INTRO_PDF.HOME.B.TITLE': 'B) Tutti i messaggi, le voci della bacheca e le segnalazioni di danni, ordinati per mese.',
  'INTRO_PDF.HOME.B.CLICK': 'Facendo clic su una voce si apre una nuova vista con maggiori dettagli.',
  'INTRO_PDF.IMPORTANT_CONTACTS.TITLE': 'Contatti importanti',
  'INTRO_PDF.IMPORTANT_CONTACTS.TEXT': 'Facendo clic sul pulsante bianco rotondo da A) si apre una panoramica di tutti i vostri immobili. Selezionando un immobile, si accede a una pagina di dettaglio in cui sono elencati tutti i contatti importanti.',
  'INTRO_PDF.REPORT.TITLE': 'Vista dettagliata di un report',
  'INTRO_PDF.REPORT.TEXT_1': 'Ora fai clic su una voce dalla schermata iniziale per vedere la vista dettagliata.',
  'INTRO_PDF.REPORT.TEXT_2': "Nell'area superiore è possibile vedere le immagini allegate, se presenti. Scorrendo a sinistra e a destra, è possibile visualizzare altre immagini.",
  'INTRO_PDF.REPORT.TEXT_3': 'Se si tratta di un ticket, tutti i progressi precedenti vengono visualizzati in una cronologia chiara: dalla creazione al completamento con successo, inquilini e proprietari rimangono sempre ben informati.',
  'INTRO_PDF.REPORT.TEXT_4': "L'area inferiore contiene gli allegati e i commenti aggiunti al messaggio.",
  'INTRO_PDF.REPORT.TEXT_5': 'Se necessario, è possibile modificare o eliminare un commento inviato.',
  'INTRO_PDF.DOCUMENTS.TITLE': 'Documenti',
  'INTRO_PDF.DOCUMENTS.TEXT_1': "È possibile passare ai documenti attraverso la barra di navigazione (in fondo all'ImmoApp).",
  'INTRO_PDF.DOCUMENTS.TEXT_2': 'Se sapete già cosa state cercando, potete inserire il nome del file nella barra di ricerca della pagina iniziale.',
  'INTRO_PDF.DOCUMENTS.TEXT_3': 'Altrimenti, fate clic su una proprietà per navigare nella sua cartella principale. Fare clic sul file system fino a raggiungere il documento desiderato e aprirlo con un clic.',
  'INTRO_PDF.DOCUMENTS.TEXT_4': "Suggerimento: se i documenti sono un po' troppo piccoli per essere letti, è possibile esportarli e visualizzarli su uno schermo più grande.",
  'INTRO_PDF.ADD_REPORT.TITLE': 'Aggiungi messaggio',
  'INTRO_PDF.ADD_REPORT.TEXT_1': "Utilizzando il pulsante rotondo colorato in basso al centro dell'ImmoApp, è possibile accedere a una selezione di rapporti da inviare all'amministrazione.",
  'INTRO_PDF.ADD_REPORT.TEXT_2': 'Selezionando una delle opzioni, si aprirà una nuova finestra in cui è possibile inserire informazioni quali luogo, titolo e descrizione. Inoltre, è possibile aggiungere facoltativamente immagini, video e documenti.',
  'INTRO_PDF.ADD_REPORT.TEXT_3': "In caso di segnalazione di un danno, potete anche specificare se il messaggio deve essere pubblico, e quindi tutti gli inquilini e i proprietari dell'immobile saranno automaticamente avvisati, o se il messaggio sarà visibile solo a voi e all'amministrazione.",
  'INTRO_PDF.VOTES.TITLE': 'Voti',
  'INTRO_PDF.VOTES.A': 'A) Panoramica: Cliccare su un voto.',
  'INTRO_PDF.VOTES.B': 'B) Vista dettagliata: Registrati qui.',
  'INTRO_PDF.VOTES.C': 'C) Codice di accesso: Una volta che la votazione è attiva, è possibile inserire il codice.',
  'INTRO_PDF.VOTES.D': 'D) Ordine del giorno: Panoramica di tutti i punti di votazione.',
  'INTRO_PDF.VOTES.E': "E) Votazioni: Cliccare su ogni voce di voto e selezionare un'opzione.",
  'INTRO_PDF.VOTES.F': 'F) Invia: Una volta selezionato tutto, è possibile inviare il voto.',
  'INTRO_PDF.PROFILE.TITLE': 'Profile',
  'INTRO_PDF.PROFILE.TEXT_1': "Attraverso la barra di navigazione (in fondo all'ImmoApp) è possibile accedere al proprio profilo.",
  'INTRO_PDF.PROFILE.TEXT_2': "Facendo clic sull'immagine rotonda in alto, è possibile aggiungere una foto del profilo al proprio account.",
  'INTRO_PDF.PROFILE.TEXT_3': 'Questa immagine del profilo verrà visualizzata accanto ai commenti creati.',
  'INTRO_PDF.PROFILE.TEXT_4': "Nella schermata del profilo troverete anche molte informazioni utili, come la possibilità di riaccedere a questa guida e i dati di contatto dell'amministrazione.",
  'INTRO_PDF.APP_SETTINGS.TITLE': "Impostazioni dell'app",
  'INTRO_PDF.APP_SETTINGS.TEXT_1': 'Nella schermata "Profilo -> Impostazioni app", è possibile modificare la lingua di ImmoApp e attivare o disattivare le notifiche',
  'INTRO_PDF.APP_SETTINGS.TEXT_2': 'Notifiche push (abilitate per impostazione predefinita).',
  'INTRO_PDF.APP_SETTINGS.TEXT_3': 'Notifiche via e-mail (disattivate per impostazione predefinita).',
  'INTRO_PDF.CONTACT.TITLE': 'Contatto',
  'INTRO_PDF.CONTACT.TEXT_1': 'Ci auguriamo che la presentazione di ImmoApp sia stata di vostro gradimento e che siamo riusciti a suscitare il vostro interesse.',
  'INTRO_PDF.CONTACT.TEXT_2': 'Se avete altre domande, non esitate a contattarci in qualsiasi momento',
  'INTRO_PDF.CONTACT.TEXT_3': 'Cordiali saluti',
  'INTRO_PDF.CONTACT.TEXT_4': 'Il vostro {{administration}}',
  'INTRO_PDF.FOOTER.PAGE': 'Pagina',

  STARTING_ADMINAPP_KEYS: '---',

  'global.interest': 'Interesse',
  'global.inflation': 'Inflazione',
  'global.from': 'da',
  'global.until': 'fino a quando',
  'global.months': 'mesi',
  'global.years': 'anni',
  'global.days': 'giorni',
  'global.hours': 'ore',
  'global.minutes': 'minuti',
  'global.seconds': 'secondi',
  'global.updateData': 'Data di aggiornamento',
  'global.allEntries': 'Tutti i dati.',
  'global.entriesFromTimeLimit': 'Data degli ultimi {time} mesi.',
  'global.search': 'Cercare...',
  'global.loadAll': 'Caricare tutti',
  'global.choices': 'Scelte',
  'global.pleaseWait': 'Per favore aspetta...',
  'global.portal': 'Portale',
  'global.currency': 'CHF',
  'global.dashboard': 'Dashboard',
  'global.flexible': 'Flexible',
  'global.mandate': 'Mandato',
  'global.mandates': 'Mandati',
  'global.property': 'Proprietà',
  'global.properties': 'Proprietà',
  'global.account': 'Conto',
  'global.object': 'Oggetto',
  'global.objects': 'Oggetti',
  'global.apartments': 'Apartamenti',
  'global.residents': 'Residenti',
  'global.message': 'Messaggio',
  'global.messages': 'Messaggi',
  'global.repair': 'Biglietto',
  'global.repairs': 'Biglietti',
  'global.report': 'Rapporto',
  'global.pinboard': 'Bacheca',
  'global.pinboardEntry': 'Voce in bacheca',
  'global.pinboardEntries': 'Voci della bacheca',
  'global.formGuide': 'Assistente di segnalazione',
  'global.user': 'Utente',
  'global.voteChoice': 'Voto',
  'global.voteChoices': 'Voti',
  'global.vote': 'Voto',
  'global.votes': 'Voti',
  'global.survey': 'Sondaggio',
  'global.person': 'Persona',
  'global.persons': 'Persone',
  'global.settings': 'Impostazioni',
  'global.info': 'Info',
  'global.confirm': 'Conferma',
  'global.hide': 'Nascondi',
  'global.hidden': 'Nascosto',
  'global.yes': 'Sì',
  'global.no': 'No',
  'global.undecided': 'Non importa',
  'global.abstain': 'Astenersi',
  'global.none': 'Nessuno',
  'global.ok': 'OK',
  'global.cancel': 'Annullare',
  'global.forward': 'Avanti',
  'global.back': 'Indietro',
  'global.inDelivery': 'In consegna',
  'global.sent': 'Inviato',
  'global.noErrors': 'Senza errori',
  'global.error': 'Errore',
  'global.showAll': 'Mostra tutti',
  'global.userroles': 'Ruoli utente',
  'global.recipientlist': 'Elenco dei destinatari per {messageTypeString}',
  'global.norecipients': 'Nessun destinatario.',
  'global.generalErrorMessage': 'Si è verificato un errore. Riprovare.',
  'global.generalErrorMessageLong': 'Si è verificato un errore imprevisto! Riprovare ad aggiornare la pagina.',
  'global.emailExistsErrorMessage': "Errore: Esiste già un utente con l'indirizzo e-mail specificato.",
  'global.previewemail.success': "L'anteprima dell'e-mail è stata inviata con successo a {email}. Si prega di notare che potrebbero essere necessari fino a 5 minuti prima che l'e-mail venga visualizzata nella casella di posta.",
  'global.previewemail.sending': "L'anteprima dell'e-mail è in corso di invio...",
  'global.previewemail.send': "Inviare l'anteprima via e-mail a {email}",
  'global.previewemail.whoreceives': 'Quali ruoli utente ricevono il messaggio? (Nota: gli amministratori ricevono sempre il messaggio).',
  'global.previewemail.warning': "I seguenti utenti non riceveranno un {messageTypeString} a causa delle loro impostazioni personali dell'app",
  'global.action': 'Azione',
  'global.done': 'Fatto',
  'global.reset': 'Reset',
  'global.synced': 'Sincronizzato',
  'global.missing': 'Mancante',
  'global.infoMissing': 'Informazioni mancanti',
  'global.loading': 'Caricamento...',
  'global.actionInProgress': 'Azione in corso...',
  'global.private': 'Privato',
  'global.active': 'Attivo',
  'global.inactive': 'Inattivo',
  'global.scheduled': 'Programmato',
  'global.completed': 'Completato',
  'global.registered': 'Registrato',
  'global.voting': 'Votazione',
  'global.upload': 'Caricare',
  'global.uploadShort': 'Caricare',
  'global.replace': 'Sostituire',
  'global.save': 'Salvare',
  'global.tenant': 'Inquilino',
  'global.tenants': 'Inquilini',
  'global.tenantRestricted': 'Inquilino limitato',
  'global.owner': 'Proprietario',
  'global.owners': 'Proprietari',
  'global.ownerFkstwe': 'Membri',
  'global.noOwnersFound': 'Nessun proprietario trovato.',
  'global.janitor': 'Inserviente',
  'global.management': 'Gestione',
  'global.manager': 'Gestore',
  'global.admin': 'Gestore (Admin)',
  'global.adminRights': 'Diritti di amministratore',
  'global.name': 'Nome',
  'global.address': 'Indirizzo',
  'global.email': 'E-mail',
  'global.emailAddress': 'Indirizzo e-mail',
  'global.close': 'Chiudere',
  'global.closeWindow': 'Chiudi finestra',
  'global.password': 'Password',
  'global.repeat': 'Ripeti',
  'global.sender': 'Mittente',
  'global.receiver': 'Destinatario',
  'global.noDataFound': 'Nessun dato disponibile',
  'global.noFilesAvailable': 'Nessun file disponibile',
  'global.noFilesUploaded': 'Nessun file caricato',
  'global.data': 'Data',
  'global.import': 'Importazione',
  'global.statistics': 'Statistiche',
  'global.document': 'Documento',
  'global.documents': 'Documenti',
  'global.status': 'Status',
  'global.selectStatus': 'Seleziona stato',
  'global.condition': 'Condizione',
  'global.timeInfix': 'alle',
  'global.timeSuffix': 'ore',
  'global.system': 'Sistema',
  'global.cloudStorage': 'Cloud Storage',
  'global.folder': 'Cartella',
  'global.title': 'Titolo',
  'global.description': 'Descrizione',
  'global.usefulInfosAbbr': 'Info utili',
  'global.usefulInfos': 'Info utili',
  'global.sendDate': 'Data di invio',
  'global.date': 'Data',
  'global.empty': 'Vuoto',
  'global.image': 'Immagine',
  'global.images': 'Immagini',
  'global.content': 'Contenuto',
  'global.file': 'File',
  'global.files': 'File',
  'global.fileName': 'Nome file',
  'global.fileNameRequired': 'Nome file richiesto',
  'global.evaluation': 'Valutazione',
  'global.details': 'Dettagli',
  'global.linkDetailPage': 'Alla pagina dei dettagli',
  'global.copy': 'Copia',
  'global.duplicate': 'Duplicato',
  'global.edit': 'Modifica',
  'global.utilize': 'Utilizza',
  'global.view': 'Visualizza',
  'global.delete': 'Elimina',
  'global.reactivate': 'Riattiva',
  'global.deactivate': 'Disattiva',
  'global.verified': 'Verificato',
  'global.not': 'Non',
  'global.notVerifiedYet': 'Non ancora verificato',
  'global.approve': 'Approve',
  'global.reject': 'Reject',
  'global.push': 'Push',
  'global.news': 'News',
  'global.all': 'All',
  'global.type': 'Tipo',
  'global.city': 'Città',
  'global.id': 'ID',
  'global.add': 'Aggiungere',
  'global.translate': 'Tradurre',
  'global.constructionYear': 'Anno di costruzione',
  'global.public': 'Pubblico',
  'global.publicText': 'Il biglietto deve essere visibile pubblicamente?',
  'global.pushToggle': 'Messaggio push',
  'global.pushText': 'Deve essere inviato un messaggio push? Solo gli utenti che hanno attivato i messaggi push riceveranno il messaggio. (Attivato per impostazione predefinita)',
  'global.defaultActivated': 'Attivato per impostazione predefinita',
  'global.defaultDeactivated': 'Disattivato per impostazione predefinita',
  'global.emailInternalToggle': "Invia un'e-mail agli utenti di ImmoApp",
  'global.emailExternalToggle': 'E-mail agli utenti non ImmoApp',
  'global.emailExternalText': "Tutti gli utenti degli immobili/proprietà selezionati che sono già stati invitati a ImmoApp ma non la stanno ancora utilizzando attivamente devono ricevere un'e-mail con le informazioni contenute in questo messaggio? Nota: all'interno dell'e-mail è presente un link per annullare l'iscrizione a future e-mail.",
  'global.emailToggle': 'E-mail',
  'global.emailInternToggle': 'E-mail interna',
  'global.emailExternToggle': 'E-mail esterna',
  'global.emailText': "Si deve inviare un'e-mail? Solo gli utenti che hanno attivato l'invio di e-mail riceveranno l'e-mail",
  'global.staff': 'Dipendenti',
  'global.category': 'Categoria',
  'global.firstName': 'Nome',
  'global.lastName': 'Cognome',
  'global.dateOfBirth': 'Data di nascita',
  'global.filter': 'Filtro',
  'global.total': 'Totale',
  'global.quantity': 'Quantità',
  'global.complement': 'Complemento',
  'global.lastUpdate': 'Aggiornato',
  'global.update': 'Aggiornamento',
  'global.sort': 'Ordinare',
  'global.updated': 'Aggiornato',
  'global.mode': 'Modo',
  'global.new': 'Nuovo',
  'global.na': 'N/A',
  'global.communication': 'Comunicazione',
  'global.logout': 'Logout',
  'global.enterTitle': 'Inserisci titolo',
  'global.enterMessage': 'Inserisci il messaggio',
  'global.enterDescription': 'Inserisci descrizione',
  'global.enterInfos': 'Inserisci informazioni',
  'global.typeDropdown': 'Seleziona il tipo',
  'global.enterLastnameEmail': 'Inserisci cognome/e-mail',
  'global.receiverDropdown': 'Selezionare il destinatario',
  'global.mandateDropdown': 'Selezionare un mandato',
  'global.propertyDropdown': 'Selezionare una proprietà',
  'global.propertiesDropdown': 'Selezionare una o più proprietà',
  'global.version': 'Versione',
  'global.errorGeneric': 'Il campo è obbligatorio',
  'global.errorTitle': 'Il titolo è obbligatorio',
  'global.errorDescription': 'La descrizione è obbligatoria',
  'global.errorMandateDropdown': 'Il mandato è obbligatorio',
  'global.errorPropertyDropdown': 'La proprietà è obbligatoria',
  'global.deleteConfirmLong': 'Vuoi davvero cancellare questo record?',
  'global.deleteConfirmShort': 'Vuoi davvero cancellare?',
  'global.code': 'Code',
  'global.optional': 'Opzionale',
  'global.fieldRequired': 'Questo campo è obbligatorio',
  'global.selectComponentType': 'Selezionare il tipo di componente',
  'global.numbersOnly': 'Sono ammessi solo numeri',
  'global.max4': 'Sono ammessi al massimo 4 caratteri',
  'global.max10': 'Sono ammessi al massimo 10 caratteri',
  'global.max50': 'Massimo 50 caratteri consentiti',
  'global.min4': 'Sono richiesti almeno 4 caratteri',
  'global.enterProperValue': 'Inserire il valore corretto',
  'global.valueBelow100': 'Inserire un valore inferiore a 100',
  'global.onlyIntegers': 'Inserire solo numeri interi',
  'global.validEmail': 'Inserire un indirizzo e-mail valido',
  'global.passwordCharacterValidation': 'La password deve essere lunga almeno 6 caratteri',
  'global.repeatPasswordMismatch': 'Le password non sono identiche',
  'global.street': 'Strada',
  'global.houseNumber': 'Numero civico',
  'global.street_and_nr': 'Street & house nr',
  'global.zipCode': 'CAP',
  'global.reusableFile': 'File riutilizzabile',
  'global.reusableFiles': 'File riutilizzabili',
  'global.enterSender': 'Inserisci mittente',
  'global.uploadRestrictionsFiles': 'Sono consentiti solo file fino a max. 20 MB',
  'global.uploadRestrictionsImages': 'Sono consentite solo immagini fino a max. 20 MB',
  'global.dateOfIncident': 'Data del danno',
  'global.selectDate': 'Selezionare la data',
  'global.selectTime': 'Seleziona ora',
  'global.errorDate': 'La data è obbligatoria',
  'global.errorUser': 'Il mittente è obbligatorio',
  'global.access': 'Accesso',
  'global.accesses': 'Accessi',
  'global.accessRights': 'Diritti di accesso',
  'global.noAccessForTool': 'Al momento non si ha accesso a questo strumento',
  'global.actionConfirm': "Eseguire l'azione?",
  'global.zip': 'Codice postale',
  'global.withoutAreaCode': 'senza prefisso',
  'global.terminationProcess': 'Processo di terminazione',
  'global.home': 'Casa',
  'global.removedDisallowedCharacter': 'Il carattere speciale non ammesso è stato rimosso',
  'global.automatic': 'Automatico',
  'global.manual': 'Manuale',
  'global.loadingIssue': "C'è un problema di caricamento",
  'global.agendaItem': "Punto all'ordine del giorno",
  'global.phoneNumberShort': 'Numero di telefono',
  'global.phoneNumberLong': 'Numero di telefono',
  'global.extraInfo': 'Informazione extra',
  'global.comment': 'Commento',
  'global.comments': 'Commenti',
  'global.commentsToggleText': 'Consentire i commenti?',
  'global.addComment': 'Aggiungi commento',
  'global.commentAdded': 'Il commento è stato aggiunto con successo',
  'global.moreInfo': 'Ulteriori informazioni',
  'global.fileUploaded': 'Il file è stato caricato con successo',
  'global.filesUploaded': 'I file sono stati caricati con successo',
  'global.filesUpdated': 'I file sono stati aggiornati con successo',
  'global.downloadResults': 'Scaricare i risultati della votazione?',
  'global.download': 'Scaricare',
  'global.downloadAgain': 'Scarica di nuovo',
  'global.downloadSuccess': 'Scaricamento riuscito',
  'global.downloadInProgress': 'Scaricamento in corso',
  'global.role': 'Ruolo',
  'global.selectRole': 'Seleziona ruolo',
  'global.allProperties': 'Tutte le proprietà',
  'global.noObjectConnection': 'Senza connessione agli oggetti',
  'global.dateAndDetails': 'Dato & Dettagli',
  'global.attachment': 'Allegato',
  'global.attachments': 'Allegati',
  'global.handyman': 'Tuttofare',
  'global.notification': 'Notifica',
  'error.page500message': 'Si è verificato un errore sconosciuto. Gli sviluppatori sono stati informati e risolveranno presto il problema. Si prega di riprovare più tardi',
  'menu.manageEmployees': 'Gestisci dipendenti',
  'menu.managePlatform': 'Gestisci piattaforma',
  'business.maxTwoComponents': "La versione demo consente max. 2 componenti dell'edificio",
  'auth.userNotFound': "Non esiste un utente con l'indirizzo e-mail inserito. L'utente potrebbe essere stato cancellato",
  'auth.wrongPassword': 'La password non è valida',
  'auth.invalidPassword': "La password non è valida o l'utente non ha una password",
  'auth.invalidEmail': "L'indirizzo e-mail è formattato in modo errato",
  'auth.unsupportedPersistence': "Il browser non sembra consentire l'accesso ai cookie. Si prega di abilitarli e riprovare",
  'auth.unsupportedApp': "Si prega di passare alla nuova versione del browser della ImmoApp all'indirizzo {webappUrl}",
  'auth.fillFields': "Inserire l'indirizzo e-mail e la password",
  'auth.weakPassword': 'La password deve essere composta da almeno 6 caratteri',
  'auth.emailExistsTenantTitle': "L'inquilino esiste già",
  'auth.emailExistsTenantText': "L'indirizzo e-mail specificato è utilizzato da un inquilino esistente. Si prega di confermare che si desidera avviare un processo di cessazione per questo inquilino.",
  'auth.emailExistsOwnerStaffTitle': "L'utente esiste già",
  'auth.emailExistsOwnerStaffText': "L'indirizzo e-mail specificato è utilizzato da un utente esistente. Poiché questo utente non ha lo stato 'Inquilino', non è possibile avviare un processo di cessazione per questo utente.",
  'auth.emailNotVerifiedTitle': 'Il vostro account non è ancora verificato.',
  'auth.emailNotVerifiedRepeat': ' di nuovo',
  'auth.emailNotVerifiedText': "Ti è stata inviata un'e-mail di verifica{ripetizione}. Si prega di controllare la casella di posta elettronica.",
  'auth.noPermissionsLong': 'Purtroppo non hai i permessi per accedere a questa pagina',
  'auth.noPermissionsShort': 'Autorizzazioni mancanti o insufficienti',
  'auth.invalidZipCode': 'Codice postale non valido',
  'auth.userRegisteredSuccess': "L'utente è stato registrato con successo.",
  'auth.userRegisteredError': "Non è stato possibile registrare l'utente. Riprovare più tardi.",
  'message.email.internal.showlist': "Mostra l'elenco dei destinatari per le e-mail interne",
  'message.email.external.showlist': "Mostra l'elenco dei destinatari per le e-mail esterne",
  'message.notification.showlist': 'Mostra elenco destinatari per notifica push',
  'mandate.pageSubTitle': 'I vostri mandati in sintesi',
  'mandate.pageSearchbox': 'Cerca nei mandati...',
  'mandate.filterButtonThree': 'Area del piano',
  'mandate.filterButtonFour': '< 100,000 CHF',
  'mandate.sublabelMandateStatus': "Solo i mandati attivi sono disponibili nell'ImmoApp e vengono conteggiati nella quota di abbonamento",
  'mandate.sublabelMandateImage': "Caricare qui un'immagine del mandato",
  'mandate.sublabelPinboard': 'È possibile pubblicare nuove voci in bacheca per questo mandato?',
  'mandate.sublabelFormGuide': "È possibile utilizzare l'assistente report per questa proprietà?",
  'mandate.addMandateImage': "Inserire un'immagine valida",
  'mandate.tablecolumnTwo': 'Nome + indirizzo',
  'mandate.StatusFilterNotSync': 'Non sincronizzato',
  'mandate.deleted': 'Il mandato è stato cancellato con successo',
  'mandate.updated': 'Il mandato è stato aggiornato con successo',
  'mandate.facility': 'Struttura',
  'mandate.form.error': 'Si prega di selezionare almeno un mandato o una struttura',
  'ticket.repair': 'Rapporto del danno',
  'ticket.keyorder': 'Ordine di chiavi o carte',
  'ticket.nameplate': 'Ordine: targhetta con il nome',
  'ticket.document': 'Ordine: documento o modulo',
  'ticket.other': 'Altro messaggio',
  'page.signInRememberMe': 'Rimani connesso',
  'page.signInButton': 'Login',
  'page.signInForgotPass': 'Hai dimenticato la password',
  'repairs.contactInfo.noEmail': 'Email non disponibile',
  'repairs.contactInfo.noPhone': 'Telefono non disponibile',
  'signintools.accesses': 'Accessi',
  'signintools.adminAccessStaff': 'Accesso amministrativo',
  'signintools.adminAccessImmoMove': 'Accesso per il processo di terminazione',
  'signintools.signIn': 'Accedi',
  'signintools.registerQuestion': 'Non hai ancora un conto?',
  'signintools.register': 'Registrazione',
  'signintools.registerAction': 'Registro',
  'signintools.registerActionNow': 'Registrati ora',
  'signintools.registerSuccessTitle': 'Registrazione riuscita!',
  'signintools.registerSuccessTextMain': "Grazie per la registrazione. Confermare l'indirizzo e-mail. Le è stata inviata un'e-mail di verifica.",
  'signintools.registerSuccessTextConditional': "Nota: l'amministrazione verificherà il vostro account prima che possiate accedervi. Sarete informati dell'approvazione tramite e-mail.",
  'signintools.registerErrorTitle': 'Registrazione fallita!',
  'signintools.registerErrorTextEmailExists': "L'indirizzo e-mail inserito è già in uso. Si prega di riprovare. Se avete bisogno di aiuto, contattate l'amministrazione.",
  'signintools.registerErrorTextGeneral': "Si prega di riprovare. Se l'errore persiste, contattare l'amministrazione.",
  'signintools.forgotPassword': 'Hai dimenticato la password?',
  'signintools.forgotLogin': 'Hai dimenticato i tuoi dati di accesso?',
  'signintools.legal': 'Legale',
  'signintools.immoDigi': 'ImmoDigi AG',
  'signintools.contact': 'Contatto',
  'signintools.faq': 'FAQs',
  'signintools.termsOfUse': 'Termini di utilizzo',
  'signintools.website': 'Sito web',
  'signintools.moreAboutImmoDigi': 'Ulteriori informazioni su ImmoDigi',
  'signintools.moreAboutImmoApp': 'Ulteriori informazioni su ImmoApp',
  'signintools.imprint': 'Impronta',
  'signintools.dataProtection': 'DataProtection',
  'signintools.accessCode': 'Codice di accesso',
  'signintools.wrongInput': 'Indirizzo e-mail non corretto o codice postale non corretto',
  'signintools.resetPasswordTitle': 'Reset password',
  'signintools.resetPasswordText': 'Se confermate con OK, vi verranno inviate via e-mail le istruzioni per modificare la password.',
  'signintools.resendCodeText': 'Se si conferma con OK, il codice di accesso verrà nuovamente inviato via e-mail.',
  'themeSwitcher.settings': 'Impostazioni',
  'topbar.help': 'Aiuto',
  'dashboard.pageTitle1': 'I tuoi mandati',
  'dashboard.pageTitle2': 'Tutte le novità',
  'dashboard.pageTitle3': 'Panoramica dello stato',
  'dashboard.importantContacts': 'Contatti importanti',
  'dashboard.actionEmail': "Invia un'e-mail",
  'dashboard.actionPhone': 'Chiama',
  'dashboard.repairsPending': '{repairs} in attesa',
  'dashboard.repairsInProgress': '{repairs} in corso',
  'dashboard.repairsCompleted': '{repairs} completate',
  'dashboard.noContactsAvailable': 'Nessun contatto disponibile.',
  'dashboard.entriesFromTimeLimit': 'Sono state prese in considerazione le voci degli ultimi {time} mesi.',
  'dashboard.allEntriesTaken': 'Tutte le voci prese in considerazione.',
  'mandateDetails.pageSearchbox': 'Cerca tra gli utenti...',
  'mandateDetails.cardTitleOne': 'Informazioni generali',
  'mandateDetails.cardSubTitleThree': 'Statistiche',
  'mandateDetails.updateImage': 'Aggiorna immagine',
  'person.pageSubTitle': 'Elenco di tutti gli utenti',
  'person.pageSearchbox': 'Cerca tra gli utenti...',
  'person.addButton': 'Aggiungi voce',
  'person.addPerson': 'Aggiungi persona',
  'person.addNewPerson': 'Aggiungi nuova persona',
  'person.updatePerson': 'Aggiorna persona',
  'person.companyName': "Nome dell'azienda",
  'person.addMandateImage': "Inserisci un'immagine valida",
  'person.addNomeAzienda': "Inserire il nome dell'azienda",
  'person.addOwnerName': 'Inserire il nome del proprietario',
  'person.userData': 'Dati utente',
  'person.staffData': 'Dati del personale',
  'person.importedData': 'Dati importati',
  'person.emailExternal': 'E-mail esterna',
  'person.company': 'Compagnia',
  'person.phoneNumber': 'Numero di telefono',
  'person.mobile': 'Telefono privato',
  'person.phoneHome': 'Telefono fisso',
  'person.phoneWork': 'Telefono di lavoro',
  'person.fax': 'Fax',
  'person.city': 'Città',
  'person.salutation': 'Titolo',
  'person.street': 'Strada',
  'person.website': 'Sito web',
  'person.deleted': 'Utente cancellato',
  'person.edited': 'Utente aggiornato',
  'person.emailAndVerified': 'Email + verifica',
  'person.showContactTitle': 'Mostrare come contatto importante?',
  'person.showContactText': 'Il dipendente viene visualizzato nella ImmoApp e nella WebApp come contatto per tutti gli immobili assegnati.',
  'person.userRestrictedTitle': 'Limitare la comunicazione?',
  'person.userRestrictedText': "Se attivato, all'utente non è consentito pubblicare messaggi o commenti.",
  'person.isContact': 'Elencato come contatto',
  'person.isNotContact': 'Non elencato come contatto',
  'upload.size': 'Dimensione',
  'upload.inprogress': 'Il caricamento è in corso...',
  'upload.progress': 'Avanzamento del caricamento',
  'upload.errorFileCount': 'Troppi file selezionati.',
  'upload.errorFileSize': 'La dimensione del file non deve superare i 20 MB.',
  'upload.errorFileType': 'Il formato del file non è supportato.',
  'upload.errorFileTypeImages': 'Il file deve essere del tipo: .jpg, .jpeg, .png',
  'upload.delete': 'Cancellare il file?',
  'repair.externalTitle': 'Biglietto inviato esternamente',
  'repair.externalDescription': 'Visibile solo agli amministratori finché non viene assegnata una proprietà.',
  'repair.moreTypesInImmoApp': 'Se è richiesto un tipo diverso, inserisci il biglietto in {immoappLink}.',
  'repair.pageSubTitle': 'Riparazioni attualmente in corso',
  'repair.pageSearchbox': 'Cerca nelle riparazioni...',
  'repair.addButton': 'Aggiungi riparazione',
  'repair.updateRepair': 'Aggiorna riparazione',
  'repair.addRepair': 'Aggiungi riparazione',
  'repair.viewRepair': 'Riparazione (modalità lettura)',
  'repair.addFileError': 'Il file selezionato non è valido.',
  'repair.tablecolumnOne': 'Rapporto',
  'repair.statusZero': 'In attesa',
  'repair.statusOne': 'In corso',
  'repair.statusTwo': 'Completato',
  'repair.selectHandyman': 'Seleziona artigiani',
  'repair.handymanHireTitle': 'Assumi artigiani',
  'repair.handymanNotificationText': "Il tuttofare deve essere avvisato dell'assegnazione del compito tramite email e push?",
  'repair.handymanIndividualMessage': "Messaggio individuale per l'artigiano",
  'repair.handymanMessageNote': "Nota: l'e-mail contiene automaticamente tutte le informazioni esistenti sul ticket (titolo, descrizione, destinatario, stato, mittente, file, ecc).",
  'repair.handymanAddStatusText': 'Vuoi aggiungere anche uno stato al ticket?',
  'repair.errorHandymanDropdown': 'È richiesto un artigiano',
  'repair.errorStatusDropdown': 'Lo stato è obbligatorio',
  'repair.addressProvided': 'Indirizzo ricevuto',
  'repair.deleted': 'La riparazione è stata eliminata con successo.',
  'repair.updated': 'La riparazione è stata aggiornata con successo.',
  'repair.yarowa_removed': 'Il processo Yarowa è stato rimosso con successo.',
  'repair.locationDetails': 'Ulteriori dettagli sulla posizione (opzionale)',
  'repair.moreInformation': 'Maggiori informazioni',
  'repair.enterLocationDetails': 'Inserire i dettagli (optional)',
  'repair.timelineMeta': 'Da {name} a {date}',
  'repair.timelineUpdated': 'La timeline è stata aggiornata con successo.',
  'repair.inserted': 'La riparazione è stata aggiunta con successo.',
  'repair.pushTextPrivateCreate': 'Se attivato, verrà inviato un messaggio push a tutti i dipendenti responsabili.',
  'repair.emailTextPrivateCreate': "Se attivato, verrà inviata un'e-mail a tutti i dipendenti responsabili.",
  'repair.pushTextPrivateUpdate': "Se attivato, un messaggio push verrà inviato a tutti i dipendenti responsabili e all'utente che ha inviato il messaggio.",
  'repair.emailTextPrivateUpdate': "Se attivato, verrà inviata un'e-mail a tutti i dipendenti responsabili e all'utente che ha inviato la segnalazione.",
  'repair.history': 'Storia',
  'repair.addStatus': 'Aggiungi stato',
  'repair.updateStatus': 'Aggiorna stato',
  'repair.withoutMandate': 'Senza mandato assegnato',
  'repair.withoutMandatePropertyInfo': 'Seleziona prima una proprietà',
  'repair.withoutProperty': 'Senza proprietà assegnata',
  'repair.withoutSender': 'Senza mittente assegnato',
  'repair.submittedContactDetails': 'Dettagli di contatto inviati',
  'repair.addHandymanInfo': 'Puoi assegnare un artigiano sotto le azioni.',
  'repair.contactInfo.noInfo': 'Nessun dettaglio di contatto disponibile.',
  'repair.contactInfo.emailSubject': 'Rapporto di riparazione',
  'messages.errorsFor': 'Errori per',
  'messages.pageSubTitle': 'I tuoi messaggi in sintesi',
  'messages.pageSearchbox': 'Cerca nei messaggi...',
  'messages.addButton': 'Aggiungi messaggio',
  'messages.addMessage': 'Aggiungi messaggio',
  'messages.updateMessage': 'Aggiorna messaggio',
  'messages.viewMessage': 'Messaggio (modalità di lettura)',
  'messages.addMessageImage': "Inserisci un'immagine valida",
  'messages.allMandates': 'Tutti i mandati',
  'messages.pushStatusDefault': '[no status]',
  'messages.pushStatusNoPush': 'Nessun messaggio push',
  'messages.pushStatusSendingOut': 'Invio...',
  'messages.pushStatusSentOut': 'Inviato',
  'messages.propertyNotice': 'Nota: I messaggi alle proprietà possono essere visti da tutti i residenti di un mandato. Tuttavia, solo i residenti della proprietà saranno avvisati tramite notifica push.',
  'messages.errorSelectValue': 'Selezionare un valore',
  'messages.errorDate': 'Selezionare una data',
  'messages.errorCategory': 'La categoria è obbligatoria',
  'messages.fileProgress': 'I file vengono caricati...',
  'messages.previewEmailSuccess': "L'e-mail è stata inviata con successo.",
  'messages.inserted': 'Il messaggio è stato aggiunto con successo.',
  'messages.updated': 'Il messaggio è stato aggiornato con successo.',
  'messages.deleted': 'Il messaggio è stato eliminato con successo.',
  'messages.entriesFromTimeLimit': 'Prossimi & inserimenti degli ultimi {time} mesi',
  'documents.noticeHeader': 'Un utente ha accesso a questa cartella se si applica almeno una condizione:',
  'documents.noticeBullet1': "Il ruolo dell'utente fa parte del campo 'Gruppi di utenti'.",
  'documents.noticeBullet2': "L'utente è stato aggiunto al campo 'Utenti individuali'.",
  'documents.noticeBullet3': "All'utente sono stati concessi i diritti di accesso alla cartella tramite importazione.",
  'documents.noticeBullet4': "L'utente ha il ruolo 'Admin'.",
  'documents.noticeBullet4abaimmo': "L'utente ha il ruolo 'Manager' o 'Admin'",
  'documents.noticeReset': 'Nota: se si rimuovono tutti i gruppi di utenti, la cartella viene ripristinata con i diritti di accesso predefiniti.',
  'documents.accessGroups': 'Gruppi di utenti',
  'documents.accessUser': 'Utenti individuali',
  'documents.accessUserImport': 'Utenti importati',
  'documents.selectGroup': 'Seleziona gruppo di utenti (opzionale)',
  'documents.selectUser': 'Seleziona utente (opzionale)',
  'documents.noImportedUsers': 'Nessun utente importato',
  'documents.accessRightsUpdated': 'I diritti di accesso sono stati aggiornati con successo.',
  'documents.mandate': 'Mandato',
  'documents.property': 'Proprietà',
  'documents.object': 'Oggetto',
  'documents.person': ' Persona',
  'settings.tabCorporateIdentity': 'Identità aziendale',
  'settings.tabInfocenter': 'Centro informazioni',
  'settings.tabImmoApp': 'ImmoApp',
  'settings.tabImmoMove': 'ImmoMove',
  'settings.newManuallyRegisteredUsers': 'Nuovi utenti registrati manualmente',
  'settings.erpConnection': 'ERP connessione',
  'settings.staffExisting': ' Esistente',
  'settings.changeLogo': 'Cambia logo',
  'settings.selectLogo': 'Seleziona logo',
  'settings.syncmeTitle': 'Sincronizzazione SyncMe',
  'settings.defaultAccessGroupNotice': 'Quali gruppi di utenti devono avere accesso alle cartelle per impostazione predefinita?',
  'settings.syncmeText': 'Si sta utilizzando il software "SyncMe" per sincronizzare i file con l\'ImmoApp.',
  'settings.documentsSync': 'Sincronizzazione dei documenti',
  'settings.documentsSyncButtonText': 'Sincronizza ora',
  'settings.documentsSynced': 'Sincronizzazione avviata con successo.',
  'settings.documentsSyncing': 'La sincronizzazione sta iniziando...',
  'settings.documentsAlreadySyncing': 'La sincronizzazione è già iniziata. Si prega di pazientare un attimo.',
  'settings.dataReset': 'Azzeramento dei dati',
  'settings.dataResetButtonText': 'Reset now',
  'settings.dataResetting': 'I dati si stanno resettando...',
  'settings.dataResetted': 'I dati sono stati resettati con successo.',
  'settings.colorUpdate': "Cambia il colore dell'accento",
  'settings.colorUpdateTextCurrent': 'Colore corrente',
  'settings.colorUpdateTextNew': 'Nuovo colore',
  'settings.colorUpdateButtonText': 'Cambia colore',
  'settings.colorUpdated': "Il colore dell'accento è stato aggiornato con successo.",
  'settings.inviteEmailTitle': "Test dell'e-mail di invito",
  'settings.inviteEmailDescription': "Per verificare l'email di invito, comprese le istruzioni per inquilini, proprietari, custodi e gestori, è possibile inviare qui un'email di prova. Attenzione: l'invio troppo frequente della stessa e-mail allo stesso indirizzo può causare un ritardo fino a 15 minuti.",
  'settings.exampleMandateTitle': 'Esempio di mandato',
  'settings.exampleMandateDescription': "Con il mandato di esempio è possibile testare le funzioni dell'ImmoApp senza modificare i dati reali o inviare accidentalmente e-mail agli utenti. Il mandato di esempio riceve l'ID 9999 e i proprietari di esempio gli ID da 99900 a 99999.",
  'settings.exampleMandateCreate': 'Aggiungi',
  'settings.exampleMandateReset': 'Azzeramento dati',
  'settings.exampleMandateDelete': 'Elimina',
  'settings.exampleMandateCreateSuccess': 'Il mandato di esempio è stato aggiunto con successo.',
  'settings.exampleMandateResetSuccess': 'Il mandato di esempio è stato resettato con successo.',
  'settings.exampleMandateDeleteSuccess': 'Il mandato di esempio è stato cancellato con successo.',
  'settings.inviteEmailButton': "Invia un'e-mail di prova",
  'settings.inviteEmailSuccess': "L'e-mail è stata inviata con successo.",
  'settings.createUsersTitle': 'Crea conti',
  'settings.createUsersButton': 'Mostra conti',
  'settings.createUsersText': "Crea un account ImmoApp per tutte le persone importate e invia un'e-mail di invito. Nella prima fase vengono visualizzati gli account applicabili per tutti i mandati selezionati. Questi account saranno creati solo dopo una nuova conferma. Nota: è possibile selezionare solo i mandati attivi.",
  'settings.language.invitation': "Lingua dell'invito",
  'settings.language.all': "Lingua dell'invito e di ImmoApp",
  'settings.dataLastUpdatedOn': 'Ultimo aggiornamento',
  'settings.defaultFolderAccess': 'Accesso predefinito alle cartelle',
  'settings.createUsersNotificationEmptyTitle': 'Nota',
  'settings.createUsersNotificationEmptyText': 'Tutte le persone importate hanno già un account.',
  'settings.createUsersNotificationConfirmTitle': 'Conferma',
  'settings.createUsersNotificationConfirmText': "Se confermate, verrà creato un nuovo account per i seguenti utenti {personCountTotal} e verrà inviata un'e-mail di invito.",
  'settings.createUsersNoInviteText': 'I seguenti utenti {personCountTotal} non riceveranno un invito.',
  'settings.createUsersNotificationConfirmNotice': "Nota: un totale di {personCountTotal} utenti importati non ha ancora un account. Tuttavia, il numero massimo per un'importazione è di 100 utenti. Ripetere la procedura finché tutti gli utenti non sono stati importati con successo.",
  'settings.createUsersNotificationWarningTitle': 'Avviso',
  'settings.createUsersNotificationWarningText': 'Attivare l\'opzione "Crea utenti automaticamente" prima di avviare l\'importazione.',
  'settings.createUsersInProgress': "Tutti gli utenti riceveranno un account e un'e-mail di invito con i dati di accesso. Questo processo potrebbe richiedere alcuni minuti, si prega di essere pazienti.",
  'settings.createUsersSuccess': "Tutti gli utenti sono stati creati con successo e hanno ricevuto un'e-mail di invito.",
  'settings.logoUpdated': 'Logo aggiornato',
  'settings.filesCommonUpdated': 'I file sono stati aggiornati con successo.',
  'settings.dataCommonUpdated': 'I dati sono stati aggiornati con successo',
  'settings.settingsFieldUpdated': "L'impostazione è stata aggiornata con successo.",
  'settings.inviteCodeForImmoApp': 'Codice invito per ImmoApp',
  'settings.autoPermitUsers': 'Attivare automaticamente gli utenti?',
  'settings.autoPermitUsersExtra': "Se attivato, gli utenti vengono approvati automaticamente per l'ImmoApp dopo la registrazione manuale se esistono già nel sistema ERP.",
  'settings.autoInviteUsers': 'Creare utenti automaticamente?',
  'settings.autoInviteUsersExtra': "Se attivato, per tutte le persone importate viene creato automaticamente un account nell'ImmoApp e viene inviata un'e-mail di invito (se non già inviata). Massimo {count} di nuovi conti al giorno. Per l'attivazione iniziale, contattare ImmoDigi.",
  'settings.autoDeactivateUsers': 'Disattivare automaticamente gli utenti?',
  'settings.autoDeactivateUsersExtra': 'Se attivato, un account utente viene disattivato automaticamente se non esiste più nel sistema ERP.',
  'settings.staffInviteMailTitle': 'Invia automaticamente i dati di accesso',
  'settings.staffInviteMailText': 'Il nuovo dipendente deve ricevere automaticamente i dati di accesso via e-mail?',
  'settings.systemEmails': 'Inviare e-mail agli utenti di ImmoApp?',
  'settings.systemEmailsExtra': 'Attenzione: se si disattiva questa opzione, non verranno più inviati inviti automatici, promemoria, codici di voto o e-mail simili',
  'settings.systemPush': 'Inviare messaggi push agli utenti di ImmoApp?',
  'settings.systemPushExtra': 'Attenzione: se si disattiva questa opzione, le notifiche push automatiche per i nuovi utenti, i messaggi, le notifiche di riparazione o simili non saranno più inviate.',
  'settings.newDocumentsPush': 'Notifica i nuovi documenti aggiunti una volta al giorno alle {time} tramite messaggio push?',
  'account.notifications': 'Notifiche',
  'account.language': 'Lingua',
  'account.loginAndSecurity': 'Login & Sicurezza',
  'account.communicationEmailAdminMessageTitle': ' E-mail: Messaggi',
  'account.communicationEmailAdminMessageDescription': "Ricevere un'e-mail per i nuovi messaggi?",
  'account.communicationEmailAdminRepairTitle': 'E-mail: Nuovi biglietti',
  'account.communicationEmailAdminRepairDescription': "Ricevere un'e-mail per i nuovi biglietti?",
  'account.communicationEmailAdminRepairUpdateTitle': 'Email: Biglietti aggiornati',
  'account.communicationEmailAdminRepairUpdateDescription': "Ricevi un'e-mail con il nuovo stato del ticket?",
  'account.communicationEmailAdminPinboardTitle': 'E-mail: Voci in bacheca',
  'account.communicationEmailAdminPinboardDescription': "Ricevere un'email per i nuovi inserimenti in bacheca?",
  'account.communicationEmailAdminCommentTitle': ' E-mail: Commenti',
  'account.communicationEmailAdminCommentDescription': "Ricevere un'e-mail per i nuovi commenti ai propri post?",
  'account.communicationEmailAdminVoteItemTitle': "E-mail: Votazione punto all'ordine del giorno (solo per i dipendenti)",
  'account.communicationEmailAdminVoteItemDescription': "Ricevere un'e-mail per i nuovi punti all'ordine del giorno?",
  'account.changeEmail': 'Cambia email',
  'account.newEmail': 'Nuovo indirizzo e-mail',
  'account.changePassword': 'Cambia password',
  'account.currentPassword': 'Password attuale',
  'account.newPassword': 'Nuova password',
  'account.modify': 'Modifica',
  'account.updateNotification': 'Aggiorna notifiche',
  'account.notificationSettingsUpdated': 'Impostazioni di notifica aggiornate',
  'account.passwordChanged': 'La password è stata aggiornata con successo.',
  'account.emailChanged': "L'indirizzo e-mail è stato aggiornato con successo.",
  'pdf.successfullyDownloaded': 'Il PDF richiesto è stato generato e scaricato con successo.',
  'pdf.errorParameters': "I dati trasferiti non sono validi. Si prega di richiamare nuovamente il link dall'e-mail.",
  'pdf.openInBrowser': 'Apri direttamente nel browser',
  'switcher.changeLanguage': 'Cambia lingua',
  'staff.addStaff': 'Aggiungi personale',
  'staff.addStaffToMandate': 'Seleziona un membro del personale...',
  'staff.errorStaffDropdown': 'Si prega di selezionare un valore',
  'staff.added': 'Il membro del personale è stato aggiunto con successo.',
  'staff.updated': 'Il membro del personale è stato aggiornato con successo.',
  'staff.deleted': 'Il membro del personale è stato cancellato con successo.',
  'staff.updateStaff': 'Aggiornamento di un membro del personale',
  'staff.viewStaff': 'Membro dello staff (modalità di lettura)',
  'staff.confirmDelete': 'Vuoi davvero cancellare questo dipendente?',
  'user.dataNotAutomaticallySynced': 'Alcuni dati non vengono sincronizzati automaticamente dal sistema ERP.',
  'user.approveUser': 'Approva utente',
  'user.viewUser': 'Utente (modalità di lettura)',
  'user.deleted': "L'utente è stato cancellato con successo.",
  'user.deactivated': "L'utente è stato disattivato con successo.",
  'user.reactivated': "L'utente è stato riattivato con successo.",
  'user.edited': "L'utente è stato aggiornato con successo.",
  'user.updateUser': 'Aggiorna utente',
  'user.confirmDelete': 'Vuoi davvero cancellare questo utente?',
  'user.confirmDeactivate': 'Vuoi davvero disattivare questo utente?',
  'user.confirmReactivate': 'Vuoi davvero riattivare questo utente?',
  'user.confirmReject': 'Vuoi davvero rifiutare questo utente?',
  'user.resentAccess': 'Reinvia i dati di accesso',
  'user.resentAccessNotice': "La nuova password è stata inviata con successo a {email}. Se avete domande o problemi di accesso, potete condividere la password visualizzata sopra direttamente con l'utente.",
  'user.resentAccessConfirm': "Se confermi, <strong>{user_name} ({user_email})</strong> ti verrà inviata un'altra email di invito con una nuova password generata in modo casuale.",
  'user.resentAccessSuccess': 'I dati di accesso sono stati inviati con successo.',
  'user.lastLogin': 'Ultimo accesso',
  'user.dataRefreshOnceADay': 'I dati vengono aggiornati una volta al giorno',
  'user.invitedOrRegistered': "L'utente è stato invitato o si è registrato manualmente.",
  'user.invited': 'Invitato',
  'user.invitedBy': 'Invitato da',
  'user.login': 'Login',
  'user.didLogin': "Ha già effettuato l'accesso",
  'user.didNeverLogin': "Non ha ancora effettuato l'accesso",
  'users.withoutERP': 'Utente senza connessione ERP',
  'users.withERP': 'Utente con connessione ERP',
  'users.deactivated': 'Utenti disattivati',
  'users.connectWithERPInfo': "I seguenti utenti ImmoApp non sono attualmente collegati a un account ERP. Per poter utilizzare tutte le funzionalità di ImmoApp, si consiglia di creare una connessione per il maggior numero possibile di utenti. Questa operazione viene eseguita automaticamente (una volta al giorno) se l'indirizzo e-mail dell'utente ImmoApp corrisponde a quello di un account ERP. (Il vostro sistema ERP attuale è {erpSystem}.)",
  'source.immoapp_register': 'Registrato in ImmoApp',
  'source.webapp_register': 'Registrato in WebApp',
  'source.immoapp_tenant': 'Invitato dal proprietario',
  'source.immotop_system': 'Invitato dal sistema',
  'source.webapp_admin': 'Invitato dal dipendente',
  'source.webapp_immomove': 'ImmoMove',
  'footer.allRightsReserved': 'Tutti i diritti riservati.',
  'notification.FormSubmit': 'Modulo inviato',
  'notification.MessageForm': 'I dati del modulo di messaggio sono stati inviati con successo',
  'pagination.of': 'di',
  'pagination.results': 'risultati',
  'filter.before': 'prima',
  'filter.equal': 'uguale',
  'filter.after': 'dopo',
  'filter.less': 'meno',
  'filter.more': 'più',
  'filter.submitButton': 'Applica',
  'filter.selectValue': 'Seleziona valore',
  'filter.selectType': 'Seleziona tipo',
  'filter.verification': 'Verifica',
  'immovable.deleting': 'Immobilie wird gelöscht...',
  'immovable.copying': 'Immobilie wird kopiert...',
  'immovable.pageSubTitle': 'Alle Immobilien im Überlick',
  'immovable.insuranceValue': 'Versicherungswert',
  'immovable.constructionYear': 'Baujahr',
  'immovable.newImmovable': 'Neue Immobilie',
  'immovable.newAccount': 'Neues Konto',
  'immovable.updateAccount': 'Konto aktualisieren',
  'immovable.copyAccount': 'Konto kopieren',
  'immovable.viewAccount': 'Konto anzeigen',
  'immovable.bankTitle': 'Bankkonten',
  'immovable.pickExistingImmovable': 'Eine vorhandene Immobilie auswählen?',
  'immovable.objectCategories': 'Immobilienkategorie',
  'immovable.selectImmovableType': 'Kategorie',
  'immovable.added': 'Immobilie wurde erfolgreich hinzugefügt.',
  'immovable.groundArea': 'Grundstücksfläche',
  'immovable.cubicGvz': 'Kubatur GVZ',
  'immovable.renewalFund': 'Erneuerungsfonds',
  'immovable.calculated': 'Berechnet',
  'immovable.entered': 'Eingegeben',
  'immovable.currentStatus': 'Aktueller Stand',
  'immovable.accountBalanceOptimal': 'Kontostand Optimal',
  'immovable.accountBalanceCurrent': 'Kontostand Aktuell',
  'immovable.depositCurrent': 'Einlage Aktuell',
  'immovable.depositOptimal': 'Einlage Optimal',
  'immovable.depositOptimal.sublabel1': 'Ohne Zins und Teuerung, bis',
  'immovable.depositOptimal.sublabel2': 'Ohne Zins und Teuerung, ab',
  'immovable.depositRegulations': 'Einlage gemäss Reglement',
  'immovable.minimumThreshold': 'Sockelbetrag',
  'immovable.yearlyCorrectionAmount': 'Jährlicher Korrekturbetrag',
  'immovable.depositForThisYearReceived': 'Diesjährige Einlage bereits enthalten',
  'immovable.regulationsGvz': 'Reglement von GVZ (in %)',
  'immovable.interest': 'Zins',
  'immovable.inflation': 'Teuerung',
  'immovable.interestAndRisingPrices': 'Zins & Teuerung',
  'immovable.forecastRenewalFund': 'Erneuerungsfonds Prognose',
  'immovable.numbersRenewalFund': 'Erneuerungsfonds Kennzahlen',
  'immovable.communityComponents': 'Gemeinschaftliche Bauteile',
  'immovable.component': 'Bauteil',
  'immovable.components': 'Bauteile',
  'immovable.replaceCost': 'Erneuerungskosten',
  'immovable.remainServiceTime': 'Restnutzungszeit',
  'immovable.accountBalance': 'Kontostand',
  'immovable.accountName': 'Kontoname',
  'immovable.bankBalance': 'Kontostand',
  'immovable.iban': 'IBAN',
  'immovable.bankName': 'Bankname',
  'immovable.newComponent': 'Neues Bauteil',
  'immovable.updateComponent': 'Bauteil aktualisieren',
  'immovable.copyComponent': 'Bauteil kopieren',
  'immovable.addComponentLabel': 'Bitte die Daten des neuen Bauteils eingeben. (Dies kann alles zu einem späteren Zeitpunkt flexibel angepasst werden.)',
  'immovable.componentName': 'Name des Bauteils',
  'immovable.componentCalculationBase': 'Berechnungsbasis',
  'immovable.componentBase': 'Basis',
  'immovable.componentFactor': 'Faktor',
  'immovable.componentRenewalDate': 'Erneuerungsjahr',
  'immovable.componentRenovationDate': 'Baujahr / Letzte Erneuerung',
  'immovable.higherRenewalDate': 'Das Erneuerungsjahr sollte über dem aktuellen Jahr liegen',
  'immovable.lowerRenovationDate': 'Das Baujahr sollte unter dem Erneuerungsdatum liegen',
  'immovable.lastUpdated': 'Daten zuletzt aktualisiert vor: ',
  'immovable.componentAdded': 'Bauteil wurde erfolgreich hinzugefügt.',
  'immovable.componentCopied': 'Bauteil wurde erfolgreich kopiert.',
  'immovable.bankAccountAdded': 'Konto wurde erfolgreich hinzugefügt.',
  'immovable.bankAccountCopied': 'Konto wurde erfolgreich kopiert.',
  'immovable.bankAccountUpdated': 'Konto wurde erfolgreich aktualisiert.',
  'immovable.year': 'Jahr',
  'immovable.years': 'Jahre',
  'immovable.noDescription': '[Keine Beschreibung vorhanden]',
  'immovable.generateReport': 'Bericht erzeugen',
  'immovable.reportInclInterestAndInflation': 'Soll der Bericht Zins und Teuerung enthalten?',
  'immovable.reportInclCompImages': 'Soll der Bericht Bilder der Bauteile enthalten?',
  'immovable.reportSelectChoiceDescription': 'Bitte wählen sie die gewünschten Teile für den Bericht aus',
  'immovable.reportSelectChoice': 'Teile auswählen',
  'immovable.selectChoiceRequired': 'Wählen Sie die Teile aus',
  'immovable.progressNotificationTitle': 'Export Fortschritt',
  'immovable.progressNotificationFailedTitle': 'Export fehlgeschlagen',
  'immovable.progressNotificationDescription': 'Der Bericht wird erstellt. Dies kann einige Sekunden dauern, bitte haben Sie etwas Geduld.',
  'immovable.progressNotificationFailedDescription': 'Bitte versuchen Sie es erneut.',
  'immovable.pdfSizeErrorTitle': 'Fehler: PDF-Bericht zu groß',
  'immovable.pdfSizeErrorDescription': 'Der Bericht ist größer als 10 MB und konnte daher nicht heruntergeladen werden. Bitte entfernen Sie ein paar Bilder oder wählen Sie weniger Gebäudeteile für den Bericht aus.',
  'immovable.duplicate': 'Möchten Sie den Datensatz wirklich duplizieren?',
  'immovable.duplicated': 'Immobilie wurde erfolgreich kopiert.',
  'immovable.buildingKeyFigures': 'Gebäude Kennzahlen',
  'immovable.bankDeleted': 'Konto wurde erfolgreich gelöscht.',
  'immovable.infoTextGraph': '<strong>EF Aktuell:</strong>\nDiese Linie zeigt den aktuellen Stand Ihres Erneuerungsfonds und die Entwicklung in den kommenden Jahren, basierend auf der aktuellen Einlage die Sie jedes Jahr von den Eigentümern erhalten.\n\n<strong>EF Optimal:</strong>\nDiese Linie zeigt den optimalen Stand Ihres Erneuerungsfonds, falls Sie Ihre aktuelle Einlage durch die berechnete optimale Einlage ersetzen.\n\n<strong>EF Perfekt:</strong>\nDiese Linie zeigt den perfekten Stand Ihres Erneuerungsfonds, beginnend mit dem perfekten Kontostand und der optimalen Einlage. Dieser Linie endet mit dem Korrekturzeitraum, da ab diesem Zeitpunkt der optimale EF dem perfekten EF entspricht.\n\n<strong>Korrekturzeitraum:</strong>\nSollte Ihr aktueller Kontostand größer oder kleiner als der optimale Kontostand sein, ergibt sich eine Anpassung über die Anfangsjahre basierend auf dem eingestellten jährlichen Korrekturbetrag, bis der optimale Stand des Erneuerungsfonds erreicht ist.',
  'immovable.infoTextNumbersCalculated': '<strong>Optimaler Stand EF:</strong>\nDer Kontostand den Ihr Erneuerungsfonds zum jetztigen Zeitpunkt haben sollte, um nie unter den eingegebenen Sockelbetrag zu fallen.\n\n<strong>Einlage Optimal:</strong>\nDie benötigte jährliche optimale Einlage um nie unter den eingegebenen Sockelbetrag zu fallen. Die Werte sind unterteilt in vor und nach Korrekturzeitraum.\n\n<strong>Einlage gemäss Reglement:</strong>\nDie jährliche Einlage die nach den gesetzlichen Auflagen anfallen würde.',
  'immovable.infoTextNumbersEntered': '<strong>Einlage Aktuell:</strong>\nDer jährliche Einlagenbetrag, den Sie aktuell von Ihren Eigentümern erhalten.\n\n<strong>Sockelbetrag:</strong>\nDer minimale Kontostand unter den Ihr Erneuerungsfonds nie fallen soll.\n\n<strong>Jährlicher Korrekturbetrag:</strong>\nSollte Ihr aktueller Kontostand größer oder kleiner als der optimale Kontostand sein, ergibt sich eine Anpassung über die Anfangsjahre basierend auf dem eingestellten jährlichen Korrekturbetrag, bis der optimale Stand des Erneuerungsfonds erreicht ist.',
  'immovable.condominium': 'Eigentumswohnung',
  'immovable.tower': 'Hochhaus',
  'immovable.house': 'Einfamilienhaus',
  'immovable.stweg': 'Stockwerkeigentumsgemeinschaft',
  'immovable.joint_ownership': 'Miteigentum',
  'component.elevator_stops': 'Aufzug-Stopps',
  'component.electricity_usage': 'Elektrizitätsnutzung',
  'component.quantity': 'Anzahl',
  'component.insurance_value': 'Versicherungswert',
  'component.offer': 'Pauschal (Offerte)',
  'component.estimate': 'Kostenschätzung',
  'component.sqmeter': 'm2-Preis',
  'ifwizard.all_available_components': 'Alle verfügbaren Bauteile',
  'ifwizard.chosen_components': 'Ausgewählte Bauteile',
  'ifwizard.infoTextChosenComponents': 'Die hier aufgeführten Bauteile dienen als Vorlage um Ihnen die Arbeit zu erleichtern. Sobald die Immobilie angelegt wurde, können Sie alle Details der Bauteile anpassen und auch komplett neue Bauteile hinzufügen.',
  'ifwizard.component.type.facade': 'Fassade',
  'ifwizard.component.type.building_isolation': 'Gebäudeisolation',
  'ifwizard.component.type.roof': 'Dach',
  'ifwizard.component.type.electrical_installations': 'Elektroinstallationen',
  'ifwizard.component.type.sanitary_lines': 'Steigleitungen / Sanitärleitungen',
  'ifwizard.component.type.heater': 'Heizung',
  'ifwizard.component.type.windows': 'Fenster (in gemeinschaftl. Räumen)',
  'ifwizard.component.type.stockroom': 'Lagerraum',
  'ifwizard.component.type.common_room': 'Gemeinschaftsraum',
  'ifwizard.component.type.visitor_parking': 'Besucherparkplätze',
  'ifwizard.component.type.shelter': 'Schutzraum',
  'ifwizard.component.type.driveway': 'Zufahrtsweg',
  'ifwizard.component.type.elevator': 'Lift',
  'ifwizard.component.type.bicycle_space': 'Veloabstellplatz',
  'ifwizard.component.type.paintwork': 'Malerarbeiten',
  'ifwizard.component.type.hot_water_boiler': 'Warmwasserboiler',
  'ifwizard.component.type.solar_collector': 'Solaranlage',
  'ifwizard.component.type.water_softening_system': 'Wasserenthärtungsanlage',
  'ifwizard.component.type.washing_machine_dryer': 'Waschmaschine und Trockner',
  'ifwizard.component.type.playground': 'Spielplatz',
  'ifwizard.component.type.winter_garden': 'Wintergarten',
  'ifwizard.component.type.balcony': 'Balkon',
  'ifwizard.component.type.flooring': 'Bodenbelag',
  'ifwizard.component.type.doors': 'Türen',
  'ifwizard.component.type.fence_and_wall': 'Zaun und Mauer',
  'ifwizard.component.type.ventilation_ducts': 'Lüftungskanäle',
  'ifwizard.component.type.slat_blinds': 'Lamellenstoren',
  'ifwizard.component.type.terrace_floor': 'Terrassenboden',
  'ifwizard.component.type.garden': 'Garten',
  'ifwizard.component.type.fireplace': 'Cheminée',
  'ifwizard.component.subtype.small': 'Klein',
  'ifwizard.component.subtype.medium': 'Mittel',
  'ifwizard.component.subtype.big': 'Groß',
  'ifwizard.component.subtype.minor': 'Geringfügig',
  'ifwizard.component.subtype.extensive': 'Großflächig',
  'ifwizard.component.subtype.wood': 'Holz',
  'ifwizard.component.subtype.plastic': 'Kunststoff',
  'ifwizard.component.subtype.low_quality': 'Geringe Qualität',
  'ifwizard.component.subtype.mid_quality': 'Mittlere Qualität',
  'ifwizard.component.subtype.high_quality': 'Hohe Qualität',
  'ifwizard.component.subtype.metal': 'Metall',
  'ifwizard.component.subtype.aluminium': 'Stahl/Aluminium',
  'ifwizard.component.subtype.laminate': 'Laminat',
  'ifwizard.component.subtype.pvc': 'PVC',
  'ifwizard.component.subtype.tiling': 'Platten',
  'ifwizard.component.subtype.parquet': 'Parkett',
  'ifwizard.component.subtype.burner': 'Brenner',
  'ifwizard.component.subtype.heat_pumps': 'Wärmepumpen',
  'ifwizard.component.subtype.steel_boiler': 'Stahlheizkessel',
  'ifwizard.component.subtype.underfloor_heating': 'Fussbodenheizung',
  'ifwizard.component.subtype.radiators': 'Heizkörper/Radiatoren',
  'ifwizard.component.subtype.flat_with_grit': 'Flachdach mit Kies',
  'ifwizard.component.subtype.pitched_with_bricks': 'Schrägdach mit Ziegeln',
  'ifwizard.component.subtype.plastered': 'Verputzt',
  'ifwizard.component.subtype.painted': 'Gestrichen',
  'ifwizard.component.subtype.styrofoam': 'Styropor',
  'ifwizard.component.subtype.mineral_wool_insulation': 'Mineralwolldämmplatten',
  'votes.pageSubTitle': 'Elenco di tutti i voti',
  'votes.begin': 'Inizio',
  'votes.end': 'Fine',
  'votes.addButton': 'Aggiungi voto',
  'votes.add': 'Aggiungi voto',
  'votes.view': 'Vota (modalità lettura)',
  'votes.subtasks.vote': 'Voci di voto',
  'votes.subtasks.survey': 'Voci del sondaggio',
  'votes.task': 'Elemento_',
  'votes.noSubtask.vote': 'Nessun elemento di voto.',
  'votes.noSubtask.survey': 'Nessun elemento del sondaggio.',
  'votes.itemError': "Errore durante l'inserimento",
  'votes.dateStart.vote': 'Inizio della votazione',
  'votes.dateStart.survey': 'Inizio del sondaggio',
  'votes.dateEnd.vote': 'Fine della votazione',
  'votes.dateEnd.survey': 'Fine del sondaggio',
  'votes.taskFileRequired': 'Selezionare i file corretti',
  'votes.errorDateStart': 'La data di inizio è obbligatoria',
  'votes.errorDateEnd': 'È obbligatoria la data di fine',
  'votes.inserted': 'Il voto è stato aggiunto con successo.',
  'votes.copied': 'Il voto è stato copiato con successo.',
  'votes.selectFiles': 'Seleziona i file',
  'votes.submittedItems': "Voci dell'ordine del giorno presentate",
  'votes.charts': 'Statistiche',
  'votes.updated': 'La votazione è stata aggiornata con successo.',
  'votes.updateData': 'Aggiornare tutti i dati (come se venisse creata una nuova votazione)?',
  'votes.participantsUpdated': 'I partecipanti sono stati aggiornati con successo.',
  'votes.participantUpdated': 'Il partecipante è stato aggiornato con successo.',
  'votes.pleaseEnterNumber': 'Si prega di inserire un numero.',
  'votes.updateVote.vote': 'Aggiorna il voto',
  'votes.updateVote.survey': 'Aggiorna sondaggio',
  'votes.deleted': 'Il voto è stato cancellato con successo.',
  'votes.choice': 'Selezione',
  'votes.considerForResults': 'Considera per i risultati',
  'votes.consideredYes': 'Considerato',
  'votes.consideredPending': 'Appena presentati',
  'votes.consideredNo': 'Non considerato',
  'votes.exportPartecipanti': "Esporta l'elenco dei partecipanti in formato PDF",
  'votes.startEditAllFields': 'Modifica tutti i campi',
  'votes.endEditAllFields': 'Esci dalla modalità di modifica',
  'votes.voteResults': 'Risultati',
  'votes.withoutResults': 'Votazione in sospeso',
  'votes.activeParticipants': 'Partecipanti attivi',
  'votes.allOwnersAndQuotas': 'Elenco dei partecipanti e delle quote di valore',
  'votes.allOwnersAndQuotasFkstwe': 'Elenco dei partecipanti e delle quote valore',
  'votes.valueQuotaPersistentNotice': 'Nota: le quote di valore inserite vengono prese in considerazione automaticamente anche per le votazioni future.',
  'votes.relationshipId': 'Numero proprietario',
  'votes.relationshipIdFkstwe': 'Numero membro',
  'votes.relationshipIds': 'Numeri del proprietario',
  'votes.relationshipIdsFkstwe': 'Numeri membri',
  'votes.relationshipIdAbbr': 'No',
  'votes.addObject': 'Aggiungi oggetto',
  'votes.relationshipIdAlreadyInUse': 'Il numero del proprietario è già in uso.',
  'votes.considerVoteUserWithoutValidRelId': "Per poter considerare questo voto per i risultati, è necessario prima assegnare un numero di proprietario valido. Per farlo, cliccare sull'icona di modifica a destra.",
  'votes.warrant': 'Procura',
  'votes.type.subLabel': "Inserisci nel campo sottostante il cognome o l'indirizzo email del destinatario della procura. Il sistema verifica quindi se nella proprietà esiste un proprietario con questi dati.",
  'votes.manuallyAdded': 'Aggiunto manualmente',
  'votes.manuallyAdd': 'Aggiungi manualmente',
  'votes.manuallyEditVoteUser': 'Aggiorna partecipante',
  'votes.manuallyAddVoteUser': 'Aggiungi partecipante manualmente',
  'votes.manuallyAddVoteUser.label': 'Cosa desidera registrare?',
  'votes.manuallyAddVoteUser.sublabel': 'Si prega di selezionare prima un numero di proprietario.',
  'votes.manuallyAddVoteUser.optionA': 'Voto',
  'votes.manuallyAddVoteUser.optionB': 'Procura',
  'votes.manuallyAddParticipant': 'Aggiungi partecipante manualmente',
  'votes.voteManuallyAddedSuccess': 'Il voto è stato aggiunto con successo.',
  'votes.countNoticeSingular': 'Il voto di {vote_count} non sarà considerato',
  'votes.countNoticePlural': '{vote_count} i voti non saranno presi in considerazione',
  'votes.participantsEligible': 'Partecipanti eleggibili',
  'votes.participantsEligibleLong': 'Comunità proprietarie',
  'votes.participantsEligibleLongFkstwe': "Membri dell'azienda",
  'votes.participantsMore': 'Altri partecipanti',
  'votes.chooseOrAddOwnerTitle': 'Proprietario',
  'votes.chooseOrAddOwnerText': 'Selezionare un proprietario esistente o crearne uno nuovo.',
  'votes.chooseOrAddQuorumText': 'Predefinito o individuale?',
  'votes.quorumsPredefined': 'Quorum predefiniti',
  'votes.predefined': 'Predefiniti',
  'votes.individual': 'Individuale',
  'votes.majority': 'Maggioranza',
  'votes.majorityAt': 'Maggioranza da {value}',
  'votes.majorityPercVariable': 'maggioranza {perc}',
  'votes.inclAllTrue': 'Tutti i proprietari',
  'votes.inclAllFalse': 'Solo i presenti',
  'votes.inclQuotaTrue': 'Con valore quota',
  'votes.inclQuotaFalse': 'Senza quota valore',
  'votes.inclAbstTrue': 'Con astensioni',
  'votes.inclAbstFalse': 'Senza astensioni',
  'votes.inclTieTrue': 'Accettato in caso di parità',
  'votes.inclTieFalse': 'Rifiutato in caso di parità',
  'votes.filterProperty': 'Filtra per proprietà',
  'votes.quorumMajorityPercentage': 'Maggioranza richiesta in percentuale',
  'votes.quorumInclTieTitle': 'Pareggio',
  'votes.quorumInclTieText': "In caso di parità (ad esempio 10 SI e 10 NO con una maggioranza del 50%), il punto all'ordine del giorno è accettato?",
  'votes.quorumInclAbstTitle': 'Astensioni',
  'votes.quorumInclAbstText': 'Le astensioni contano come NO?',
  'votes.quorumInclAllTitle': 'Tutti i proprietari',
  'votes.quorumInclAllText': "Includere tutti i proprietari dell'immobile o solo quelli presenti?",
  'votes.quorumInclQuotaTitle': 'Valore quota',
  'votes.quorumInclQuotaText': 'Considerare la quota valore per i risultati?',
  'votes.quorumMinParticipantsTitle': 'Numero minimo di partecipanti',
  'votes.quorumMinParticipantsText': 'Qual è il numero minimo di voti che devono essere espressi?',
  'votes.selectUser': 'Seleziona utente',
  'votes.participantsMoreNotice1': 'È possibile aggiungere o rimuovere voti per la valutazione utilizzando la spunta a sinistra.',
  'votes.participantsMoreNotice2': 'Nota: gli utenti senza una connessione ERP attiva non sono inizialmente inclusi automaticamente nei risultati.',
  'votes.resultDownloadError': 'Errore interno. Si prega di riprovare.',
  'votes.userStatusUpdated': "Lo stato dell'utente è stato aggiornato con successo.",
  'votes.voteDeleted': 'Il voto è stato cancellato con successo.',
  'votes.registeredAndEmailSent': 'Codice di accesso inviato',
  'votes.pageSearchbox': 'Cerca nei voti...',
  'votes.votingPossibilityInfo': "Nota: la partecipazione alla votazione è possibile solo nella ImmoApp, non tramite l'area di amministrazione.",
  'votes.planning': 'Pianificazione',
  'votes.beforehand': 'In anticipo',
  'votes.live': 'In diretta',
  'votes.online': 'Online',
  'votes.onlineOnly': 'Solo online',
  'votes.onlineAndLive': 'Voti online + in diretta',
  'votes.liveOnly': 'Solo in diretta',
  'votes.changeToLive.vote': 'Passaggio al voto in diretta',
  'votes.changeToLive.survey': 'Cambia in un sondaggio in diretta',
  'votes.changeToLiveConfirm.vote': 'Se si conferma, la votazione online viene conclusa e viene avviata la votazione in diretta. Non è possibile annullare questa operazione.',
  'votes.changeToLiveConfirm.survey': 'Se si conferma, il sondaggio online viene terminato e viene avviato il sondaggio in diretto. Questa operazione non può essere annullata.',
  'votes.finishEarly.vote': 'Termina il voto in anticipo',
  'votes.finishEarly.survey': 'Termina il sondaggio in anticipo',
  'votes.finishConfirm.vote': 'Sei sicuro di voler terminare la votazione?',
  'votes.finishConfirm.survey': 'Sei sicuro di voler terminare il sondaggio?',
  'votes.buttonProtocol': 'Protocollo',
  'votes.confirmProtocolFileType': 'Selezionare il tipo di file desiderato.',
  'votes.pdfResults': 'Tutte le schede elettorali',
  'votes.participatingProperties': 'Proprietà partecipanti',
  'votes.editPropertiesInfo': "Qui è possibile selezionare le proprietà che possono partecipare per il punto all'ordine del giorno.",
  'votes.valueQuota': 'Quota valore',
  'votes.yes': 'Sì',
  'votes.no': 'No',
  'votes.abstain': 'Astenersi',
  'votes.notActiveYet': 'Non ancora attivo',
  'votes.active.vote': 'Votazione in corso...',
  'votes.active.survey': 'Sondaggio in corso...',
  'votes.completed': 'Completato',
  'votes.liveNowNoticeTitle.vote': 'Il voto in diretta è attivo',
  'votes.liveNowNoticeTitle.survey': 'Il sondaggio in diretta è attivo',
  'votes.liveFutureNoticeTitle.vote': 'La votazione in diretta è programmata',
  'votes.liveFutureNoticeTitle.survey': 'È previsto un sondaggio in diretta',
  'votes.beforehandNowNoticeTitle.vote': 'Il voto online è attivo',
  'votes.beforehandNowNoticeTitle.survey': 'Il sondaggio online è attivo',
  'votes.beforehandFutureNoticeTitle.vote': 'La votazione online è prevista',
  'votes.beforehandFutureNoticeTitle.survey': 'È previsto un sondaggio online',
  'votes.onlineNowNoticeTitle.vote': 'Il voto online è attivo',
  'votes.onlineNowNoticeTitle.survey': 'Il sondaggio online è attivo',
  'votes.onlineFutureNoticeTitle.vote': 'La votazione online è prevista',
  'votes.onlineFutureNoticeTitle.survey': 'È previsto un sondaggio online',
  'votes.finishedNoticeTitle.vote': 'La votazione è terminata.',
  'votes.finishedNoticeTitle.survey': 'Il sondaggio è terminato.',
  'votes.activeNoticeStart.vote': 'La votazione inizierà come previsto il giorno {date_start}.',
  'votes.activeNoticeStart.survey': 'Il sondaggio inizierà come previsto il giorno {date_start}.',
  'votes.finishNoticeText': "Nota: l'ImmoApp vi supporta con una valutazione standardizzata dei risultati delle votazioni. Si prega di controllare attentamente l'accuratezza dei risultati.",
  'votes.liveVoteResultsInclude': 'I risultati tengono conto di tutti i voti con lo stato "Completato" e "Voto" (in diretta).',
  'votes.onlineVoteResultsInclude': 'I risultati tengono conto di tutte le votazioni con lo stato  "Completato".',
  'votes.activeNoticeEnd.vote': 'La votazione termina come previsto il giorno {date_end}.',
  'votes.activeNoticeEnd.survey': 'Il sondaggio termina come previsto il {date_end}.',
  'votes.pastNoticeEnd.vote': 'La votazione è terminata il {date_end}.',
  'votes.pastNoticeEnd.survey': 'Il sondaggio si è concluso il {date_end}.',
  'votes.liveNoticeCode.vote': 'Il codice di invito per la votazione in diretta è',
  'votes.liveNoticeCode.survey': 'Il codice di invito per il sondaggio dal vivo è',
  'votes.mode': 'Modo',
  'votes.modePlaceholder': 'Selezionare la modalità di voto',
  'votes.itemActiveTitle': "Voce dell'ordine del giorno attiva",
  'votes.itemActiveDescription': "Se si chiude il punto all'ordine del giorno, i proprietari non possono più esprimere un voto.",
  'votes.newFilesNotice': 'Nota: i nuovi file possono essere aggiunti alla votazione tramite la modifica.',
  'votes.reactivateVoteItem': "Riattiva il punto all'ordine del giorno",
  'votes.updateVoteItem': "Aggiorna l'ordine del giorno",
  'votes.finishVoteItem': "Termina punto all'ordine del giorno",
  'votes.viewVoteItem': "Punto all'ordine del giorno (modalità lettura)",
  'votes.agendaItemUpdated': "Il punto all'ordine del giorno è stato aggiornato con successo.",
  'votes.agendaItemModalHeader': 'Punto all\'ordine del giorno  "{title}"',
  'votes.agendaItemModalNoticeHeader': "Selezionare una votazione a cui aggiungere il punto all'ordine del giorno.",
  'votes.agendaItemModalNoticeHeaderOLD': "Non appena si aggiunge l'elemento dell'ordine del giorno a una votazione, il proprietario riceve un'e-mail che indica per quale votazione è utilizzato l'elemento dell'ordine del giorno.",
  'votes.quorum': 'Quorum',
  'votes.quorumDropdown': 'Selezionare il quorum',
  'votes.simpleMajorityNoAbstention': "Maggioranza semplice (l'astensione non viene conteggiata)",
  'votes.simpleMajorityWithAbstention': "Maggioranza semplice (l'astensione conta come no)",
  'votes.qualifiedMajorityNoAbstention': "Maggioranza qualificata (l'astensione non viene conteggiata)",
  'votes.qualifiedMajorityWithAbstention': "Maggioranza qualificata (l'astensione conta come no)",
  'votes.simpleTotalMajority': 'Maggioranza semplice di tutti i proprietari',
  'votes.qualifiedTotalMajority': 'Maggioranza qualificata di tutti i proprietari',
  'votes.unanimityAttende': 'Unanimità dei presenti',
  'votes.unanimityTotal': 'Unanimità di tutti i proprietari',
  'votes.accepted': 'Accettato',
  'votes.rejected': 'Rifiutato',
  'votes.paused': 'In pausa',
  'votes.noValidQuorum': 'Non quorum',
  'votes.validQuorum': 'Quorum',
  'votes.regulation': 'Regolamento',
  'votes.voteCountRule': 'Regolamento dei voti',
  'votes.voteCountDropdown': 'Selezionare il regolamento di voto',
  'votes.countQuota': 'Quota di voto',
  'votes.countQuotaAbbr': 'SQ',
  'votes.valueQuotaAbbr': 'WQ',
  'votes.valueHeadCountAbbr': 'KS',
  'votes.valueObjectCountAbbr': 'OS',
  'votes.voteCount': 'Numero di voti',
  'votes.objectName': "Nome dell'oggetto",
  'votes.headCount': 'Conteggio dei presenti',
  'votes.objectCount': 'Conteggio oggetti',
  'voteResults.quorum': 'Quorum',
  'votes.setAvailable': 'Activate',
  'votes.setPaused': 'Pause',
  'votes.setCompleted': 'Finish',
  'votes.signature.label': 'Firma obbligatoria',
  'votes.signature.description': "I partecipanti devono apporre una firma all'interno dell'ImmoApp prima di inviare i risultati? (Non si applica alle votazioni in diretta)",
  'votes.visibility.label': "Il voto è visibile prima dell'inizio?",
  'votes.visibility.description': "Il voto deve essere visibile nell'ImmoApp prima che inizi?",
  'votes.notification.sublabel': 'I proprietari devono essere informati tramite notifica push non appena inizia la votazione?',
  'votes.errorSelectAllItems': 'Si prega di selezionare prima tutti gli elementi di voto.',
  'votes.errorSelectWarrantReceiver': 'Si prega di selezionare prima il destinatario di una procura.',
  'voteResults.sublabelUserStatus': "Cambia lo stato dell'utente",

  'survey.online': 'Online',
  'survey.selectChoices': 'Specifica le scelte',

  'component.calculationBasis': 'Berechnungsgrundlage',
  'component.description': 'Bezeichnung',
  'component.value': 'Wert',
  'component.assessment': 'Einschätzung',
  'component.broken': 'Defekt',
  'component.badlyDamaged': 'Stark beschädigt',
  'component.noticeableDamaged': 'Merkbar beschädigt',
  'component.slightlyDamaged': 'Leicht beschädigt',
  'component.goodCondition': 'Guter Zustand',
  'component.likeNew': 'Neuwertig',
  'component.amortisation': 'Amortisation',
  'component.remainingUsefulAmount': 'Restnutzungsbetrag',
  'component.usefulLife': 'Nutzungsdauer',
  'component.remainingLife': 'Restnutzungsdauer',
  'component.costOverview': 'Kostenübersicht',
  'component.use': 'Nutzung',
  'component.deposit': 'Einlagen',
  'component.currentCoverageAmount': 'Aktueller Deckungsbetrag',
  'component.openCoverageAmount': 'Offener Deckungsbetrag',
  'component.forAction': 'Handlungsbedarf',
  'component.lifeExpectancy': 'Lebenserwartung',
  'component.untilRenewal': 'Bis zur Erneuerung',
  'component.depositOptimalPerYear': 'Einlage Optimal pro Jahr',
  'component.deleted': 'Bauteil wurde erfolgreich gelöscht.',
  'component.updated': 'Bauteil wurde erfolgreich aktualisiert.',
  'component.sublabelComponentStatus': 'Nur aktive Bauteile werden bei der Berechnung berücksichtigt.',
  'component.statusAutomaticAdjustmentTitle': 'Zustand Automatisierung',
  'component.statusAutomaticAdjustmentDescription': 'Zustand automatisch anpassen über Zeit',
  'image.gridView': 'Grid',
  'image.listView': 'Elenco',
  'pdfexport.overviewImmovable': 'Übersicht - Immobilie',
  'pdfexport.overviewCalculations': 'Übersicht - Berechnungen',
  'pdfexport.overviewComponents': 'Übersicht - Bauteile',
  'immoMoveHome.created': 'Erstellt',
  'immoMoveHome.reminded': 'Erinnert',
  'immoMoveHome.accessed': 'Login',
  'immoMoveHome.answered': 'Antwort',
  'immoMoveHome.archived': 'Archiv',
  'immoMoveHome.createNewTerminations': 'Neue Kündigung',
  'immoMoveHome.updateTerminations': 'Kündigung aktualisieren',
  'immoMoveHome.pageSubTitle': 'Alle Kündigungen im Überlick',
  'immoMoveHome.terminations': 'Kündigungen',
  'immoMoveHome.searchTermination': 'In Kündigungen suchen...',
  'immoMoveHome.rating': 'Bewertung',
  'immoMoveHome.civilstand': 'Zivilstand',
  'immoMoveHome.staff': 'Bewirtschafter/in',
  'immoMoveHome.timeCreated': 'Erstellt am',
  'immoMoveHome.newTerminationAdding': 'Kündigung wird erstellt...',
  'immoMoveHome.newTerminationCreated': 'Kündigung wurde erfolgreich hinzugefügt und der Mieter informiert.',
  'immoMoveHome.terminationUpdated': 'Kündigung wurde erfolgreich aktualisiert.',
  'immoMoveHome.terminationDeleted': 'Kündigung wurde erfolgreich gelöscht.',
  'immoMoveHome.emailExists': 'Die Kündigung mit derselben E-Mail-Adresse ist vorhanden. Möchten Sie frühere Benutzerdetails überschreiben?',
  'newTermination.terminationType': 'Art der Kündigung',
  'newTermination.salutation': 'Anrede',
  'newTermination.mieterFirstName': 'Mieter Vorname',
  'newTermination.mieterLastName': 'Mieter Nachname',
  'newTermination.mieterEmail': 'Mieter E-Mail-Adresse',
  'terminationType.regular': 'Terminliche Kündigung',
  'terminationType.irregular': 'Ausserterminliche Kündigung',
  'salutation.male': 'Herr',
  'salutation.female': 'Frau',
  'pinboard.pageSubTitle': "La tua bacheca a colpo d'occhio",
  'pinboard.pageSearchbox': 'Cerca nella bacheca...',
  'pinboard.updatePinboard': 'Aggiorna voce',
  'pinboard.copyPinboard': 'Copia voce',
  'pinboard.addPinboard': 'Aggiungi voce',
  'pinboard.viewPinboard': 'Voce in bacheca (modalità lettura)',
  'pinboard.inserted': 'La voce della bacheca è stata aggiunta con successo.',
  'pinboard.updated': 'La voce della bacheca è stata aggiornata con successo.',
  'pinboard.deleted': 'La voce della bacheca è stata eliminata con successo.',
  'process.evaluationHouse': 'Casa del feedback',
  'process.evaluationAdmin': 'Amministrazione del feedback',
  'process.resendingAccessCode': 'Il codice di accesso viene inviato...',
  'process.resendAccessCodeConfirm': 'Reinvio del codice di accesso',
  'process.resendAccessCodeSuccess': 'Codice di accesso inviato con successo.',
  'process.resendAccessCodeError': "Errore nell'invio del codice di accesso.",
  'process.resetEmailSuccess': "È stata inviata un'e-mail per reimpostare la password.",
  'process.resetEmailNotFound': "Non è stato trovato alcun utente con l'indirizzo e-mail inserito.",
  'process.resetEmailError': "Errore nell'invio dell'e-mail. Riprovare.",
  'process.overView': 'Panoramica',
  'process.address': 'Indirizzo',
  'process.invitationSentOn': 'Invito inviato il',
  'process.lastUpdatedOn': 'Ultimo aggiornamento il',
  'process.voucherRequestedOn': 'Voucher richiesti il',
  'process.attachments': 'Allegati',
  'process.feedbackManagement': 'Gestione dei feedback',
  'process.noAnswersYet': 'Nessuna risposta ancora ricevuta...',
  'process.questionnaire': 'questionnaire',
  'process.replies': 'Risposte',
  'start.home': 'Terminations',
  'start.weArePleased': 'We are very pleased that you are taking the time to complete the digital termination process.',
  'start.clickToSurvey': 'Start survey',
  'start.continueSurvey': 'Continue survey',
  'start.contactUs': 'I will be happy to answer any questions you may have:',
  'start.greetingsMister': 'Dear Sir',
  'start.greetingsMisses': 'Dear Mrs.',
  'questionnaire. almostDone': 'Quasi finito',
  'questionnaire.completeTheSurvey': "Compila le domande rimanenti {remainingCount} per completare l'indagine.",
  'questionnaire.submitSurveyText': 'Hai risposto a tutte le domande.',
  'questionnaire.questionsAlreadyAnswered': 'Domande già risposte',
  'questionnaire.updateSurvey': 'Aggiorna il questionnaire',
  'questionnaire.submitSurvey': 'Invia il questionnaire',
  'questionnaire.objectQuestions': "Domande sull'appartamento",
  'questionnaire.adminQuestions': "Domande sull'amministrazione",
  'questionnaire.addDate': 'Aggiungi data',
  'questionnaire.addPerson': 'Aggiungi persona',
  'questionnaire.accountOwner': 'Titolare del conto',
  'questionnaire.bankLocation': 'Ubicazione della banca',
  'questionnaire.questionNotAnsweredYet': 'La domanda non ha ancora ricevuto risposta',
  'questionnaire.q1': 'Perché sta dando il preavviso per il suo appartamento?',
  'questionnaire.q1Note': '(Si prega di selezionare tutto ciò che si applica)',
  'questionnaire.q1heading1': 'Motivi privati:',
  'questionnaire.q1heading2': 'Motivi della proprietà:',
  'questionnaire.q1heading3': "Motivi per l'ubicazione:",
  'questionnaire.q1heading4': 'Altri motivi:',
  'questionnaire.professional_change': 'Cambio di lavoro / pendolarismo',
  'questionnaire.need_smaller_apartment': 'Necessità di un appartamento più piccolo',
  'questionnaire.need_larger_apartment': 'Necessità di un appartamento più grande',
  'questionnaire.homestead': 'Casa propria',
  'questionnaire.separation': 'Separazione',
  'questionnaire.moving_in_together': 'Trasferirsi con il (coniuge) partner',
  'questionnaire.children_born': 'prole',
  'questionnaire.death': 'Morte',
  'questionnaire.facilities_dated': 'strutture obsolete',
  'questionnaire.existing_flaw': 'Carenza esistente:',
  'questionnaire.inconvenient_layout': 'Layout scomodo',
  'questionnaire.not_age_appropriate': "Non adatto all'età",
  'questionnaire.facilities_missing': 'Servizi mancanti:',
  'questionnaire.high_house_rent': 'Affitto troppo alto',
  'questionnaire.poor_appearance': 'Aspetto scadente',
  'questionnaire.noise_immission': 'Immissioni di rumore',
  'questionnaire.not_child_friendly': 'Non adatto ai bambini',
  'questionnaire.no_parking': 'Nessun parcheggio',
  'questionnaire.no_visitor_parking': 'Nessun/troppo pochi posti auto per i visitatori',
  'questionnaire.distance_main_roads': 'Lunga distanza da strade/autostrade principali',
  'questionnaire.bad_connection_public_transport': 'Collegamento sfavorevole ai trasporti pubblici',
  'questionnaire.distance_groceries': 'Nessuna struttura commerciale nelle vicinanze',
  'questionnaire.discrepancies_within_neighborhood': "Discrepanze all'interno del quartiere",
  'questionnaire.discrepancies_with_janitor': 'Discrepanze con il custode',
  'questionnaire.discrepancies_with_admin': "Discrepanze con l'amministrazione/proprietario",
  'questionnaire.q2': 'Cosa le è piaciuto particolarmente del suo appartamento?',
  'questionnaire.q2Nota': '(Si prega di selezionare tutto ciò che si applica)',
  'questionnaire.q2heading1': "Nell'oggetto:",
  'questionnaire.q2heading2': 'Nel luogo:',
  'questionnaire.good_facilities': 'Buone strutture',
  'questionnaire.convenient_layout': 'Planimetria pratica',
  'questionnaire.age_appropriate': "Adatto all'età",
  'questionnaire.fair_house_rent': 'Affitto equo',
  'questionnaire.child_friendly': 'A misura di bambino',
  'questionnaire.pleasant_neighborhood': 'Quartiere piacevole',
  'questionnaire.reliable_janitor': 'Portellone affidabile',
  'questionnaire.competent_administration': 'Amministrazione competente',
  'questionnaire.quiet_area': 'Zona residenziale tranquilla',
  'questionnaire.proximity_main_roads': 'Vicinanza alle strade/autostrade principali',
  'questionnaire.good_connection_public_transport': 'Buon collegamento ai trasporti pubblici',
  'questionnaire.proximity_groceries': 'Possibilità di fare acquisti nelle vicinanze',
  'questionnaire.q3': 'Desiderate il nostro aiuto nella ricerca di un nuovo inquilino? Saremo lieti di inserire per voi un annuncio su Internet per un importo forfettario di CHF 100.00 (IVA inclusa) più CHF 8.00 (più IVA) al giorno della pubblicità.',
  'questionnaire.q3o1': 'Sì, Flatfox',
  'questionnaire.q3o2': 'Sì, Flatfox e Homegate',
  'questionnaire.q4': "<span>Dipendiamo dal tuo aiuto per la riaffitto </span>Possiamo fornire il tuo numero di telefono agli interessati per la visione dell'appartamento e, se sì, quale?",
  'questionnaire.q5': 'In alternativa è possibile concordare anche appuntamenti fissi per le visite. Vi preghiamo di darci qualche suggerimento per le date in tempo utile:',
  'questionnaire.q5Note': '(Confermeremo definitivamente le date comunicate in anticipo.)',
  'questionnaire.q6': 'Quando il vostro appartamento potrà essere ispezionato in anticipo dalla direzione per scattare foto e registrare eventuali difetti? Vi preghiamo di darci in tempo qualche suggerimento per le date:',
  'questionnaire.q6Note': '(Confermeremo la data definitiva)',
  'questionnaire.q7': 'Qual è il tuo nuovo indirizzo?',
  'questionnaire.q8': 'Quali sono le tue attuali coordinate bancarie?',
  'questionnaire.q9': "Quali persone sono attualmente registrate nell'appartamento?",
  'questionnaire.q10': 'Qual è il tuo stato civile attuale?',
  'questionnaire.single': 'singolo',
  'questionnaire.married': 'Coniugato/Unione registrata',
  'questionnaire.judicially_separated': 'Separato giudizialmente',
  'questionnaire.divorced': 'Divorziato',
  'questionnaire.widowed': 'Vedova',
  'questionnaire.q11': 'Quale connessione TV/Internet hai utilizzato?',
  'questionnaire.none': 'Nessuno',
  'questionnaire.swisscom': 'Swisscom',
  'questionnaire.upc_cablecom': 'UPC Cablecom',
  'questionnaire.sunrise': 'Sunrise',
  'questionnaire.salt': 'Salt',
  'questionnaire.glattnet': 'Glattnet',
  'questionnaire.gga_maur': 'GGA Maur',
  'questionnaire.lkw': 'LKW',
  'questionnaire.ew_rumlang': 'EW Rümlang',
  'questionnaire.q12': 'Hai già una data preferita per la consegna del tuo appartamento?',
  'questionnaire.q13': 'Sei a conoscenza di eventuali difetti o lavori di riparazione nel tuo appartamento che dovrebbero essere commissionati dalla direzione?',
  'questionnaire.13o1': 'Sì, i seguenti difetti:',
  'questionnaire.13o2': 'Nessun difetto',
  'questionnaire.q14': 'Come ti sei trovato a lavorare con<span> il</span> {administrationName} o con il tuo padrone di casa?',
  'questionnaire.14o1': 'Accessibilità:',
  'questionnaire.14o2': 'Cordialità:',
  'questionnaire.14o3': 'Tempo di risposta:',
  'questionnaire.14o4': 'Competenza professionale:',
  'questionnaire.q15': 'Hai qualche suggerimento per migliorare<span> il</span> {administrationName}?',
  'questionnaire.15o1': 'Sì, i seguenti suggerimenti:',
  'questionnaire.q16': 'Saresti felice di affittare di nuovo un appartamento gestito da {administrationName}?',
  'questionnaire.q17': 'Hai qualche feedback personale per<span> il</span> {administrationName} o il tuo manager responsabile?',
  'questionnaire.17o1': 'Sì, il seguente feedback:',
  'questionario.17o2': 'No',
  'questionnaire.q18': "Se stai cercando un alloggio, saremo felici di aiutarti fornendo al tuo potenziale nuovo proprietario informazioni di riferimento sull'affitto esistente. Per questo abbiamo bisogno del tuo consenso.",
  'questionnaire.18o1': 'Dai il consenso per le informazioni di riferimento',
  'questionnaire.18o1a': 'Consenso prestato per le informazioni di riferimento',
  'questionnaire.18o2': 'No, grazie',
  'questionnaire.nothingKnown': 'Non si sa nulla',
  'questionnaire.notKnownYet': 'Non ancora noto',
  'questionnaire.other': 'altro:',
  'questionnaire.informingLater': 'Le informazioni seguiranno in un secondo momento.',
  'questionnaire.answersUploadedToDatabase': 'Le tue risposte sono state inviate con successo.',
  'questionnaire.answerError': 'Per favore rispondi prima a tutte le domande.',
  'questionnaire.errorPrivacyPolicy': "Leggi e accetta prima l'informativa sulla privacy.",
  'questionnaire.ratingModalTitle': 'Grazie mille',
  'questionnaire.ratingModalBody': 'È fantastico che tu sia soddisfatto di noi! Una valutazione positiva di Google ci renderebbe molto felici.',
  'questionnaire.ratingModalButton': 'Link a Google',
  'questionnaire.bottomPopupText': 'Risposta',
  'end.thankYou': 'Vielen Dank',
  'end.usefulDocumentsText': 'Im unteren Bereich dieser Seite finden Sie einige nützliche Dokumente für Ihren Auszug. Wir empfehlen Ihnen, diese herunterzuladen. Ansonsten können Sie sich jederzeit erneut auf dieser Webseite einloggen und darauf zugreifen.',
  'end.voucherText': 'Als kleines Dankeschön für Ihre Bemühungen bieten wir Ihnen Gutscheine für einen erfolgreichen Umzug.',
  'end.requestVoucherButton': 'Gutscheine anfordern',
  'end.requestVoucherText': 'Möchten Sie die Gutscheine anfordern?',
  'end.remainingAnswers': 'Offene Fragen beantworten ({questionsCount})',
  'end.deferredQuestions': 'Zurückgestellte Fragen beantworten',
  'end.send': 'Senden',
  'end.logoutConfirmText': 'Sind Sie sicher, dass Sie sich abmelden möchten?',
  'end.voucherRequestSending': 'Anfrage wird übermittelt...',
  'end.voucherRequestSent': 'Gutscheine wurden erfolgreich angefordert.',
  'end.answerDeferredQuestions': 'Möchten Sie Ihre noch offenen Fragen jetzt beantworten?',
  'end.allQuestionsAnswered': 'Sie haben alle Fragen beantwortet.',
  'end.timeVoucherRequested': 'Sie haben die Gutscheine am {timeVoucherRequested} angefordert. Ihr Ansprechpartner wird sich in Kürze mit den Gutscheinen bei Ihnen per E-Mail melden.',
  'document.pageSubtitle': 'I vostri documenti in sintesi',
  'document.fileSize': 'Dimensione del file',
  'document.rootFolder': 'Directory principale',
  'document.disabledInParentFolder': 'Disabilitato in una cartella principale',
  'document.inactiveMandate': 'Mandato inattivo - visibile solo agli amministratori',
  'document.personalFolder': 'Cartella personale',
  'statistics.tabImmoApp': 'ImmoApp',
  'statistics.tabImmoMove': 'ImmoMove',
  'statistics.totalCreatedProcesses': 'Processi di terminazione creati',
  'statistics.totalFinishedProcesses': 'Processi di terminazione completati',
  'statistics.rating': '{rating} di 5',
  'statistics.totalRating': 'Ricevuto una valutazione',
  'statistics.totalRatings': '{count} delle valutazioni ricevute',
  'statistics.evaluationAdministration': 'Amministrazione della valutazione',
  'statistics.evaluationObject': 'Oggetto di valutazione',
  'statistics.filterObjects': 'Filtro per indirizzo',
  'statistics.filterStaffMember': 'Filtra per amministratore',
  'statistics.suggestions': 'Suggerimenti per il miglioramento',
  'statistics.feedback': 'Feedback personale',
  'statistics.linkTerminationProcess': 'Al processo di terminazione',
  'statistics.users_30days': 'Utenti (attivi negli ultimi 30 giorni)',
  'statistics.users_365days': 'Utenti (attivi negli ultimi 365 giorni)',
  'statistics.users_total': 'Utenti (totale)',
  'statistics.communication_emails_7days': ' E-mail (negli ultimi 7 giorni)',
  'statistics.communication_push_7days': 'Push (negli ultimi 7 giorni)',
  'statistics.communication_docs_7days': 'Documenti consultati (negli ultimi 7 giorni)',
  'statistics.content_messages_7days': 'Messaggi (negli ultimi 7 giorni)',
  'statistics.content_repairs_7days': 'Rapporti di riparazione (negli ultimi 7 giorni)',
  'statistics.content_pinboards_7days': ' Voci in bacheca (negli ultimi 7 giorni)',
  'statistics.agreeing': "D'accordo",
  'statistics.refusing': 'Rifiuto',
  'statistics.undecided': 'Indeciso',
  'license.limit': 'Quota di abbonamento',
  'license.limitExceededTitle': 'Quota di abbonamento superata',
  'license.limitExceededText1': 'Attualmente si stanno utilizzando {activeObjects} da {allowedObjects} prenotati',
  'license.limitExceededText2': 'Si prega di ridurre il numero di oggetti disattivando le singole proprietà o di contattarci per richiedere un aumento della quota di abbonamento.',

  STARTING_FORMGUIDE_KEYS: '---',

  'FORM.GO_TO_FORM': 'Direttamente al modulo',
  'FORM.REPAIR.TITLE.WHERE': 'Dove deve essere riparato?',
  'FORM.REPAIR.TITLE.WHAT': 'Cosa è difettoso?',
  'FORM.REPAIR.DETAILS.TITLE': 'Determinazione del danno',
  'FORM.REPAIR.APARTMENT': 'Appartamento',
  'FORM.REPAIR.APARTMENT.DESCRIPTION': 'Cucina, bagno, soggiorno',
  'FORM.REPAIR.COMMONSPACE': 'Aree comuni',
  'FORM.REPAIR.COMMONSPACE.DESCRIPTION': 'Ascensore, garage, cantina',
  'FORM.REPAIR.COMMERCIAL': 'Commerciale',
  'FORM.REPAIR.COMMERCIAL.DESCRIPTION': 'Ufficio, negozi, ristorazione',
  'FORM.REPAIR.EXTERIOR': 'Area esterna',
  'FORM.REPAIR.EXTERIOR.DESCRIPTION': 'Aree verdi, facciata',

  'FORM.REPAIR.1X': '1',
  'FORM.REPAIR.2X': '2',
  'FORM.REPAIR.3X': '3',
  'FORM.REPAIR.4X': '4',
  'FORM.REPAIR.5X': '5',
  'FORM.REPAIR.6X': '6',
  'FORM.REPAIR.7X': '7',
  'FORM.REPAIR.8X': '8',
  'FORM.REPAIR.ALL': 'Tutti',
  'FORM.REPAIR.APARTMENT.WHOLE': 'Intero appartamento',
  'FORM.REPAIR.APARTMENTDOOR': "Porta dell'appartamento",
  'FORM.REPAIR.ATTIC': 'Massetto',
  'FORM.REPAIR.ATTICDOOR': 'Porta del massetto',
  'FORM.REPAIR.ATTICLADDER': 'Sportello del massetto, scala',
  'FORM.REPAIR.ATTICLADDER.BROKEN': 'Rotto',
  'FORM.REPAIR.ATTICLADDER.CEILINGFLAP': 'Aletta del soffitto',
  'FORM.REPAIR.ATTICLADDER.CEILINGFLAP.CROOKED': 'Storto, inclinato',
  'FORM.REPAIR.ATTICLADDER.CEILINGFLAP.HINGEDEFECT': 'Cerniere difettose, strappate',
  'FORM.REPAIR.ATTICLADDER.FOLD': 'Alette del massetto, piegate',
  'FORM.REPAIR.ATTICLADDER.FOLD.NOTCLOSES': 'Si chiude male',
  'FORM.REPAIR.ATTICLADDER.FOLD.NOTOPENS': 'Non si apre correttamente',
  'FORM.REPAIR.ATTICLADDER.FOLD.DIFFICULTY': 'Si apre e si chiude con difficoltà',
  'FORM.REPAIR.ATTICLADDER.GRINDS': 'Si sgrana, si ferma',
  'FORM.REPAIR.ATTICLADDER.HANDRAIL': 'Corrimano',
  'FORM.REPAIR.ATTICLADDER.LOCK': 'Blocco, chiusura',
  'FORM.REPAIR.ATTICLADDER.LOCK.NOTCLOSES': 'Non rimane chiuso',
  'FORM.REPAIR.ATTICLADDER.LOCK.TORN': 'Strappato',
  'FORM.REPAIR.ATTICLADDER.LOOSE': 'Allentato, traballante',
  'FORM.REPAIR.ATTICLADDER.STAIRS': 'Scaletta, scale',
  'FORM.REPAIR.ATTICLADDER.STAIRS.ROPETORN': 'Corda metallica rotta',
  'FORM.REPAIR.ATTICLADDER.STAIRS.SPRINGDEFECT': 'Molla difettosa',
  'FORM.REPAIR.ATTICLADDER.STAIRS.STEPLOOSE': 'Gradino allentato, allentato',
  'FORM.REPAIR.ATTICLADDER.STAIRS.TORN': 'Gradino strappato',
  'FORM.REPAIR.BALCONY': 'Balcone',
  'FORM.REPAIR.BALCONY.BALCONYDOOR': 'Balcone, terrazza porta',
  'FORM.REPAIR.BATHROOM': 'Bagno',
  'FORM.REPAIR.BATHROOM.FURNITURE': 'Arredo bagno',
  'FORM.REPAIR.BATHROOM.FURNITURE.BROKEN': 'Rotto, incrinato',
  'FORM.REPAIR.BATHROOM.FURNITURE.CISTERN': 'Cisterna mobili difettosi',
  'FORM.REPAIR.BATHROOM.FURNITURE.EDGE': 'Bordo del lavabo',
  'FORM.REPAIR.BATHROOM.FURNITURE.EDGE.CRACK': 'Crepa',
  'FORM.REPAIR.BATHROOM.FURNITURE.EDGE.FRAYED': 'Sfilacciato',
  'FORM.REPAIR.BATHROOM.FURNITURE.EDGE.PEELING': 'Rivestimento scrostato',
  'FORM.REPAIR.BATHROOM.FURNITURE.LOOSE': 'Allentato, traballante',
  'FORM.REPAIR.BATHROOM.FURNITURE.MIRROR': 'Specchio',
  'FORM.REPAIR.BATHROOM.FURNITURE.MIRROR.FALLEN': 'Caduto',
  'FORM.REPAIR.BATHROOM.FURNITURE.MIRROR.SCRATCHED': 'Graffiato',
  'FORM.REPAIR.BATHROOM.FURNITURE.MIRROR.STAINS': 'Ha macchie, è appannato',
  'FORM.REPAIR.BATHROOM.FURNITURE.MIRRORCABINET': 'Specchio&scivolo;mobile',
  'FORM.REPAIR.BATHROOM.FURNITURE.MIRRORCABINET.LAMPCRACKS': 'Lampada incrinata',
  'FORM.REPAIR.BATHROOM.FURNITURE.MIRRORCABINET.LAMPDEFECT': 'Lampada difettosa',
  'FORM.REPAIR.BATHROOM.FURNITURE.MIRRORCABINET.LIGHTSHIELDDEFECT': 'Schermi luminosi difettosi',
  'FORM.REPAIR.BATHROOM.FURNITURE.MIRRORCABINET.SHELFDEFECT': 'Ripiano difettoso',
  'FORM.REPAIR.BATHROOM.FURNITURE.MIRRORCABINET.SHELFOLDERDEFECT': 'Ripiano difettoso',
  'FORM.REPAIR.BATHROOM.FURNITURE.MIRRORCABINET.SHELFMISSING': 'Mensola mancante',
  'FORM.REPAIR.BATHROOM.FURNITURE.MIRRORCABINET.SOCKETDEFECT': 'Presa difettosa',
  'FORM.REPAIR.BATHROOM.FURNITURE.MIRRORCABINETDOOR': 'Specchio&sciolto;mobile&sciolto;porta',
  'FORM.REPAIR.BATHROOM.FURNITURE.MIRRORCABINETDOOR.HANDLEDEFECT': 'Maniglia difettosa',
  'FORM.REPAIR.BATHROOM.FURNITURE.MIRRORCABINETDOOR.HANGSDOWN': 'Appeso',
  'FORM.REPAIR.BATHROOM.FURNITURE.MIRRORCABINETDOOR.HINGEDEFECT': 'Cerniere difettose, strappate',
  'FORM.REPAIR.BATHROOM.FURNITURE.MIRRORCABINETDOOR.HINGEFALLEN': 'I cardini sono caduti',
  'FORM.REPAIR.BATHROOM.FURNITURE.MIRRORCABINETDOOR.RUBBERMISSING': 'Manca il tampone di gomma',
  'FORM.REPAIR.BATHROOM.FURNITURE.SINK': 'Lavabo furniture',
  'FORM.REPAIR.BATHROOM.FURNITURE.SINK.CHAFINGPOINT': 'Punto di lavaggio',
  'FORM.REPAIR.BATHROOM.FURNITURE.SINK.HOLE': 'Foro',
  'FORM.REPAIR.BATHROOM.FURNITURE.SINK.STAINS': 'Macchie, scolorimento',
  'FORM.REPAIR.BATHROOM.FURNITURE.SWOLLEN': 'Gonfiore',
  'FORM.REPAIR.BATHROOM.TOILET': 'WC, toilette',
  'FORM.REPAIR.BATHROOM.TOILET.BOWL': 'WC bowl',
  'FORM.REPAIR.BATHROOM.TOILET.BOWL.HANDRAILDEFECT': 'Corrimano traballante, strappato',
  'FORM.REPAIR.BATHROOM.BOWL.ROLLHOLDERDEFECT': 'Portarotolo difettoso',
  'FORM.REPAIR.BATHROOM.TOILET.BROKEN': 'Rotto, incrinato',
  'FORM.REPAIR.BATHROOM.TOILET.DRAIN': 'Sciacquone, scarico, ecc',
  'FORM.REPAIR.BATHROOM.TOILET.DRAIN.CLOGGED': 'Intasato',
  'FORM.REPAIR.BATHROOM.TOILET.DRAINSSLOW': 'Scarico lento',
  'FORM.REPAIR.BATHROOM.TOILET.DRAIN.LITTLEPRESSURE': "Poca pressione dell'acqua",
  'FORM.REPAIR.BATHROOM.TOILET.DRAIN.NOWATER': 'Nessuna o poca acqua',
  'FORM.REPAIR.BATHROOM.TOILET.DRAIN.OVERFLOW': 'Scorre troppo',
  'FORM.REPAIR.BATHROOM.TOILET.DRAIN.SEWAGE': 'Acqua sporca',
  'FORM.REPAIR.BATHROOM.TOILET.DRAIN.WATERRINSEDEFECT': "Lo sciacquone dell'acqua è in funzione",
  'FORM.REPAIR.BATHROOM.TOILET.LOOSE': 'Allentato, traballa',
  'FORM.REPAIR.BATHROOM.TOILET.SEAT': 'Sedile del water',
  'FORM.REPAIR.BATHROOM.SHOWER': 'Vasca da bagno, doccia',
  'FORM.REPAIR.BATHROOM.SHOWER.BACKWATER': "L'acqua di scarico fuoriesce dallo scarico",
  'FORM.REPAIR.BATHROOM.SHOWER.BATHTUB': 'Vasca da bagno',
  'FORM.REPAIR.BATHROOM.SHOWER.CHALKY': 'Calcareo',
  'FORM.REPAIR.BATHROOM.SHOWER.CLOGGED': 'Intasato',
  'FORM.REPAIR.BATHROOM.SHOWER.CRACK': 'Crepa',
  'FORM.REPAIR.BATHROOM.SHOWER.CURTAIN': 'Tenda, cabina doccia',
  'FORM.REPAIR.BATHROOM.SHOWER.CURTAIN.RODDEFECT': 'Asta della tenda difettosa',
  'FORM.REPAIR.BATHROOM.SHOWER.CURTAIN.GLIDERDEFECT': 'Tenda a scorrimento difettosa',
  'FORM.REPAIR.BATHROOM.SHOWER.CURTAIN.CUBICLEDEFECT': 'Box doccia difettoso',
  'FORM.REPAIR.BATHROOM.SHOWER.CURTAIN.SEALDEFECT': 'Guarnizione in gomma della porta difettosa',
  'FORM.REPAIR.BATHROOM.SHOWER.DIRTY': 'Sporco',
  'FORM.REPAIR.BATHROOM.SHOWER.FITTINGS': 'Fittings',
  'FORM.REPAIR.BATHROOM.SHOWER.HANDRAIL': 'Corrimano',
  'FORM.REPAIR.BATHROOM.SHOWER.HANDRAIL.BROKEN': 'Rotto, incrinato',
  'FORM.REPAIR.BATHROOM.SHOWER.HANDRAIL.TOINSTALL': 'Da installare',
  'FORM.REPAIR.BATHROOM.SHOWER.HOLE': 'Foro',
  'FORM.REPAIR.BATHROOM.SHOWER.JOINT': 'Giunti',
  'FORM.REPAIR.BATHROOM.SHOWER.JOINT.DISSOLVES': 'Dissolve',
  'FORM.REPAIR.BATHROOM.SHOWER.JOINTDEFECT': 'Giunto in silicone che perde',
  'FORM.REPAIR.BATHROOM.SHOWER.LEAKY': 'Perdita',
  'FORM.REPAIR.BATHROOM.SHOWER.LOOSE': 'Allentato, traballante',
  'FORM.REPAIR.BATHROOM.SHOWER.MOLDY': 'Ammuffito',
  'FORM.REPAIR.BATHROOM.SHOWER.NOISE': 'Gorgoglii/rumori',
  'FORM.REPAIR.BATHROOM.SHOWER.NOTDRAINING': 'Scarso scarico',
  'FORM.REPAIR.BATHROOM.SHOWER.QUIRK': 'Quirk',
  'FORM.REPAIR.BATHROOM.SHOWER.SHOWERHEAD': 'Doccia, tubo flessibile',
  'FORM.REPAIR.BATHROOM.SHOWER.SHOWERHEAD.DRIPS': 'Gocciolamenti',
  'FORM.REPAIR.BATHROOM.SHOWER.SHOWERHEAD.SPLASHES': 'Spruzzi',
  'FORM.REPAIR.BATHROOM.SHOWER.SHOWERTRAY': 'Piatto doccia',
  'FORM.REPAIR.BATHROOM.SHOWER.SMELLS': 'Puzza',
  'FORM.REPAIR.BATHROOM.SHOWER.STAINS': 'Ha macchie, è appannato',
  'FORM.REPAIR.BATHROOM.SHOWER.WORN': 'Usurato',
  'FORM.REPAIR.BEDROOM': 'Camera da letto',
  'FORM.REPAIR.BENCH': 'Panchina',
  'FORM.REPAIR.BENCH.LOOSE': 'Allentato, traballante',
  'FORM.REPAIR.BENCH.DIRTY': 'Sporco, usurato',
  'FORM.REPAIR.BENCH.FALLINGAPART': 'Cade a pezzi',
  'FORM.REPAIR.BIKEROOM': 'Locale per biciclette',
  'FORM.REPAIR.BIKESTAND': 'Cavalletto per biciclette',
  'FORM.REPAIR.BIKESTAND.DEFECT': 'Difetto',
  'FORM.REPAIR.BIKESTAND.LOOSE': 'Allentato, traballante',
  'FORM.REPAIR.BIKESTAND.MISSING': 'Mancante',
  'FORM.REPAIR.BIKESHELTER': 'Cavalletto per bicicletta, scatola',
  'FORM.REPAIR.BIKESHELTER.BIKEBOX': 'Ricovero per biciclette',
  'FORM.REPAIR.BIKESHELTER.BIKEBOX.BROKENROOF': 'Tetto difettoso',
  'FORM.REPAIR.BIKESHELTER.BIKEBOX.DOORDEFECT': 'Porta difettosa',
  'FORM.REPAIR.BIKESHELTER.BIKEBOX.LOOSE': 'Allentato, traballa',
  'FORM.REPAIR.BIKESHELTER.BIKEBOX.NOTDRAINING': "L'acqua piovana non defluisce",
  'FORM.REPAIR.BOILERROOM': 'Locale caldaia',
  'FORM.REPAIR.BUILDINGENTRANCE': 'Ingresso della casa',
  'FORM.REPAIR.CABINET': 'Armadio a muro',
  'FORM.REPAIR.CABINET.BASE': 'Base',
  'FORM.REPAIR.CABINET.BASE.BROKEN': 'Rotto, incrinato',
  'FORM.REPAIR.CABINET.BASE.JOINTLEAKS': 'Giunto che perde, che si allenta',
  'FORM.REPAIR.CABINET.BRACKETDEFECT': 'Staffa difettosa',
  'FORM.REPAIR.CABINET.BRACKETMISSING': 'Staffa mancante',
  'FORM.REPAIR.CABINET.DOOR': 'Anta del mobile',
  'FORM.REPAIR.CABINET.DOOR.EDGESDEFECT': 'Bordi difettosi',
  'FORM.REPAIR.CABINET.DOOR.EDGESMISSING': 'Bordo mancante, rotto',
  'FORM.REPAIR.CABINET.DOOR.GRINDS': 'Graffi, in sospeso',
  'FORM.REPAIR.CABINET.DOOR.HINGEBROKEN': 'Cerniere difettose, strappate',
  'FORM.REPAIR.CABINET.DRAWER': 'Cassetto',
  'FORM.REPAIR.CABINET.DRAWER.NOTOPEN': 'Non si apre correttamente',
  'FORM.REPAIR.CABINET.DRAWER.REMAINSOPEN': 'Si chiude male',
  'FORM.REPAIR.CABINET.DRAWER.RUSTED': 'Arrugginito',
  'FORM.REPAIR.CABINET.DRAWER.WORN': 'Strappato',
  'FORM.REPAIR.CABINET.HANDLEDEFECT': 'Maniglia difettosa',
  'FORM.REPAIR.CABINET.HANGSDOWN': 'Appeso',
  'FORM.REPAIR.CABINET.LOOSE': 'Allentato, traballa',
  'FORM.REPAIR.CABINET.MISSING': 'Mancante',
  'FORM.REPAIR.CABINET.MOLD': 'Muffa',
  'FORM.REPAIR.CABINET.RAIL': 'Binario per abiti',
  'FORM.REPAIR.CABINET.RAIL.DEFECT': 'Difetto',
  'FORM.REPAIR.CABINET.SHELF': 'Ripiano',
  'FORM.REPAIR.CABINET.SHELF.CROOKED': 'Storto',
  'FORM.REPAIR.CABINET.SHELF.PEELING': 'Rivestimento scrostato',
  'FORM.REPAIR.CABINET.SHELF.WORN': 'Usurato',
  'FORM.REPAIR.CABINET.SWOLLEN': 'Gonfio',
  'FORM.REPAIR.CABINET.WALLSFLOOR': 'Pareti, pavimento, ...',
  'FORM.REPAIR.CABINET.WALLSFLOOR.REARWALLBROKEN': 'Parete posteriore rotta',
  'FORM.REPAIR.CABINET.WALLSFLOOR.REARWALLBACK': 'Parete posteriore arretrata',
  'FORM.REPAIR.CABINET.WALLSFLOOR.WORN': 'Usurato',
  'FORM.REPAIR.CANOPY': 'Tettoia',
  'FORM.REPAIR.CANOPY.GLASSBROKEN': 'Tettoia in vetro rotta',
  'FORM.REPAIR.CANOPY.NOTDRAINING': "L'acqua piovana non defluisce",
  'FORM.REPAIR.CANOPY.OVERFLOWS': "L'acqua piovana trabocca",
  'FORM.REPAIR.CEILING': 'Soffitto',
  'FORM.REPAIR.CELLAR': 'Cantina',
  'FORM.REPAIR.CELLAR.COMPARTMENTDOOR': 'Porta del vano cantina',
  'FORM.REPAIR.CELLARCOMPARTMENT': 'Vano cantina',
  'FORM.REPAIR.CELLARDOOR': 'Porta della cantina',
  'FORM.REPAIR.CLEANING': 'Pulizia',
  'FORM.REPAIR.COLORDISSOLVING': 'Il colore si stacca',
  'FORM.REPAIR.COMMONSPACE.OTHER': 'Altro luogo',
  'FORM.REPAIR.CONTAINERROOM': 'Spazio contenitore',
  'FORM.REPAIR.CORRIDOR.APARTMENT': 'Corridoio, ingresso appartamento',
  'FORM.REPAIR.CORRIDOR.COMMERCIAL': 'Corridoio, zona ingresso',
  'FORM.REPAIR.CRACK': 'Crepa',
  'FORM.REPAIR.DAMAGED': 'Danneggiato',
  'FORM.REPAIR.DISPLAYDEFECT': 'Display difettoso',
  'FORM.REPAIR.DRAIN': 'Scarico del pavimento del garage',
  'FORM.REPAIR.DOOR': 'Porta della stanza',
  'FORM.REPAIR.DOOR.OPENINGCLOSING': 'Apertura e chiusura',
  'FORM.REPAIR.DOOR.OPENINGCLOSING.BADCLOSING': 'Si chiude male',
  'FORM.REPAIR.DOOR.OPENINGCLOSING.DIFFICULTCLOSING': 'Apre, chiude con difficoltà',
  'FORM.REPAIR.DOOR.OPENINGCLOSING.GRINDS': 'Si apre, si ferma',
  'FORM.REPAIR.DOOR.OPENINGCLOSING.REMAINSOPEN': 'Non rimane chiuso',
  'FORM.REPAIR.DOOR.DOOR': 'Porta',
  'FORM.REPAIR.DOOR.DOOR.BROKEN': 'Apertura rotta',
  'FORM.REPAIR.DOOR.DOOR.GLASSPANELDEFECT': 'Vetro difettoso',
  'FORM.REPAIR.DOOR.DOOR.HINGEBROKEN': 'Cerniere difettose, strappate',
  'FORM.REPAIR.DOOR.DOOR.HOLE': 'Foro',
  'FORM.REPAIR.DOOR.DOOR.LIGHT': 'Incidenza della luce',
  'FORM.REPAIR.DOOR.DOOR.SPLIT': 'Spaccato',
  'FORM.REPAIR.DOOR.DOORBELL': 'Campanello',
  'FORM.REPAIR.DOOR.DOORBELL.CHANGENAME': 'Cambio targhetta',
  'FORM.REPAIR.DOOR.DOORBELL.DEFECT': 'Campanello difettoso',
  'FORM.REPAIR.DOOR.DOORBELL.MISSING': 'Targhetta mancante',
  'FORM.REPAIR.DOOR.DOORSTEP': 'Soglia della porta',
  'FORM.REPAIR.DOOR.DOORSTEP.BARBROKEN': 'Profilo di transizione rotto',
  'FORM.REPAIR.DOOR.DOORSTEP.BARLOOSE': 'Profilo di transizione allentato',
  'FORM.REPAIR.DOOR.DOORSTEP.BARMISSING': 'Profilo di transizione mancante',
  'FORM.REPAIR.DOOR.DOORSTEP.SEALINGDEFECT': 'Giunto di silicone difettoso',
  'FORM.REPAIR.DOOR.FRAME': 'Telaio della porta',
  'FORM.REPAIR.DOOR.FRAME.BROKEN': 'Apertura rotta',
  'FORM.REPAIR.DOOR.FRAME.HINGEBROKEN': 'Cerniere difettose, strappate',
  'FORM.REPAIR.DOOR.FRAME.SEALINGDEFECT': 'Guarnizione difettosa',
  'FORM.REPAIR.DOOR.FRAME.SEALINGMISSING': 'Guarnizione mancante',
  'FORM.REPAIR.DOOR.FRONTDOOR': "Porta d'ingresso",
  'FORM.REPAIR.DOOR.KNOB': 'Maniglia della porta',
  'FORM.REPAIR.DOOR.KNOB.FALLSAPART': 'Cade a pezzi',
  'FORM.REPAIR.DOOR.KNOB.HANGSDOWN': 'Si blocca',
  'FORM.REPAIR.DOOR.KNOB.LOOSE': 'Allentato, oscilla',
  'FORM.REPAIR.DOOR.KNOB.MISSING': 'Mancante',
  'FORM.REPAIR.DOOR.KNOB.WORN': 'Fortemente usurato',
  'FORM.REPAIR.DOOR.LOCK': 'Serratura',
  'FORM.REPAIR.DOOR.LOCK.BOLTBLOCKS': 'Bullone bloccato',
  'FORM.REPAIR.DOOR.LOCK.BROKEN': 'Rotto',
  'FORM.REPAIR.DOOR.LOCK.BROKENKEY': 'Chiave annullata',
  'FORM.REPAIR.DOOR.LOCK.COVERMISSING': 'Serratura mancante',
  'FORM.REPAIR.DOOR.LOCK.LATCHBLOCKS': 'Scrocco bloccato',
  'FORM.REPAIR.DOOR.LOCK.MISSINGKEY': 'Manca la chiave',
  'FORM.REPAIR.DOOR.LOCK.NOTWORKING': 'Sblocco e blocco difettosi',
  'FORM.REPAIR.DOOR.LOCK.PLATELOOSE': 'Frontale allentato',
  'FORM.REPAIR.DOOR.RAMPDEFECT': 'Rampa difettosa',
  'FORM.REPAIR.DOOR.TRAPPINGDEFECT': 'Trappola per lo sporco difettosa',
  'FORM.REPAIR.ELECTRICITY': 'Elettricità, luce',
  'FORM.REPAIR.ELECTRICITY.ENTRANCELIGHT': "Illuminazione dell'ingresso",
  'FORM.REPAIR.ELECTRICITY.EXTERIORLIGHT': 'Illuminazione esterna',
  'FORM.REPAIR.ELECTRICITY.EXTERIORLIGHT.DAMAGED': 'Danneggiato',
  'FORM.REPAIR.ELECTRICITY.EXTERIORLIGHT.LIGHTSDURINGDAY': 'Si accende durante il giorno',
  'FORM.REPAIR.ELECTRICITY.EXTERIORLIGHT.LOOSE': 'Allentato, traballante',
  'FORM.REPAIR.ELECTRICITY.EXTERIORLIGHT.SCREENDEFECT': 'Tonalità della luce difettosa',
  'FORM.REPAIR.ELECTRICITY.FUSEOUT': 'Fusibile bruciato',
  'FORM.REPAIR.ELECTRICITY.GARAGELIGHT': 'Illuminazione del garage',
  'FORM.REPAIR.ELECTRICITY.GARAGELIGHT.AUTOSWITCHONDEFECT': 'Accensione automatica difettosa',
  'FORM.REPAIR.ELECTRICITY.GARAGELIGHT.AUTOSWITCHOFFDEFECT': 'Funzione di spegnimento automatico difettosa',
  'FORM.REPAIR.ELECTRICITY.INTERIORLIGHT': 'Illuminazione interna',
  'FORM.REPAIR.ELECTRICITY.INTERIORLIGHT.LOOSE': 'Allentato, traballante',
  'FORM.REPAIR.ELECTRICITY.INTERIORLIGHT.SCREENDEFECT': 'Tonalità della luce difettosa',
  'FORM.REPAIR.ELECTRICITY.LIGHT.BURNMARK': 'Segno di bruciatura',
  'FORM.REPAIR.ELECTRICITY.LIGHT.DEFECT': 'Sorgente luminosa difettosa',
  'FORM.REPAIR.ELECTRICITY.LIGHT.FLICKERING': 'Sfarfallio',
  'FORM.REPAIR.ELECTRICITY.LIGHT.SMELLS': 'Odore di bruciato',
  'FORM.REPAIR.ELECTRICITY.NOPOWER': 'Assenza di corrente',
  'FORM.REPAIR.ELECTRICITY.NOPOWER.APARTMENT': 'Intero appartamento / spazio commerciale',
  'FORM.REPAIR.ELECTRICITY.NOPOWER.BUILDING': 'Intero edificio',
  'FORM.REPAIR.ELECTRICITY.NOPOWER.ONLYROOM': 'Solo una stanza o un gruppo di alimentazione',
  'FORM.REPAIR.ELECTRICITY.POWER': 'Potenza',
  'FORM.REPAIR.ELECTRICITY.SOCKET': 'Presa',
  'FORM.REPAIR.ELECTRICITY.SOCKET.BROKEN': 'Telaio rotto',
  'FORM.REPAIR.ELECTRICITY.SOCKET.DIRTY': 'Sporco',
  'FORM.REPAIR.ELECTRICITY.SOCKET.LOOSE': 'Allentato, traballante',
  'FORM.REPAIR.ELECTRICITY.SOCKET.SMELLS': 'Odore di bruciato',
  'FORM.REPAIR.ELECTRICITY.STAIRWELLLIGHT': 'Scale casa illuminazione',
  'FORM.REPAIR.ELECTRICITY.SWITCH': 'Interruttore luce, dimmer',
  'FORM.REPAIR.ELECTRICITY.SWITCH.BROKEN': 'Telaio rotto',
  'FORM.REPAIR.ELECTRICITY.SWITCH.MALFUNCTION': 'Difetto',
  'FORM.REPAIR.ELECTRICITY.SWITCH.SMELLS': 'Odore di bruciato',
  'FORM.REPAIR.ELECTRICITY.WIRE': 'Cavo',
  'FORM.REPAIR.ELECTRICITY.WIRE.HANGSDOWN': 'Pende',
  'FORM.REPAIR.ELECTRICITY.WIRE.SMELLS': 'Odore di bruciato',
  'FORM.REPAIR.ERROR': 'Messaggio di errore',
  'FORM.REPAIR.EXTERIORSHAFT': 'Chiusino (esterno)',
  'FORM.REPAIR.FACADE': 'Facciata, tetto',
  'FORM.REPAIR.FACADE.BRICK': 'Mattone',
  'FORM.REPAIR.FACADE.BRICK.BROKEN': 'Rotto',
  'FORM.REPAIR.FACADE.BRICK.MISSING': 'Mancante',
  'FORM.REPAIR.FACADE.BRICK.POSTPONED': 'Rinviato',
  'FORM.REPAIR.FACADE.FACADEWALL': 'Facciata',
  'FORM.REPAIR.FACADE.FACADEWALL.MOLD': 'Muffa sulla facciata',
  'FORM.REPAIR.FACADE.FACADEWALL.PEELING': 'Facciata scrostata',
  'FORM.REPAIR.FACADE.RAINGUTTER': 'Grondaia',
  'FORM.REPAIR.FACADE.RAINGUTTER.CLOGGED': 'Intasato',
  'FORM.REPAIR.FACADE.RAINGUTTER.DEFECT': 'Difetto',
  'FORM.REPAIR.FACADE.RAINGUTTER.DOWNPIPECLOGGED': 'Tubo di scarico bloccato',
  'FORM.REPAIR.FACADE.RAINGUTTER.DOWNPIPEDEFECT': 'Tubo di scarico difettoso',
  'FORM.REPAIR.FACADE.RAINGUTTER.LICKS': 'Perdite',
  'FORM.REPAIR.FILLINGDRILLHOLE': 'Chiusura foro',
  'FORM.REPAIR.FIREPROTECTION': 'Protezione antincendio',
  'FORM.REPAIR.FIREPROTECTION.SMOKESENSOR': 'Rivelatore di fumo',
  'FORM.REPAIR.FLOORDOOR': 'Porta del pavimento',
  'FORM.REPAIR.FLOOR': 'Pavimento',
  'FORM.REPAIR.FLOOR.SURFACE': 'Rivestimento del pavimento, superficie',
  'FORM.REPAIR.FLOOR.SURFACE.BULGES': "Rigonfiamenti verso l'alto",
  'FORM.REPAIR.FLOOR.SURFACE.BURNMARK': 'Segno di bruciatura',
  'FORM.REPAIR.FLOOR.SURFACE.CHAFEMARK': 'Segno di abrasione',
  'FORM.REPAIR.FLOOR.SURFACE.DISSOLVING': 'Rivestimento in dissoluzione',
  'FORM.REPAIR.FLOOR.SURFACE.LIGHTSHADE': 'Ombra: chiara, scura',
  'FORM.REPAIR.FLOOR.SURFACE.LOOSE': 'Blocchi sciolti',
  'FORM.REPAIR.FLOOR.SURFACE.QUIRK': 'Quirk',
  'FORM.REPAIR.FLOOR.SURFACE.SCRATCH': 'Graffiato',
  'FORM.REPAIR.FLOOR.SURFACE.STAINS': 'Macchie, scolorimento',
  'FORM.REPAIR.FLOOR.SURFACE.WATERDAMAGE': 'Danno da acqua',
  'FORM.REPAIR.FLOOR.SURFACE.WORNOUT': 'Strappi',
  'FORM.REPAIR.FLOOR.TILES': 'Piastrelle',
  'FORM.REPAIR.FLOOR.TILES.BROKEN': 'Rotto',
  'FORM.REPAIR.FLOOR.TILES.JOINTSDEFECT': 'Giunto difettoso',
  'FORM.REPAIR.FLOOR.TILES.LOOSE': 'Allentato, traballante',
  'FORM.REPAIR.FLOOR.TILES.WORNOUT': 'Sporco, usurato',
  'FORM.REPAIR.FLOOR.SKIRTINGBOARDS': 'Battiscopa',
  'FORM.REPAIR.FLOOR.SKIRTINGBOARDS.GAP': 'Distanza dal pavimento',
  'FORM.REPAIR.FLOOR.SKIRTINGBOARDS.LOOSE': 'Allentato, traballante',
  'FORM.REPAIR.FLOOR.SKIRTINGBOARDS.MISSING': 'Mancante',
  'FORM.REPAIR.FLOOR.SKIRTINGBOARDS.WORNOUT': 'Strappato',
  'FORM.REPAIR.FLOOR.JOINTS': 'Giunti per pavimenti',
  'FORM.REPAIR.FLOOR.JOINTS.DISSOLVING': 'Scioglie',
  'FORM.REPAIR.FLOOR.JOINTS.MISSING': 'Mancante',
  'FORM.REPAIR.FLOOR.JOINTS.MOLD': 'Ammuffito',
  'FORM.REPAIR.FLOOR.DOORSTEP': 'Gradino, pianerottolo',
  'FORM.REPAIR.FLOOR.DOORSTEP.BARBROKEN': 'Profilo di transizione rotto',
  'FORM.REPAIR.FLOOR.DOORSTEP.BARLOOSE': 'Profilo di transizione allentato',
  'FORM.REPAIR.FLOOR.DOORSTEP.BARMISSING': 'Profilo di transizione mancante',
  'FORM.REPAIR.FLOOR.DOORSTEP.WORNOUT': 'Strappato',
  'FORM.REPAIR.FLOOR.DRAIN': 'Scarico a pavimento (interno)',
  'FORM.REPAIR.GARAGE': 'Garage',
  'FORM.REPAIR.GARAGEDOOR': 'Porta del garage',
  'FORM.REPAIR.GARAGEGATE': 'Porta del garage',
  'FORM.REPAIR.GARAGEGATE.DRAINDEFECT': 'Scarico pioggia difettoso',
  'FORM.REPAIR.GARAGEGATE.ENGINE': 'Motore',
  'FORM.REPAIR.GARAGEGATE.ENGINE.DEFECT': 'Difetto',
  'FORM.REPAIR.GARAGEGATE.ENGINE.NOISE': 'Rumore insolito',
  'FORM.REPAIR.GARAGEGATE.GATE': 'Porta del garage, sospensione',
  'FORM.REPAIR.GARAGEGATE.GATE.BENT': 'Piegato',
  'FORM.REPAIR.GARAGEGATE.GATE.BROKEN': 'Apertura rotta',
  'FORM.REPAIR.GARAGEGATE.GATE.DISENGAGED': 'Sganciato',
  'FORM.REPAIR.GARAGEGATE.GATE.LOOSE': 'Allentato, traballante',
  'FORM.REPAIR.GARAGEGATE.GATE.NOISE': 'Fa rumori insoliti',
  'FORM.REPAIR.GARAGEGATE.OPENINGCLOSING.KEYDEFECT': 'Key opening defective',
  'FORM.REPAIR.GARAGEGATE.OPENINGCLOSING.KEYPOSTDEFECT': 'Chiave&cattiva;apertura post difettosa',
  'FORM.REPAIR.GARAGEGATE.OPENINGCLOSING.NOOPENS': 'Non si apre più',
  'FORM.REPAIR.GARAGEGATE.OPENINGCLOSING.NOTCLOSES': 'Non si chiude',
  'FORM.REPAIR.GARAGEGATE.OPENINGCLOSING.REMOTEDEFECT': "L'apertura remota non funziona",
  'FORM.REPAIR.GARAGEGATE.OPENINGCLOSING.STUCK': 'Bloccato',
  'FORM.REPAIR.GARAGEGATE.RUSTEDDOORSTEP': 'Soglia arrugginita',
  'FORM.REPAIR.GARBAGE': 'Livello rifiuti',
  'FORM.REPAIR.GARBAGE.BROKEN': 'Cestino dei rifiuti, contenitore difettoso',
  'FORM.REPAIR.GARDEN': "Aree verdi, giardino dell'inquilino",
  'FORM.REPAIR.GREENAREA': 'Verde',
  'FORM.REPAIR.GREENAREA.BRANCHESFELL': 'Rami caduti',
  'FORM.REPAIR.GREENAREA.BRANCHESHANG': 'Rami pendenti',
  'FORM.REPAIR.GREENAREA.HEDGESUNCUT': 'Siepi non tagliate',
  'FORM.REPAIR.GREENAREA.UNMOWED': 'Prato non tagliato',
  'FORM.REPAIR.GUESTAREA': 'Camera degli ospiti',
  'FORM.REPAIR.HEATING': "Riscaldamento dell'edificio",
  'FORM.REPAIR.HEATING.DEFECT': 'Guasto',
  'FORM.REPAIR.HEATING.INSUFFICIENT': 'Riscalda in modo insufficiente',
  'FORM.REPAIR.HEATING.NOISE': 'Rumore (ad es. bussare)',
  'FORM.REPAIR.HEATING.OILEMPTY': "Serbatoio dell'olio quasi vuoto o vuoto",
  'FORM.REPAIR.HEATING.PELLETEMPTY': 'Deposito pellet: quasi vuoto o vuoto',
  'FORM.REPAIR.HEATING.WOODCHIPEMPTY': 'Deposito di cippato: quasi vuoto o vuoto',
  'FORM.REPAIR.HEATINGVENTILATION': 'Riscaldamento, ventilazione',
  'FORM.REPAIR.HEATINGVENTILATION.FLOORHEATING': 'Riscaldamento a pavimento',
  'FORM.REPAIR.HEATINGVENTILATION.FLOORHEATING.COLD': 'Rimane freddo',
  'FORM.REPAIR.HEATINGVENTILATION.FLOORHEATING.CONTROLLERDEFECT': 'Regolatore difettoso',
  'FORM.REPAIR.HEATINGVENTILATION.FLOORHEATING.NOTWARMINGUP': "Non riscalda l'intero pavimento",
  'FORM.REPAIR.HEATINGVENTILATION.FLOORHEATING.WATERDAMAGE': 'Danno da acqua',
  'FORM.REPAIR.HEATINGVENTILATION.RADIATOR': 'Radiatore',
  'FORM.REPAIR.HEATINGVENTILATION.RADIATOR.COLD': 'Rimane freddo',
  'FORM.REPAIR.HEATINGVENTILATION.RADIATOR.COLORDISSOLVING': 'Il colore si stacca',
  'FORM.REPAIR.HEATINGVENTILATION.RADIATOR.CONTROLLERDEFECT': 'Controllore difettoso',
  'FORM.REPAIR.HEATINGVENTILATION.RADIATOR.DRIPPING': 'Gocciolamento',
  'FORM.REPAIR.HEATINGVENTILATION.RADIATOR.GROUNDWATERDAMAGE': 'Danno da acqua sul pavimento',
  'FORM.REPAIR.HEATINGVENTILATION.RADIATOR.NOTWARMINGUP': 'Non si scalda più correttamente',
  'FORM.REPAIR.HEATINGVENTILATION.RADIATOR.NOISE': 'Fa rumore',
  'FORM.REPAIR.HEATINGVENTILATION.THERMOSTAT': 'Termostato',
  'FORM.REPAIR.HEATINGVENTILATION.THERMOSTAT.DEFECT': 'Non funziona più',
  'FORM.REPAIR.HEATINGVENTILATION.THERMOSTAT.HANGSDOWN': 'Si blocca',
  'FORM.REPAIR.HEATINGVENTILATION.VENTILATION': 'Ventilazione',
  'FORM.REPAIR.HEATINGVENTILATION.VENTILATION.DEFECT': 'Non funziona più',
  'FORM.REPAIR.HEATINGVENTILATION.VENTILATION.LOWPERFORMANCE': 'Prestazioni troppo basse',
  'FORM.REPAIR.HEATINGVENTILATION.VENTILATION.NOISE': 'Fa rumore',
  'FORM.REPAIR.HOLE': 'Foro',
  'FORM.REPAIR.HOMESTAIRS': 'Scale (in casa)',
  'FORM.REPAIR.HOTWATER': 'Acqua calda',
  'FORM.REPAIR.HOTWATER.NOHOTWATER': 'Niente acqua calda',
  'FORM.REPAIR.HOTWATER.NOHOTWATER.ONECONNECTION': 'Solo un collegamento',
  'FORM.REPAIR.HOTWATER.NOHOTWATER.TITLE': "A quanti collegamenti manca l'acqua calda?",
  'FORM.REPAIR.HOTWATER.NOWARMWATER': "L'acqua non è molto calda",
  'FORM.REPAIR.HOTWATER.NOWARMWATER.TITLE': "In quanti collegamenti c'è solo acqua calda?",
  'FORM.REPAIR.HOUSEDOOR': "Porta d'ingresso della casa",
  'FORM.REPAIR.INNERSHAFT': 'Chiusino (interno)',
  'FORM.REPAIR.KITCHEN': 'Cucina',
  'FORM.REPAIR.KITCHEN.APPLIANCES': 'Cucina elettrodomestici',
  'FORM.REPAIR.KITCHEN.APPLIANCES.BROKENLIGHT': 'Luce rotta',
  'FORM.REPAIR.KITCHEN.APPLIANCES.COOKER': 'Fornello',
  'FORM.REPAIR.KITCHEN.APPLIANCES.COOKER.HOB': 'Solo piano cottura',
  'FORM.REPAIR.KITCHEN.APPLIANCES.COOKER.HOBOVEN': 'Piano cottura e forno',
  'FORM.REPAIR.KITCHEN.APPLIANCES.COOKER.HOBOVEN.LOOSE': 'Allentato, traballante',
  'FORM.REPAIR.KITCHEN.COOKER.HOBOVEN.SWITCHONDEFECT': "L'accensione non funziona",
  'FORM.REPAIR.KITCHEN.APPLIANCES.COOKER.OVEN': 'Solo forno',
  'FORM.REPAIR.KITCHEN.APPLIANCES.DISHWASHER': 'Lavastoviglie',
  'FORM.REPAIR.KITCHEN.APPLIANCES.DISHWASHER.BACKWATER': "L'acqua di scarico fuoriesce dallo scarico",
  'FORM.REPAIR.KITCHEN.APPLIANCES.DISHWASHER.BASKETDEFECT': 'Cestino posate difettoso',
  'FORM.REPAIR.KITCHEN.APPLIANCES.DISHWASHER.FALLSOFF': 'La parte anteriore si stacca',
  'FORM.REPAIR.KITCHEN.APPLIANCES.DISHWASHER.FOAMS': 'Schiuma',
  'FORM.REPAIR.KITCHEN.APPLIANCES.DISHWASHER.GRINDS': 'Spazzole anteriori, fermate',
  'FORM.REPAIR.KITCHEN.APPLIANCES.DISHWASHER.LEAKING': 'Perdite: acqua',
  'FORM.REPAIR.KITCHEN.APPLIANCES.DISHWASHER.LOUD': 'Ronzio forte',
  'FORM.REPAIR.KITCHEN.APPLIANCES.DISHWASHER.NOTCLEAN': 'Non lava in modo pulito',
  'FORM.REPAIR.KITCHEN.APPLIANCES.DISHWASHER.NOTDRIES': 'Non asciuga più',
  'FORM.REPAIR.KITCHEN.APPLIANCES.DISHWASHER.WATERINMACHINE': "L'acqua è ferma nella macchina",
  'FORM.REPAIR.KITCHEN.APPLIANCES.EXTRACTOR': 'Aspiratore',
  'FORM.REPAIR.KITCHEN.APPLIANCES.EXTRACTOR.CLOGGED': 'Filtro intasato',
  'FORM.REPAIR.KITCHEN.APPLIANCES.EXTRACTOR.DRIPS': 'Gocciolamento',
  'FORM.REPAIR.KITCHEN.APPLIANCES.EXTRACTOR.LOUD': 'Suona / troppo forte / fa rumore',
  'FORM.REPAIR.KITCHEN.APPLIANCES.EXTRACTOR.POWERDEFECT': 'Non estrae / troppo debole',
  'FORM.REPAIR.KITCHEN.APPLIANCES.EXTRACTOR.REGULATIONDEFECT': 'Non può essere regolato',
  'FORM.REPAIR.KITCHEN.APPLIANCES.EXTRACTOR.SMELLS': 'Puzza',
  'FORM.REPAIR.KITCHEN.APPLIANCES.FREEZER': 'Freezer',
  'FORM.REPAIR.KITCHEN.APPLIANCES.HOB.BROKEN': 'Rotto, incrinato',
  'FORM.REPAIR.KITCHEN.APPLIANCES.HOB.BROKENFRAME': 'Telaio rotto',
  'FORM.REPAIR.KITCHEN.APPLIANCES.HOB.BURNMARK': 'Segno di bruciatura',
  'FORM.REPAIR.KITCHEN.APPLIANCES.HOB.JOINTLEAKS': 'Giunto che perde, che si allenta',
  'FORM.REPAIR.KITCHEN.APPLIANCES.HOB.LOOSE': 'Allentato, traballa',
  'FORM.REPAIR.KITCHEN.APPLIANCES.HOB.SWITCHDEFECT': "L'accensione non funziona",
  'FORM.REPAIR.KITCHEN.APPLIANCES.HOB.WORN': 'Usurato',
  'FORM.REPAIR.KITCHEN.APPLIANCES.MICROWAVE': 'Microonde',
  'FORM.REPAIR.KITCHEN.APPLIANCES.MICROWAVE.HANDLEDEFECT': 'Maniglia difettosa',
  'FORM.REPAIR.KITCHEN.APPLIANCES.MICROWAVE.LOCKDEFECT': 'Serratura danneggiata',
  'FORM.REPAIR.KITCHEN.APPLIANCES.MICROWAVE.TURNTABLEDEFECT': 'Giradischi difettoso',
  'FORM.REPAIR.KITCHEN.APPLIANCES.NOELECTRICITY': 'Assenza di alimentazione',
  'FORM.REPAIR.KITCHEN.APPLIANCES.NOTHOT': 'Non si riscalda',
  'FORM.REPAIR.KITCHEN.APPLIANCES.OVEN': 'Forno',
  'FORM.REPAIR.KITCHEN.APPLIANCES.OVEN.GLASSDEFECT': 'Vetro difettoso',
  'FORM.REPAIR.KITCHEN.APPLIANCES.OVEN.REPLACEACCESSORIES': 'Sostituire/aggiungere accessori',
  'FORM.REPAIR.KITCHEN.APPLIANCES.OVEN.SEALDEFECT': 'Guarnizione porosa, difettosa',
  'FORM.REPAIR.KITCHEN.APPLIANCES.OVEN.SMELL': 'Forte odore',
  'FORM.REPAIR.KITCHEN.APPLIANCES.REFRIGERATOR': 'Frigorifero',
  'FORM.REPAIR.KITCHEN.APPLIANCES.REFRIGERATOR.CONDENSATION': 'Condensa',
  'FORM.REPAIR.KITCHEN.APPLIANCES.REFRIGERATOR.GLASSDEFECT': 'Ripiano in vetro, ripiano difettoso',
  'FORM.REPAIR.KITCHEN.APPLIANCES.REFRIGERATOR.DOOR': 'Porta',
  'FORM.REPAIR.KITCHEN.APPLIANCES.REFRIGERATOR.DOOR.BOTTLEHOLDERDEFECT': 'Bottiglia e supporto difettosi',
  'FORM.REPAIR.KITCHEN.APPLIANCES.REFRIGERATOR.DOOR.CRACKS': 'Crepe',
  'FORM.REPAIR.KITCHEN.APPLIANCES.REFRIGERATOR.DOOR.GRINDS': 'Colpi, arresti',
  'FORM.REPAIR.KITCHEN.APPLIANCES.REFRIGERATOR.DOOR.HANGSDOWN': 'Parte anteriore appesa',
  'FORM.REPAIR.KITCHEN.APPLIANCES.REFRIGERATOR.DOOR.HANDLEDEFECT': 'Maniglia difettosa',
  'FORM.REPAIR.KITCHEN.APPLIANCES.REFRIGERATOR.DOOR.NOTCLOSE': 'Non si chiude',
  'FORM.REPAIR.KITCHEN.APPLIANCES.REFRIGERATOR.DOORCOMPARTMENTSDEFECT': 'Vani porta difettosi',
  'FORM.REPAIR.KITCHEN.APPLIANCES.REFRIGERATOR.DRAWERDEFECT': 'Cassetto verdure difettoso, scomparto',
  'FORM.REPAIR.KITCHEN.APPLIANCES.REFRIGERATOR.FREEZERDOORDEFECT': 'Sportello del vano congelatore difettoso',
  'FORM.REPAIR.KITCHEN.APPLIANCES.REFRIGERATOR.FREEZES': 'Spesso ghiacciato',
  'FORM.REPAIR.KITCHEN.APPLIANCES.REFRIGERATOR.LOUD': 'Ronza forte',
  'FORM.REPAIR.KITCHEN.APPLIANCES.REFRIGERATOR.NOCOOLS': 'Non raffredda più',
  'FORM.REPAIR.KITCHEN.APPLIANCES.REFRIGERATOR.SEALDEFECT': 'La guarnizione non tiene',
  'FORM.REPAIR.KITCHEN.APPLIANCES.STEAMER': 'Vapore',
  'FORM.REPAIR.KITCHEN.APPLIANCES.STEAMER.LEAKING': 'Perdita: acqua',
  'FORM.REPAIR.KITCHEN.APPLIANCES.STEAMER.TANKDEFECT': "Serbatoio dell'acqua difettoso",
  'FORM.REPAIR.KITCHEN.APPLIANCES.SWITCHDEFECT': 'Interruttore difettoso',
  'FORM.REPAIR.KITCHEN.HOB': 'Piano cottura',
  'FORM.REPAIR.KITCHEN.WORKTOP': 'Piano di lavoro',
  'FORM.REPAIR.KITCHEN.WORKTOP.EDGE': 'Bordo',
  'FORM.REPAIR.KITCHEN.WORKTOP.EDGE.CRACK': 'crack',
  'FORM.REPAIR.KITCHEN.WORKTOP.EDGE.FRAYS': 'Frangia fuori',
  'FORM.REPAIR.KITCHEN.WORKTOP.JOINT': 'GIUNTO',
  'FORM.REPAIR.KITCHEN.WORKTOP.JOINT.DISSOLVES': 'Si scioglie',
  'FORM.REPAIR.KITCHEN.WORKTOP.JOINT.MOLDY': 'Ammuffito',
  'FORM.REPAIR.KITCHEN.WORKTOP.SURFACE': 'Surface',
  'FORM.REPAIR.KITCHEN.WORKTOP.SURFACE.BROKEN': 'Rotto',
  'FORM.REPAIR.KITCHEN.WORKTOP.SURFACE.BURNMARK': 'Segno di bruciatura',
  'FORM.REPAIR.KITCHEN.WORKTOP.SURFACE.CHAFING': 'Segno di abrasione',
  'FORM.REPAIR.KITCHEN.WORKTOP.SURFACE.HOLE': 'Foro',
  'FORM.REPAIR.KITCHEN.WORKTOP.SURFACE.WATERSTAINS': "macchie d'acqua",
  'FORM.REPAIR.KITCHEN.WORKTOP.SWOLLEN': 'Gonfio',
  'FORM.REPAIR.KITCHEN.FURNITURE': 'Mobili da cucina',
  'FORM.REPAIR.KITCHEN.FURNITURE.APOTHECARYCABINET': 'Armadio da farmacia',
  'FORM.REPAIR.KITCHEN.FURNITURE.AUTOFEEDDEFECT': 'Alimentazione automatica difettosa',
  'FORM.REPAIR.KITCHEN.FURNITURE.BASE': 'Base',
  'FORM.REPAIR.KITCHEN.FURNITURE.BASE.BROKEN': 'Rotto',
  'FORM.REPAIR.KITCHEN.FURNITURE.BASE.DEPRESSED': 'Pressato',
  'FORM.REPAIR.KITCHEN.FURNITURE.BASE.JOINTDEFECT': 'Giunto difettoso',
  'FORM.REPAIR.KITCHEN.FURNITURE.DRAWER': 'Cassetto',
  'FORM.REPAIR.KITCHEN.FURNITURE.DRAWER.INSERTMISSING': 'Manca il portaposate',
  'FORM.REPAIR.KITCHEN.FURNITURE.DOORS': 'Porte, frontali',
  'FORM.REPAIR.KITCHEN.FURNITURE.DOORS.CUSHIONINGDEFECT': 'Cuscinetto difettoso',
  'FORM.REPAIR.KITCHEN.FURNITURE.DOORS.EDGEDEFECT': 'Bordi difettosi',
  'FORM.REPAIR.KITCHEN.FURNITURE.DOORS.EDGEMISSING': 'Bordo mancante',
  'FORM.REPAIR.KITCHEN.FURNITURE.DOORS.GRINDS': 'Strisce, battute',
  'FORM.REPAIR.KITCHEN.FURNITURE.DOORS.HINGEDEFECT': 'Cerniera difettosa',
  'FORM.REPAIR.KITCHEN.FURNITURE.DOORS.SNAPPERDEFECT': 'Chiusura a scatto difettosa',
  'FORM.REPAIR.KITCHEN.FURNITURE.HANDLEDEFECT': 'Maniglia difettosa',
  'FORM.REPAIR.KITCHEN.FURNITURE.HANGSDOWN': 'Pende',
  'FORM.REPAIR.KITCHEN.FURNITURE.LOOSE': 'Allentato, traballa',
  'FORM.REPAIR.KITCHEN.FURNITURE.MOLDY': 'Ammuffito',
  'FORM.REPAIR.KITCHEN.FURNITURE.NOTOPENS': 'Non si apre correttamente',
  'FORM.REPAIR.KITCHEN.FURNITURE.NOTCLOSES': 'Non si chiude correttamente',
  'FORM.REPAIR.KITCHEN.FURNITURE.PANSPULLOUT': 'Padella, bottiglia estraibile',
  'FORM.REPAIR.KITCHEN.FURNITURE.SHELF': 'Ripiano',
  'FORM.REPAIR.KITCHEN.FURNITURE.SHELF.BRACKETDEFECT': 'Supporto difettoso',
  'FORM.REPAIR.KITCHEN.FURNITURE.SHELF.BRACKETMISSING': 'Staffa mancante',
  'FORM.REPAIR.KITCHEN.FURNITURE.SHELF.CROOKED': 'Storto',
  'FORM.REPAIR.KITCHEN.FURNITURE.SHELF.MISSING': 'Mancante',
  'FORM.REPAIR.KITCHEN.FURNITURE.SHELF.SURFACEDEFECT': 'Superficie difettosa',
  'FORM.REPAIR.KITCHEN.FURNITURE.SWOLLEN': 'Gonfio',
  'FORM.REPAIR.KITCHEN.FURNITURE.WALLS': 'Pareti, pavimento, ...',
  'FORM.REPAIR.KITCHEN.FURNITURE.WALLS.BROKEN': 'Parete posteriore rotta',
  'FORM.REPAIR.KITCHEN.FURNITURE.WALLS.WALLPRESSED': 'Parete posteriore pressata',
  'FORM.REPAIR.KITCHEN.FURNITURE.WORN': 'Usurato',
  'FORM.REPAIR.KITCHEN.WASTE': 'Sistema di scarico',
  'FORM.REPAIR.KITCHEN.WASTE.TRASHBIN': 'Cestino dei rifiuti',
  'FORM.REPAIR.KITCHEN.WASTE.TRASHBIN.BRACKETTORN': 'Staffa del pavimento strappata',
  'FORM.REPAIR.KITCHEN.WASTE.TRASHBIN.CONNECTIONDEFECT': 'Collegamento porta difettoso',
  'FORM.REPAIR.KITCHEN.WASTE.TRASHBIN.LIDHOLDERDEFECT': 'Supporto del coperchio difettoso',
  'FORM.REPAIR.KITCHEN.WASTE.TRASHBIN.LIDTORN': 'Coperchio, ripiano inclinato strappato',
  'FORM.REPAIR.KITCHEN.WASTE.TRASHBIN.MISSING': 'Mancante',
  'FORM.REPAIR.KITCHEN.WASTE.TRASHBIN.WORN': 'Usurato',
  'FORM.REPAIR.KITCHEN.WASTE.FLOOR': 'Cestino del pavimento dei mobili',
  'FORM.REPAIR.KITCHEN.WASTE.FLOOR.EDGEDEFECT': 'Bordi difettosi',
  'FORM.REPAIR.KITCHEN.WASTE.FLOOR.MOLDY': 'Ammuffito',
  'FORM.REPAIR.KITCHEN.WASTE.FLOOR.SWOLLEN': 'Gonfio',
  'FORM.REPAIR.KITCHEN.WASTE.FLOOR.WORN': 'Strappato',
  'FORM.REPAIR.KITCHEN.WASTE.SUSPENSION': 'Sospensione',
  'FORM.REPAIR.KITCHEN.WASTE.SUSPENSION.BROKEN': 'Rotto, incrinato',
  'FORM.REPAIR.KITCHEN.WASTE.SUSPENSION.LOOSE': 'Allentato, traballante',
  'FORM.REPAIR.KITCHEN.WASTE.DOOR': 'Estrazione o porta',
  'FORM.REPAIR.KITCHEN.WASTE.DOOR.AUTOFEEDDEFECT': 'Cassetto automatico difettoso',
  'FORM.REPAIR.KITCHEN.WASTE.DOOR.GRINDS': 'Si sgrana, si ferma',
  'FORM.REPAIR.KITCHEN.WASTE.DOOR.HANDLEDEFECT': 'Maniglia difettosa',
  'FORM.REPAIR.KITCHEN.WASTE.DOOR.NOTCLOSES': 'Si chiude male',
  'FORM.REPAIR.KITCHEN.WASTE.DOOR.NOTOPENS': 'Non si apre correttamente',
  'FORM.REPAIR.KITCHEN.SINK': 'Lavello',
  'FORM.REPAIR.KITCHEN.SINK.BACKWATER': "L'acqua di scarico fuoriesce dallo scarico",
  'FORM.REPAIR.KITCHEN.SINK.CLOGGED': 'Intasato',
  'FORM.REPAIR.KITCHEN.SINK.DRAIN': 'Scarico',
  'FORM.REPAIR.KITCHEN.SINK.DRAIN.DRAINSSLOW': 'Scarico lento',
  'FORM.REPAIR.KITCHEN.SINK.DRAIN.NOISE': 'Gorgoglii / rumori',
  'FORM.REPAIR.KITCHEN.SINK.DRIPS': 'Gocciola',
  'FORM.REPAIR.KITCHEN.SINK.FITTINGS': 'Raccordi',
  'FORM.REPAIR.KITCHEN.SINK.FITTINGS.CHALKY': 'Calcareo',
  'FORM.REPAIR.KITCHEN.SINK.FITTINGS.LITTLEPRESSURE': "Poca pressione dell'acqua",
  'FORM.REPAIR.KITCHEN.SINK.FITTINGS.SEALDEFECT': 'Guarnizione porosa, difettosa',
  'FORM.REPAIR.KITCHEN.SINK.FITTINGS.SPLASHES': 'Spruzzi',
  'FORM.REPAIR.KITCHEN.SINK.JOINT': 'Giunto',
  'FORM.REPAIR.KITCHEN.SINK.JOINT.DIRTY': 'Sporco',
  'FORM.REPAIR.KITCHEN.SINK.JOINT.DISSOLVES': 'Si scioglie',
  'FORM.REPAIR.KITCHEN.SINK.JOINT.MOLDY': 'Ammuffito',
  'FORM.REPAIR.KITCHEN.SINK.LEAKY': 'Perdita',
  'FORM.REPAIR.KITCHEN.SINK.LOOSE': 'Allentato, traballante',
  'FORM.REPAIR.KITCHEN.SINK.NOPLUG': 'Tappo mancante',
  'FORM.REPAIR.KITCHEN.SINK.PIPES': 'Sifone, tubi di scarico',
  'FORM.REPAIR.KITCHEN.SINK.PIPES.LOOSE': 'Allentato, non collegato',
  'FORM.REPAIR.KITCHEN.SINK.SUPPLYLINES': 'Tubi di alimentazione',
  'FORM.REPAIR.KITCHEN.SINK.SINKBASIN': 'Lavello',
  'FORM.REPAIR.KITCHEN.SINK.SINKBASIN.GRAZES': 'Setaccio di drenaggio mancante/difettoso',
  'FORM.REPAIR.KITCHEN.SINK.SINKBASIN.WORN': 'Usurato',
  'FORM.REPAIR.KITCHEN.SINK.SMELLS': 'Puzza',
  'FORM.REPAIR.LAUNDRYROOM': 'Locale di lavaggio e asciugatura',
  'FORM.REPAIR.LIFT': 'Ascensore',
  'FORM.REPAIR.LIFT.ALARMBUTTON': 'Pulsante di chiamata di emergenza difettoso',
  'FORM.REPAIR.LIFT.BURNINGSMELL': 'Odore di bruciato',
  'FORM.REPAIR.LIFT.CALLBUTTON': 'Pulsante di chiamata (esterno)',
  'FORM.REPAIR.LIFT.CONTROLPANEL': 'Pannello di controllo della cabina',
  'FORM.REPAIR.LIFT.DOOR': 'Porta',
  'FORM.REPAIR.LIFT.DOOR.CLOSLOWLY': 'Si chiude troppo lentamente',
  'FORM.REPAIR.LIFT.DOOR.GRINDS': 'Smeriglia',
  'FORM.REPAIR.LIFT.DOOR.NOTCLOSING': 'Non si chiude',
  'FORM.REPAIR.LIFT.DOOR.SLAMS': 'Sbatte (si chiude)',
  'FORM.REPAIR.LIFT.DOOR.SQUEAKS': 'Scricchiola',
  'FORM.REPAIR.LIFT.DOOR.STUCK': 'Morsetti',
  'FORM.REPAIR.LIFT.FAILURE': 'Malfunzionamento della guida',
  'FORM.REPAIR.LIFT.FAILURE.COMPLETE': 'Guasto completo',
  'FORM.REPAIR.LIFT.FAILURE.JERKING': 'Scatti',
  'FORM.REPAIR.LIFT.FAILURE.NOISE': 'Fa rumore',
  'FORM.REPAIR.LIFT.FAILURE.SPORADICALLY': 'Fallisce sporadicamente',
  'FORM.REPAIR.LIFT.FAILURE.STOPS': 'Si ferma al piano sbagliato',
  'FORM.REPAIR.LIFT.INDICATOR': 'Indicatore di piano in anticamera',
  'FORM.REPAIR.LIFT.LIGHT': 'Luce difettosa',
  'FORM.REPAIR.LIFT.OBJECTINSHAFT': 'Oggetto nel pozzo',
  'FORM.REPAIR.LIFT.OUTSIDEPANEL': 'Pannello esterno',
  'FORM.REPAIR.LIFT.POSITION': 'Posizione cabina non allineata',
  'FORM.REPAIR.LIFT.WATERDAMAGE': 'Ingresso acqua',
  'FORM.REPAIR.LIVINGROOM': 'Soggiorno',
  'FORM.REPAIR.MAILBOX': 'Cassetta postale',
  'FORM.REPAIR.MAILBOX.DOORDEFECT': 'Sportello del vano pacchi difettoso',
  'FORM.REPAIR.MAILBOX.FLAPDEFECT': 'Sportello della casella di posta difettoso',
  'FORM.REPAIR.MAILBOX.LOCKDEFECT': 'Serratura difettosa',
  'FORM.REPAIR.MAILBOX.LOOSE': 'Allentato, traballante',
  'FORM.REPAIR.MAILBOX.LOSTKEY': 'Chiave persa',
  'FORM.REPAIR.MAILBOX.TAGCHANGE': 'Cambio etichetta nome',
  'FORM.REPAIR.MAILBOX.TAGMISSING': 'Etichetta del nome mancante',
  'FORM.REPAIR.MOIST': 'Umido',
  'FORM.REPAIR.MOLD': 'Muffa, fungo',
  'FORM.REPAIR.MULTIMEDIA': 'Servizio multimediale (TV, Internet, fibra ottica, ...)',
  'FORM.REPAIR.MULTIMEDIA.FIBERFAIL': 'Connessione in fibra ottica non riuscita',
  'FORM.REPAIR.MULTIMEDIA.SEALCONNECTION': 'Sigillatura del cavo di connessione',
  'FORM.REPAIR.MULTIMEDIA.UNSEALCONNECTION': 'Scollegamento del cavo connessione',
  'FORM.REPAIR.NURSERY': 'Stanza dei bambini',
  'FORM.REPAIR.OFFICE': 'Ufficio',
  'FORM.REPAIR.OTHER': 'Altri danni',
  'FORM.REPAIR.OUTSIDEFLOOR': 'Pavimento esterno',
  'FORM.REPAIR.OUTSIDEFLOOR.BIGJOINTS': 'Giunti troppo grandi',
  'FORM.REPAIR.OUTSIDEFLOOR.BROKEN': 'Rotto, mancante',
  'FORM.REPAIR.OUTSIDEFLOOR.DIRTY': 'Decolorato, macchiato, sporco',
  'FORM.REPAIR.OUTSIDEFLOOR.LOOSE': 'Allentato',
  'FORM.REPAIR.OUTSIDEFLOOR.UNEVEN': 'Superficie irregolare',
  'FORM.REPAIR.OUTSIDEFLOOR.WEEDSINJOINTS': 'Erbacce nelle fughe',
  'FORM.REPAIR.PAINTING': 'Verniciatura',
  'FORM.REPAIR.PAINTING.DURATION.TITLE': "Quando è stata l'ultima volta che ha dipinto?",
  'FORM.REPAIR.PAINTING.MINUSFIVE': 'Meno di 5 anni',
  'FORM.REPAIR.PAINTING.NONSMOKERS': 'Non fumatori',
  'FORM.REPAIR.PAINTING.PLUSFIVE': 'Più vecchio di 5 anni',
  'FORM.REPAIR.PAINTING.SMOKERS': 'Fumatori',
  'FORM.REPAIR.PAINTING.SMOKING.TITLE': 'Ha fumato?',
  'FORM.REPAIR.PARKING': 'Parcheggio',
  'FORM.REPAIR.PARKING.TIREBOX': 'Armadietto per pneumatici, box per pneumatici',
  'FORM.REPAIR.PARKING.TIREBOX.LOOSE': 'Allentato, traballante',
  'FORM.REPAIR.PARKING.TIREBOX.LOCKDEFECT': 'Serratura difettosa',
  'FORM.REPAIR.PARKING.TIREBOX.MISSINGKEY': 'Chiave mancante',
  'FORM.REPAIR.PARKING.TIREBOX.BOXDEFECT': 'Box difettoso',
  'FORM.REPAIR.PLAYGROUND': 'Parco giochi',
  'FORM.REPAIR.PLAYGROUND.EQUIPMENT': 'Attrezzatura del parco giochi',
  'FORM.REPAIR.PLAYGROUND.EQUIPMENT.FRAMEDEFECT': 'Telaio di arrampicata difettoso',
  'FORM.REPAIR.PLAYGROUND.EQUIPMENT.OTHERDEFECT': 'Altre attrezzature da gioco difettose',
  'FORM.REPAIR.PLAYGROUND.EQUIPMENT.SANDPITDEFECT': 'Sabbiera difettosa',
  'FORM.REPAIR.PLAYGROUND.EQUIPMENT.SLIDEDEFECT': 'Scivolo difettoso',
  'FORM.REPAIR.PESTS': 'Parassiti',
  'FORM.REPAIR.PESTS.ANTS': 'Formiche',
  'FORM.REPAIR.PESTS.BEDBUGS': 'Cimici dei letti',
  'FORM.REPAIR.PESTS.BIRDS': 'Piccioni / passeri / uccelli',
  'FORM.REPAIR.PESTS.COCKROACHES': 'Scarafaggi',
  'FORM.REPAIR.PESTS.MICE': 'Topi / Ratti',
  'FORM.REPAIR.PESTS.OTHER': 'Altri parassiti',
  'FORM.REPAIR.PESTS.SILVERFISH': "Pesce d'argento",
  'FORM.REPAIR.PESTS.WASPS': 'Vespe',
  'FORM.REPAIR.RAILINGS': 'Ringhiere',
  'FORM.REPAIR.RAILINGS.BROKEN': 'Rotto',
  'FORM.REPAIR.RAILINGS.LOOSE': 'Allentato, traballante',
  'FORM.REPAIR.RAILINGS.RUSTY': 'Arrugginito',
  'FORM.REPAIR.RAILINGS.STACKSDEFECT': 'Pila difettosa',
  'FORM.REPAIR.SIDEWALK': 'Marciapiede',
  'FORM.REPAIR.SINGLEGARAGE': 'Garage singolo',
  'FORM.REPAIR.SHADES': 'Ombreggiatura e oscuramento',
  'FORM.REPAIR.SHADES.BLINDS': 'Persiane',
  'FORM.REPAIR.SHADES.BLINDS.BRACKETBROKEN': 'Ritenzione rotta',
  'FORM.REPAIR.SHADES.BLINDS.GRINDS': 'Graffi, è acceso',
  'FORM.REPAIR.SHADES.BLINDS.HANGSDOWN': 'Appeso',
  'FORM.REPAIR.SHADES.BLINDS.HINGEDEFECT': 'Cerniera difettosa',
  'FORM.REPAIR.SHADES.BLINDS.HOLDERDEFECT': 'Supporto difettoso',
  'FORM.REPAIR.SHADES.BLINDS.JAMS': 'Inceppato',
  'FORM.REPAIR.SHADES.BLINDS.LOOSE': 'Allentato, oscilla',
  'FORM.REPAIR.SHADES.BLINDS.REMAINSOPEN': 'Non si chiude',
  'FORM.REPAIR.SHADES.BLINDS.SLATSDEFECT': 'Lamelle difettose',
  'FORM.REPAIR.SHADES.BLINDS.WEATHERED': 'Intemperie',
  'FORM.REPAIR.SHADES.CANOPEN': 'Sì, le finestre possono essere aperte',
  'FORM.REPAIR.SHADES.CANTOPEN': 'No, le finestre non possono essere aperte',
  'FORM.REPAIR.SHADES.CRANK': 'Manovella',
  'FORM.REPAIR.SHADES.CONTROLLINGQUANTITY.TITLE': "Quante tende vengono controllate contemporaneamente con l'interruttore?",
  'FORM.REPAIR.SHADES.CRANKJOINT.TITLE': 'Il giunto a manovella è intatto?',
  'FORM.REPAIR.SHADES.CRANKTURN.TITLE': 'La manovella gira?',
  'FORM.REPAIR.SHADES.CROOKED': 'Pende storto',
  'FORM.REPAIR.SHADES.CURTAINRAIL': 'Binario della tenda',
  'FORM.REPAIR.SHADES.CURTAINRAIL.MISSING': 'Mancante',
  'FORM.REPAIR.SHADES.CURTAINRAIL.RIPPED': 'Strappato',
  'FORM.REPAIR.SHADES.CURTAINRAIL.STUCK': 'Aliante, la tenda non scorre',
  'FORM.REPAIR.SHADES.DETACHED': 'Fuori dalla guida',
  'FORM.REPAIR.SHADES.ELECTRICALREMOTE': 'Elettrico (telecomando)',
  'FORM.REPAIR.SHADES.ELECTRICALREMOTE.BATTERIES.TITLE': 'Avete provato le nuove batterie per il telecomando?',
  'FORM.REPAIR.SHADES.ELECTRICALREMOTE.NEWBATTERIES': 'New',
  'FORM.REPAIR.SHADES.ELECTRICALREMOTE.OLDBATTERIES': 'Old',
  'FORM.REPAIR.SHADES.ELECTRICALWALL': 'Elettrico (interruttore a parete)',
  'FORM.REPAIR.SHADES.ENGINESOUND': 'Sì, un motore rumore',
  'FORM.REPAIR.SHADES.FABRICBLINDS': 'Tende in tessuto',
  'FORM.REPAIR.SHADES.FABRICBLINDS.DEFECT': 'Asta in tessuto difettosa',
  'FORM.REPAIR.SHADES.FABRICBLINDS.KIND.TITLE': 'Che tipo di tenda in tessuto è?',
  'FORM.REPAIR.SHADES.FABRICBLINDS.RIPPED': 'Tessuto strappato o allentato',
  'FORM.REPAIR.SHADES.FABRICBLINDS.UNROLLS': 'Il tessuto si stacca, le tende non si chiudono',
  'FORM.REPAIR.SHADES.HAILDAMAGE': 'Danno da grandine',
  'FORM.REPAIR.SHADES.HANDCRANK': 'Manovella',
  'FORM.REPAIR.SHADES.HANDCRANK.NOTOKAY': 'Non ok',
  'FORM.REPAIR.SHADES.HANDCRANK.NOTTURN': 'Non gira',
  'FORM.REPAIR.SHADES.HANDCRANK.OKAY': 'OK',
  'FORM.REPAIR.SHADES.HANDCRANK.TURNSEMPTY': 'Gira a vuoto',
  'FORM.REPAIR.SHADES.INSTALLATIONAGE.TITLE': "A quando risale l'installazione?",
  'FORM.REPAIR.SHADES.KIND.TITLE': 'Che tipo di tenda a lamelle è?',
  'FORM.REPAIR.SHADES.LOOSE': 'Guida allentata',
  'FORM.REPAIR.SHADES.METALBLINDS': 'Ganz metall storen',
  'FORM.REPAIR.SHADES.METALBLINDS.KIND.TITLE': 'Che tipo di tenda a lamelle è?',
  'FORM.REPAIR.SHADES.MINUS2YEARS': 'Più giovane di 2 anni',
  'FORM.REPAIR.SHADES.MINUS3M': 'Più stretto di 3 metri',
  'FORM.REPAIR.SHADES.NOISE': 'Fa rumore',
  'FORM.REPAIR.SHADES.NOSOUND': 'No, nessun rumore del motore',
  'FORM.REPAIR.SHADES.NOTPULLDOWNUP': 'Non va più su/giù',
  'FORM.REPAIR.SHADES.NOTSHUTOFF': 'Non può più essere spento',
  'FORM.REPAIR.SHADES.NOTTURN': 'Non si inclina più',
  'FORM.REPAIR.SHADES.ONE': '1',
  'FORM.REPAIR.SHADES.PLUS2YEARS': 'Più vecchio di 2 anni',
  'FORM.REPAIR.SHADES.PLUS3M': 'Più largo di 3 m',
  'FORM.REPAIR.SHADES.QUANTITY.TITLE': 'Quante tende sono interessate?',
  'FORM.REPAIR.SHADES.SEVERAL': 'Multiplo',
  'FORM.REPAIR.SHADES.SHUTTER': 'Tapparella',
  'FORM.REPAIR.SHADES.SHUTTER.BELT': 'Cinghia per tapparelle',
  'FORM.REPAIR.SHADES.SHUTTER.COVER': 'Coperchio tapparella',
  'FORM.REPAIR.SHADES.SHUTTER.FALLSDOWN': 'Non tiene, cade',
  'FORM.REPAIR.SHADES.SHUTTER.NOTFULLYUP': 'Non si solleva completamente',
  'FORM.REPAIR.SHADES.SHUTTER.REMAINSOPEN': 'Non si chiude completamente',
  'FORM.REPAIR.SHADES.SHUTTER.STUCK': 'Bloccato',
  'FORM.REPAIR.SHADES.SLATBLINDS': 'Lamelle tende',
  'FORM.REPAIR.SHADES.SLATBLINDS.CORDTORN': 'Cordone strappato',
  'FORM.REPAIR.SHADES.SOUND.TITLE': "Quando si aziona l'interruttore, si sente un suono?",
  'FORM.REPAIR.SHADES.SQUEAKING': 'Sì, stridio o cigolio',
  'FORM.REPAIR.SHADES.TWO': '2',
  'FORM.REPAIR.SHADES.WIDTHSTOREN.TITLE': 'Quanto è larga la tenda?',
  'FORM.REPAIR.SHADES.WINDOWSOPEN.TITLE': 'È possibile aprire le finestre?',
  'FORM.REPAIR.SHAFT.BACKWATER': "L'acqua di scarico fuoriesce dallo scarico",
  'FORM.REPAIR.SHAFT.CLOGGED': 'Intasato',
  'FORM.REPAIR.SHAFT.DIRTY': 'Sporco',
  'FORM.REPAIR.SHAFT.LOOSE': 'Oscilla',
  'FORM.REPAIR.SHAFT.SMELLS': 'Puzza',
  'FORM.REPAIR.SHOWROOM': 'Showroom',
  'FORM.REPAIR.SHOWWINDOW': 'Vetrina',
  'FORM.REPAIR.SHOWWINDOW.FRAME': 'Telaio della finestra',
  'FORM.REPAIR.SHOWWINDOW.FRAME.JOINTLEAK': 'Giunto del vetro che perde, si allenta',
  'FORM.REPAIR.SHOWWINDOW.FRAME.LEAK': 'Perdita',
  'FORM.REPAIR.SHOWWINDOW.GLASS': 'Vetro della finestra',
  'FORM.REPAIR.SHOWWINDOW.RAILS': 'Binario della tenda',
  'FORM.REPAIR.SHOWWINDOW.RAILS.MISSING': 'Mancante',
  'FORM.REPAIR.SHOWWINDOW.RAILS.STUCK': 'Binario, la tenda non scorre',
  'FORM.REPAIR.SHOWWINDOW.RAILS.TORN': 'Strappato',
  'FORM.REPAIR.SHOWWINDOW.SHUTTER': 'Tapparella, inferriata',
  'FORM.REPAIR.SHOWWINDOW.SHUTTER.BELT': 'Cinghia per tapparelle',
  'FORM.REPAIR.SHOWWINDOW.SHUTTER.CRANK': 'Tapparella manovella',
  'FORM.REPAIR.SHOWWINDOW.SHUTTER.CRANKBRACKET': 'Tapparella manovella staffa',
  'FORM.REPAIR.SHOWWINDOW.SHUTTER.GRILLES': 'Griglia avvolgibile',
  'FORM.REPAIR.SHOWWINDOW.SHUTTER.LID': 'Tapparella coperchio',
  'FORM.REPAIR.SHOWWINDOW.SHUTTER.ROLLER': 'Tapparella',
  'FORM.REPAIR.SHOWWINDOW.SILL': 'Davanzale della finestra, davanzale',
  'FORM.REPAIR.SMARTCONTROL': 'Controllo intelligente',
  'FORM.REPAIR.SMARTCONTROL.BLINDS': 'Controllo delle tende difettoso',
  'FORM.REPAIR.SMARTCONTROL.DISPLAY': 'Display difettoso (hardware)',
  'FORM.REPAIR.SMARTCONTROL.HEATINGCOOLING': 'Controllo riscaldamento/raffreddamento difettoso',
  'FORM.REPAIR.SMARTCONTROL.OTHER': 'Altro problema',
  'FORM.REPAIR.SMARTCONTROL.USERGUIDE': "Domanda sull'utilizzo",
  'FORM.REPAIR.SMARTCONTROL.VIDEO': 'Sistema videocitofonico difettoso',
  'FORM.REPAIR.SNOWREMOVAL': 'Sgombero neve',
  'FORM.REPAIR.STAIRWELL': 'Scale',
  'FORM.REPAIR.STAIRS': 'Scale',
  'FORM.REPAIR.STAIRS.STEPS': 'Scale, gradini',
  'FORM.REPAIR.STAIRS.STEPS.BROKEN': 'Rotto',
  'FORM.REPAIR.STAIRS.STEPS.LOOSE': 'Sciolto, traballante',
  'FORM.REPAIR.STAIRS.STEPS.WORN': 'Superficie lacerata',
  'FORM.REPAIR.STAIRS.HANDRAIL': 'Corrimano',
  'FORM.REPAIR.STAIRS.HANDRAIL.COATINGLOOSE': 'Rivestimento allentato',
  'FORM.REPAIR.STAIRS.HANDRAIL.LOOSE': 'Allentato, traballante',
  'FORM.REPAIR.STAIRS.HANDRAIL.STACKSDEFECT': 'Pila difettosa',
  'FORM.REPAIR.STAIRS.HANDRAIL.TORN': 'Strappato',
  'FORM.REPAIR.STORAGE': 'Magazzino / Riduzione',
  'FORM.REPAIR.TECHNICALROOM': 'Locale tecnico',
  'FORM.REPAIR.TREATMENTROOM': 'Sala trattamenti',
  'FORM.REPAIR.VENTILATOR': 'Ventola',
  'FORM.REPAIR.VENTILATOR.FILTERDEFECT': 'Filtro difettoso',
  'FORM.REPAIR.VENTILATOR.LOWPERFORMANCE': 'Non estrae più correttamente',
  'FORM.REPAIR.VENTILATOR.NOISE': 'Rumoroso, fischiante',
  'FORM.REPAIR.VENTILATOR.NOTSWITCHINGOFFAUTO': 'Non si spegne automaticamente',
  'FORM.REPAIR.VENTILATOR.NOTSWITCHINGON': 'Non si accende più',
  'FORM.REPAIR.WALL': 'Parete',
  'FORM.REPAIR.WALL.COMESOFF': 'Colori spenti',
  'FORM.REPAIR.WALL.PLASTER': 'Intonaco, intonaco',
  'FORM.REPAIR.WALL.PLASTER.CRUMBLES': 'Si sbriciola',
  'FORM.REPAIR.WALL.STAINS': 'Con macchie',
  'FORM.REPAIR.WALL.WALLPAPER': 'Carta da parati',
  'FORM.REPAIR.WALL.WALLPAPER.DISSOLVING': 'Si stacca',
  'FORM.REPAIR.WALL.YELLOWSPOTS': 'Macchie gialle, muro giallo',
  'FORM.REPAIR.WASHBASIN': 'Lavandino',
  'FORM.REPAIR.WASHBASIN.BASIN': 'Lavabo',
  'FORM.REPAIR.WASHBASIN.BASIN.BACKWATER': "L'acqua di scarico fuoriesce dallo scarico",
  'FORM.REPAIR.WASHBASIN.BASIN.CRACK': 'Crepa',
  'FORM.REPAIR.WASHBASIN.BASIN.HOLE': 'Foro',
  'FORM.REPAIR.WASHBASIN.BASIN.QUIRK': 'Quirk',
  'FORM.REPAIR.WASHBASIN.BASIN.SOAPDISHDEFECT': 'Portasapone difettoso',
  'FORM.REPAIR.WASHBASIN.BASIN.SOAPDISHHOLDERDEFECT': 'Portasapone difettoso',
  'FORM.REPAIR.WASHBASIN.BASIN.TOOTHBRUSHCUPDEFECT': 'Tazza per spazzolino difettosa',
  'FORM.REPAIR.WASHBASIN.BASIN.TOOTHBRUSHCUPHOLDERDEFECT': 'Porta spazzolino cup difettoso',
  'FORM.REPAIR.WASHBASIN.CLOGGED': 'Intasato',
  'FORM.REPAIR.WASHBASIN.DRAIN': 'Scarico, sifone',
  'FORM.REPAIR.WASHBASIN.DRAIN.DRAINSSLOW': 'Scarico lento',
  'FORM.REPAIR.WASHBASIN.DRAIN.NOISE': 'Gorgoglio/rumore',
  'FORM.REPAIR.WASHBASIN.FITTINGS': 'Raccordi',
  'FORM.REPAIR.WASHBASIN.FITTINGS.CLAMPS': 'Morsetti',
  'FORM.REPAIR.WASHBASIN.FITTINGS.DRIPS': 'Gocciolamenti',
  'FORM.REPAIR.WASHBASIN.FITTINGS.MOLDY': 'Ammuffito',
  'FORM.REPAIR.WASHBASIN.FITTINGS.SPLASHES': 'Spruzzi',
  'FORM.REPAIR.WASHBASIN.FITTINGS.STAINS': 'Ha macchie, è appannato',
  'FORM.REPAIR.WASHBASIN.LEAKY': 'Perde',
  'FORM.REPAIR.WASHBASIN.LOOSE': 'Allentato, traballante',
  'FORM.REPAIR.WASHBASIN.NOTCONNECTED': 'Allentato, non collegato',
  'FORM.REPAIR.WASHBASIN.PIPES': 'Tubi di scarico',
  'FORM.REPAIR.WASHBASIN.SMELLS': 'Puzza',
  'FORM.REPAIR.WASHBASIN.SUPPLYLINES': 'Tubi di alimentazione',
  'FORM.REPAIR.WASHBASIN.TOWELRAIL': 'Portasciugamani',
  'FORM.REPAIR.WASHBASIN.TOWELRAIL.BROKEN': 'Rotto',
  'FORM.REPAIR.WASHBASIN.TOWELRAIL.TORN': 'Strappato',
  'FORM.REPAIR.WASHBASIN.TOWELRAILHEATER': 'Scaldasalviette',
  'FORM.REPAIR.WASHBASIN.TOWELRAILHEATER.NOTWARM': 'Non si scalda più',
  'FORM.REPAIR.WASHBASIN.TOWELRAILHEATER.PAINTOFF': 'Il colore si sta staccando',
  'FORM.REPAIR.WASHERDRYER': 'Lavatrice, asciugatrice',
  'FORM.REPAIR.WASHERDRYER.DRYER': 'Asciugatrice',
  'FORM.REPAIR.WASHERDRYER.DRYER.NOISE': 'Rumori insoliti',
  'FORM.REPAIR.WASHERDRYER.DRYER.NOTDRIES': 'Non asciuga più',
  'FORM.REPAIR.WASHERDRYER.HANGER': 'Stendibiancheria',
  'FORM.REPAIR.WASHERDRYER.HANGER.LINELOOSE': 'Appendiabiti allentato',
  'FORM.REPAIR.WASHERDRYER.HANGER.LINETORN': 'Stendibiancheria strappato',
  'FORM.REPAIR.WASHERDRYER.HANGER.LOOSE': 'Attacco traballante, allentato',
  'FORM.REPAIR.WASHERDRYER.LEAKING': 'Perdita: acqua',
  'FORM.REPAIR.WASHERDRYER.NOELECTRICITY': 'Assenza di corrente',
  'FORM.REPAIR.WASHERDRYER.NOTHEATUP': 'Non si riscalda',
  'FORM.REPAIR.WASHERDRYER.SMELLS': 'Puzza',
  'FORM.REPAIR.WASHERDRYER.SWITCHDEFECT': 'Interruttore difettoso',
  'FORM.REPAIR.WASHERDRYER.WASHER': 'Lavatrice',
  'FORM.REPAIR.WASHERDRYER.WASHER.CARDNOTWORKING': 'La scheda di lavaggio non funziona',
  'FORM.REPAIR.WASHERDRYER.WASHER.NOTPUMPOUTWATER': "Non far uscire l'acqua",
  'FORM.REPAIR.WASHERDRYER.WASHER.NOISE': 'Fa rumori insoliti',
  'FORM.REPAIR.WASHERDRYER.WASHER.NOTFLING': 'Non gira più',
  'FORM.REPAIR.WASHERDRYER.WASHER.NOOPENS': 'Non si apre più',
  'FORM.REPAIR.WASTE': 'Cestino dei rifiuti',
  'FORM.REPAIR.WASTE.CROWDED': 'Sovraccarico, non svuotato',
  'FORM.REPAIR.WASTE.DEFECT': 'Cestino dei rifiuti difettoso',
  'FORM.REPAIR.WASTEDUMP': 'Discarica',
  'FORM.REPAIR.WATERDAMAGE': 'Danni da acqua, muffa, macchie',
  'FORM.REPAIR.WATERDAMAGE.5CHFCOIN': 'Circa la dimensione di una moneta da cinque franchi ',
  'FORM.REPAIR.WATERDAMAGE.BULGES': 'Sollevamenti dal fondo',
  'FORM.REPAIR.WATERDAMAGE.CEILING': 'Soffitto',
  'FORM.REPAIR.WATERDAMAGE.CEILINGPITCHEDROOF': 'Soffitto con tetto inclinato',
  'FORM.REPAIR.WATERDAMAGE.CEILINGWITHSKYLIGHT': 'Soffitto: vicino a un lucernario',
  'FORM.REPAIR.WATERDAMAGE.DOUBLEDORMORE': 'Raddoppiato o ancora più grande',
  'FORM.REPAIR.WATERDAMAGE.DROPPING': 'Acqua che gocciola sulla parete o sul soffitto',
  'FORM.REPAIR.WATERDAMAGE.DURATION': 'Da quando il danno è visibile?',
  'FORM.REPAIR.WATERDAMAGE.FLOODING': "Pozza d'acqua o allagamento",
  'FORM.REPAIR.WATERDAMAGE.JOINTSMOLD': 'Giunti del pavimento ammuffiti',
  'FORM.REPAIR.WATERDAMAGE.LARGERNOTDOUBLED': 'Più grande, ma non raddoppiato',
  'FORM.REPAIR.WATERDAMAGE.LOWERHALF': 'Muro: metà inferiore',
  'FORM.REPAIR.WATERDAMAGE.MINUS24HOURS': 'Nelle ultime 24 ore',
  'FORM.REPAIR.WATERDAMAGE.MINUS5CHFCOIN': 'Meno di un pezzo da cinque franchi',
  'FORM.REPAIR.WATERDAMAGE.MINUS7DAYS': 'Negli ultimi 7 giorni',
  'FORM.REPAIR.WATERDAMAGE.MOLD': 'Muffa su parete o soffitto',
  'FORM.REPAIR.WATERDAMAGE.NODROPPING': 'No',
  'FORM.REPAIR.WATERDAMAGE.NOSIZECHANGE': 'Non si nota',
  'FORM.REPAIR.WATERDAMAGE.PLUS5CHFCOIN': 'Notevolmente più grande di una moneta da cinque franchi',
  'FORM.REPAIR.WATERDAMAGE.PLUS7DAYS': 'Già più lungo',
  'FORM.REPAIR.WATERDAMAGE.SIZE': 'Quanto è grande il danno?',
  'FORM.REPAIR.WATERDAMAGE.SIZECHANGE': 'Come è cambiata la dimensione nelle 24 ore?',
  'FORM.REPAIR.WATERDAMAGE.UPPERHALF': 'Muro: metà superiore',
  'FORM.REPAIR.WATERDAMAGE.VISIBILITY': 'Le gocce si vedono?',
  'FORM.REPAIR.WATERDAMAGE.WALLCEILING': 'Parete e soffitto',
  'FORM.REPAIR.WATERDAMAGE.WALLCEILINGFLOOR': 'Parete, soffitto e pavimento',
  'FORM.REPAIR.WATERDAMAGE.WHERE': 'Dove si trova il danno?',
  'FORM.REPAIR.WATERDAMAGE.WINDOW': 'Muro: in corrispondenza di una finestra',
  'FORM.REPAIR.WATERDAMAGE.WINDOWMOLD': 'Muffa sulla finestra',
  'FORM.REPAIR.WATERDAMAGE.WINDOWSAFFECTED': 'Quante finestre sono interessate?',
  'FORM.REPAIR.WATERDAMAGE.YELLOWSTAINS': 'Macchie gialle sulla parete o sul soffitto',
  'FORM.REPAIR.WATERDAMAGE.YESDROPPING': 'Sì',
  'FORM.REPAIR.WATERSTAINS': "Macchie d'acqua",
  'FORM.REPAIR.WATERWASTE': 'Canale di drenaggio (esterno)',
  'FORM.REPAIR.WHOLE': 'Intera superficie',
  'FORM.REPAIR.WINDOW': 'Finestra',
  'FORM.REPAIR.WINDOW.CASEMENT': 'Telaio a battente',
  'FORM.REPAIR.WINDOW.FRAME': 'Telaio della finestra',
  'FORM.REPAIR.WINDOW.FRAME.BROKEN': 'Rotto',
  'FORM.REPAIR.WINDOW.FRAME.MOLD': 'Muffa',
  'FORM.REPAIR.WINDOW.FRAME.ROTTEN': 'Marcio / arrugginito',
  'FORM.REPAIR.WINDOW.GLASS': 'Vetro della finestra',
  'FORM.REPAIR.WINDOW.GLASS.BLIND': 'Cieco',
  'FORM.REPAIR.WINDOW.GLASS.BROKEN': 'Rotto',
  'FORM.REPAIR.WINDOW.GLASS.CONDENSATION': 'Condensa sul vetro della finestra',
  'FORM.REPAIR.WINDOW.GLASS.CONDENSATIONBETWEEN': 'Condensa tra i vetri',
  'FORM.REPAIR.WINDOW.GLASS.CATFLAPDEFECT': 'Porta per gatti difettosa',
  'FORM.REPAIR.WINDOW.GLASS.CATFLAPINSTALL': 'Installazione porta gatto',
  'FORM.REPAIR.WINDOW.GLASS.CATFLAPREMOVE': 'Rimuovere la porta per gatti',
  'FORM.REPAIR.WINDOW.GLASS.CRACKED': 'Incrinato',
  'FORM.REPAIR.WINDOW.GLASS.SMASHED': 'Sfondato',
  'FORM.REPAIR.WINDOW.GLASS.STONECHIP': 'Scheggia di pietra',
  'FORM.REPAIR.WINDOW.HANDLE': 'Maniglia della finestra',
  'FORM.REPAIR.WINDOW.HANDLE.COVERMISSING': 'Coperchio della maniglia difettoso, mancante',
  'FORM.REPAIR.WINDOW.HANDLE.FALLEN': 'Caduta',
  'FORM.REPAIR.WINDOW.HANDLE.HARDTURN': 'Gira forte',
  'FORM.REPAIR.WINDOW.HANDLE.IDLE': 'Gira a vuoto',
  'FORM.REPAIR.WINDOW.HANDLE.JAMS': 'Si inceppa',
  'FORM.REPAIR.WINDOW.HANDLE.KEYBROKEN': 'Chiave cancellata',
  'FORM.REPAIR.WINDOW.HANDLE.KEYMISSING': 'Chiave mancante',
  'FORM.REPAIR.WINDOW.HANDLE.LOOSE': 'Allentato, traballante',
  'FORM.REPAIR.WINDOW.HANDLE.STAINS': 'Macchie, appannamento',
  'FORM.REPAIR.WINDOW.HANDLE.VERTICALTURN': 'Non ruota verticalmente',
  'FORM.REPAIR.WINDOW.JOINTMOLD': 'Giunto di vetro ammuffito',
  'FORM.REPAIR.WINDOW.LEAKING': 'Perdita',
  'FORM.REPAIR.WINDOW.LEAKING.CONDENSATION': 'Condensa',
  'FORM.REPAIR.WINDOW.LEAKING.DRAFTS': 'Perdita di spifferi',
  'FORM.REPAIR.WINDOW.LEAKING.HEAVYRAIN': 'Perdite in caso di pioggia intensa',
  'FORM.REPAIR.WINDOW.LEAKING.SEALDEFECT': 'Guarnizione di gomma difettosa',
  'FORM.REPAIR.WINDOW.OPENINGCLOSING': 'Apertura e chiusura',
  'FORM.REPAIR.WINDOW.OPENINGCLOSING.AUTOMATICDEFECT': 'Funzionamento automatico difettoso',
  'FORM.REPAIR.WINDOW.OPENINGCLOSING.BROKEN': 'Apertura rotta',
  'FORM.REPAIR.WINDOW.OPENINGCLOSING.GRINDS': 'Graffio, arresto',
  'FORM.REPAIR.WINDOW.OPENINGCLOSING.HUNGOUT': 'Sganciato',
  'FORM.REPAIR.WINDOW.OPENINGCLOSING.JAMS': 'Inceppato',
  'FORM.REPAIR.WINDOW.OPENINGCLOSING.LIMITER': 'Limitatore di apertura',
  'FORM.REPAIR.WINDOW.OPENINGCLOSING.MECHANISMDEFECT': 'Meccanismo di apertura difettoso',
  'FORM.REPAIR.WINDOW.OPENINGCLOSING.ONLYTILTED': "Possibile solo l'inclinazione",
  'FORM.REPAIR.WINDOW.OPENINGCLOSING.REMAINSOPEN': 'Non si chiude',
  'FORM.REPAIR.WINDOW.OPENINGCLOSING.SECURITY': 'Sicurezza della finestra',
  'FORM.REPAIR.WINDOW.SEAL': 'Sigilla',
  'FORM.REPAIR.WINDOW.SEAL.DEFECT': 'Guarnizione di gomma difettosa',
  'FORM.REPAIR.WINDOW.SEAL.JOINTLEAK': 'Giunto di vetro che perde, si allenta',
  'FORM.REPAIR.WINDOW.SEAL.MISSING': 'Guarnizione in gomma mancante',
  'FORM.REPAIR.WINDOW.SEAL.OVERLAPDEFECT': 'Guarnizione di sovrapposizione difettosa',
  'FORM.REPAIR.WINDOW.SEAL.OVERLAPMOLD': 'Guarnizione di sovrapposizione ammuffita',
  'FORM.REPAIR.WINDOW.SILL': 'Davanzale della finestra, davanzale',
  'FORM.REPAIR.WINDOW.SILL.BROKEN': 'Rotto, incrinato',
  'FORM.REPAIR.WINDOW.SILL.EDGEDEFECT': 'Bordi difettosi',
  'FORM.REPAIR.WINDOW.SILL.LOOSE': 'Allentato, traballante',
  'FORM.REPAIR.WINDOW.SILL.RELEASING': 'Superfici che si dissolvono',
  'FORM.REPAIR.WINDOW.SILL.WATERSTAINS': "Macchie d'acqua",

  'FORM.KEYORDER.DETAILS.TITLE': "Motivo dell'ordine",
  'FORM.KEYORDER.TITLE': "Qual è il motivo dell'ordine?",
  'FORM.KEYORDER.LOST': 'Chiave persa',
  'FORM.KEYORDER.KEY.TITLE': 'Chiave da',
  'FORM.KEYORDER.APARTMENTDOOR': "Porta dell'appartamento",
  'FORM.KEYORDER.BUILDINGENTRANCE': 'Ingresso della casa',
  'FORM.KEYORDER.BASEMENT': 'Seminterrato',
  'FORM.KEYORDER.MAILBOX': 'Cassetta postale',
  'FORM.KEYORDER.OTHER': 'Altra chiave',
  'FORM.KEYORDER.ALL': "Per tutti: porta dell'appartamento, ingresso della casa, cantina, cassetta postale",
  'FORM.KEYORDER.EXTRA': 'Chiave aggiuntiva',
  'FORM.KEYORDER.REPLACE': 'Sostituzione del cilindro e della chiave',
  'FORM.KEYORDER.WASHCARD': 'Nuova scheda di lavaggio',
  'FORM.KEYORDER.SIPA': 'Nuova chiave a bussola SIPA',
  'FORM.KEYORDER.SIPA.TITLE': 'Di che colore è la chiave?',
  'FORM.KEYORDER.SIPA.BLUE': 'Blu',
  'FORM.KEYORDER.SIPA.GREEN': 'Verde',
  'FORM.KEYORDER.SIPA.RED': 'Rosso',
  'FORM.KEYORDER.SIPA.YELLOW': 'Giallo',
  'FORM.KEYORDER.PARKINGTRADITIONAL': 'Nuova tessera di parcheggio',
  'FORM.KEYORDER.PARKINGWIFI': 'Nuova tessera di parcheggio wireless',

  'FORM.NAMEPLATE.TITLE': 'Dove serve una nuova targhetta?',
  'FORM.NAMEPLATE.DETAILS.TITLE': 'Dove è necessaria la targhetta',
  'FORM.NAMEPLATE.DOOROUTSIDE': 'Campanello',
  'FORM.NAMEPLATE.DOORINSIDE': "Porta dell'appartamento",
  'FORM.NAMEPLATE.MAILBOX': 'Cassetta della posta',
  'FORM.NAMEPLATE.ALL': "Per tutti: Campanello, porta dell'appartamento e cassetta della posta",

  'FORM.DOCUMENT.TITLE': 'Di quale documento avete bisogno?',
  'FORM.DOCUMENT.DETAILS.TITLE': 'Documento o modulo',
  'FORM.DOCUMENT.HANDOVER': 'Copia del protocollo di trasferimento degli alloggi',
  'FORM.DOCUMENT.RENTALCONTRACT': 'Copia del contratto di locazione',
  'FORM.DOCUMENT.UTILITYBILL': "Copia dell'estratto conto delle spese di servizio",
  'FORM.DOCUMENT.APPLICATIONPET': 'Richiesta di detenzione di animali domestici',
  'FORM.DOCUMENT.REMODELLING': "Richiesta di miglioramento dell'inquilino",
  'FORM.DOCUMENT.SUBLEASING': 'Richiesta di subaffitto',
  'FORM.DOCUMENT.INSURANCE': 'Prova di assicurazione del fabbricato',
  'FORM.DOCUMENT.OTHER': 'Altro documento',

  'FORM.OTHER.TITLE': 'Qual è la sua richiesta?',
  'FORM.OTHER.DETAILS.TITLE': 'Richiesta',
  'FORM.OTHER.PROPERTY': "Informazioni generali sull'immobile",
  'FORM.OTHER.NAMECHANGE': 'Cambio di nome, matrimonio',
  'FORM.OTHER.EMAILCHANGE': 'Cambio di indirizzo e-mail',
  'FORM.OTHER.BANKACCOUNTCHANGE': 'Modifica delle coordinate bancarie',
  'FORM.OTHER.SUBLEASING': 'Subaffitto',
  'FORM.OTHER.TERMOFNOTICE': 'Periodo di disdetta',
  'FORM.OTHER.OTHER': 'Altro',
};
